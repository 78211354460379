import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderFieldRadio, renderNumber, renderTextArea, SelectField } from '../../../Utils/renderField/renderField';
import { COUNTRIES } from '../../../../../utility/paises';
import CustomTooltip from '../../../Utils/Tooltip';
import { NotificationManager } from "react-notifications";

let ExamenKPIsForm = props => {
    const { handleSubmit } = props;
    const notificacion = ()=> {
        if(!props.valid){
            NotificationManager.error(props.t('¡Por favor, conteste todas las preguntas!'), 'ERROR', 3000)
        } 
    }
    return (
        <form onSubmit={handleSubmit} className="form-validate mb-lg">
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-column flex-md-row flex-1">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">1-{props.t('¿Cuales fueron sus ventas en total del año pasado?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1"  msj={props.t('Ingrese la cantidad en dolares de las ventas del año pasado, puede utilizar una herramienta online para realizar la conversion si maneja otro tipo de moneda')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-md-end justify-content-center align-items-center flex-2">
                        <Field name="pregunta1" prefix="USD " component={renderNumber} placeholder="USD 0.00" decimalScale={2} className="form-control w-100"/>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul-gris-oscuro pl-2">1.1.{props.t('¿Cuáles son sus ventas en lo que va del año?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1"  msj={props.t('Ingrese la cantidad en dolares de las ventas desde el inicio del año hasta este momento, puede utilizar una herramienta online para realizar la conversion si maneja otro tipo de moneda')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-center flex-2">
                        <Field name="pregunta1_a" prefix="USD " component={renderNumber} placeholder="USD 0.00" decimalScale={2} type="text" className="form-control w-100"/>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul-gris-oscuro pl-2">1.2.{props.t('¿Incremento o decremento al mismo periodo del año pasado?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese el porcentaje de incremento o decremento de las ventas, comparando las ventas en lo que va del año con las ventas del año pasado en el mismo periodo')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-center flex-2">
                        <Field name="pregunta1_b" prefix="% " component={renderNumber} placeholder="% 0.00" decimalScale={2} type="text" className="form-control w-100"/>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul-gris-oscuro pl-2">1.3.{props.t('¿Espera sobrepasar los ingresos del año pasado?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si considera sobrepasar los ingresos del año pasado')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('Sí')}
                                className="oso"
                                type="radio"
                                value={true}
                                name="pregunta1_c"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta1_c"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta1_c) && (
                    <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                        <div className="d-flex align-items-md-center flex-3">
                            <span className="text-sm azul-gris-oscuro pl-4">1.3.1.{props.t('De ser así, ¿por cuánto aproximadamente?')} </span>
                            <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1"  msj={props.t('Ingrese el porcentaje de incremento en los ingresos que considera obtener este año')+'.'}/>
                        </div>
                        <div className="d-flex justify-content-end align-items-center flex-2">
                            <Field name="pregunta1_c_i" prefix="% " component={renderNumber} placeholder="% 0.00" decimalScale={2} type="text" className="form-control w-100"/>
                        </div>
                    </div>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">2-{props.t('¿Cuantos empleados de tiempo completo tiene?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese la cantidad de empleados de tiempo completo que trabajan en su empresa')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-center flex-2">
                        <Field name="pregunta2" component={renderNumber} type="text" className="form-control w-100"/>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul-gris-oscuro pl-2">2.1.{props.t('¿Ha hecho nuevas contrataciones en el ultimo trimestre?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si ha realizado nuevas contrataciones en el ultimo trimestre')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('Sí')}
                                type="radio"
                                value={true}
                                name="pregunta2_a"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta2_a"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta2_a) && (
                    <Fragment>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4">2.1.1.{props.t('De ser así, ¿Cuántas?')} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese la cantidad de las contrataciones que ha realizado en el ultimo trimestre')+'.'}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2">
                                <Field name="pregunta2_a_i"  component={renderNumber} type="text" className="form-control w-100"/>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4">2.1.2.{props.t('Tipo de trabajo / roles')} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese cada uno de los roles de las personas que ha contratado separados de una coma. ejemplo; contadores, agente de ventas, agentes de publicidad')+'.'}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2">
                                <Field name="pregunta2_a_ii" component={renderField} type="text" className="form-control w-100"/>
                            </div>
                        </div>
                    </Fragment>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">3-{props.t('¿Obtuvo nuevo financiamiento ?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si ha obtenido un nuevo financiamiento por ejemplo, préstamos, líneas de crédito, etc')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('Sí')}
                                type="radio"
                                value={true}
                                name="pregunta3"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta3"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta3) && (
                    <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                        <div className="d-flex align-items-md-center flex-3">
                            <span className="text-sm azul-gris-oscuro pl-2">3.1.{props.t('De ser así, ¿por cuánto?')} </span>
                            <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese la cantidad obtenida en el financiamiento o la suma de los financiamientos obtenidos son mas de uno')+'.'}/>
                        </div>
                        <div className="d-flex justify-content-end align-items-center flex-2">
                            <Field name="pregunta3_a" prefix="USD " placeholder="USD 0.00" component={renderNumber} decimalScale={2} type="text" className="form-control w-100"/>
                        </div>
                    </div>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">4-{props.t('¿Ha expandido su capacidad ( por ejemplo, capacidad de manufactura, edificio o puntos de venta?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si ha crecido en las areas de ventas, manufactura, edificios y otros')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('Sí')}
                                type="radio"
                                value={true}
                                name="pregunta4"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta4"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta4) && (
                    <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                        <div className="d-flex align-items-md-center flex-3">
                            <span className="text-sm azul-gris-oscuro pl-2">4.1.{props.t('Por favor, describa')} </span>
                            <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Describa cada una de las areas donde ha tenido una expansion de capacidad en el ultimo trimestre')+'.'}/>
                        </div>
                        <div className="d-flex justify-content-end align-items-center flex-2">
                            <Field name="pregunta4_a" component={renderTextArea} type="text" className="form-control w-100"/>
                        </div>
                    </div>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">5-{props.t('¿Ha vendido a clientes en otros estados/regiones de su país?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si le ha vendido a clientes de otros estados, regiones o departamentos dentro de su país en lo que va del año')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('Sí')}
                                type="radio"
                                value={true}
                                name="pregunta7"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta7"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta7) && (
                    <Fragment>
                        <div className="d-flex flex-row flex-1 mt-3">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-2">5.1.{props.t('De ser así, porfavor indique')} </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4">5.1.1.{props.t('Estados/regiones')+'.'} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese cada uno de los estados, regiones o departamentos donde realizado las ventas en lo que va del año, separadas por una coma, ejemplo; San Marcos, Quetzaltenango')+'.'}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2">
                                <Field name="pregunta7_i" component={renderTextArea} type="text" className="form-control w-100"/>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4">5.1.2.{props.t('Total aproximado de ingresos por ventas a otros estados o regiones en lo que va del año')+'.'} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese el total de las ventas realizadas a otros estados, regiones o departamentos (que no sean en suyo) en lo que va del año')+'.'}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2">
                                <Field name="pregunta7_ii" prefix="USD " placeholder="USD 0.00" component={renderNumber} decimalScale={2} type="text" className="form-control w-100"/>
                            </div>
                        </div>
                    </Fragment>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">6-{props.t('¿Ha exportado a otros países?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si a realizado exportaciones a otros países en lo que va del año')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('Sí')}
                                type="radio"
                                value={true}
                                name="pregunta8"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding pt-2 pt-md-0 ">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta8"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta8) && (
                    <Fragment>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro bold pl-2">6.1.{props.t('De ser así, porfavor indique')} </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4">6.1.1.{props.t('Países (todos los que apliquen)')+'.'} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Seleccione todos los países a los que ha realizado exportaciones en lo que va del año')+'.'}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2 select-kpis">
                                <Field
                                    name="pregunta8_i"
                                    className="w-100"
                                    isMulti
                                    valueKey="id"
                                    labelKey="name"
                                    options={COUNTRIES}
                                    component={SelectField}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4">6.1.2.{props.t('Total aproximado de ingresos de exportación en lo que va del año')+'.'} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese la cantidad en dolares del total de ventas en exportaciones a otros países en lo que va del año, puede utilizar una herramienta online para realizar la conversion si maneja otro tipo de moneda')+'.'}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2">
                                <Field name="pregunta8_ii" prefix="USD " placeholder="USD 0.00" component={renderNumber} decimalScale={2} type="text" className="form-control w-100"/>
                            </div>
                        </div>
                    </Fragment>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">7-{props.t('¿Cuánto fueron sus impuestos federales o nacionales (IVA si aplica), pagados en el anterior año calendario?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese la cantidad en dolares del total de los impuestos federales o nacionales pagados el año pasado por su empresa')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <Field name="pregunta9" prefix="USD " placeholder="USD 0.00" component={renderNumber} decimalScale={2} type="text" className="form-control w-100"/>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul-gris-oscuro pl-2">7.1.{props.t('¿Cuánto son sus impuestos federales o nacionales en lo que va del año actual?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese la cantidad en dolares del total de los impuestos federales o nacionales pagados en lo que va del año por su empresa')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-center flex-2">
                        <Field name="pregunta9_a" prefix="USD " component={renderNumber} placeholder="USD 0.00" decimalScale={2} type="text" className="form-control w-100" />
                    </div>
                </div>


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">8-{props.t('¿Cúanto son sus impuestos estatales o regionales pagados en el anterior año calendario (si aplica)?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('(Si en su país aplica) Ingrese la cantidad en dolares del total de los impuestos estatales o regionales pagados el año pasado por su empresa')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <Field name="pregunta10" prefix="USD " placeholder="USD 0.00" component={renderNumber} decimalScale={2} type="text" className="form-control w-100"/>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row flex-1 mt-3">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul-gris-oscuro pl-2">8.1.{props.t('¿Cuánto son sus impuestos estatales o regionales en lo que va del año actual (si aplica)?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('(Si en su país aplica) Ingrese la cantidad en dolares del total de los impuestos estatales o regionales pagados en lo que va del año por su empresa')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-center flex-2">
                        <Field name="pregunta10_a" prefix="USD " component={renderNumber} placeholder="USD 0.00" decimalScale={2} type="text" className="form-control w-100" />
                    </div>
                </div>

                <div className="d-none d-lg-flex buttons-box mt-5">
                    <button type="submit" onClick={notificacion} className="btn btn-primary">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-5">
                    <button type="submit" onClick={notificacion} className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )

}

const selector = formValueSelector('ExamenKPIsForm');

ExamenKPIsForm = reduxForm({
    form: 'ExamenKPIsForm',
    asyncBlurFields: [],
    initialValues: {
        pregunta1_c: false,
        pregunta2_a: false,
        pregunta3: false,
        pregunta4: false,
        pregunta5: false,
        pregunta6: false,
        pregunta7: false,
        pregunta8: false,
        pregunta10: 0,
        pregunta10_a: 0,
        unico: true,
    },
    validate: (data, props) => {
        return validate(data, {
            'pregunta1': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta1_a': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta1_b': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta1_c_i': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta2': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta2_a_i': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta2_a_ii': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta3_a': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta4_a': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta7_i': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta7_ii': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta8_i': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta9': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta9_a': validators.exists()(`${props.t('Campo requerido')}`),
            // 'pregunta10': validators.exists()(`${props.t('Campo requerido')}`),
            // 'pregunta10_a': validators.exists()(`${props.t('Campo requerido')}`),
        })
    },
})(ExamenKPIsForm);

const mstp = state => {
    const pregunta1_c = selector(state, 'pregunta1_c');
    const pregunta2_a = selector(state, 'pregunta2_a');
    const pregunta3 = selector(state, 'pregunta3');
    const pregunta4 = selector(state, 'pregunta4');
    const pregunta5 = selector(state, 'pregunta5');
    const pregunta6 = selector(state, 'pregunta6');
    const pregunta7 = selector(state, 'pregunta7');
    const pregunta8 = selector(state, 'pregunta8');

    return {
        pregunta1_c,
        pregunta2_a,
        pregunta3,
        pregunta4,
        pregunta5,
        pregunta6,
        pregunta7,
        pregunta8,
    }
};

export default connect(mstp, null)(ExamenKPIsForm);
