import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector} from 'redux-form';
import { renderCountrySelect, renderDatePicker3, renderField, renderFieldRadio, RenderImageUpload,
    renderTextArea, SelectField, renderFieldCheck, TimeZoneSelectField } from '../../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';
import { ADMIN, GROWTH_ANGEL, HOMBRE, ME_GUSTA, MUJER, OTRO, PARTNER, SPONSOR, TIPO_IDIOMAS, USUARIO } from '../../../../../utility/constants';
import { connect } from 'react-redux';

const validators = (values, props) => {
    const errors = {};
    if (!values.nombres) {
        errors.nombres = `${props.t('Campo requerido')}`;;
    }
    if (!values.email) {
        errors.email = `${props.t('Campo requerido')}`;;
    }
    if (!values.cargo) {
        errors.cargo = `${props.t('Campo requerido')}`;;
    }
    if (!values.pais) {
        errors.pais = `${props.t('Campo requerido')}`;;
    }
    if (!values.fecha_nacimiento) {
        errors.fecha_nacimiento = `${props.t('Campo requerido')}`;;
    }
    if (!values.genero) {
        errors.genero = `${props.t('Campo requerido')}`;;
    }
    if (!values.timezone) {
        errors.timezone = `${props.t('Campo requerido')}`;;
    }
    if (values.new_password && !values.verificar_new_password) {
        errors.verificar_new_password = `${props.t('Campo requerido')}`;
    }
    if (values.new_password && String(values.new_password).length < 8) {
        errors.new_password = `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`;
    }
    if (values.verificar_new_password && String(values.verificar_new_password).length < 8) {
        errors.verificar_new_password = `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`;
    }
    if (values.new_password && values.verificar_new_password) {
        if (values.new_password !== values.verificar_new_password) {
            errors.verificar_new_password = `${props.t('Las contraseñas no coinciden')}`;
        }
    }
    return errors;
}

let ColaboradorEditarForm = (props) => {
    const { handleSubmit, item, me } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    {item.avatar && (
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="imagen" className="m-0">{props.t('Avatar actual')}</label>
                            <img src={item.avatar} alt="fotografia" className="img-fluid" style={{ width: "10rem", marginTop: "7px" }} />
                        </div>
                    )}
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="imagen" className="m-0">{props.t('Nuevo avatar')}</label>
                        <RenderImageUpload aspect={1} multiple={false} setFile={props.setAvatar} />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombres" className="m-0">{props.t('Nombre')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nombres" component={renderField} type="text" className="form-control" />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="email" className="m-0">{props.t('Correo')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="email" component={renderField} type="email" className="form-control" />
                    </div>
                </div>
                <div className='d-flex flex-lg-row flex-column mt-2' >
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor='colider' className='m-0'>{props.t('Co-lider')}</label>
                            <Field name="colider" component={renderFieldCheck} label={ props.colider?props.t('Si'): props.t('No')} />
                        </div>
                        <div className='d-flex d-none d-lg-flex' />
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="telefono" className="m-0">{props.t('Teléfono')}</label>
                        <Field name="telefono" component={renderField} type="text" className="form-control" />
                    </div>
                    <div className="d-flex flex-column flex-1 justify-content-end p-2">
                        <Field
                            name="tiene_whats"
                            label={props.t("Tiene what's app")}
                            component={renderFieldCheck}
                        />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="fecha_nacimiento" className="m-0">{props.t('Fecha de nacimiento')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <Field name="fecha_nacimiento" component={renderDatePicker3} noFuturos={true} />
                    </div>
                    {(me.tipo_usuario == USUARIO) && (
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="cargo" className="m-0">{props.t('Puesto')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                            <Field name="cargo" component={renderField} type="text" className="form-control" />
                        </div>
                    )}
                    {(me.tipo_usuario == GROWTH_ANGEL) && (
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="pais">{props.t('País')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                            <Field name="pais" label="País" component={renderCountrySelect} type="text" />
                        </div>
                    )}
                    {(me.tipo_usuario == ADMIN) && (
                        <div className="d-flex flex-column flex-1 p-2"></div>
                    )}
                    {(me.tipo_usuario ===SPONSOR || me.tipo_usuario===PARTNER )&&(
                        <div className='d-none d-md-flex flex-1' />
                    )}
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="timezone" className="m-0">{props.t('Zona horaria')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="timezone" component={TimeZoneSelectField} />
                    </div>
                    <div className="d-none d-md-flex flex-1 p-2" ></div>
                </div>
                {(me.tipo_usuario == USUARIO || me.tipo_usuario == GROWTH_ANGEL) && (
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2 px-5">
                            <label htmlFor="genero" className="m-0">{props.t('Género')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                            <div className="d-flex flex-row align-items-end mt-2">
                                <div className="flex-1">
                                    <Field
                                        label={props.t('Hombre')}
                                        type="radio"
                                        value={HOMBRE}
                                        name="genero"
                                        parse={val => +val}
                                        component={renderFieldRadio}
                                    />
                                </div>
                                <div className="flex-1">
                                    <Field
                                        label={props.t('Mujer')}
                                        type="radio"
                                        value={MUJER}
                                        name="genero"
                                        parse={val => +val}
                                        component={renderFieldRadio}
                                    />
                                </div>
                                <div className="flex-1">
                                    <Field
                                        label={props.t('No especificar')}
                                        type="radio"
                                        value={OTRO}
                                        name="genero"
                                        parse={val => +val}
                                        component={renderFieldRadio}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {(me.tipo_usuario == GROWTH_ANGEL) && (
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="idiomas">{props.t('Idiomas')} <span className="gris m-0 text-xsm">{props.t('(Selecciona todos los que hablas con fluidez)')}</span></label>
                            <Field name="idiomas" label="Idiomas" component={SelectField} isMulti options={TIPO_IDIOMAS} />
                        </div>
                        <div className="flex-1"></div>
                    </div>
                )}
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="descripcion" className="m-0">{props.t('Biografía')}</label>
                        <Field name="descripcion" component={renderTextArea} type="text" className="form-control" />
                    </div>
                </div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <span className="text-sm azul-gris-oscuro bold">{props.t('Nueva contraseña')}</span>
                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="new_password" className="m-0">{props.t('Contraseña')}</label>
                        <Field name="new_password" component={renderField} type="password" className="form-control" />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="verificar_new_password" className="m-0">{props.t('Verificar contraseña')}</label>
                        <Field name="verificar_new_password" component={renderField} type="password" className="form-control" />
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/colaboradores">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/colaboradores">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )

}
const selector = formValueSelector('ColaboradorEditarForm');

ColaboradorEditarForm = reduxForm({
    form: 'ColaboradorEditarForm', // a unique identifier for this form
    validate: validators,
})(withTranslation()(ColaboradorEditarForm));

const mstp = state => {
    const colider = selector(state, 'colider');
    return {
        colider,
    }
};

export default connect(mstp, null)(ColaboradorEditarForm)