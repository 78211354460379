import React, {Fragment} from 'react';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderDatePicker3, renderField, renderTextArea, SelectField } from '../../Utils/renderField/renderField';
import { MODELOS, TIPO_SECTORES } from '../../../../utility/constants';
import { withTranslation } from "react-i18next";
import CustomTooltip from '../../Utils/Tooltip';
import moment from "moment";

const validators=(values, props)=>{
    const errors = {}

    if(!values.descripcion_empresa){
        errors.descripcion_empresa = `${props.t('Campo requerido')}`
    }
    if(!values.sectores ||(values.sectores&&values.sectores.length<1)){
        errors.sectores = `${props.t('Campo requerido')}`
    }
    if(!values.modelo_negocios){
        errors.modelo_negocios = `${props.t('Campo requerido')}`

    }
    if(!values.fecha_fundacion|| (values.fecha_fundacion&&!moment(values.fecha_fundacion).isValid())){
        errors.fecha_fundacion = `${props.t('Campo requerido')}`
    }
    return errors;
}

const PasoUnoForm = props => {
    const { handleSubmit, previousStep } = props;

    return (
        <form onSubmit={handleSubmit} className="form-validate mb-lg">
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="descripcion_empresa" className="m-0">{props.t('¿Qué es lo que hace la empresa?')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Ingrese una pequeña descripción respondiendo las siguientes preguntas ¿Cual es el giro de negocio de la empresa?, ¿Que vende su empresa?')+'.'}/>
                    </div>
                    <Field name="descripcion_empresa" component={renderField} type="text" className="form-control"/>
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="sector" className="m-0">{props.t('Sector')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Seleccione los sectores donde se desempeña su empresa')+'.'}/>
                    </div>
                    <Field
                        name="sectores"
                        isMulti
                        options={TIPO_SECTORES}
                        component={SelectField}
                    />
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="sector" className="m-0">{props.t('Modelo de negocio')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Seleccione el modelo de negocio que utiliza su empresa')+'.'}/>
                    </div>
                    <Field
                        name="modelo_negocios"
                        component={SelectField}
                        options={MODELOS}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >    
                        <label htmlFor="fecha_fundacion" className="m-0">{props.t('Fecha de fundación de la empresa')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Seleccione el mes, dia y año que fundo la empresa')+'.'}/>
                    </div>
                    <Field name="fecha_fundacion" component={renderDatePicker3} noFuturos={true} />
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="retos_importantes" className="m-0">{props.t('Retos más importantes que están impidiendo el crecimiento de mi empresa')}</label>
                        <CustomTooltip msj={props.t('Describa todos los obstáculos o problemas que le han impedido obtener el crecimiento que desea para su empresa')+'.'}/>
                    </div>
                    <Field name="retos_importantes" component={renderTextArea} type="text" className="form-control"/>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <button type="button" onClick={previousStep} className="btn btn-secondary ml-3">{props.t('Regresar')}</button>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Siguiente')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <button type="button" onClick={previousStep} className="btn btn-secondary flex-1 mt-3">{props.t('Regresar')}</button>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Siguiente')}</button>
                </div>
            </div>
        </form>
    )

}

export default reduxForm({
    form: 'register',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncBlurFields: [],
    validate: validators,
})(withTranslation()(PasoUnoForm));
