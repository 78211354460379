import React, { Fragment} from 'react';

function CardForm(props) {
    const {noPaddingXContent, noPaddingYContent} = props;
    return (
        <Fragment>
            <div className="mb-4 card">
                <div className={'card-content-form' + (noPaddingXContent ? ' px-0' : '') + (noPaddingYContent ? ' py-0' : '')}>
                    <div className={`col-12 col-lg-${props.col ? props.col : '10'}`}>
                        {props.children}
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default CardForm;
