import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/cuenta/login';
import CambioPwd from './CambioPwd';


const ms2p = (state) => {
  return {
    ...state.login,
  };
};

const md2p = { ...actions };

const Recuperar = connect(ms2p, md2p)(CambioPwd);
const Solicitar = connect(ms2p, md2p)(CambioPwd);


export default {
  Recuperar,
  Solicitar,
}