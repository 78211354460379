import React, { useState, useEffect } from 'react';
import ArchivosUploader from '../../../Utils/ArchivoUploader/index';
import moment from 'moment';

const ModalSlots = (props) => {

    const { item, cerrar, me } = props;

    return (
        <div style={{width: "45vw"}} className="d-flex flex-column flex-1 w-100-responsivo">
            <div className="d-flex flex-row borde-bottom px-3 py-1 flex-1">
                <div className="d-flex justify-content-center align-items-center mr-3">
                    <img style={{width: "2rem", height: "2rem"}} src={item ? item.empresa.logo : null} className="logo-tabla mr-3"></img>
                </div>
                <div className="d-flex flex-column justify-content-center">
                    <span className="text-sm bold gris-oscuro" >{item ? item.empresa.nombre : ''}</span>
                    <span className="text-xs gris" >{props.t('Encargado')} {item ? item.empresa.nombre_admin : ''}</span>
                </div>
            </div>
            <div className="d-flex flex-row flex-1 my-3 px-3">
                <div className="d-flex flex-column flex-1">
                    <span className="text-qts bold gris-oscuro">{props.t('Fecha')}</span>
                    <span className="text-sm celeste-oscuro">{item ? me && me.timezone ? moment(item.fecha_inicio).tz(me.timezone).format("MMM-DD-YYYY") : moment(item.fecha_inicio).format("MMM-DD-YYYY") : ''}</span>
                </div>
                <div className="d-flex flex-column flex-1">
                    <span className="text-qts bold gris-oscuro">{props.t('Hora')}</span>
                    <span className="text-sm celeste-oscuro">{item ? me && me.timezone ?  moment(item.fecha_inicio).tz(me.timezone).format("hh:mm a z") : moment(item.fecha_inicio).format("hh:mm a z") : ''}</span>
                </div>
            </div>
            <div className="d-flex flex-column flex-1 my-3 px-3">
                <span className="azul-gris-oscuro ligth text-sm borde-bottom mb-3">{props.t('Consulta')}</span>
                <div className="w-100 pt-3" dangerouslySetInnerHTML={{ __html: item ? item.descripcion : '' }}></div>
            </div>
            <div className="d-flex flex-column flex-1 my-3 px-3">
                <span className="azul-gris-oscuro ligth text-sm borde-bottom mb-3">{props.t('Archivos')}</span>
                <ArchivosUploader archivos={item ? item.archivos : []} onlyLectura={true} />
            </div>
            <div className="d-flex flex-1 justify-content-center align-items-center my-4">
                <button onClick={cerrar} className="d-none d-md-flex btn btn-secondary">{props.t('Regresar')}</button>
                <button onClick={cerrar} className="d-flex d-md-none flex-1 btn btn-secondary">{props.t('Regresar')}</button>
            </div>
        </div>
    )
}

export default ModalSlots;
