import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import {initialize as initializeForm} from "redux-form";
import { NotificationManager } from "react-notifications";
import { HOMBRE } from '../../../utility/constants';
import { COUNTRIES } from '../../../utility/paises';
import moment from 'moment';
import _ from 'lodash';
import { api } from "api";
import { getI18n } from 'react-i18next';

const SUBMIT = 'REGISTER_SUBMIT';
const LOADER = 'REGISTER_LOADER';
const ITEM = 'REGISTER_ITEM';
const COLABORADORES = 'REGISTER_COLABORADORES';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setColaboradores = colaboradores => ({
    type: COLABORADORES,
    colaboradores,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user', data).then(() => {
        dispatch(push("/login"));
        NotificationManager.success(getI18n().t('Cuenta creada con éxito, puedes iniciar sesión'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Credenciales incorrectas, vuelva a intentar'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const inscribirEmpresa = (id, data, attachments = [], nextStep) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments(`empresa/${id}/inscripcion`, data, attachments).then(() => {
        NotificationManager.success(getI18n().t('Se ha registrado la empresa, puedes continuar con el proceso de inscripción'), getI18n().t('Éxito'), 5000);
        dispatch(cleanForm()); // Limpiar el formulario
        nextStep();
        // dispatch(push('/login'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al registrar la empresa');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje,  getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const crearExamen = (id, data, setEvaluacionKPIs) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`empresa/${id}/examen_nivelacion`, data).then(() => {
        NotificationManager.success(getI18n().t('Ha contestado la evaluación de KPIs, puedes continuar con el proceso de inscripción'), getI18n().t('Éxito'), 5000);
        dispatch(cleanForm()); // Limpiar el formulario
        setEvaluacionKPIs();
    }).catch((error) => {
        let mensaje = getI18n().t('Error al registrar la empresa');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje,  getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const crearEvaluacionKPIs = (id, data, setPasarelaPago) => (dispatch, getStore) => {
    const item = getStore().register.item;
    dispatch(setLoader(true));
    api.post(`empresa/${id}/evaluacion_kpis`, data).then(() => {
        NotificationManager.success(getI18n().t('Ha contestado la información sobre nivel de conocimiento, puedes continuar con el proceso de inscripción'), getI18n().t('Éxito'), 5000);
        dispatch(cleanForm()); // Limpiar el formulario
        if (item.cohorte && item.cohorte.tiene_costo) {
            setPasarelaPago();
        } else {
            dispatch(push('/login'));
        }
    }).catch((error) => {
        let mensaje = getI18n().t('Error al registrar la empresa');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje,  getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const pagarCohorte = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('pasarela_pago/primer_pago', data).then(() => {
        NotificationManager.success(getI18n().t('Has pagado tu cohorte, ya puedes iniciar sesión'), getI18n().t('Éxito'), 5000);
        dispatch(cleanForm()); // Limpiar el formulario
        localStorage.removeItem('token');
        dispatch(push('/login'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al registrar la empresa');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje,  getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerEmpresa = (id, setExamen, setEvaluacionKPIs, setPasarelaPago) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`empresa/${id}`).then((response) => {
        response.fecha_fundacion = moment(response.fecha_fundacion);
        response.usuario = {genero: HOMBRE};
        if (response.pais) {
            const item = _.find(COUNTRIES, {name: response.pais});
            console.log("item ", item);
            response.pais = item;
        }
        if (response.sectores)
            response.sectores = response.sectores.map(item => ({value: item.tipo, label: item.tipo_text, id: item.id}));
        console.log("--------")
        if (!response.colaboradores || response.colaboradores.filter(colab => colab.admin_empresa == true ).length == 0) {
            // Nada
        }
        else if (!response.examen_nivelacion && response.colaboradores.length && response.cohorte && response.cohorte.cuestionario_nivelacion) {
            setExamen()
        } else if (!response.primera_evaluacion_kpis) {
            setEvaluacionKPIs()
        } else if (!response.primer_pago) {
            setPasarelaPago()
        } else if (response.examen_nivelacion && response.colaboradores.length && response.primera_evaluacion_kpis && response.primer_pago) {
            dispatch(push('/login'));
        }
        dispatch(setItem(response));
        dispatch(initializeForm('register', response));
        dispatch(setColaboradores(response.colaboradores.filter(colab => colab.admin_empresa == false )))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const invitarColaboradores = (data, nextStep)=>(dispatch)=>{
    dispatch(setLoader(true));
    api.post('colaborador/invitar_varios_colaboradores', data).then((response)=>{
        NotificationManager.success(getI18n().t('Registro e invitación de colaboradores exitosa'), getI18n().t('Éxito'), 3000);
        nextStep();
    }).catch((error)=>{
        let mensaje = getI18n().t('Error al registrar colaboradores');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje,  getI18n().t('ERROR'), 3000);

    }).finally(()=>{
        dispatch(setLoader(false));
    })

}

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('register', {}));
};


export const actions = {
    onSubmit,
    crearExamen,
    crearEvaluacionKPIs,
    inscribirEmpresa,
    logOut,
    leerEmpresa,
    pagarCohorte,
    cleanForm,
    invitarColaboradores,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [COLABORADORES]: (state, { colaboradores }) => {
        return {
            ...state,
            colaboradores,
        };
    },
};

export const initialState = {
    loader: false,
    item: {},
    colaboradores: [],
};

export default handleActions(reducers, initialState);
