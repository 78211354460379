import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import { getI18n } from 'react-i18next';

const LOADER = 'LOADER_COLABORADORES';
const DATA = 'DATA_COLABORADORES';
const ITEM_DATA = 'ITEM_COLABORADORES';
const PAGE = 'PAGE_COLABORADORES';
const ORDERING = 'ORDERING_COLABORADORES';
const SEARCH = 'SEARCH_COLABORADORES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().colaboradores;
    const me = getStore().login.me;
    const params = { page };
    params.admin_empresa = false;
    params.empresa = me.empresa;
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('colaborador', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id, registro=false) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`colaborador/${id}`).then((response) => {
        if(response.is_active){
            dispatch(push('/'));
            NotificationManager.error(getI18n().t('Ya estas registrado'), getI18n().t('ERROR'), 3000);
        }
        dispatch(setItem(response));
        dispatch(initializeForm(registro ? 'RegistroColabForm' : 'ColaboradorForm', response));
    }).catch(() => {
        dispatch(push('/login'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('colaborador', data).then(() => {
        NotificationManager.success(getI18n().t('Invitación enviada'), getI18n().t('Éxito'), 3000);
        dispatch(push('/colaboradores'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la creación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`colaborador/${id}`, data).then(() => {
        NotificationManager.success(getI18n().t('Invitación modificada'), getI18n().t('Éxito'), 3000);
        dispatch(push('/colaboradores'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la edición');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const reenviar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`colaborador/${id}/reenviar_invitacion`).then(() => {
        dispatch(listar());
        NotificationManager.success(getI18n().t('Correo reenviado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la transacción'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`colaborador/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(getI18n().t('Registro eliminado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la transacción'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const registrarColab = (id, data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`colaborador/${id}/registro_colaborador`, data, attachments).then(() => {
        NotificationManager.success(getI18n().t('Se ha registrado, ahora inicie sesión'), getI18n().t('Éxito'), 3000);
        localStorage.removeItem('token');
        dispatch(push('/login'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al registrarse');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().colaboradores.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    reenviar,
    searchChange,
    onSortChange,
    registrarColab,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
