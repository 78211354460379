import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/publicaciones/familyMeeting';
import DetalleFamilyMeeting from './DetalleFamilyMeeting';

const ms2p = (state) => {
    return {
        ...state.familyMeeting,
        me: state.login.me
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DetalleFamilyMeeting);
