import React, { Component, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '../../../Utils/Cards/Card';
import { Collapse } from 'antd';
import { ADMIN } from '../../../../../utility/constants';
import ListaInvitados from './ListaInvitados';
import i18n from '../../../../../i18nextInit'
import moment from 'moment';
import _ from 'lodash';

const iconoTaller = require('../../../../../../assets/img/iconos/taller.png');
const defaultImg = require('../../../../../../assets/img/avatar.png');

const MasInfoTab = (props) => {
    const { item, loader, me, data_asignados } = props;
    
    const lang = i18n.language ? i18n.language : 'en'
    return (
        <Card noPadding={true}>
            <div className='card-content-form mx-0 mx-md-3 flex-column px-3 p-md-0 pt-3 pb-0' style={{ maxWidth: "100%" }}>
                <div className="card-post-header borde-bottom pb-3 pt-3">
                    <div className="d-flex align-items-center justify-content-center flex-1">
                        <img src={iconoTaller} style={{ width: "2rem" }} />
                    </div>
                    <div className="d-flex flex-column flex-5">
                        <div className="d-flex flex-row align-items-center flex-1">
                            <span className="gris-oscuro h5">{item.titulo}</span>
                            <span className="gris-oscuro h5">&nbsp;/&nbsp;{moment(item.creado).locale(lang).fromNow()}</span>
                        </div>
                        <div className="d-flex flex-row align-items-center flex-1">
                            <Link className="link-perfil" to={`/perfil/${item.publicado_por ? item.publicado_por.id : 0}`}>
                                {props.t('Publicado por')} {item.publicado_por ? item.publicado_por.nombres : ''}
                            </Link>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex flex-row align-items-center flex-1"></div>
                </div>
                <Collapse className="mb-3" defaultActiveKey={['panel-invitados','panel-drills']}>
                    <Collapse.Panel header={
                            <div className="d-flex flex-row flex-1 py-2">
                                <span className="azul-gris-oscuro bold text-sm ml-4" style={{ textTransform: "uppercase" }}>{props.t('Invitados')}</span>
                            </div>

                        } key='panel-invitados' >
                            <ListaInvitados 
                                invitados={props.data_invitados} 
                                id={props.item.id} item={props.item} 
                                obtenerInvitados={props.obtenerInvitados}
                                page={props.page}
                                descargarExcel={props.descargarExcel} />

                    </Collapse.Panel>
                    {(me && me.tipo_usuario == ADMIN) && (
                        <Collapse.Panel
                            header={
                                <div className="d-flex flex-row flex-1 py-2">
                                    <span className="azul-gris-oscuro bold text-sm ml-4" style={{ textTransform: "uppercase" }}>{props.t('Asistencias')}</span>
                                </div>
                            }
                            key='panel-asistencias'
                        >
                            <div className="d-flex flex-column flex-1 px-lg-1">
                                {(data_asignados && data_asignados.length)  && (
                                    data_asignados.map((item2, index) => (
                                        <div key={index} className="d-flex flex-row flex-1 align-items-center borde-bottom py-2">
                                            <div className="flex-5">
                                                <img src={item2.empresa.logo ? item2.empresa.logo : defaultImg} className="collapse__logo ml-3"></img>
                                                <span className="pl-2 text-sm azul-gris-oscuro" >{item2.empresa.nombre}</span>
                                            </div>
                                            <div className="d-flex flex-1 justify-content-center">
                                                <button className="btn btn-clear" onClick={() => props.openModalAsistencia(item2)}>
                                                    <i className="fas fa-eye azul-oscuro" />
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </Collapse.Panel>
                    )}
                    {(item.tiene_drill) && (
                        <Collapse.Panel
                            header={
                                <div className="d-flex flex-row flex-1 py-2">
                                    <span className="azul-gris-oscuro bold text-sm ml-4" style={{ textTransform: "uppercase" }}>{props.t('Drills')}</span>
                                </div>
                            }
                            key='panel-drills'
                        >
                            <div className="d-flex flex-column flex-1 px-lg-1">
                                {(data_asignados && data_asignados.length)  && (
                                    data_asignados.map((item2, index) => {
                                        const drill_empresa = _.find(item.memos_drill, _item =>  _item.empresa ? _item.empresa.id == item2.empresa.id : false );
                                        return (
                                            <div key={index} className="d-flex flex-row flex-1 align-items-center borde-bottom py-2">
                                                <div className="flex-5">
                                                    <img src={item2.empresa.logo ? item2.empresa.logo : defaultImg} className="collapse__logo ml-3"></img>
                                                    <span className="pl-2 text-sm azul-gris-oscuro" >{item2.empresa.nombre}</span>
                                                </div>
                                                <div className="d-flex flex-1 justify-content-center">
                                                    {(drill_empresa) && (
                                                        <button className="btn btn-clear" onClick={() => props.openModalDrill(drill_empresa)}>
                                                            <i className="fas fa-eye azul-oscuro" />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        </Collapse.Panel>
                    )}
                    {(item.evaluaciones && item.evaluaciones.length) && (
                        <Collapse.Panel
                            header={
                                <div className="d-flex flex-row flex-1 py-2">
                                    <span className="azul-gris-oscuro bold text-sm ml-4" style={{ textTransform: "uppercase" }}>{props.t('Responsable de autoevaluciones')}</span>
                                </div>
                            }
                            key='panel-responsables'
                        >
                            <div className="d-flex flex-column flex-1 px-lg-1">
                                {(data_asignados && data_asignados.length)  && (
                                    data_asignados.map((item2, index) => (
                                        <div key={index} className="d-flex flex-row flex-1 align-items-center borde-bottom py-2">
                                            <div className="flex-5">
                                                <img src={item2.empresa.logo ? item2.empresa.logo : defaultImg} className="collapse__logo ml-3"></img>
                                                <span className="pl-2 text-sm azul-gris-oscuro" >{item2.empresa.nombre}</span>
                                            </div>
                                            <div className="d-flex flex-1 justify-content-center">
                                                <button className="btn btn-clear" onClick={() => props.openModalResponsable(item2)}>
                                                    <i className="fas fa-eye azul-oscuro" />
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </Collapse.Panel>
                    )}
                </Collapse>
            </div>
        </Card>
    )
}

export default MasInfoTab;
