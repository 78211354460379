import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import {ProgressBar, Step} from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";

const check = require("assets/img/iconos/check.png");


function Wizard(props) {
    const { step, totalSteps, steps, stepPositions } = props;
    return (
        <div className="d-flex flex-row  align-items-center flex-1">
            <ProgressBar
                percent={step === 1 ? 0 : (step / totalSteps ) * 100 }
                filledBackground="#363C3F"
                unfilledBackground="#363C3F"
                stepPositions={stepPositions}
                height={5}
            >
                {(steps && steps.length) && (
                    steps.map( (item, i) => (
                        // <div key={i}>
                            <Step key={i} transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot" />
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center justify-content-center">
                                                        <img src={check} alt="" width="15" height="15" />
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index + 1 === step ? 'azul' : (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>{item}</span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                        // </div>
                    ) )
                ) }
            </ProgressBar>
        </div>

    )
}

export default Wizard;
