import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/publicaciones/intercambios';
import ListaGeneral from './ListaGeneral';

const ms2p = (state) => {
    return {
        ...state.intercambios,
        me:state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListaGeneral);
