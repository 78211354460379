
import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import Grid from '../../../components/Utils/Grid/';
import {TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';

import iconoUsuario from '../../../../../assets/img/avatar.png';

const listarReacciones =  (props)=>{

    return(
        <Modal open={props.open} onClose={e=>props.onClose()} >
            <div style={{borderBottom:"solid 2px #E6E6E6"}} >
                <div className="d-flex flex-row pb-2 px-5 pt-5 align-items-center"  >
                    <span className="pr-2" >
                        {props.t('Todos')}
                    </span>
                    <i className={`text-equal2 fas fa-thumbs-up marron pl`}></i>
                    <span className="pl-2" >{props.cantidad}</span>
                </div>
            </div>
            <div className="px-3 px-md-5 hide__header__table pt-3 pb-5" >
                <Grid hover striped data={props.data ? props.data : {results:[], count:0}} pagination={false} noResponsive={true} >
                    <TableHeaderColumn
                        isKey
                        dataField="usuario"
                        dataAlign='center'
                        dataFormat={
                            (cell,row)=>(
                                    <div className="d-flex flex-1 align-items-center pl-1 pl-md-3">
                                        <img className="logo-tabla mr-2" src={cell.avatar ? cell.avatar : iconoUsuario } />
                                        <span>{cell.nombres} {cell.empresa_nombre&& `/ ${cell.empresa_nombre}`} </span>
                                    </div>)}>
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="usuario"
                        dataAlign='center'
                        dataFormat={
                            (cell)=>(
                                <div>
                                    <Link className="btn btn-secondary-fill" to={`/perfil/${cell.id}`} >{props.t('Ver Perfil')}</Link>
                                </div>
                            )
                        }
                    >
                    </TableHeaderColumn>
                </Grid>

            </div>


        </Modal>

    )
}

export default withTranslation()(listarReacciones);