import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import CardFamily from '../../../Utils/Cards/CardTaller';
import moment from 'moment';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { Fragment } from 'react';
import { Tabs } from 'antd';

class ListarTalleres extends Component {
    constructor(props){
        super(props);
        this.state={
            tab:'nuevos',
        }
    }
    componentWillMount() {
        console.log('props ', this.props);
        const { listar } = this.props;
        listar(1);
    }
    onChangeTabs=(tab)=>{
        const { listar } = this.props;
        this.setState({tab:tab});
        listar(1, true, tab);
    }
    render() {
        const { data, loader, listar, page } = this.props;
        return (
            <div className="social-layout mb-4 col-12 mt-0">
                <LoadMask loading={loader} blur fixed>
                    <InfiniteScroll
                        dataLength={data.results.length} //This is important field to render the next data
                        next={() => listar(page + 1, false, this.state.tab)}
                        hasMore={true}
                        loader={<LoadMask loading={loader} blur></LoadMask>}
                        endMessage={
                            <div className="d-flex flex-row flex-1 justify-content-center">
                                {(data.count) ? (
                                    <span className="text-md gris-oscuro regular text-center">
                                        {this.props.t('No cuenta con más reuniones familiares')}
                                    </span>
                                ) : (
                                    <span style={{marginTop: "20%"}} className="text-md gris-oscuro regular text-center">
                                        {this.props.t('No cuenta con reuniones familiares actualmente')}
                                    </span>
                                )}
                            </div>
                        }
                        // below props only if you need pull down functionality
                        refreshFunction={async () => { await listar(1); return true }}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={100}
                        pullDownToRefreshContent={
                            <Fragment>
                                <h3 className="text-md blanco regular text-center" style={{ textAlign: 'center' }}>
                                    <i className="fas fa-chevron-circle-down blanco mr-2" />
                                    {this.props.t('Deslizar para cargar')}
                                </h3>
                            </Fragment>
                        }
                        releaseToRefreshContent={
                            <Fragment>
                                <h3 className="text-md blanco regular text-center" style={{ textAlign: 'center' }}>
                                    <i className="fas fa-chevron-circle-up blanco mr-2" />
                                    {this.props.t('Suelte para cargar')}
                                </h3>
                            </Fragment>
                        }
                        hasMore={data.next}
                    >
                        <Tabs defaultActiveKey='nuevos' onChange={this.onChangeTabs} >
                            <Tabs.TabPane tab={`${screen.width<768 ? this.props.t('R. F. nuevas'): this.props.t('Reuniones familiares nuevas')}`} key='nuevos' >
                                {(data.results.length) ? (
                                    data.results.map((item, index) => (
                                        <div key={index}>
                                            <CardFamily id={item.id} item={item} me={this.props.me} obtenerAcceso={this.props.obtenerAcceso} />
                                        </div>
                                    ))
                                ):null}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={`${screen.width<768 ? this.props.t('R. F. pasadas'): this.props.t('Reuniones familiares pasadas')}`} key='pasados' >
                                {(data.results.length) ? (
                                    data.results.map((item, index) => (
                                        <div key={index}>
                                            <CardFamily id={item.id} item={item} me={this.props.me} obtenerAcceso={this.props.obtenerAcceso} />
                                        </div>
                                    ))
                                ):null}
                            </Tabs.TabPane>
                        </Tabs>
                    </InfiniteScroll>
                </LoadMask>
            </div>
        )
    }

}

export default withTranslation()(ListarTalleres)
