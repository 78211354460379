import React from 'react';
import { withTranslation } from 'react-i18next';
import CardIntercambios from '../../../Utils/Cards/CardIntercambios';
import LoadMask from '../../../Utils/LoadMask/LoadMask';

class Detalle extends React.Component{

    componentDidMount=()=>{
        const { me }= this.props;
        const id = this.props.match.params.id;
        this.props.leer(id);
    }
    

    render(){
        const { item, loader } =this.props;
        return(
            <div className="social-layout mb-4 col-12 mt-0" >
                <LoadMask loading={loader} blur>
                
                    {(item&&item.id)&&(
                        <CardIntercambios id={item.id} item={item} me={this.props.me} 
                            aprobar={this.props.aprobar} eliminar={this.props.eliminar} 
                            editar={this.props.editar} detalle={true} meGusta={this.props.meGusta} 
                            removerMeGusta={this.props.removerMeGusta}
                            listarMeGustas={this.props.listarMeGustas}
                        />)}


                </LoadMask>
            </div>
        )
    }
}


export default withTranslation()(Detalle);