import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import Card from '../../../Utils/Cards/Card';
import CardForm from '../../../Utils/Cards/CardForm';
import { USUARIO } from '../../../../../utility/constants'
import { withTranslation } from 'react-i18next';
import DetalleEvaluacionForm from './DetalleForm';


class DetalleEvaluacion extends Component {

    state = {
        bloque: 0,
        bloque_name: '',
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const idRespuesta = this.props.match.params.idRespuesta;
        const { leerEvaluacion, leerRespuesta2 } = this.props;
        leerEvaluacion(id, this.setBloqueName);
        leerRespuesta2(idRespuesta);
    }

    setBloqueName = (bloque_name) => {
        this.setState({ bloque_name });
        window.scroll(0, 0);
    }

    nextBloque = () => {
        let bloque = this.state.bloque;
        const { item } = this.props;
        if (bloque < item.cantidad_bloques) {
            bloque += 1;
            const bloque_name = item.bloques[bloque].nombre
            this.setState({ bloque, bloque_name });
            window.scroll(0, 0);
        }
    }

    previousBloque = () => {
        let bloque = this.state.bloque;
        const { item } = this.props;
        if (bloque > 0) {
            bloque -= 1;
            const bloque_name = item.bloques[bloque].nombre
            this.setState({ bloque, bloque_name });
            window.scroll(0, 0);
        }
    }

    obtenerTotalBloque = (bloque_index) => {
        let punteo = 0;
        const { item2 } = this.props;
        if (item2.bloques) {
            const item = item2.bloques[bloque_index];
            for(const pregunta of item.preguntas) {
                if (pregunta.opcion_elegida)
                    punteo += parseInt(pregunta.opcion_elegida);
            }
        }
        console.log("punteo ", punteo);
        return punteo;
    }

    render() {
        const { loader, item, item2, me } = this.props;
        const { bloque, bloque_name } = this.state;
        const id = this.props.match.params.id;

        return (
            <div className="social-layout mb-4 col-12 mt-0">
                <LoadMask loading={loader} blur>
                    <Card noPadding={true}>
                        <div className="d-flex flex-lg-row flex-column flex-1 align-items-lg-center px-lg-4 my-3">
                            <div className="d-flex flex-row flex-2 align-items-center">
                                <div className="flex-1">
                                    <span className="text-xs bold azul">{this.props.t('Resultados de la Autoevaluación')}</span>
                                </div>
                                <div className="flex-1">
                                    <div className="circulo-puntos">
                                        <span className="blanco text-sm bold">{`${item2.punteo} PT`}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-2">
                                <span className="text-sm">{this.props.t('Taller')}:</span>
                                <span className="celeste-oscuro bold text-sm">{item.publicacion ? item.publicacion.titulo : ''}</span>
                            </div>
                            <div className="d-flex flex-column flex-2">
                                <span className="text-sm">{this.props.t('Autoevaluación')}:</span>
                                <span className="celeste-oscuro bold text-sm">{item.nombre}</span>
                            </div>
                            <div className="d-flex flex-column flex-2 px-lg-5">
                                <span className="text-sm">{this.props.t('Empresa')}:</span>
                                <span className="celeste-oscuro bold text-sm">{item2.empresa ? item2.empresa.nombre : ''}</span>
                            </div>
                            <div className="d-flex flex-column flex-2 px-lg-5">
                                { (me.tipo_usuario === 10 || me.tipo_usuario === 20) && 
                                (
                                    <button onClick={ () => {this.props.descargarExcel(id, item2.empresa.id)} } className="btn btn-primary d-flex flex-1 mx-lg-3 mt-1">{this.props.t('Descargar excel')}</button>
                                )
                                }
                                <Link to={`/tablero/${id}`} className="btn btn-secondary d-flex flex-1 mx-lg-3 mt-2">{this.props.t('Regresar a tablero')}</Link>
                            </div>
                        </div>
                    </Card>
                    <CardForm noPaddingXContent={true} noPaddingYContent={true}>
                        <div className="d-flex flex-column flex-1 px-lg-2 my-3">
                            <div className="d-flex flex-lg-row flex-column mb-3">
                                <div className="d-flex flex-row flex-2">
                                    <div className="mr-2">
                                        <i className="fas fa-file-alt azul-gris-oscuro" style={{ fontSize: "2rem" }}></i>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h5 className="mb-0 bold azul-gris-oscuro">{`${this.props.t('Bloque')} ${bloque + 1} ${this.props.t('de')} ${item.cantidad_bloques}`}</h5>
                                        <span className="gris-oscuro text-sm">{`${bloque_name}`}</span>
                                    </div>
                                </div>
                                <div className="d-flex flex-row flex-1 my-auto">
                                    <div className="align-self-center flex-2">
                                        <div className="ml-auto circulo-puntos">
                                            <span className="blanco text-sm bold">{`${this.obtenerTotalBloque(bloque)} PT`}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DetalleEvaluacionForm
                                {...this.state}
                                item_inicial={item}
                                item={item2}
                                loader={false}
                                previousBloque={this.previousBloque}
                                nextBloque={this.nextBloque}
                                me={this.props.me}
                                t={this.props.t}
                            />
                        </div>
                    </CardForm>
                </LoadMask>
            </div>
        )
    }

}

export default withTranslation()(DetalleEvaluacion)
