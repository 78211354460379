import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';
import Card from '../../../Utils/Cards/Card';
import { ADMIN, GROWTH_ANGEL, USUARIO } from '../../../../../utility/constants';
import { withTranslation } from 'react-i18next';

const avatar = require("../../../../../../assets/img/avatar.png");

const Personas = (props) => {
    const { id } = props;
    return (
        <div className='mb-2 bg-blanco mb-3'>
            <div className="d-flex flex-column flex-1 pt-3 px-3">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <span className="text-xsm ml-4">{props.t('Exporta la lista de invitados y asistentes a formato Excel')}</span>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="d-flex flex-row justify-content-end">
                            <button onClick={()=> props.descargarExcel(id)} className="btn btn-primary">{props.t('Exportar')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(Personas);
