import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { COUNTRIES } from '../../../utility/paises';
import { getMe } from '../cuenta/login';
import { SwalExito, SwalError } from '../../../common/components/Utils/Notificaciones';
import _ from "lodash";
import { getI18n } from 'react-i18next';

const LOADER = 'LOADER_EMPRESA_PERFIL';
const DATA = 'DATA_EMPRESA_PERFIL';
const DATA_TARJETAS = 'DATA_TARJETAS_EMPRESA_PERFIL';
const ITEM_DATA = 'ITEM_EMPRESA_PERFIL';
const ITEM_EVALUACION = 'ITEM_EVALUACION_EMPRESA_PERFIL';
const ITEM_TARJETA = 'ITEM_TARJETA_EMPRESA_PERFIL';
const ITEM_KPIS = 'ITEM_KPIS_EMPRESA_PERFIL';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

// ------------------------------------
// Actions
// ------------------------------------

const leer = (id, form=false) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`empresa/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(obtenerEmpresas(id));
        if (form) {
            const data = {...response}
            if (data.pais) {
                const item = _.find(COUNTRIES, {name: data.pais});
                data.pais = item;
            }
            if (data.sectores) {
                data.sectores = data.sectores.map(item => ({...item, value: item.tipo, label: item.tipo_text}))
            }
            dispatch(initializeForm('EmpresaPerfilForm', data));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const obtenerEmpresas = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`empresa/${id}/empresas_en_mi_grupo`).then((response) => {
        console.log("respose ", response)
        dispatch(setData(DATA, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`empresa/actualizar_datos`, data, attachments).then(() => {
        NotificationManager.success(getI18n().t('Perfil de la empresa actualizado'), getI18n().t('Éxito'), 3000);
        dispatch(push('/mi-empresa'));
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la edición'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setDatosEvaluacion = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`empresa/datos_kpis`).then((response) => {
        dispatch(setData(ITEM_EVALUACION, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const mandarEvaluacionKPIs = (data) => (dispatch, getStore) => {
    const me = getStore().login.me;
    dispatch(setLoader(true));
    api.post(`empresa/${me.empresa}/evaluacion_kpis`, data).then(() => {
        NotificationManager.success(getI18n().t('Evaluación de KPIs enviada'), getI18n().t('Éxito'), 3000);
        dispatch(leer(me.empresa));
        dispatch(getMe());
        dispatch(setDatosEvaluacion());
        dispatch(push('/examen_kpis'));
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la edición'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarTarjetas = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('pasarela_pago/listar_tarjetas').then((response) => {
        dispatch(setData(DATA_TARJETAS, response));
    }).catch(() => {
        dispatch(setData(DATA_TARJETAS, {
            default_source: null,
            data: []
        }));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cambiarTarjeta = (tarjeta) => (dispatch) => {
    dispatch(setLoader(true));
    api.put('pasarela_pago/cambiar_tarjeta', { tarjeta }).then(() => {
        NotificationManager.success(getI18n().t('Tarjeta por defecto cambiada'), getI18n().t('Éxito'), 3000);
        dispatch(listarTarjetas());
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error al cambiar la tarjeta por defecto'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarTarjeta = (tarjeta) => (dispatch) => {
    dispatch(setLoader(true));
    api.put('pasarela_pago/borrar_tarjeta', { tarjeta }).then(() => {
        NotificationManager.success(getI18n().t('Tarjeta eliminada'), getI18n().t('Éxito'), 3000);
        dispatch(listarTarjetas());
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error al eliminar la tarjeta'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const obtenerTarjeta = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('pasarela_pago/tarjeta_defecto').then((response) => {
        dispatch(setData(ITEM_TARJETA, response.detail));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const pagarMembresia = (data, idEmpresa) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('pasarela_pago/pago_membresia', data).then(() => {
        SwalExito(getI18n().t('Éxito'), getI18n().t('Membresía pagada correctamente, el recibo se envió a su correo'));
        dispatch(getMe());
        dispatch(leer(idEmpresa));
        dispatch(obtenerTarjeta());
        dispatch(push('/'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al pagar la membresía');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        SwalError(getI18n().t('Error'), mensaje);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const agregarTarjeta = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('pasarela_pago/crear_tarjeta', data).then(() => {
        NotificationManager.success(getI18n().t('Tarjeta agregada'), getI18n().t('Éxito'), 3000);
        dispatch(listarTarjetas());
        dispatch(push('/mis-tarjetas'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al agregar tarjeta')
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const obtenerUltimoKPIs =()=>(dispatch, getStore)=>{
    dispatch(setLoader(true));
    api.get('empresa/obtener_ultimo_kpi').then((response)=>{
        response.pregunta8_i = response.pregunta8_i.map((item)=>_.find(COUNTRIES,(pais)=>pais.name===item.pais));
        response.pregunta8_i = response.pregunta8_i.map((item)=>({...item,value:item.id, label:item.name}));
        response.pregunta5_a = response.pregunta5_a.map(item=>item.empresa)
        dispatch(setData(ITEM_KPIS,response));
        dispatch(initializeForm('ExamenKPIsForm', response));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al obtener evaluación de KPIs');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const updateUltimoKPIs = (data) => (dispatch, getStore) => {
    const me = getStore().login.me;
    dispatch(setLoader(true));
    api.post(`empresa/update_ultimo_kpis`, data).then(() => {
        NotificationManager.success(getI18n().t('Evaluación de KPIs enviada'), getI18n().t('Éxito'), 3000);
        dispatch(leer(me.empresa));
        dispatch(getMe());
        dispatch(setDatosEvaluacion());
        dispatch(push('/examen_kpis'));
        dispatch(setData(ITEM_KPIS,{}))
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la edición'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    leer,
    obtenerEmpresas,
    editar,
    setDatosEvaluacion,
    mandarEvaluacionKPIs,
    listarTarjetas,
    cambiarTarjeta,
    eliminarTarjeta,
    obtenerTarjeta,
    pagarMembresia,
    agregarTarjeta,
    obtenerUltimoKPIs,
    updateUltimoKPIs,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_TARJETAS]: (state, { data }) => {
        return {
            ...state,
            data_tarjetas: data,
        };
    },
    [ITEM_EVALUACION]: (state, { data }) => {
        return {
            ...state,
            evaluacion: data,
        };
    },
    [ITEM_TARJETA]: (state, { data }) => {
        return {
            ...state,
            item_tarjeta: data,
        };
    },
    [ITEM_KPIS]: (state, { data }) => {
        return {
            ...state,
            item_kpis: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
    item_kpis:{},
    data_tarjetas: {
        default_source: null,
        data: []
    },
    item: {},
    item_tarjeta: null,
    evaluacion: {
        cohorte: null,
        mes_proximo: 3,
        anio_proximo: 2020
    },
};

export default handleActions(reducers, initialState);

