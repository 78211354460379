import React, { useState } from 'react'
import { Timeline } from 'antd';
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";

const imgPrueba = require('../../../../../assets/img/icono-empresa.png')
// Constantes utiles
const VALUES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13]
const TOP_BASE = 180;
const HEIGHT_BASE = 80;

function Tablero({total_empresas, punteo_maximo, results, punteo_menor, irDetalle, ReactTooltip}) {

    const [seleccionado, setSeleccionado] = useState(null);

    const getNivel = (index) => {
        let multiplo = 3;
        if (results.length < 5) multiplo = 1;
        else if (results.length < 14) multiplo = 2;
        else if (results.length > 14) multiplo = 3;
        if (index == 0 || ((index % multiplo) == 0))
            return 1;
        if (index == 1 || ((index -1) % multiplo == 0))
            return 8;
        return 16;
    }

    const getPosiciones = () => {
        const posiciones = [];
        let posicion = 0;
        for (const item of results) {
            posicion = parseInt(item.punteo) - parseInt(punteo_menor);
            posicion = (posicion * 100) / (parseInt(punteo_maximo) - parseInt(punteo_menor));
            posiciones.push(posicion);
        }
        return posiciones;
    }

    const seleccionarItem = (index) => {
        setSeleccionado(null);
        if (index != seleccionado)
            setSeleccionado(index);
    }

    return (
        <div className="d-flex flex-row flex-1">
            <div className="tablero pt-5 px-4"
                style={{height: results.length < 5 ? "50vh" : results.length < 14 ? "80vh" : "100vh", overflowX: "auto"}}>
                <ProgressBar percent={0} stepPositions={getPosiciones()}>
                    {results.map((item, index) => (
                        <Step key={index} transition="scale">
                            {({ accomplished }) => (
                                <div className="">
                                    {(item.punteo==punteo_menor||item.punteo==punteo_maximo||item.punteo%10==0) &&
                                        <div className="tablero-scala">
                                            <span className="azul text-equal">{`${item.punteo}`}</span>
                                            <div className="tablero-scala-linea"></div>
                                        </div>
                                    }
                                    <div className={`
                                        ${item.punteo==punteo_menor||item.punteo==punteo_maximo||item.punteo%10==0 ? 'tablero-dot' : 'tablero-dot-transparent' } 
                                        ${seleccionado == index ? 'activo' : ''}
                                        `} 
                                        onClick={() => seleccionarItem(index)}
                                    >
                                    </div>
                                    {(item.empresas && item.empresas.length) ? (
                                        <div className={`tablero-linea-vertical ${seleccionado == index ? 'activo' : ''}`} style={{height: `${((HEIGHT_BASE + 4) * item.empresas.length)+(18 * (item.empresas.length - 1))}px`}}></div>
                                    ) : (<div></div>)}
                                    <div className={`tablero-timeline ${seleccionado == index ? 'activo' : ''}`} style={{ width: '28px' }}>
                                        <Timeline mode="left">
                                            {item.empresas.map((item2, index2) => (
                                                <div onClick={() => irDetalle(item2.id)}>
                                                    <Timeline.Item 
                                                        key={index2} 
                                                        dot={<img src={item2.empresa ? item2.empresa.logo : imgPrueba} 
                                                        className="dot-empresa" />}
                                                        style={{cursor: "pointer", marginTop: `${HEIGHT_BASE - ((item.empresas.length - 1) * 10)}px`, height: '18px'}}
                                                        data-for={`info${item2.empresa.id}`}
                                                        data-tip=''
                                                    >
                                                        
                                                    </Timeline.Item>
                                                    <div>
                                                        <span className="timeline-item-empresa" 
                                                            style={{ 
                                                                marginLeft: `-${item2.punteo.toString().length * 2}px`,
                                                                backgroundColor: 'white'
                                                            }}
                                                        >
                                                            {`${item2.punteo}`}
                                                        </span>
                                                    </div>
                                                    <ReactTooltip 
                                                        id={`info${item2.empresa.id}`}  
                                                        place="bottom" 
                                                        type='dark'
                                                        effect="float"
                                                    >
                                                        <span className="timeline-item-empresa">{`${item2.empresa ? item2.empresa.nombre : ''}`}</span>
                                                        <br />
                                                        <span className="timeline-item-dueño">{item2.empresa ? item2.empresa.nombre_admin : ''}</span>
                                                    </ReactTooltip>
                                                </div>
                                            ))}
                                        </Timeline>
                                    </div>
                                </div>
                            )}
                        </Step>
                    ))}
                </ProgressBar>
            </div>
        </div>
    )
}

export default Tablero;
