import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Toolbar extends Component {
    render() {
        const { to, textBtn } = this.props;

        return (
            <div className="d-flex flex-1 row px-3">
                <div className="col-md-4 col-6 px-0">
                    {(to !== undefined) && (
                        <Link to={to} className="btn btn-primary mb-3">{textBtn}</Link>
                    )}
                </div>
                {/* mas botones */}
                <div className="col-md-4"></div>
                <div className="col-md-4 col-6 px-0">
                </div>
            </div>
        )
    }
}
