import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/cuenta/perfilEmpresa';
import { actions as actionsLogin } from '../../../../../redux/modules/cuenta/login';
import PagoMembresia from './PagoMembresia';

const ms2p = (state) => {
    return {
        ...state.perfilEmpresa,
        me: state.login.me
    };
};

const md2p = { ...actions, logOut: actionsLogin.logOut };

export default connect(ms2p, md2p)(PagoMembresia);
