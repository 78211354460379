import React, { Fragment } from 'react';
import { RECOMENDADO, USUARIO } from '../../../../utility/constants';
import { Link } from 'react-router-dom';
import { withTranslation } from  'react-i18next';
import { api } from 'api';
import  LoadMask from '../LoadMask/LoadMask';
const iconoTaller = require('../../../../../assets/img/iconos/taller.png');


const CardProximosEventos = (props)=>{
    const [loader, setLoader] = React.useState(false);
    const [data, setData] = React.useState({'results':[],'count':0});
    const [verTodos, setVerTodos] = React.useState(false);
    
    React.useEffect(()=>{
        obtenerEventos();
    },[]);

    React.useEffect(()=>{
        obtenerEventos();
    },[verTodos]);

    const obtenerEventos = () => {
        const cohorte = localStorage.getItem('cohorte');
        const params = { };
        if (cohorte){
            params.cohorte = JSON.parse(cohorte).id;
        }

        if(verTodos){
            params.todos = ''
        }
    
        setLoader(true);
        api.get(`evento/proximos_eventos`, params).then((response) => {
            setData(response)
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoader(false);
        });
    };

    const getDescription = (descripcion=undefined)=>{
        const div = document.createElement("div");
        div.innerHTML = descripcion ? descripcion : ''
        const texto = String(div.textContent);
        return texto.substring(0, 25)
    }

    return (
        <Fragment>
            <LoadMask loading={loader} blur >
                {(data.results&&data.results.length) ? (
                    <div className={`mb-4 card `}>
                        <div className='card-content-form flex-column px-0 py-0'>
                            <div className="card-intereses-header py-2">
                                <span className="azul-gris-oscuro text-sm ml-3">{props.t('Próximos eventos')}</span>

                            </div>
                            <div className="card-intereses-body">
                                {data.results.map((item, index) => (
                                    <div key={index} className="card-row-interes py-2 px-2">
                                        <div className="d-flex justify-content-center align-items-center flex-1">
                                            <img src={iconoTaller} style={{ width: "2.5rem" }} />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-start flex-3 px-1">
                                            <span className={`text-equal azul-gris-oscuro`}>{`${item.titulo}`}</span>
                                            <span style={{width:"10rem"}} className="texto-cortado text-equal2 gris">{getDescription(item.descripcion)}</span>
                                        </div>
                                        <div className="d-flex flex-2 justify-content-center align-items-center">
                                            <Link to={`/taller/${item.id}`} className="btn-intereses btn-primary" style={{padding: "0.4rem 1rem !important"}}>{props.t('Ver taller')}</Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            { data.count>4 &&(
                                <div className="card-intereses-footer">
                                    <span className="text-right azul text-touch" onClick={e=>setVerTodos(!verTodos)} >{ !verTodos ? props.t('Ver todos los próximos talleres') : props.t('Ocultar')}</span>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        {(props.me&&props.me.tipo_usuario===USUARIO)&&(
                            <div className={`mb-4 card `}>
                                <div className='card-content-form flex-column justify-content-center px-5 py-5 my-5'>
                                    <br/>
                                        <span className="azul-gris-oscuro text-center" >
                                            {props.t('No hay talleres disponibles')}
                                        </span>
                                    <br/>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </LoadMask>
        </Fragment>

    )
}

export default withTranslation()(CardProximosEventos);
