import React, { Fragment } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RECOMENDADO, USUARIO } from '../../../../utility/constants';
const imgDefault =  require('../../../../../assets/img/avatar.png');
const iconoTaller = require('../../../../../assets/img/iconos/taller.png');

function CardInteres(props) {
    const { talleres, fullWidth, empresas, perfilEmpresa, small } = props;

    const getDescription = (descripcion=undefined)=>{
        const div = document.createElement("div");
        div.innerHTML = descripcion ? descripcion : ''
        const texto = String(div.textContent);
        return texto.substring(0, 25)
    }

    return (
        <Fragment>
            {(empresas && empresas.length) || (talleres && talleres.length) ? (
                <div className={`mb-4 card ${fullWidth ? 'w-100' : ''}`}>
                    <div className='card-content-form flex-column px-0 py-0'>
                        <div className="card-intereses-header py-2">
                            {(perfilEmpresa?(
                                <span className="azul-gris-oscuro text-sm ml-3">{props.t('Empresas en su grupo')}</span>

                            ):(
                                <span className="azul-gris-oscuro text-sm ml-3">{talleres ? props.t('Próximos eventos') : props.t('Empresas en tu grupo')}</span>

                            ))}
                        </div>
                        <div className="card-intereses-body">
                            {(empresas && empresas.length) ? (
                                empresas.map((item, index) => (
                                    <div key={index} className="card-row-interes py-2 px-2">
                                        <div className="d-flex justify-content-center align-items-center flex-1">
                                            <img src={item.empresa.logo ? item.empresa.logo : imgDefault} className="card-intereses-logo" style={{width: fullWidth ? "3rem" : "2.5rem"}}/>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-start flex-3 px-1">
                                            <span className={`${small ? '' : 'texto-cortado chiquito' } text-equal azul-gris-oscuro`}>{`${props.t('Empresa')} ${item.empresa.nombre}`}</span>
                                            <span style={{width: fullWidth ? "100%" : "10rem"}} className="texto-cortado text-equal2 gris">{item.empresa.descripcion_empresa}</span>
                                        </div>
                                        <div className="d-flex flex-2 justify-content-center align-items-center">
                                            <Link to={`/empresa/${item.empresa.id}`} className="btn-intereses btn-primary" style={{padding: "0.4rem 1rem !important"}}>{props.t('Ver perfil')}</Link>
                                        </div>
                                    </div>
                                ))
                            ) : (<div></div>) }
                            {(talleres && talleres.length) ? (
                                talleres.map((item, index) => (
                                    <div key={index} className="card-row-interes py-2 px-2">
                                        <div className="d-flex justify-content-center align-items-center flex-1">
                                            <img src={iconoTaller} style={{ width: "2.5rem" }} />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-start flex-3 px-1">
                                            <span className={`${small ? '' : 'texto-cortado chiquito' } text-equal azul-gris-oscuro`}>{`${item.titulo}`}</span>
                                            <span style={{width: fullWidth ? "100%" : "10rem"}} className="texto-cortado text-equal2 gris">{getDescription(item.descripcion)}</span>
                                        </div>
                                        <div className="d-flex flex-2 justify-content-center align-items-center">
                                            <Link to={`/taller/${item.id}`} className="btn-intereses btn-primary" style={{padding: "0.4rem 1rem !important"}}>{props.t('Ver taller')}</Link>
                                        </div>
                                    </div>
                                ))
                            ) : (<div></div>) }
                        </div>
                        <div className="card-intereses-footer">
                            {(talleres) ? (
                                <Link className="text-right azul" to="/talleres">{props.t('Ver listado de talleres')}</Link>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    {(props.me&&props.me.tipo_usuario===USUARIO)&&(
                        <div className={`mb-4 card ${fullWidth ? 'w-100' : ''}`}>
                            <div className='card-content-form flex-column justify-content-center px-5 py-5 my-5'>
                                <br/>
                                {(talleres) ? (
                                    <span className="azul-gris-oscuro text-center" >
                                        {props.t('No hay talleres disponibles')}
                                    </span>
                                        
                                    ) : (
                                        <span className="azul-gris-oscuro text-center" >
                                            {props.t('No hay sugerencias')}
                                        </span>
                                    )}
                                <br/>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </Fragment>

    )
}

export default CardInteres;
