import { createAction, handleActions } from 'redux-actions';
import {initialize as initializeForm} from "redux-form";
import { api } from "api";

const LOADER = 'LOADER_CALIFICACION_EVENTO';
const DATA1 = 'DATA1_CALIFICACION_EVENTO';
const PAGE1 = 'PAGE1_CALIFICACION_EVENTO';
const SEARCH1 = 'SEARCH1_CALIFICACION_EVENTO';
const DATA2 = 'DATA2_CALIFICACION_EVENTO';
const PAGE2 = 'PAGE2_CALIFICACION_EVENTO';
const ITEM = 'ITEM_CALIFICACION_EVENTO';
const SEARCH2 = 'SEARCH2_CALIFICACION_EVENTO';
const FILTRO = 'FILTRO_DETALLE_CALIFICACION_EVENTO';



// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (data, type=DATA1) => ({
    type,
    data
});

const setPage = (page, type=PAGE1) => ({
    type,
    page
});

const setSearch = (search, type=SEARCH1) => ({
    type,
    search,
});
const setItem = (data, type=ITEM)=>({
    type,
    data
})

const setFiltro = (filtro, type=FILTRO)=>({
    type,
    filtro
})

// ------------------------------------
// Actions
// ------------------------------------

const listarEventos = (page=1) => (dispatch, getStore) => {
    const resource = getStore().calificacionesEventos;
    const params = { page };
    params.search = resource.search1;
    dispatch(setLoader(true));
    api.get('reportes/calificaciones_eventos', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const detalleEvento = (id, page=1) => (dispatch, getStore) => {
    const resource = getStore().calificacionesEventos;
    const params = { page };
    // filtrando la data
    console.log(resource.filtro)
    if(resource.filtro===2) params.ceo = true
    if (resource.filtro===3) params.participante = true
    dispatch(setLoader(true));
    api.get(`reportes/${id}/detalle_calificacion_evento`, params).then((response) => {
        dispatch(setData(response.reporte, DATA2));
        dispatch(setItem(response.evento))
        dispatch(setPage(page, PAGE2));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getCalificacion =(id)=> (dispatch)=>{
    dispatch(setLoader(true));
    return api.get(`/calificar_evento/${id}`).then((response)=>{
        dispatch(initializeForm('CalificacionForm',response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const searchChange1 = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarEventos());
};

const searchChange2 = search => (dispatch) => {
    dispatch(setSearch(search, SEARCH2));
    dispatch(listarAsistencias());
};
const clearForm = ()=>(dispatch)=>{
    dispatch(initializeForm('CalificacionForm',{}));

}

const changeFiltro =(id, filtro)=>(dispatch)=>{
    dispatch(setFiltro(filtro));
    dispatch(detalleEvento(id))
}

const descargarExcel = () => (dispatch, getStore) => {
    const resource = getStore().asistencias;
    let endpoint = '/api/reportes/asistencia_eventos?excel=true&';
    if (resource.cohorte)
        endpoint += `cohorte=${resource.cohorte.id}&`;
    if (resource.evento)
        endpoint += `evento=${resource.evento.id}&`;

    endpoint += `auth_token=${localStorage.getItem("token")}`
    window.open(endpoint, '_blank');
}

export const actions = {
    listarEventos,
    detalleEvento,
    searchChange1,
    searchChange2,
    getCalificacion,
    clearForm,
    changeFiltro,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA1]: (state, { data }) => {
        return {
            ...state,
            data1: data,
        };
    },
    [DATA2]: (state, { data }) => {
        return {
            ...state,
            data2: data,
        };
    },
    [PAGE1]: (state, { page }) => {
        return {
            ...state,
            page1: page,
        };
    },
    [PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [SEARCH1]: (state, { search }) => {
        return {
            ...state,
            search1: search,
        };
    },
    [SEARCH2]: (state, { search }) => {
        return {
            ...state,
            search2: search,
        };
    },
    [FILTRO]: (state, { filtro }) => {
        return {
            ...state,
            filtro: filtro,
        };
    },
};

export const initialState = {
    loader: false,
    filtro:1,
    data1: {
        count: 0,
        results: []
    },
    data2: {
        count: 0,
        results: []
    },
    page1: 1,
    page2: 1,
    item: {},
    search1: '',
    search2: '',
};

export default handleActions(reducers, initialState);
