import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';
import Card from '../../../Utils/Cards/Card';

const avatar = require("../../../../../../assets/img/avatar.png");

const EmpresasTab = (props) => {
    const { empresas, page_emp } = props;
    return (
        <Card noPadding={true}>
            <div className="d-flex flex-column flex-1 py-3 px-3">

                <span className="text-xsm gris ml-4">{`${props.t('Mostrando')} ${empresas.results.length} ${props.t('resultados')}`}</span>
                <div className="d-flex flex-column flex-1 my-2">
                    <span className="text-sm bold azul-gris-oscuro ml-4">{props.t('Personas')}</span>
                    {empresas.results.map((item, index) => (
                        <Link key={index} to={`/empresa/${item.id}`} className="d-flex flex-row borde-bottom flex-1 py-3">
                            <div className="d-flex align-items-center justify-content-center flex-1">
                                <img style={{width: "2.5rem", height: "2.5rem"}} className="logo-tabla" src={item.logo ? item.logo : avatar} />
                            </div>
                            <div className="d-flex flex-column justify-content-center flex-4">
                                <span className="text-sm bold gris-oscuro mr-2">{item.nombre}</span>
                                <span className="text-xs gris">{item.pais}</span>
                            </div>
                        </Link>
                    ))}
                </div>
                {(empresas.results.length) ? (
                    <div className="d-flex flex-row flex-1 align-items-center my-3">
                        <Pagination pageSize={10} showSizeChanger={false} current={page_emp} total={empresas.count} onChange={props.resultadosEmpresas}/>
                    </div>
                ) : (
                    <div className="d-flex flex-row flex-1 justify-content-center align-items-center my-3">
                        <span className="text-sm bold gris-oscuro">{props.t('Sin resultados')}</span>
                    </div>
                )}
            </div>
        </Card>
    )
}

export default EmpresasTab;
