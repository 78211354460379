import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderCountrySelect, renderTextArea, SelectField, renderDatePicker3, TimeZoneSelectField } from '../../Utils/renderField/renderField';
import { TIPO_IDIOMAS } from '../../../../utility/constants';
import { withTranslation } from 'react-i18next';
import CustomTooltip from '../../Utils/Tooltip';
import Terminos from '../../Utils/Terminos';

const validators = (values, props)=>{
    const errors= [];
    if (!values.pais){
        errors.pais= `${props.t('Campo requerido')}`;
    }
    if (!values.idiomas){
        errors.idiomas = `${props.t('Campo requerido')}`;
    }
    if (!values.fecha_nacimiento){
        errors.fecha_nacimiento = `${props.t('Campo requerido')}`;
    }
    if (!values.timezone){
        errors.timezone = `${props.t('Campo requerido')}`
    }
    if (values.descripcion){
        const conteo = String(values.descripcion).split(' ').length
        if(conteo>100){
            errors.descripcion = `${props.t('Has sobre pasado el limite de palabras')}`
        }
    }
    return errors;
}


const PasoDosForm = (props) => {
    const { handleSubmit, previousStep } = props;
    const [acepto, setAcepto] = React.useState(false);

    return (
        <form className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <div className="d-flex justify-content-between" >
                    <label htmlFor="fecha_nacimiento">{props.t('Fecha de nacimiento')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Seleccione el mes, dia y año de su fecha de nacimiento')+'.'} />
                </div>
                <Field name="fecha_nacimiento" label="Fecha nacimiento" component={renderDatePicker3} noFuturos={true} />
            </div>
            <div className="form-group has-feedback">
                <div className="d-flex justify-content-between" >
                    <label htmlFor="timezone">{props.t('Zona horaria')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Seleccione su zona horaria local, ejemplo si eres de Guatemala esta seria America/Guatemala')+'.'} />
                </div>
                <Field name="timezone" component={TimeZoneSelectField} className="d-flex flex-grow-1" />
            </div>
            <div className="form-group has-feedback">
                <div className="d-flex justify-content-between">
                    <label htmlFor="descripcion">{props.t('Acerca de')} <span className="gris m-0 text-xsm">{props.t('(Limite 100 palabras)')}</span></label>
                    <CustomTooltip msj={props.t('Ingrese una pequeña descripcion de ¿quien es usted?,  ¿a que se dedica? y cuales han sido sus logros profesionales')+'.'} />
                </div>
                <Field name="descripcion" label="Acerca de" component={renderTextArea} rows={4} type="text" className="form-control" />
            </div>
            <div className="form-group has-feedback">
                <div className="d-flex justify-content-between" >
                    <label htmlFor="pais">{props.t('País') } <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Seleccione su país')+'.'} />
                </div>
                <Field name="pais" label="País" component={renderCountrySelect} type="text" />
            </div>
            <div className="form-group has-feedback">
                <div className="d-flex justify-content-between" >
                    <label htmlFor="idiomas">{props.t('Idiomas')} <span className="gris m-0 text-xsm">{props.t('(Seleccione todos los que hablas con fluidez.)')}</span></label>
                    <CustomTooltip msj={props.t('Seleccione los idiomas que habla')+'.'} />
                </div>
                <Field name="idiomas" label="Idiomas" component={SelectField}  isMulti options={TIPO_IDIOMAS} />
            </div>
            <Terminos onChange={setAcepto} />
            <div className="d-none d-lg-flex buttons-box mt-4">
                <button type="button" onClick={previousStep} className="btn btn-secondary ml-3">{props.t('Regresar')}</button>
                <button type="submit" disabled={!acepto} className="btn btn-primary ml-3">{props.t('Registrar')}</button>
            </div>
            <div className="d-flex d-lg-none flex-column">
                <button type="button" onClick={previousStep} className="btn btn-secondary flex-1 mt-3">{props.t('Regresar')}</button>
                <button type="submit" disabled={!acepto} className="btn btn-primary flex-1 mt-3">{props.t('Registrar')}</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'FormGrowthAngel', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: validators,
})(withTranslation()(PasoDosForm));
