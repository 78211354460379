import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import Tablero from '../../../Utils/Tablero/index';
import Card from '../../../Utils/Cards/Card';
import { USUARIO, ENCUENTRO_GUERREROS, FAMILY_MEETING, TALLER,MENTORIA } from '../../../../../utility/constants'
import { withTranslation } from 'react-i18next';
import ReactTooltip from "react-tooltip";


class TableroResultados extends Component {

    componentDidMount() {
        const id = this.props.match.params.id;
        const { leerEvaluacion, obtenerResultados } = this.props;
        leerEvaluacion(id, null);
        obtenerResultados(id);

        this.resultados = setInterval(() => this.actualizarResultados(), 15000);
    }

    componentWillUnmount() {
        clearInterval(this.resultados);
    }

    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            ReactTooltip.rebuild();
        }
        }

    actualizarResultados = () => {
        const id = this.props.match.params.id;
        const { leerEvaluacion, obtenerResultados } = this.props;
        // leerEvaluacion(id, null);
        obtenerResultados(id);
    }
    getRegresarBtn =()=>{
        const { item }= this.props;
        if(item.publicacion){
            switch(item.publicacion.tipo_publicacion){
                case ENCUENTRO_GUERREROS:
                    return (<Link to={'/panel'} className="btn btn-secondary d-flex flex-1 mx-xl-5 mt-1">{this.props.t('Regresar')}</Link>)
                    break;
                case FAMILY_MEETING:
                    return (<Link to={'/family_meetings'} className="btn btn-secondary d-flex flex-1 mx-xl-5 mt-1">{this.props.t('Regresar')}</Link>)
                    break;
                case TALLER:
                    return (<Link to={'/talleres'} className="btn btn-secondary d-flex flex-1 mx-xl-5 mt-1">{this.props.t('Regresar')}</Link>)
                    break;
                case MENTORIA:
                    return (<Link to={'/mentorias'} className="btn btn-secondary d-flex flex-1 mx-xl-5 mt-1">{this.props.t('Regresar')}</Link>)
                    break;
            }
        }
    }
    irDetalle = (idRespuesta) => {
        const { me } = this.props;
        if (me.tipo_usuario != USUARIO) {
            const id = this.props.match.params.id;
            this.props.history.push(`/evaluacion/${id}/detalle/${idRespuesta}`);
        }
    }
    render() {
        const { loader, item, data, me } = this.props;
        const id = this.props.match.params.id;

        return (
            <div className="social-layout mb-4 col-12 mt-0">
                <div className="px-3 px-lg-0">
                    <Card noPadding={true}>
                        <div className="d-flex flex-lg-row flex-column flex-1 align-items-lg-center px-lg-4 px-3 my-3">
                            {( me && me.tipo_usuario == USUARIO ) && (
                                <div className="d-flex flex-row flex-2 align-items-center">
                                    <div className="flex-1">
                                        <span className="text-xs bold azul">{this.props.t('Resultados de la Autoevaluación')}</span>
                                    </div>
                                    <div className="flex-1">
                                        <div className="circulo-puntos">
                                            <span className="blanco text-sm bold">{`${data.punteo_empresa} PT`}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex flex-column flex-2">
                                <span className="text-sm">{this.props.t('Taller')}:</span>
                                <span className="celeste-oscuro bold text-sm">{item.publicacion ? item.publicacion.titulo : ''}</span>
                            </div>
                            <div className="d-flex flex-column flex-2">
                                <span className="text-sm">{this.props.t('Autoevaluación')}:</span>
                                <span className="celeste-oscuro bold text-sm">{item.nombre}</span>
                            </div>
                            <div className="d-flex flex-column flex-2 px-lg-5">
                                {( me && me.tipo_usuario == USUARIO ) && (
                                    <React.Fragment>
                                        {(data&&data.empresa_id!==0)&&(
                                            <Link to={`/evaluacion/${id}/detalle/${data.empresa_id}`} className="btn btn-primary d-flex flex-1 mx-xl-5 mb-2">{this.props.t('Ver respuesta oficial')}</Link>
                                        )}
                                        {(data&&data.res_usuario!==0)&&(
                                        <Link to={`/evaluacion/${id}/detalle/${data.res_usuario}`} className="btn btn-secondary-fill d-flex flex-1 mx-xl-5 mb-1">{this.props.t('Ver mis respuestas')}</Link>
                                        )}
                                    </React.Fragment>
                                )}
                                {this.getRegresarBtn()}
                            </div>
                        </div>
                    </Card>
                    <Card noPadding={true}>
                        <div className="d-flex flex-1 px-lg-2 px-2 my-3">
                            <Tablero {...data} irDetalle={this.irDetalle} ReactTooltip={ReactTooltip}/>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }

}

export default withTranslation()(TableroResultados)
