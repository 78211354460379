import React, { Component } from "react";
import { Fragment } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import CardIntercambios from "../../../Utils/Cards/CardIntercambios";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Select from 'react-select'
import { withTranslation, getI18n, WithTranslation } from 'react-i18next';
import '../../../../../i18nextInit'

const options = [
    { value: getI18n().t('Aprobados'), label: getI18n().t('Aprobados') },
    { value: getI18n().t('Pendientes'), label: getI18n().t('Pendientes') },
    { value: getI18n().t('Todos'), label: getI18n().t('Todos') }
]

import IntercambioForm from "../IntercambioForm";
import { ADMIN, INTERCAMBIO, USUARIO } from '../../../../../utility/constants';

class ListaGeneral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            expandir: false,
            editar:false,
            imagen: null,
            progress: {
                conteo: 0,
                porcentaje: 0,
            },
            srcImagen:null,
        };
    }
    componentWillMount=()=> {
        const { listar, match, leer, item } = this.props;
        listar(1);
    }
    onExpandir = () => {
        this.setState({ expandir: !this.state.expandir });
    };
    onSubmit = (values) => {

        const { match } = this.props;
        if (!this.state.expandir) {
            this.setState({ expandir: true });
        } else {
            if (this.state.progress && this.state.progress.conteo <= 280) {
                this.setState({ expandir: false });
                const body = { ...values };
                body.tipo_publicacion = INTERCAMBIO;
                const array = [];
                if(this.state.imagen&&this.state.imagen.file){
                    array.push({ file: this.state.imagen.file, name: "imagen" });
                }else{
                    array.push({ file: null, name: "imagen" });
                }
                if (this.state.editar&&this.props.item.id) {
                    this.props.editar(values.id, body, array);
                    this.setState({imagen:null});
                    this.setState({editar:false});
                } else {
                    this.props.onSubmit(body, array);
                    this.setState({imagen:null});
                }
            }
        }
    };
    removeImagen=()=>{
        this.setState({imagen:null})
    }
    setFiltro = (filtro)=>{
        if(filtro===this.props.t('Aprobados')) this.props.listar(1,{valida:true})
        if(filtro===this.props.t('Pendientes')) this.props.listar(1,{valida:false})
        if(filtro===this.props.t('Todos')) this.props.listar()
    }
    setProgress = (conteo, porcentaje) => {
        if (conteo && porcentaje) {
            this.setState({ progress: { conteo, porcentaje } });
        }
    };
    setImagen = (imagen) => {
        this.setState({ imagen });
    };
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    render() {
        const { data, loader, listar, page, match, me } = this.props;
        return (
            <div className="social-layout mb-4 col-12 mt-0">
                <LoadMask loading={loader} blur fixed>
                    <InfiniteScroll
                        dataLength={data.results.length} //This is important field to render the next data
                        next={() => listar(page + 1, null , false)}
                        hasMore={true}
                        loader={<LoadMask loading={loader} blur></LoadMask>}
                        endMessage={
                            <div className="d-flex flex-row flex-1 justify-content-center">
                                {(data.count) ? (
                                    <span className="text-md gris-oscuro regular text-center">
                                        {this.props.t('No cuenta con más intercambios')}
                                    </span>
                                ) : (
                                    <span style={{marginTop: "20%"}} className="text-md gris-oscuro regular text-center">
                                        {this.props.t('No cuenta con intercambios actualmente')}
                                    </span>
                                )}
                            </div>
                        }
                        // below props only if you need pull down functionality
                        refreshFunction={async () => {
                            await listar(1);
                            return true;
                        }}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={100}
                        pullDownToRefreshContent={
                            <Fragment>
                                <h3 className="text-md blanco regular text-center" style={{ textAlign: 'center' }}>
                                    <i className="fas fa-chevron-circle-down blanco mr-2" />
                                    {this.props.t('Deslizar para cargar')}
                                </h3>
                            </Fragment>
                        }
                        releaseToRefreshContent={
                            <Fragment>
                                <h3 className="text-md blanco regular text-center" style={{ textAlign: 'center' }}>
                                    <i className="fas fa-chevron-circle-up blanco mr-2" />
                                    {this.props.t('Suelte para cargar')}
                                </h3>
                            </Fragment>
                        }
                        hasMore={data.next}
                    >
                        <div
                            key={
                                match.params.id
                                    ? match.params.id
                                    : "historiaForm"
                            }>
                            {
                                (me&&me.tipo_usuario===USUARIO)&&(
                                    <IntercambioForm
                                        expandir={this.state.expandir}
                                        onSubmit={this.onSubmit}
                                        imagen={this.state.imagen}
                                        setImagen={this.setImagen}
                                        progress={this.state.progress}
                                        setProgress={this.setProgress}
                                        onExpandir={this.onExpandir}
                                        editar={this.state.editar}
                                        item={this.props.item}
                                        openMoldal={this.onOpenModal}
                                        srcImagen={this.state.srcImagen}
                                        removeImagen={this.removeImagen}
                                        me={this.props.me}
                                        t={this.props.t}
                                    />
                                )
                            }
                        </div>
                            {
                                data.results.length ? (
                                    data.results.map((item) => (
                                    <div key={item.id}>
                                        <CardIntercambios id={item.id} item={item} me={this.props.me}
                                            aprobar={this.props.aprobar} eliminar={this.props.eliminar}
                                            editar={this.props.editar} meGusta={this.props.meGusta}
                                            removerMeGusta={this.props.removerMeGusta}
                                            listarMeGustas={this.props.listarMeGustas}
                                            />
                                    </div>
                            ))
                                ):( null)
                            }
                    </InfiniteScroll>
                </LoadMask>
            </div>
        );
    }
}

export default withTranslation()(ListaGeneral);
