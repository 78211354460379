import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// idiomas soportados
import { I18NEXT_IDIOMAS } from '../../I18next';

const resources = ()=>{
    const files = {};
    I18NEXT_IDIOMAS.forEach((idioma)=>{
        const source = require(`../assets/idiomas/utilizar/${idioma}.json`);
        files[`${idioma}`] = { translation: source }
    })
    return files;
}

i18n.use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: resources(),
        fallbackLng: "en",
        debug: false,
        lng: "en",
        supportedLngs: I18NEXT_IDIOMAS,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            wait: true,
        },
    });

export default i18n;
