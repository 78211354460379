import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_NOTIFICACIONES';
const DATA = 'DATA_NOTIFICACIONES';
const PAGE = 'PAGE_NOTIFICACIONES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (data) => ({
    type: DATA,
    data
});

const setPage = (page) => ({
    type: PAGE,
    page
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page=1) => (dispatch) => {
    dispatch(setLoader(true));
    api.get('notificaciones', { page }).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    listar,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        count: 0,
        results: []
    },
    page: 1,
};

export default handleActions(reducers, initialState);

