import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {push} from "react-router-redux";
import _ from "lodash";
import { SwalExito, SwalError, SwalWarning } from '../../../common/components/Utils/Notificaciones';
import { getI18n } from 'react-i18next';


const LOADER = 'LOADER_EVALUACIONES';
const DATA = 'DATA_EVALUACIONES';
const ITEM_DATA = 'ITEM_EVALUACIONES';
const ITEM2 = 'ITEM2_EVALUACIONES';
const PAGE = 'PAGE_EVALUACIONES';
const ORDERING = 'ORDERING_EVALUACIONES';
const SEARCH = 'SEARCH_EVALUACIONES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => {
    window.tasks = window.tasks ? window.tasks : 0
    window.tasks += loader ? 1 : -1
    loader = !loader && window.tasks === 0 ? loader : true
    return ({
        type: LOADER,
        loader,
    })
};

const setData = data => ({
    type: DATA,
    data,
});

const setItem = (item, type=ITEM_DATA) => ({
    type,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const leerEvaluacion = (id, setBloqueName) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`evaluacion/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('ResolverEvaluacionForm', response));
        if (setBloqueName) setBloqueName(response.bloques[0].nombre);
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const descargarExcel = (id, empresa) => () => {
    let endpoint = `/api/evaluacion/${id}/descargar_resultado_excel?excel=true&empresa=${empresa}&`;

    endpoint += `auth_token=${localStorage.getItem("token")}`
    window.open(endpoint, '_blank');
};

const leerRespuesta = (id, bandera = true, loader = true) => (dispatch) => {
    if(loader) dispatch(setLoader(true));
    api.get(`respuesta_evaluacion/${id}`).then((response) => {
        const data = {...response};

        const bloques = [];
        for (const item of response.respuestas) {
            const _bloque = _.find(bloques, { 'id': item.pregunta.bloque});
            const _bloque_id = _.findIndex(bloques, { 'id': item.pregunta.bloque});
            if (_bloque) {
                _bloque.preguntas.push({...item, opcion_elegida: null});
                bloques[_bloque_id] = _bloque
            } else {
                bloques.push({
                    id: item.pregunta.bloque,
                    nombre: item.pregunta.bloque_nombre,
                    preguntas: [
                        {...item, opcion_elegida: null}
                    ]
                })
            }
        }

        data.bloques = bloques;
        dispatch(setItem(data, ITEM2));
        if (bandera)
            dispatch(initializeForm('ResolverEvaluacionOficialForm', {bloques: bloques}));
    }).catch(() => {
    }).finally(() => {
        if(loader) dispatch(setLoader(false));
    });
};

const leerRespuesta2 = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`respuesta_evaluacion/${id}`).then((response) => {
        const data = {...response};

        const bloques = [];
        for (const item of response.respuestas) {
            const _bloque = _.find(bloques, { 'id': item.pregunta.bloque});
            const _bloque_id = _.findIndex(bloques, { 'id': item.pregunta.bloque});
            if (_bloque) {
                _bloque.preguntas.push({...item, opcion_elegida: `${item.opcion_elegida}`});
                bloques[_bloque_id] = _bloque
            } else {
                bloques.push({
                    id: item.pregunta.bloque,
                    nombre: item.pregunta.bloque_nombre,
                    preguntas: [
                        {...item, opcion_elegida: `${item.opcion_elegida}`}
                    ]
                })
            }
        }

        data.bloques = bloques;
        dispatch(setItem(data, ITEM2));
        dispatch(initializeForm('ResolverEvaluacionOficialForm', {bloques: bloques}));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerResultados = (id, loader = true) => (dispatch) => {
    if(loader) dispatch(setLoader(true));
    api.get(`evaluacion/${id}/obtener_resultados`).then((response) => {
        const data ={...response};
        const results = [];
        let menor = data.punteo_maximo;
        for (const item of data.results) {
            if (item.punteo < menor)
                menor = item.punteo
        }
        data.punteo_maximo = data.punteo_maximo;
        data.punteo_menor = menor < 2 ? 0 : menor;

        // Encontrar el mas cercano a multiplo de 5
        while (data.punteo_menor % 5 !== 0) {
            data.punteo_menor = data.punteo_menor - 1;
        }

        while (data.punteo_maximo % 5 !== 0) {
            data.punteo_maximo = data.punteo_maximo + 1;
        }

        for (let i = data.punteo_menor; i <= data.punteo_maximo; i++) {
            const sub = _.filter(data.results, { punteo: i});

            if (sub && sub.length){
                results.push({punteo: i, empresas: [...sub]})
            } else {
                if (i % 5 == 0){
                    results.push({punteo: i, empresas: []})
                }
            }
        }

        data.results = results;
        dispatch(setData(data));
    }).catch(() => {
    }).finally(() => {
        if(loader) dispatch(setLoader(false));
    });
}

const existeEvaluacion = (id, setPaso = null, setBloqueName = null, bandera = true, loader = true) => (dispatch, getStore) => {
    if(loader) dispatch(setLoader(true));
    api.get(`evaluacion/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('ResolverEvaluacionForm', response));
        if (setBloqueName) setBloqueName(response.bloques[0].nombre);

        const resource = getStore().evaluaciones;
        const me = getStore().login.me;

        if(loader) dispatch(setLoader(true));
        api.get(`respuesta_evaluacion/existe_respuesta`, { evaluacion: id }).then((response) => {
            if (response.existe_respuesta) {
                if (resource.item.responsable == me.id) {
                    dispatch(leerRespuesta(response.id_respuesta, bandera, false));
                    console.log("es el responsable")
                    if (setPaso) setPaso(2);
                } else {
                    console.log("no es el responsable")
                    dispatch(push(`/tablero/${id}`));
                }
            } else {
                dispatch(leerEvaluacion(id))
            }
        }).catch(() => {
        }).finally(() => {
            if(loader) dispatch(setLoader(false));
        });

    }).catch(() => {
    }).finally(() => {
        if(loader) dispatch(setLoader(false));
    });
};

const guardarRespuesta = (id, data, setPaso) => (dispatch, getStore) => {
    const resource = getStore().evaluaciones;
    const me = getStore().login.me;
    dispatch(setLoader(true));
    api.post('respuesta_evaluacion', data).then((response) => {
        SwalExito(getI18n().t('Éxito'), getI18n().t('Autoevaluación guardada'));
        dispatch(existeEvaluacion(id, setPaso));
        // if (resource.item.responsable == me.id) {
        //     console.log("es el responsable")
        //     setPaso(2)
        // } else {
        //     console.log("no es el responsable")
        //     dispatch(push(`/tablero/${id}`));
        // }
    }).catch(() => {
        SwalError(getI18n().t('Error'), getI18n().t('Error al guardar la autoevaluación'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const guardarRespuestaOficial = (data, id) => (dispatch, getStore) => {
    const { respuestas_faltantes } = getStore().evaluaciones.item
    if(respuestas_faltantes.value){
        SwalWarning(getI18n().t('Advertencia'), 
            getI18n().t('Aún faltan {{faltantes}} colaboradores por llenar la encuesta, ¿Desea continuar?', { 'faltantes': respuestas_faltantes.quantity} ),
            () => {
                    dispatch(setLoader(true));
                    api.post('respuesta_evaluacion/mandar_respuesta', data).then((response) => {
                        SwalExito(getI18n().t('Éxito'), getI18n().t('Autoevaluación oficial guardada'));
                        dispatch(push(`/tablero/${id}`));
                        dispatch(clearForm());
                    }).catch(() => {
                        SwalError(getI18n().t('Error'), getI18n().t('Error al guardar la autoevaluación'));
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
            })
    } else {
        dispatch(setLoader(true));
        api.post('respuesta_evaluacion/mandar_respuesta', data).then((response) => {
            SwalExito(getI18n().t('Éxito'), getI18n().t('Autoevaluación oficial guardada'));
            dispatch(push(`/tablero/${id}`));
            dispatch(clearForm());
        }).catch(() => {
            SwalError(getI18n().t('Error'), getI18n().t('Error al guardar la autoevaluación'));
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
};

const obtenerRespuestaOficial = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`respuesta_evaluacion/existe_respuesta_oficial`, { evaluacion: id }).then((response) => {
        if (response.existe_respuesta) {
            dispatch(push(`/tablero/${id}`))
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const clearForm = () => (dispatch) => {
    dispatch(initializeForm('ResolverEvaluacionOficialForm', {}));
    dispatch(initializeForm('ResolverEvaluacionForm', {}));
}

const resetData = () => (dispatch) => {
    dispatch(setItem({}));
    dispatch(setItem({}, ITEM2));
    dispatch(setData({
        total_empresas: 0,
        punteo_menor: 0,
        punteo_maximo: 0,
        punteo_empresa: 0,
        empresa_id: 0,
        results: []
    }));
    dispatch(clearForm());
}

export const actions = {
    leerEvaluacion,
    descargarExcel,
    existeEvaluacion,
    obtenerResultados,
    guardarRespuesta,
    leerRespuesta,
    leerRespuesta2,
    guardarRespuestaOficial,
    clearForm,
    obtenerRespuestaOficial,
    resetData,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [ITEM2]: (state, { item }) => {
        return {
            ...state,
            item2: item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        total_empresas: 0,
        punteo_menor: 0,
        punteo_maximo: 0,
        punteo_empresa: 0,
        empresa_id: 0,
        results: [],
    },
    item: {},
    item2: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
