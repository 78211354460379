import React from "react";
import { withTranslation } from "react-i18next";
import Card from "../../../Utils/Cards/Card";
import { Field, reduxForm } from 'redux-form';
import {  AsyncSelectField } from '../../../Utils/renderField/renderField';
import { api } from "api";


const Options = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("cohorte/obtener_cohortes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const validators = (values, props)=>{
    const errors ={}
    if(!values.cohortes||(values.cohortes&&values.cohortes.length<=0)){
        errors.cohortes=`${props.t('Campo requerido')}`
    }
    return errors
}

const Compartir = (props)=> {
    const { handleSubmit }= props;
    return (
        <Card className="p-md-4 md-4 mb-0">
            <form onSubmit={handleSubmit} className="px-md-4 mx-md-4" >
                <div className="d-flex flex-column flex-1 p-2">
                    <label htmlFor="cohorte" className="p-md-4">{props.t('Selecciona la cohorte que deseas compartir')}
                        <span className="gris m-0 text-xsm"> ({props.t('Obligatorio')})</span></label>
                        <Field
                            name="cohortes"
                            loadOptions={Options}
                            type="text"
                            component={AsyncSelectField}
                            isMulti={true}
                            placeholder={`${props.t('Buscar cohortes')}...`}
                            valueKey="id"
                            labelKey="nombre"
                            className="form-control"
                        />
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <button type="button" onClick={e=>props.onCloseModal()} className="btn btn-secondary mr-3" to="/empresas">{props.t('Cancelar')}</button>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Compartir')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <button type="button" onClick={e=>props.onCloseModal()} className="btn btn-secondary flex-1" to="/empresas">{props.t('Cancelar')}</button>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Compartir')}</button>
                </div>

            </form>
        </Card>
    );
};

export default reduxForm({
    form: "CompartirForm",
    asyncBlurFields: [],
    validate: validators
})(withTranslation()(Compartir));
