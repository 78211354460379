import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import _ from "lodash";
import { renderFieldCheck } from '../../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';
import ColaboradorForm from '../../Colaboradores/Crear/ColaboradorForm';
import LoadMask from '../../../Utils/LoadMask/LoadMask';


let ModalColaboradores = (props) => {
    const [invitar, setInvitar]= React.useState(false);

    const { loader, t, colaboradores, item, seleccionados, handleSubmit, cerrar } = props;

    const getSeleccionados = () => {

        let asignados = item.asignados_taller.filter(t => { return !t.usuario.colider && !t.usuario.admin_empresa});
        console.log("DATA: ", asignados, seleccionados)
        asignados = asignados ? asignados.length: 0;
        let total = 0;
        if (seleccionados) {
            for (const _item in seleccionados){
                total += seleccionados[_item] ? 1 : 0;
            }
        }

        return total + asignados;
    }
    const onSubmitColab=(values)=>{
        //console.log(values, "asdfasd")
        props.invitarNuevoColab(values, setInvitar);
    }

    console.log('Hola', props.t)
    
    return (
        <React.Fragment>
            {invitar ? (
                <div style={{ width: "50vw" }} className="flex-1 w-100-responsivo px-md-4 pb-4">
                    <LoadMask loading={loader} blur >
                        <div className='d-flex flex-1 pb-4' >
                            <span className='gris-oscuro text-qts' >{props.t('Registrar nuevo colaborador')}</span>
                        </div>
                        <div className='d-flex flex-1 justify-content-end' >
                            <span className='marron bold' >{[props.t('Te quedan {{cantidad}} invitaciones de 10',{'cantidad':10-props.cant_colaboradores})]}</span>
                        </div>
                        <ColaboradorForm onCancelar={e=>setInvitar(false)} onSubmit={onSubmitColab} />
                    </LoadMask>
                </div>
            ):(
                <form onSubmit={handleSubmit} className="d-flex flex-column flex-1 max-width-100">
                    <div className="d-flex flex-column flex-lg-row flex-1 mb-4">
                        <div className='d-flex flex-1 flex-column'>
                            <span className="gris-oscuro text-qts">{props.t('Agrega a tus colaboradores al taller')}</span>
                            <span className="marron text-sm">{props.t('Límite de colaboradores {{seleccionados}}/{{limite}}', { 'seleccionados': getSeleccionados(), 'limite': item.limite_participantes })}</span>
                        </div>
                        {(props.cant_colaboradores<10)&&(
                            <div className='d-flex flex-1 justify-content-end' >
                                <div className='d-none d-md-flex flex-column'>
                                    <button type='button' onClick={e=>setInvitar(true)} className='btn btn-secondary-fill'>
                                        {props.t('Registrar nuevo colaborador')}
                                    </button>
                                </div>
                                <div className='d-flex d-md-none flex-1 pt-3' >
                                    <button type='button' onClick={e=>setInvitar(true)} className='btn btn-secondary-fill flex-1'>
                                        {props.t('Registrar nuevo colaborador')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="layout-tabla d-flex flex-row flex-1 mb-3">
                        <Grid hover striped data={colaboradores} loading={loader} pagination={false} noResponsive={true} >
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataAlign='center'
                                dataFormat={(cell, row) => {
                                    return (
                                        <div className="d-flex flex-row flex-1 justify-content-center">
                                            <Field
                                                name={`seleccionados.s-${cell}`}
                                                label=""
                                                component={renderFieldCheck}
                                            />
                                        </div>
                                    )
                                }}
                            >
                                {t('Acciones')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="nombres"
                            >
                                {t('Colaboradores')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="is_active"
                                dataSort
                                dataFormat={ cell => cell ? <span className="verde">{props.t('Confirmado')}</span> : <span className="marron" >{props.t('Sin confirmar')}</span>}
                            >
                                {props.t('Estado')}
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                    {(getSeleccionados()>item.limite_participantes)&&(
                        <span className="text-center h4" style={{color:"red"}} >
                        {props.t('Ha sobrepasado el limite de colaboradores permitidos para este evento')+'.'}</span>
                    )}
                    <div className="d-none d-lg-flex buttons-box my-4">
                        <button type="button" onClick={cerrar} className="btn btn-secondary mr-3">{t('Cerrar')}</button>
                        <button type="submit" className="btn btn-primary ml-3">{t('Guardar')}</button>
                    </div>
                    <div className="d-flex d-lg-none flex-column my-4">
                        <button type="button" onClick={cerrar} className="btn btn-secondary flex-1">{t('Cerrar')}</button>
                        <button type="submit" className="btn btn-primary flex-1 mt-3">{t('Guardar')}</button>
                    </div>
                </form>
            ) }
        </React.Fragment>
    )
}

const selector = formValueSelector('ColabAsignadosForm');

ModalColaboradores = reduxForm({
    form: 'ColabAsignadosForm',
    asyncBlurFields: [],
})(withTranslation()(ModalColaboradores));

const mstp = state => {
    let seleccionados = selector(state, 'seleccionados');
    // seleccionados = seleccionados ? seleccionados : []
    // seleccionados = seleccionados.filter(item => item == true);
    return {
        seleccionados,
    }
};

export default connect(mstp, null)(ModalColaboradores);
