import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderDatePicker, renderField, renderFieldCheck } from '../../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


let ColaboradorForm = props => {
    const { handleSubmit, t } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombres" className="m-0">{props.t('Nombre')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nombres" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="email" className="m-0">{props.t('Correo')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="email" component={renderField} type="email" className="form-control"/>
                    </div>
                </div>
                {(props.me&&props.me.admin_empresa)&&(
                    <div className='d-flex flex-lg-row flex-column mt-2' >
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor='colider' className='m-0'>{props.t('Co-lider')}</label>
                            <Field name="colider" component={renderFieldCheck} label={ props.colider?props.t('Si'): props.t('No')} />
                        </div>
                        <div className='d-flex d-none d-lg-flex' />
                    </div>
                )}
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <button onClick={e=>props.onCancelar()} className="btn btn-secondary mr-3">{props.t('Cancelar')}</button>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <button onClick={e=>props.onCancelar()}  className="btn btn-secondary flex-1">{props.t('Cancelar')}</button>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )

}

const selector = formValueSelector('ColaboradorForm');

ColaboradorForm = reduxForm({
    form: 'ColaboradorForm',
    asyncBlurFields: [],
    initialValues: {
        // fecha_fundacion: moment()
    },
    validate: (data, props ) => {
        try {
            return validate(data, {
                'nombres': validators.exists()(`${props.t('Campo requerido')}`),
                'email': validators.exists()(`${props.t('Campo requerido')}`),
            })
        } catch(e) {
            console.log(e);
            return validate(data, {
                'nombres': validators.exists()("Campo requerido"),
                'email': validators.exists()("Campo requerido"),
            })
        }
    },
})(withTranslation()(ColaboradorForm));

const mstp = state => {
    const colider = selector(state, 'colider');
    return {
        colider,
    }
};

export default connect(mstp, null)(ColaboradorForm)