import React, { Component, Fragment } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import { Progress } from 'antd';
import { EN_DESACUERDO, ALGO_EN_DESACUERDO, ALGO_DE_ACUERDO, DE_ACUERDO } from '../../../../../utility/constants';
// import _ from "lodash";
import _, { round } from "lodash";



const obtenerRespuestas = (pregunta, opcion) => {
    const resp = {
        porcentaje: 0,
        results: []
    }
    if (pregunta) {
        const total = pregunta.otras_respuestas.length;
        const respuestas = pregunta.otras_respuestas;

        const opciones =  _.filter(respuestas, { opcion_elegida: opcion });

        resp.porcentaje = opciones.length / total;
        resp.results = opciones;
    }

    return resp;
}


const ModalRespuestas = (props) => {
    const { loader, item, item_opcion, t } = props;
    const resp = obtenerRespuestas(item, item_opcion);
    return (
        <div className="d-flex flex-column flex-1 max-width-100">
            {console.log(item)}
            <div className="d-flex flex-column flex-1 mb-4">
                <span className="gris-oscuro text-sm">{item ? item.pregunta ? item.pregunta.pregunta : '' : ''}</span>
                <div className="d-flex flex-row">
                    <div className="d-flex flex-row flex-1">
                        <Progress showInfo={false} percent={resp.porcentaje * 100} strokeColor="#538BAF" trailColor="#E6E6E6" />
                        <span className="gris-oscuro">{resp.porcentaje ? `${round(resp.porcentaje * 100, 2)}%` : '0%'}</span>
                    </div>
                    <div className="d-none d-lg-flex flex-1"></div>
                </div>
            </div>
            <div className="layout-tabla d-flex flex-row flex-1">
                <Grid hover striped data={{ count: 4, results: resp.results }} loading={false} pagination={false} >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataFormat={cell => ''}
                        width="5%"
                    />
                    <TableHeaderColumn
                        dataField="usuario"
                        dataFormat={cell => cell ? cell.nombres : ''}
                    >
                        {t('Usuario')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataFormat={cell => {
                            if (item_opcion == EN_DESACUERDO) return t('En desacuerdo');
                            if (item_opcion == ALGO_EN_DESACUERDO) return t('Algo en desacuerdo');
                            if (item_opcion == ALGO_DE_ACUERDO) return t('Algo de acuerdo');
                            if (item_opcion == DE_ACUERDO) return t('De acuerdo');
                            return '';

                        }}
                    >
                        {t('Respuesta')}
                    </TableHeaderColumn>
                </Grid>

            </div>
        </div>
    )
}

export default ModalRespuestas;
