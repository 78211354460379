import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import LoadMask from "../../Utils/LoadMask/LoadMask";

import PasoUnoForm from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';
import PasoTresForm from './PasoTresForm';
import moment from 'moment';
import { SPONSOR, PARTNER }  from '../../../../utility/constants';
import { SwalError } from '../../Utils/Notificaciones';

import Navbar from "../../layout/Navbar/Navbar2";
import Card from "../../Utils/Cards/CardForm";
import { withTranslation } from 'react-i18next';

class Registro extends Component {
    static propTypes = {
        inscribir: PropTypes.func.isRequired,
    };

    state = {
        step: 1,
        step_name: this.props.t('Info general'),
        logo: null,
        avatar: null,
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.leer(id);
    }

    setLogo = (logo) => {
        this.setState({ logo });
    };

    setAvatar = (avatar) => {
        this.setState({ avatar });
    };

    nextStep = () => {
        let step = this.state.step;
        if (step==1 && this.state.logo===null){
            SwalError('Error', this.props.t('Necesita agregar el logo de su empresa'));
        }else{
            step += 1;
        }
        if (step == 2)
            this.setState({step_name: this.props.t('Giro de negocio') });
        if (step == 3)
            this.setState({step_name: this.props.t('Info del representante') });
        if (step == 4)
            this.setState({step_name: this.props.t('Info del colaboradores') });
        this.setState({ step });
        window.scroll(0, 0);
    }

    previousStep = () => {
        let step = this.state.step;
        step -= 1;
        if (step == 1)
            this.setState({step_name: this.props.t('Info general') });
        if (step == 2)
            this.setState({step_name: this.props.t('Giro de negocio') });
        if (step == 2)
            this.setState({step_name: this.props.t('Giro de negocio') });
        if (step == 3)
            this.setState({step_name: this.props.t('Info del representante') });
        this.setState({ step });
        window.scroll(0, 0);
    }

    onSubmit = (values) => {
        const body = {...values};
        body.fecha_fundacion = moment(body.fecha_fundacion).format("YYYY-MM-DD");
        if (body.sectores) {
            body.sectores = body.sectores.map(item => ({...item, tipo: item.value}))
        }
        if (body.pais){
            body.pais= values.pais.name
        }

        if(body.usuario && body.usuario.fecha_nacimiento){
            body.usuario.fecha_nacimiento = moment(body.usuario.fecha_nacimiento).format('YYYY-MM-DD');
        }

        const array = [];
        array.push({ "file": this.state.logo, "name": "logo" })
        array.push({ "file": this.state.avatar, "name": "avatar" })

        if(this.state.avatar!==null){
            this.props.inscribir(this.props.match.params.id, body, array);
        }else{
            SwalError('Error', this.props.t('Necesita agregar su foto de perfil'));
        }
    }

    getNavbar = ()=>{
        const { tipo_usuario } = this.props;

        switch(tipo_usuario){
            case SPONSOR:
                return <Navbar title="Registro de Patrocinador" />
            case PARTNER:
                return <Navbar title="Registro de Partner"/>
        }
    }
    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="d-flex flex-column">
                { this.getNavbar() }
                <div className='col-12 col-lg-6 m-auto pt-4'>
                    <Card>
                        <div className="d-flex flex-row mb-3">
                            <div className="mr-2">
                                <i className="fas fa-file-alt azul-gris-oscuro" style={{fontSize: "3rem"}}></i>
                            </div>
                            <div className="d-flex flex-column">
                                <h4 className="mb-0 bold azul-gris-oscuro">{`Paso ${this.state.step} de ${3}`}</h4>
                                <span className="gris-oscuro text-sm">{`${this.state.step_name}`}</span>
                            </div>
                        </div>
                        <LoadMask loading={loader} light>
                            {(this.state.step == 1 && (
                                <PasoUnoForm logo={this.state.logo} setLogo={this.setLogo} onSubmit={this.nextStep} previousStep={this.previousStep} t={this.props.t}  />
                            ))}
                            {(this.state.step == 2 && (
                                <PasoDosForm onSubmit={this.nextStep} previousStep={this.previousStep} t={this.props.t} />
                            ))}
                            {(this.state.step == 3 && (
                                <PasoTresForm onSubmit={this.onSubmit} t={this.props.t} imagen={this.state.avatar} previousStep={this.previousStep} setAvatar={this.setAvatar} />
                            ))}
                        </LoadMask>
                    </Card>

                </div>
            </div>
        );
    }
}

export default withTranslation()(Registro);
