import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import PerfilForm from './PerfilForm';
import moment from 'moment';

import { withTranslation } from 'react-i18next';


class EditarPerfilEmpresa extends Component {

    state = {
        logo: null
    }

    componentDidMount() {
        const { leer, me } = this.props;
        leer(me.empresa, true);
    }

    setLogo = (logo) => {
        this.setState({ logo });
    }

    onSubmit = (values) => {
        const data = {...values};
        if (data.fecha_fundacion)
            data.fecha_fundacion = moment(data.fecha_fundacion).format("YYYY-MM-DD");
        if (data.pais){
            data.pais = data.pais.name;
        }
        if (data.sectores) {
            data.sectores = data.sectores.map(item => ({...item, tipo: item.value}))
        }
        if (this.state.logo != null) {
            this.props.editar(data, [{"file": this.state.logo, "name": "logo"}])
        } else {
            this.props.editar(data, [])
        }
    }

    render() {
        const { loader, item, me } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <PerfilForm item={item} me={me} onSubmit={this.onSubmit} setLogo={this.setLogo} t={this.props.t} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(EditarPerfilEmpresa)
