import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ToolbarDashboard from '../../../Utils/Grid/ToolbarDashboard';
import { TableHeaderColumn } from "react-bootstrap-table";
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import { ADMIN, PARTNER, SPONSOR, USUARIO } from '../../../../../utility/constants';
import Graficas from './Graficas';
import i18n from '../../../../../i18nextInit'
import { RenderCurrency, RenderNumber } from '../../../Utils/renderField/renderReadField';

import moment from 'moment';

class Dashboard extends Component {

    componentDidMount() {
        this.props.listarTabla(1);
        const { me } = this.props;
        if (me && ((me.admin_empresa||me.colider)&&me.tipo_usuario===USUARIO || me.tipo_usuario === ADMIN)) {
            this.props.graficas();
        }else if(me && me.tipo_usuario === PARTNER){
            this.props.history.push('/beneficios');
        }
        else{
            this.props.history.push('/talleres');
        }
    }

    render() {
        const { loader, data, page, listarTabla, me } = this.props;
        const lang = i18n.language ? i18n.language : 'en'
        if (me && ((me.admin_empresa||me.colider) || me.tipo_usuario == ADMIN)) {
            return (
                <div className="mb-4 col-12 mt-0">
                    <ToolbarDashboard {...this.props} />
                    <LoadMask loading={loader} blur fixed={true}>
                        <Graficas {...this.props} />
                    </LoadMask>
                    <Card>
                        <div className="px-lg-2 my-2">
                            <HeaderBasico {...this.props} />
                        </div>
                        <div className="px-lg-2 my-3 d-flex flex-row flex-1 justify-content-end">
                            <button onClick={() => this.props.descargarExcel()} className="btn btn-primary">{this.props.t('Descargar excel')}</button>
                        </div>
                        <Grid hover striped data={data} page={page} loading={loader} onPageChange={listarTabla} scrollTable={true} >
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                width='1rem'
                                dataFormat={standardActions({})}
                            />
                            <TableHeaderColumn
                                dataField="empresa"
                                width='10rem'
                                dataFormat={cell => (cell ?
                                    <div className="d-flex flex-1 align-items-center">
                                        <img className="logo-tabla mr-3" src={cell.logo} />
                                        <span>{cell.nombre}</span>
                                    </div>
                                    : ''
                                )}
                            >
                                {this.props.t('Empresa')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="month"
                                width='10rem'
                                dataFormat={(cell, row) => {
                                    const trimeste_fin = moment(`${row.year}-${cell}-1`).locale(lang);
                                    const trimeste_inicio = moment(trimeste_fin).locale(lang).subtract(2, 'months');

                                    return `${trimeste_inicio.format("MMMM")} - ${trimeste_fin.format("MMM")} / ${row.year}`
                                }}
                            >
                                {this.props.t('Trimestre / Año')}
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn
                                dataField="pregunta1"
                                dataFormat={cell=> (<RenderCurrency prefix={'USD '} value={cell} />)}
            
                            >
                                {this.props.t('P1-Ventas')}
                            </TableHeaderColumn> */}
                            <TableHeaderColumn
                                dataField="pregunta1_a"
                                dataFormat={cell=>{
                                    if(cell!==null||cell!==undefined) return(<RenderCurrency prefix={'USD '} value={cell} />)}}
                            >
                                {this.props.t('P1 Ventas')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta1_b"
                                width='17rem'
                                dataFormat={cell=>{
                                    if(cell!==null||cell!==undefined) return(<RenderNumber suffix=' %' value={cell} decimalScale={2}/> )}}
                            >
                                {this.props.t('P1.1 Incremento o decremento')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta1_c"
                                width='13rem'
                                dataFormat={cell=> cell ? this.props.t('Si'):this.props.t('No')}
                            >
                                {this.props.t('P1.2 Sobrepasar Ingresos')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta1_c_i"
                                width='13rem'
                                dataFormat={cell=>{
                                    if(cell!==null||cell!==undefined) return(<RenderNumber suffix=' %' value={cell} decimalScale={2}/> )}}
                            >
                                {this.props.t('P1.2.1 Aproximadamente')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta2"
                            >
                                {this.props.t('P2 Empleados')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta2_a"
                                width='15rem'
                                dataFormat={cell=> cell ? this.props.t('Si'):this.props.t('No')}
                            >
                                {this.props.t('P2.1 Nuevas contrataciones')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta2_a_i"
                                width='17rem'
                            >
                                {this.props.t('P2.1.1 Cantidad contrataciones')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta2_a_ii"
                                width='15rem'
                            >
                                {this.props.t('P2.1.2 Puestos')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta3"
                                width='10rem'
                                dataFormat={cell=> cell ? this.props.t('Si'):this.props.t('No')}
                            >
                                {this.props.t('P3 Financiamiento')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta3_a"
                                width='13rem'
                                dataFormat={cell=>{
                                    if(cell!==null||cell!==undefined) return(<RenderCurrency prefix={'USD '} value={cell} />)}}
                            >
                                {this.props.t('P3.1 Cantidad obtenida')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta4"
                                width='12rem'
                                dataFormat={cell=> cell ? this.props.t('Si'):this.props.t('No')}
                            >
                                {this.props.t('P4 Manufacturación')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta4_a"
                                width='20rem'
                            >
                                {this.props.t('P4.1 Descripción de manufacturacion')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta5"
                                width='15rem'
                                dataFormat={cell=> cell ? this.props.t('Si'):this.props.t('No')}
                            >
                                {this.props.t('P5 Negocios otras empresas')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta5_a"
                                width='9rem'
                                dataFormat={cell=> cell.join(', ')}
                            >
                                {this.props.t('P5.1 Empresas')}
                            </TableHeaderColumn>
                           {/*  <TableHeaderColumn
                                dataField="pregunta6"
                                dataFormat={cell=> cell ? this.props.t('Si'):this.props.t('No')}
                            >
                                {this.props.t('P6 Negocios regionales')}
                            </TableHeaderColumn> */}
                            <TableHeaderColumn
                                dataField="pregunta7"
                                width='12rem'
                                dataFormat={cell=> cell ? this.props.t('Si'):this.props.t('No')}
                            >
                                {this.props.t('P6 Negocios regionales')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta7_i"
                                width='12rem'
                            >
                                {this.props.t('P6.1 Estados/regiones')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta7_ii"
                                width='8rem'
                                dataFormat={cell=>{
                                    if(cell!==null||cell!==undefined) return(<RenderCurrency prefix={'USD '} value={cell} />)}}
                            >
                                {this.props.t('P6.2 Ingresos')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta8"
                                width='9rem'
                                dataFormat={cell=> cell ? this.props.t('Si'):this.props.t('No')}
                            >
                                {this.props.t('P7 Exportación')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta8_i"
                                width='10rem'
                                dataFormat={cell=> cell.join(', ')}
                            >
                                {this.props.t('P7.1 Países ')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pregunta8_ii"
                                dataFormat={cell=>{
                                    if(cell!==null||cell!==undefined) return(<RenderCurrency prefix={'USD '} value={cell} />)}}
                            >
                                {this.props.t('P7.2 Ingresos')}
                            </TableHeaderColumn>
{/*                             <TableHeaderColumn
                                dataField="pregunta9"
                            >
                                {this.props.t('P9')}
                            </TableHeaderColumn> */}
                            <TableHeaderColumn
                                dataField="pregunta9_a"
                                width='13rem'
                                dataFormat={cell=>{
                                    if(cell!==null||cell!==undefined) return(<RenderCurrency prefix={'USD '} value={cell} />)}}
                            >
                                {this.props.t('P8 Impuestos federales')}
                            </TableHeaderColumn>
{/*                             <TableHeaderColumn
                                dataField="pregunta10"
                            >
                                {this.props.t('P10')}
                            </TableHeaderColumn> */}
                            <TableHeaderColumn
                                width='13rem'
                                dataField="pregunta10_a"
                                dataFormat={cell=>{
                                    if(cell!==null||cell!==undefined) return(<RenderCurrency prefix={'USD '} value={cell} />)}}
                            >
                                {this.props.t('P9 Impuestos estatales')}
                            </TableHeaderColumn>
                        </Grid>
                    </Card>
                </div>
            )
        }
        return (
            <div className="mb-4 col-12 mt-0">
                <span className="text-sm blanco">{this.props.t('Bienvenido a')}</span>
                <h1 className="blanco">Growmotor</h1>
            </div>
        )
    }
}

export default withTranslation()(Dashboard)
