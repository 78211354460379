import React from "react";
import { Progress, Input, Form } from 'antd';
const avatar = require("../../../../../assets/img/avatar.png");
import { withTranslation } from  'react-i18next';

const Img = require('../../../../../assets/img/icono-imagen.png');
import FileImgUploder from '../FileUploader/FileImgUploader';

class Comentario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            contenido:'',
            imagen:null,
            imagenSrc: '',
            loaded: false,
            progress: {
                conteo: 0,
                porcentaje: 0,
            },
        }
    }
    onSubmit=()=>{
        const { publicacion}= this.props;
        const array = [];
        array.push({ file: this.state.imagen, name: "imagen" });
        console.log(this.state.imagen)
        const body = {'contenido':this.state.contenido}
        if(publicacion){
            body.publicacion = publicacion.id;
            this.props.onSubmit(body, array).then(()=>{
                this.setState({contenido:''})
                this.setState({disabled:true})
                this.removeImagen();
                this.props.getComentarios(1, publicacion)
            })
        }
    }
    setProgress = (value) => {
        const texto = String(value);
        const conteo = texto.length;
        const porcentaje = conteo / 2.8;
        this.setState({ progress: { conteo, porcentaje } });
        this.setState({contenido:value})
        if(conteo<=0){
            this.setState({disabled:true})
        }else{
            this.setState({disabled:false})
        }
    };
    setImagen = (file) =>{
        this.setState({imagen:file.file, 
            imagenSrc:file.imageSrc, 
            loaded:file.loaded,
            disabled:false,
        })
    }
    removeImagen = ()=>{
        this.setState({imagen:null, imagenSrc:'', loaded:false, disabled:true});
    }

    render(){
        const { me, isReply } = this.props;
        return (
            <div className="d-flex flex-grow-1" >
                <div className="d-flex flex-column flex-1 py-3">
                    <div className="d-flex flex-column flex-1">
                        <div className="d-flex  flex-column flex-1 p-1 m-auto">
                            {this.state.loaded && (
                                <React.Fragment>
                                    <div className="d-flex justify-content-end" style={{position:"relative", bottom:"-1.8rem", cursor:"pointer"}}  
                                        onClick={()=>this.removeImagen()}>
                                        <li className="blanco bg-gris p-2 fa fa-times fa-lg"  style={{borderRadius:"5px"}}/>
                                    </div>
                                    <img src={this.state.imagenSrc} style={{width:"20rem", height:"12rem"}} />
                                </React.Fragment>
                            )}
                        </div>
                        <div className="d-flex flex-row align-content-center">
                            <img
                                className="perfil-img mr-2"
                                style={{ height: "1.5rem", width: "1.5rem" }}
                                src={me ? (me.avatar ? me.avatar : avatar) : avatar}
                                alt="Perfil"
                            />
                            <span className="text-sm gris-oscuro">
                                {me.nombres}
                            </span>
                        </div>
                        <div>
                            <div className="d-flex flex-column flex-1 py-2">
                            <textarea
                                maxLength="280"
                                placeholder={this.props.t("Escribe un comentario")}
                                style={{ resize: 'none' }}
                                rows={3}
                                className='form-control'
                                onChange={(e)=>this.setProgress(e.target.value)}
                                value={this.state.contenido}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center px-4">
                        <button className="btn btn-primary ml-2" onClick={(e)=>{this.onSubmit()}} disabled={this.state.disabled}>
                            { isReply && isReply ? this.props.t('Responder'): this.props.t('Comentar') }
                        </button>
                        <div className="d-flex align-items-center">
                            <span className='gris text-xs flex-2 pr-2'>{this.state.progress.conteo}/280</span>
                            <Progress percent={this.state.progress.porcentaje} className="flex-1" />
                            <FileImgUploder width="1rem" height="1rem" imageSrc={Img} onFileChange={this.setImagen} />
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
};

export default withTranslation()(Comentario);
