import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { SwalError } from '../../Utils/Notificaciones';

import PasoUnoForm from "./PasoUnoForm";
import PasoDosForm from "./PasoDosForm";
import moment from "moment";

import { withTranslation } from 'react-i18next';
import Navbar from "../../layout/Navbar/Navbar2";
import Card from "../../Utils/Cards/CardForm";
// import PasoTresForm from './RegisterForm';

class RegistroGrowth extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    state = {
        step: 1,
        step_name: this.props.t("Info general"),
        avatar: null,
    };
    componentWillMount() {
        const id = this.props.match.params.id;
        if (id) {
            this.props.leer(id);
        }
    }

    setAvatar = (avatar) => {
        this.setState({ avatar });
    };

    nextStep = () => {
        let step = this.state.step;
        if(step==1 && this.state.avatar===null){
            SwalError('Error', this.props.t('Necesita agregar su foto de perfil'));
        }else{
            step += 1;
        }
        if (step == 2) this.setState({ step_name: this.props.t("Info personal") });
        this.setState({ step });
        window.scroll(0, 0);
    };

    previousStep = () => {
        let step = this.state.step;
        step -= 1;
        if (step == 1) this.setState({ step_name: this.props.t("Info general") });
        if (step == 2) this.setState({ step_name: this.props.t("Info personal") });
        this.setState({ step });
        window.scroll(0, 0);
    };

    onSubmit = (values) => {
        const body = { ...values };
        const array = [];
        array.push({ file: this.state.avatar, name: "avatar" });
        delete body.verificar_password;
        if (body.pais){
            body.pais = values.pais.name;
        }
        if (body.fecha_nacimiento){
            body.fecha_nacimiento = moment(body.fecha_nacimiento).format("YYYY-MM-DD");
        }

        if (body.idiomas){
            body.idiomas = values.idiomas.map((item)=>({...item, tipo:item.value}))
        }
        this.props.onSubmit(this.props.match.params.id, body, array);
    };

    render() {
        const { onSubmit, loader } = this.props;
        return (
            <div className="d-flex flex-column">
                <Navbar title={this.props.t('REGISTRO DE GROWTH ANGEL')} />
                <div className='col-12 col-lg-6 m-auto pt-4'>
                    <Card>
                        <div className="d-flex flex-row mb-3">
                            <div className="mr-2">
                                <i className="fas fa-file-alt azul-gris-oscuro" style={{fontSize: "3rem"}}></i>
                            </div>
                            <div className="d-flex flex-column">
                                <h4 className="mb-0 bold azul-gris-oscuro">{`${this.props.t('Paso')} ${this.state.step} ${this.props.t('de')} ${2}`}</h4>
                                <span className="gris-oscuro text-sm">{`${this.state.step_name}`}</span>
                            </div>
                        </div>
                        <LoadMask loading={loader} light>
                            {this.state.step == 1 && (
                                <PasoUnoForm
                                    imagen={this.state.avatar}
                                    setAvatar={this.setAvatar}
                                    onSubmit={this.nextStep}
                                    t={this.props.t}
                                />
                            )}
                            {this.state.step == 2 && (
                                <PasoDosForm
                                    onSubmit={this.onSubmit}
                                    previousStep={this.previousStep}
                                    t={this.props.t}
                                />
                            )}
                        </LoadMask>
                    </Card>

                </div>
            </div>
        );
    }
}

export default withTranslation()(RegistroGrowth);
