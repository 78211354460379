import React from 'react';
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Cuestionario from './cuestionario';
import { withTranslation } from 'react-i18next';

class CalificacionEvento extends React.Component{

    render(){
        return(
            <Modal  key="modal-1" open={this.props.calificarEvento} 
                onClose={!this.props.calificarEvento} closeOnOverlayClick={false} 
                showCloseIcon={false} >
                <Cuestionario 
                    item={this.props.me.calificar_evento} 
                    loader={this.props.loader} 
                    onSubmit={this.props.onSubmit} 
                    me={this.props.me}
                    t={this.props.t}
                    />
            </Modal>
        )
    };
};


export default withTranslation()(CalificacionEvento);