import React, { useEffect, useState } from 'react';
import { Page, Text, View, Image } from "@react-pdf/renderer";


const EmpresPage = ({ fondoPadre, encabezadoAll, respuesta, evaluacion, bloque, bloqueNombre, textoPreguntas, linea, preguntasHTML, contenedor, t }) => {
    return (
        <Page
            size="A4"
            style={fondoPadre}
        >
            {encabezadoAll}
            <View style={contenedor}>
            {respuesta.respuestas
            ? respuesta.respuestas.map((item, index) => {
                return (
                (item.entrega_elegida && (
                        <View key={`empresaN-${index}`} style={{paddingTop: '20px', paddingLeft: '20px', alignItems:'center', flexDirection: 'row'}}>
                            <View style={{marginRight: '30px'}}><Image style={{width:'50px'}} src={item.empresa.logo} alt={'logo'}></Image></View>
                            <View>
                                <Text style={{fontSize: '12px', color: '#5F5F5F'}}>{t('Empresa')}:</Text>
                                <Text style={{fontSize: '12px', color: '#538BAF'}}>{item.empresa ? item.empresa.nombre : ''}</Text>
                            </View>
                            <View style={{paddingRight:'20px', marginLeft:'auto'}}>
                                <View style={{backgroundColor: '#1446A0', display:'flex', justifyContent:'center', borderRadius:'50%', alignItems:'center', width:'40px', height:'40px'}}>
                                    {respuesta.results&&respuesta.results.map((val) => {
                                        return(
                                        <Text style={{color:'#FFFFFF', fontSize:'13px'}}>{val.punteo+" PT"}</Text>
                                        )
                                    })}
                                </View>
                            </View>
                        </View>
                    ))
                )
            })
            : ""}
                {evaluacion.bloques
                    ? evaluacion.bloques.map((a, indexB) => {
                        return(
                            <View key={`empresa-${indexB}`} style={{padding: '20px'}} break={indexB>0}>
                                <View>
                                    {/* bloque */}
                                    <Text style={bloque}>{t('Bloque')} {indexB + 1} {t('De')} {evaluacion.cantidad_bloques}</Text>
                                    <Text style={bloqueNombre}>{a.nombre}</Text>
                                        {/* pregunta */}
                                        {a.preguntas ? a.preguntas.map( (res, indexP) => {
                                            return(
                                                <View wrap={false}>
                                                    <Text style={textoPreguntas} key={`empresaV-${indexP}`}>{indexP + 1}{". "}{res.pregunta}</Text>
                                                    <View style={linea}></View>

                                                    {/* Inicio */}
                                                    {respuesta.respuestas
                                                        ? respuesta.respuestas.map((item, indexPG) => {
                                                            return (
                                                            (item.entrega_elegida && (
                                                                item.respuestas.map((x) => {
                                                                    if (x.pregunta.bloque_nombre === a.nombre && x.pregunta.id === res.id) {
                                                                        return (
                                                                            preguntasHTML(x.opcion_elegida, indexPG, t)
                                                                        )
                                                                    }
                                                                    })
                                                                ))
                                                            )
                                                        })
                                                    : ""}
                                                    {/* Fin */}
                                                </View>
                                            )
                                        }):""}
                                </View>
                            </View>
                        )
                    })
                : ""}
            </View>
        </Page>
    )
    }

export default EmpresPage