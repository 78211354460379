import React from 'react'
import { Document } from "@react-pdf/renderer";
import EmpresPage from './pagesPDF/EmpresPage';
import CeoPage from './pagesPDF/CeoPage';
import ColaboradoresPage from './pagesPDF/ColaboradoresPage';
import ColaboradorPage from './pagesPDF/ColaboradorPage';

const DocPDF = ({ fondoPadre, encabezadoAll, respuesta, evaluacion, bloque, bloqueNombre, textoPreguntas, linea, preguntasHTML, contenedor, t, me}) => {
  return (
    (me.admin_empresa)?(
      <Document>
        {/* EVALUACION */}
        {/* CEO */}
        <CeoPage
            fondoPadre={fondoPadre}
            encabezadoAll={encabezadoAll}
            contenedor={contenedor}
            respuesta={respuesta}
            evaluacion={evaluacion}
            bloque={bloque}
            bloqueNombre={bloqueNombre}
            textoPreguntas={textoPreguntas}
            linea={linea}
            preguntasHTML={preguntasHTML}
            t={t}
        />
        {/* EVALUACION */}
        {/* EMPRESA */}
        <EmpresPage
            fondoPadre={fondoPadre}
            encabezadoAll={encabezadoAll}
            contenedor={contenedor}
            respuesta={respuesta}
            evaluacion={evaluacion}
            bloque={bloque}
            bloqueNombre={bloqueNombre}
            textoPreguntas={textoPreguntas}
            linea={linea}
            preguntasHTML={preguntasHTML}
            t={t}
        />
        {/* EVALUACION */}
        {/* COLABORADORES */}
        <ColaboradoresPage
            fondoPadre={fondoPadre}
            encabezadoAll={encabezadoAll}
            contenedor={contenedor}
            respuesta={respuesta}
            evaluacion={evaluacion}
            bloque={bloque}
            bloqueNombre={bloqueNombre}
            textoPreguntas={textoPreguntas}
            linea={linea}
            preguntasHTML={preguntasHTML}
            t={t}
        />
    </Document>
    ):(
    <Document>
      <ColaboradorPage
        fondoPadre={fondoPadre}
        encabezadoAll={encabezadoAll}
        contenedor={contenedor}
        respuesta={respuesta}
        evaluacion={evaluacion}
        bloque={bloque}
        bloqueNombre={bloqueNombre}
        textoPreguntas={textoPreguntas}
        linea={linea}
        preguntasHTML={preguntasHTML}
        t={t}
        me={me}
      />
    </Document>)
  )
}

export default DocPDF