import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import perfil from './modules/cuenta/perfil';
import perfilEmpresa from './modules/cuenta/perfilEmpresa';
import buscador from './modules/cuenta/buscador';
import notificaciones from './modules/cuenta/notificaciones';
import usuarios from './modules/usuarios/usuarios';
import growthAngels from './modules/cuenta/growthAngelRegister';
import talleres from './modules/publicaciones/talleres';
import mentorias from './modules/publicaciones/mentorias';
import evaluaciones from './modules/evaluaciones';
import historias from './modules/publicaciones/historias';
import intercambios from './modules/publicaciones/intercambios';
import comentarios from './modules/comentarios';
import colaboradores from './modules/empresas/colaboradores';
import colaboradoresEditar from './modules/empresas/colaboradoresEditar';
import guerreros from './modules/publicaciones/guerreros';
import familyMeeting from './modules/publicaciones/familyMeeting';
import amigosGrowmotor from './modules/cuenta/amigosGrowmotor';
import beneficios from './modules/publicaciones/beneficios';
import calificacionesEventos from './modules/reportes/calificacionEventos';
import dashboard from './modules/reportes/dashboard';

export default combineReducers({
    form: formReducer,
    login,
    register,
    perfil,
    perfilEmpresa,
    usuarios,
    growthAngels,
    talleres,
    mentorias,
    guerreros,
    familyMeeting,
    evaluaciones,
    historias,
    intercambios,
    colaboradores,
    colaboradoresEditar,
    comentarios,
    amigosGrowmotor,
    beneficios,
    notificaciones,
    buscador,
    calificacionesEventos,
    dashboard,
    routing,
});
