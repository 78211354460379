import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import { SwalExito, SwalError } from '../../../common/components/Utils/Notificaciones';
import {  MUY_EFICAZ } from '../../../utility/constants';
import { getI18n } from 'react-i18next';

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ME = 'LOGIN_ME';
export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setMe = me => ({
    type: ME,
    me,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('user/token', data).then((response) => {
        localStorage.setItem('token', response.token);
        dispatch(initializeForm('profile', response.user));
        dispatch(setMe(response.user));
        dispatch(push("/"));
    }).catch(error => {
        let mensaje = getI18n().t('Credenciales incorrectas, vuelva a intentar');
        if (error && error.empresa) {
            // Lo manadmos al registro
            NotificationManager.error(getI18n().t('El proceso de registro aún no está finalizado'), getI18n().t('ERROR'), 3000);
            dispatch(push(`/registro/${error.empresa}`));
        }
        else {
            if (error && error.detail)
                mensaje = error.detail;
            NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getMe = () => (dispatch) => {
    api.get('/user/me').then(me => {
        dispatch(initializeForm('profile', me));
        dispatch(setMe(me));
    })
        .catch(() => {
    }).finally(() => {});
};

export const logOut = () => (dispatch) => {
    api.post('/user/logout').then(() => {
    }).catch(() => {
    }).finally(() => {});
    localStorage.removeItem('token');
};

const verificarToken=(token)=>(dispatch)=>{
    dispatch(setLoader(true))
    const params= { token };
    return api.post('user/verificar_token/', params).then((response)=>{
        return response;
    }).catch(()=>{
        SwalError(getI18n().t('Error'),getI18n().t('El link se vencido o es invalido')).then(()=>dispatch(push('/')))
    }).finally(()=>dispatch(setLoader(false)))
}

const cambiarPwd = (email, contraseña)=>(dispatch)=>{
    dispatch(setLoader(true))
    const params = { email, contraseña }
    api.post('user/cambio_contraseña/', params).then(()=>{
        SwalExito(getI18n().t('Éxito'), getI18n().t('Cambio de contraseña Exitoso')).then((e)=>dispatch(push('/login')))
    }).catch(()=>{
        NotificationManager.error(getI18n().t('Error al cambiar la contraseña'), getI18n().t('ERROR'), 3000);
    }).finally(()=>dispatch(setLoader(false)))
}

const SolicitarPwd =(email)=>(dispatch)=>{
    dispatch(setLoader(true))
    const params = { email };
    api.post('user/recuperar_contraseña/', params).then(()=>{
        SwalExito(getI18n().t('Éxito'), getI18n().t('Se te ha enviado un correo para continuar con el proceso')).then((e)=>dispatch(push('/login')))
    }).catch(()=>{
        SwalError(getI18n().t('Error'), getI18n().t('Correo invalido'))

    }).finally(()=>dispatch(setLoader(false)))
}
export const newToken = (token) => (dispatch) => {
    const body = { token };
    api.post('fcm', body).catch((error) => { }).then((data) => {
    });
};

export const getEvento=()=>(dispatch)=>{
    dispatch(setLoader(true));
    return api.get('calificar_evento/get_evento/').then(
    ).finally(
        ()=>dispatch(setLoader(false))
    )
}

export const enviarCalificacion=(values)=>(dispatch,getStore)=>{
    const { calificar_evento } = getStore().login.me;
    const preguntas = getStore().form.CalificacionForm;
    dispatch(setLoader(true));
    const data = {...values, evento:calificar_evento}
    if(preguntas.values.pregunta1 && preguntas.values.pregunta2 && preguntas.values.pregunta3){
        api.post('calificar_evento', data)
        .then(
            ()=> {
                dispatch(getMe());
                dispatch(clearCalificacionForm())
                SwalExito(getI18n().t('Éxito'), getI18n().t('Gracias! Tu opinión es importante para nosotros'));
            }
        ).catch(
            ()=>{ SwalError(getI18n().t('Error'), getI18n().t('Algo salio mal, intenta de nuevo'));
            }
        ).finally(
            ()=>dispatch(setLoader(false))
        )
    }
    else{
        SwalError(getI18n().t('Error'), getI18n().t('Debe contestar todas las preguntas'));
    }
}

const clearCalificacionForm =()=>(dispatch)=>{
    dispatch(initializeForm('CalificacionForm',{
    }))
}

export const cambiarIdioma=(data)=>(dispatch)=>{
    api.post('user/cambiar_idioma',data).then(()=>{
    }).catch(()=>{})
}

export const actions = {
    onSubmit,
    logOut,
    cambiarPwd,
    verificarToken,
    SolicitarPwd,
    newToken,
    enviarCalificacion,
    cambiarIdioma,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
};

export const initialState = {
    loader: false,
    me: {},
};

export default handleActions(reducers, initialState);
