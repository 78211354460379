import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Loader from 'react-loader-spinner';

import "./verify_login.css";

const logo = require('../../../../../assets/header/Logo.png');
const cohorteLoader = require('../../../../../assets/img/cohorte_loader.png')
const cohorteIcon = require('../../../../../assets/img/cohorte_icon.png')


class LoaderCohorte extends Component {

    render() {
        return (
            <div id="verify_login" >
                <div
                    className="d-flex flex-column align-items-center justify-content-center bg-azul-oscuro"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        margin: "0",
                        padding: "0",
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        zIndex: "9999999"
                    }}
                >
                    <img src={logo} style={{width: '15rem'}} />
                    <p className="load-texto">{`${this.props.t('Cambiando de Cohorte')}...`}</p>
                    <div style={{backgroundImage: `url(${cohorteLoader})`}} className="cohorte-loader">
                        <img className="cohorte-icon" src={cohorteIcon} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(LoaderCohorte);
