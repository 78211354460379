import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import { renderFieldRadio } from '../../../Utils/renderField/renderField';
import { EN_DESACUERDO, ALGO_EN_DESACUERDO, ALGO_DE_ACUERDO, DE_ACUERDO } from '../../../../../utility/constants';


const renderPreguntas = ({ fields, item, bloques, bloque_index, openModal, me, loader, t, meta: { error } }) => (
    <div className="d-flex flex-1 flex-column">
        {fields.map((pregunta, index) => (
            <div key={index} className="d-flex flex-1 flex-column my-2">
                {(item.bloques && item.bloques.length) && (
                    <div className="d-flex flex-column px-0 mt-2">
                        <span className="text-sm azul bold">{`${index + 1}.`} {item.bloques[bloque_index].preguntas[index].pregunta.pregunta}</span>
                        <div className="d-flex w-100 bg-gris" style={{ height: '3px' }} />
                    </div>
                )}
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2 mb-4 ">
                    <div className="flex-1">
                        <Field
                            type="radio"
                            name={`${pregunta}.opcion_elegida`}
                            label={t('En desacuerdo')}
                            value={`${EN_DESACUERDO}`}
                            component={renderFieldRadio}
                            disabled
                        />
                    </div>
                    <div className="flex-1">
                        <Field
                            type="radio"
                            name={`${pregunta}.opcion_elegida`}
                            label={t('Algo en desacuerdo')}
                            value={`${ALGO_EN_DESACUERDO}`}
                            component={renderFieldRadio}
                            disabled
                        />
                    </div>
                    <div className="flex-1">
                        <Field
                            type="radio"
                            name={`${pregunta}.opcion_elegida`}
                            label={t('Algo de acuerdo')}
                            value={`${ALGO_DE_ACUERDO}`}
                            component={renderFieldRadio}
                            disabled
                        />
                    </div>
                    <div className="flex-1">
                        <Field
                            type="radio"
                            name={`${pregunta}.opcion_elegida`}
                            label={t('De acuerdo')}
                            value={`${DE_ACUERDO}`}
                            component={renderFieldRadio}
                            disabled
                        />
                    </div>
                </div>
            </div>
        ))}
    </div>
)

let DetalleEvaluacionForm = props => {
    const { handleSubmit, previousBloque, nextBloque, bloque, item, loader, bloques, item_inicial } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-row px-0 mt-2">
                    <div className="d-flex flex-column flex-1 mt-2 max-width-100">
                        <FieldArray
                            name={`bloques[${bloque}].preguntas`}
                            bloques={bloques}
                            openModal={props.openModal}
                            component={renderPreguntas}
                            item={item}
                            bloque_index={bloque}
                            loader={loader}
                            me={props.me}
                            t={props.t}
                        />
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    {(bloque > 0) && (
                        <button type="button" onClick={previousBloque} className="btn btn-secondary mr-3">{props.t('Anterior')}</button>
                    )}
                    {!(item_inicial.cantidad_bloques && item_inicial.cantidad_bloques == bloque + 1) && (
                        <button type="button" onClick={nextBloque} className="btn btn-primary ml-3">{props.t('Siguiente')}</button>
                    )}
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    {(bloque > 0) && (
                        <button type="button" onClick={previousBloque} className="btn btn-secondary flex-1">{props.t('Anterior')}</button>
                    )}
                    {!(item_inicial.cantidad_bloques && item_inicial.cantidad_bloques == bloque + 1) && (
                        <button type="button" onClick={nextBloque} className="btn btn-primary flex-1 mt-3">{props.t('Siguiente')}</button>
                    )}
                </div>
            </div>
        </form>
    )

}

const selector = formValueSelector('ResolverEvaluacionOficialForm');

DetalleEvaluacionForm = reduxForm({
    form: 'ResolverEvaluacionOficialForm',
    asyncBlurFields: [],
})(DetalleEvaluacionForm);

const mstp = state => {
    const bloques = selector(state, 'bloques');

    return {
        bloques,
    }
};

export default connect(mstp, null)(DetalleEvaluacionForm);
