import React from 'react';
import { withTranslation } from 'react-i18next';


const iconoUser = require('../../../../../../assets/img/avatar.png')
const imagenPost = require('../../../../../../assets/img/imagen-post.png')

const Intercambio = (props) =>{
    return (
        <div className='card card-post bg-blanco mb-4'>
            <div className="d-flex flex-1 flex-row px-4 pt-4" >
                <div>
                    <img 
                        src={iconoUser} 
                        style={{ height: "1.5rem", width: "1.5rem" }}
                        className="perfil-img"
                    />
                </div>
                <div className="d-flex flex-column flex-5 pl-2">
                        <div className="d-flex flex-row align-items-center flex-1">
                            <span className="text-sm medium link-perfil">
                                {props.item.user}
                            </span>
                            <span className="text-sm  gris">
                                &nbsp;/&nbsp;
                                {props.t('hace unos momentos')}
                            </span>
                        </div>
                        <div className="d-flex flex-row align-items-center flex-1">
                            <span className="text-equal2 gris">
                                {props.t('Usuario')}
                            </span>
                        </div>
                    </div>
            </div>
            <div className='card-post-texto' >
                <p>
                    {props.item.contenido}
                </p>
            </div>
            <div className='d-flex flex-1' style={{borderTop:"3px solid #E6E6E6"}} >
                <div className='d-flex flex-1 justify-content-center' >
                    <i className="fas fa-thumbs-up mr-1 py-2 me__gusta"></i>
                </div>
                <div className='d-flex flex-1 justify-content-center'>
                    <i className='fas fa-comments azul-oscuro mr-1 py-2'></i>
                </div>
            </div>
            <div className='d-flex flex-1  flex-row px-5 py-4' style={{borderTop:"3px solid #E6E6E6"}} >
                <div className="d-flex align-items-start pt-1 pr-1 justify-content-center w-20">
                        <img
                            src={iconoUser}
                            style={{ height: "1.5rem", width: "1.5rem" }}
                            className="perfil-img"
                        />
                </div>
                <div className="d-flex flex-column  bg-gris-claro p-2 flex-grow-1 " style={{borderRadius:"0.3rem"}} >
                    <div className="d-flex flex-column flex-5">
                        <div className="d-flex flex-row align-items-center flex-1">
                            <span className="text-sm medium link-perfil">
                                {props.item.comentario.user}
                            </span>
                            <span className="text-sm  gris">
                                &nbsp;/&nbsp;
                                {props.t('hace unos momentos')}
                            </span>
                        </div>
                        <div className="d-flex flex-row align-items-center flex-1">
                            <span className="text-equal2 gris">
                                {props.t('Usuario')}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column" >      
                        <p className="mb-0 text-sm light">
                            {props.item.comentario.contenido}&nbsp;&nbsp;
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ayudaIntercambios =(props)=>{

    const intercambiosArray = [
        {'index':1, 'user':'María' , 'contenido':props.t('Necesito un experto en e-commerce')+'.', 
            comentario:{'user':'Bruno', 'contenido':props.t('Te recomiendo la empresa C-Consult. Logramos buenos resultados con ellos. Puedes contactar a Peter Oliveira (poliveira@cconsult.com) de mi parte')+'.'} },
        {'index':2, 'user':'Sergio' , 'contenido':props.t('Tenemos una pasantía en diseño gráfico que estará disponible a partir del próximo mes por si alguien le interesa')+'.', 
            comentario:{'user':'Kelechi', 'contenido':props.t(' A mí sí que me interesa porque estamos realizando un proyecto de diseño')+'.'} },
        {'index':3, 'user':'Juan' , 'contenido':props.t(' ¿Alguien sabe cómo preparar el informe que pide el gobierno acerca de la instalación de una fábrica de comida para niños?'), 
            comentario:{'user':'David', 'contenido':props.t('Nosotros entregamos uno exitosamente hace dos años. Te ayudamos. Contáctame')+'.'} },
        {'index':4, 'user':'Ahmed' , 'contenido':props.t('Tengo espacio en un camión que va a realizar una entrega en el capital el próximo jueves. Por si alguien le interesar aprovechar de ello')+'.', 
            comentario:{'user':'Lorraine', 'contenido':props.t('A nosotros nos interesa. Te contactamos. Muchas gracias')+'.'} },
    ]

    return(
        <div className="social-layout mb-4 col-12 mt-0" >
            <div className="card card-post bg-blanco mb-4" >
                <div className="d-flex flex-1 flex-column pt-4">
                    <div className='d-flex justify-content-center' style={{borderBottom:"3px solid #E6E6E6"}} >
                        <span className='gris h4 bold p-2' >{props.t('¿No sabes cómo realizar un Intercambio?')}</span>
                    </div>
                    <div className='d-flex flex-1 flex-column py-4 px-5' >
                        <span className='azul-gris-oscuro h5 light' >
                            {props.t('Durante los Intercambios los participantes de Growmotor ofrecen o solicitan contactos para recursos específicos (idea, alguien, gente, entidad, oportunidad, conocimiento, información, datos) que pueden desencadenar el crecimiento (en renta, caja y productividad) de las empresas de Growmotor')+'.'}
                        </span>
                        <span className='azul-gris-oscuro h5 light mt-3' >
                            {props.t('Por ejemplo, un participante de Growmotor puede estar buscando espacio adicional en el almacén, acceso a un experto/talento o acceso a un responsable clave en una entidad y un otro participante de Growmotor puede tener contactos, ideas o informaciones sobre dónde encontrarlos')+'.'}
                        </span>
                        <div className='mt-3' >
                            <span className='azul-gris-oscuro h5 bold' >{props.t('No es una actividad de compra y venta. ')}</span><span className='azul-gris-oscuro h5 light' >{props.t('Está bien y muy recomendable que haya oportunidades de negocio entre participantes de Growmotor, pero eso se trata fuera de los Intercambios. Los siguientes son ejemplos de Intercambios')+'.'}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column' >
                {intercambiosArray.map((item, index)=>(
                    <Intercambio key={index} item={item} t={props.t} />
                    ))}
            </div>
        </div>
        
    )
}

export default withTranslation()(ayudaIntercambios);


