import React, { Fragment } from 'react';
import moment from 'moment';
import "moment-timezone";
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';
import i18n from '../../../../i18nextInit'
import Com from '../Comentarios';
import { OPCIONAL, RECOMENDADO, getNivelRecomendacion, TALLER, MENTORIA, ENCUENTRO_GUERREROS, FAMILY_MEETING } from '../../../../utility/constants';
import _ from 'lodash';

const iconoTaller = require('../../../../../assets/img/iconos/taller.png');
const iconoMentoria = require('../../../../../assets/img/iconos/mentoria.png');
const iconoEncuentro = require('../../../../../assets/img/iconos/panel.png');
const iconoFamilyMeeting = require('../../../../../assets/img/iconos/familia.png');
const gmailCalender1 = require('../../../../../assets/img/iconos/gmail1.png');
const gmailCalender2 = require('../../../../../assets/img/iconos/gmail2.png');
const icsCalender1 = require('../../../../../assets/img/iconos/calender1.png');
const icsCalender2 = require('../../../../../assets/img/iconos/calender2.png');

const imgTaller = require('../../../../../assets/img/taller.png');
const imgMentoria = require('../../../../../assets/img/mentoria.png');
const imgEncuentro = require('../../../../../assets/img/panel.png');
const imgFamilyMeeting = require('../../../../../assets/img/reunion_familiar.png');


class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            comentar: false,
        };
    }
    setComentar = () => {
        this.setState({ comentar: !this.state.comentar });

    }

    getIcono = () => {
        const { item } = this.props;
        if (item) {
            if (item.tipo_publicacion == TALLER)
                return iconoTaller
            if (item.tipo_publicacion == MENTORIA)
                return iconoMentoria
            if (item.tipo_publicacion == ENCUENTRO_GUERREROS)
                return iconoEncuentro
            if (item.tipo_publicacion == FAMILY_MEETING)
                return iconoFamilyMeeting
        }
        return iconoTaller
    }

    getImg = () => {
        const { item } = this.props;
        if (item) {
            if (item.tipo_publicacion == TALLER)
                return imgTaller
            if (item.tipo_publicacion == MENTORIA)
                return imgMentoria
            if (item.tipo_publicacion == ENCUENTRO_GUERREROS)
                return imgEncuentro
            if (item.tipo_publicacion == FAMILY_MEETING)
                return imgFamilyMeeting
        }
        return imgTaller
    }

    getRuta = () => {
        const { item } = this.props;
        if (item) {
            if (item.tipo_publicacion == TALLER)
                return 'taller'
            if (item.tipo_publicacion == MENTORIA)
                return 'mentoria'
            if (item.tipo_publicacion == ENCUENTRO_GUERREROS)
                return 'panel'
            if (item.tipo_publicacion == FAMILY_MEETING)
                return 'family_meeting'
        }
        return 'taller'
    }
    getDescription(){
        const { item }= this.props;
        const div = document.createElement("div");
        div.innerHTML = item.descripcion ? item.descripcion : ''
        const texto = String(div.textContent);
        return texto.substring(0,100)
    }

    getIcs = () =>{
        const { id } = this.props.item;
        let endpoint = `/api/evento_read/${id}/obtener_ics?`;
        endpoint += `auth_token=${localStorage.getItem("token")}`
        window.open(endpoint, '_blank');
    }
    render() {
        const { id, item, isLike, isComment, verMas, verLike, me, obtenerAcceso } = this.props;
        const nivel = item.pre_workshop ? getNivelRecomendacion(me, item.tipo_preworkshop) : null;
        const image_style = {
            float: "left",
            width:  "100%",
            height: "100%",
            "object-fit": "cover",
            "object-position": "50% 50%"
        }

        const lang = i18n.language ? i18n.language : 'en'
        return (
            <Fragment>
                <div className="card card-post">
                    <div className='card-content-form flex-column px-0 pt-2 pb-0'>
                        <div className="card-post-header borde-bottom py-1">
                            <div className="d-flex align-items-center justify-content-center flex-1">
                                <img src={this.getIcono()} style={{ width: "2rem" }} />
                            </div>
                            <div className="d-flex flex-column flex-5 pr-2">
                                <div className="d-flex flex-row align-items-center flex-1">
                                    <Link to={`/${this.getRuta()}/${id}`} className="btn-post-vermas gris-oscuro h5">
                                        {item.titulo}
                                    </Link>
                                </div>
                                <div className="d-flex flex-row align-items-center flex-1">
                                    <Link className="link-perfil" to={`/perfil/${item.publicado_por ? item.publicado_por.id : 0}`}>
                                        {this.props.t('Publicado por')} {item.publicado_por ? item.publicado_por.nombres : ''}
                                        &nbsp;{me && me.timezone ? moment(item.creado).tz(me.timezone).locale(lang).fromNow() : moment(item.creado).locale(lang).fromNow()}
                                    </Link>
                                </div>
                            </div>
                            <div className="d-none d-lg-flex flex-row align-items-center flex-1">
                                {(item.proximo_taller) && (
                                    <Tag color={"warning"}>
                                        {this.props.t('Próximo evento')}
                                    </Tag>
                                )}
                            </div>
                            <div className="d-none d-lg-flex flex-row align-items-center flex-1">
                                {(item.pre_workshop) && (
                                    <Tag color={nivel ? nivel.tag : "success"}>
                                        {nivel == null ? this.props.t('Pre-workshop') : nivel.nivel == OPCIONAL ? this.props.t('Opcional')
                                            : nivel.nivel == RECOMENDADO ? this.props.t('Recomendado')
                                                : this.props.t('Muy recomendado')}
                                    </Tag>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column-reverse flex-lg-row flex-1 borde-bottom py-lg-3">
                            {(item.imagen) ? (
                                <div className="d-flex flex-1 my-3 my-lg-0">
                                    <img src={item.imagen} style={image_style} />
                                </div>
                            ):(
                                <div className="d-flex flex-1 my-3 my-lg-0">
                                    <img src={this.getImg()} style={image_style} />
                                </div>
                            )}
                            <div className="d-flex flex-column flex-1 px-4 py-3 pt-lg-0">
                                <div className={`d-flex flex-row flex-1 align-items-center ${item.link&&'borde-bottom'} mt-lg-0`}>
                                    <div className="d-flex flex-column flex-1">
                                        <i className="fas fa-calendar-alt azul-gris-oscuro"></i>
                                        <span className="text-xs azul-gris-oscuro mb-1">{this.props.t('Fecha')}</span>
                                        <span className="text-sm celeste-oscuro">{me && me.timezone ? moment(item.fecha_inicio).locale(lang).tz(me.timezone).format("MMM-DD-YYYY") : moment(item.fecha_inicio).format("MMM-DD-YYYY")}</span>
                                    </div>
                                    <div className="d-flex flex-column flex-1">
                                        <i className="fas fa-clock azul-gris-oscuro"></i>
                                        <span className="text-xs azul-gris-oscuro mb-1">{this.props.t('Hora')}</span>
                                        <span className="text-sm celeste-oscuro">{me && me.timezone ? moment(item.fecha_inicio).tz(me.timezone).format("hh:mm a z") : moment(item.fecha_inicio).format("hh:mm a z")}</span>
                                    </div>
                                </div>
                                {(item.calender)&&(
                                    <div className="d-flex flex-row flex-column justify-content-center flex-1 borde-bottom py-3">
                                        <div className='d-flex flex-row' >
                                            <span className='azul-gris-oscuro flex-2' >
                                                {this.props.t('Agregar evento a su Google Calendar')}
                                            </span>
                                            <button type='button' className='btn-xs btn-secondary-fill btn-icon ml-md-1 flex-1' onClick={e=>window.open(item.calender,'_blank')}>
                                                {this.props.t('Gmail')} 
                                                <img src={gmailCalender1} className='icon-btn-1 ml-1'/> 
                                                <img src={gmailCalender2} className='icon-btn-2 ml-1' /> 
                                            </button>
                                        </div>
                                        <div className='d-flex flex-row pt-3' >
                                            <span className='azul-gris-oscuro flex-2'>
                                                {this.props.t('Agregar evento a su iCal/Outlook')}
                                            </span>
                                            <button type='button' className='btn-xs btn-secondary-fill ml-md-1 btn-icon  flex-1' onClick={e=>this.getIcs()}>
                                                {this.props.t('Outlook')}
                                                <img src={gmailCalender1} className='icon-btn-1 ml-1'/> 
                                                <img src={gmailCalender2} className='icon-btn-2 ml-1' /> 
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {(item&&item.link)&&(
                                    <div className="d-flex flex-row flex-1 justify-content-end py-3">
                                        <div>
                                            <button onClick={() => obtenerAcceso(id)} disabled={!item.acceder_taller} className="btn btn-primary mr-2">
                                                {this.props.t('Entrar a videollamada')}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-1 py-3 px-4">
                            <p className="text-sm azul-gris-oscuro" >{this.getDescription()}...&nbsp;&nbsp;<Link to={`/${this.getRuta()}/${id}`} className="btn-post-vermas">{this.props.t('Ver más')}</Link></p>
                            
                        </div>
                        <div className="card-post-actions">
                            <div className="d-flex align-items-center justify-content-center w-50">
                                <i className="text-equal2 fas fa-comments celeste-oscuro"></i>
                                <span className="text-equal2 gris-oscuro ml-1">{item.cantidad_comentarios}</span>
                            </div>
                            <div className="d-flex w-50">
                                <button
                                    onClick={(e) => { this.setComentar() }}
                                    className={`btn-post-action azul-oscuro ${isComment && 'active'}`}>
                                    <i className="fas fa-comments azul-oscuro mr-1 py-3"></i>
                                    {this.props.t('Ver comentarios')}
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                {(this.state.comentar && item.id) && (
                    <Com.Base publicacion={item} me={this.props.me} />
                )}
                <div className="mb-4" />
            </Fragment>
        )
    }
}

export default withTranslation()(Card);
