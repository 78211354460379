import * as firebase from "firebase/app";
import "firebase/messaging";

let messaging = null
if(firebase.messaging.isSupported()){
     const initializedFirebaseApp = firebase.initializeApp({
          // Project Settings => Add Firebase to your web app
          messagingSenderId: "589035098460", //! cambiar luego
     });
     messaging = initializedFirebaseApp.messaging();
}
export { messaging };
