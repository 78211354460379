import React, { Fragment } from 'react';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderMonthPicker, renderNumber, renderFieldCheck } from '../../Utils/renderField/renderField';
import { RenderCurrency } from '../../Utils/renderField/renderReadField';
import CustomTooltip from '../../Utils/Tooltip';

const tarjeta = require("../../../../../assets/img/iconos/tarjeta.png")

const PasarelaForm = props => {
    const { handleSubmit, item } = props;

    return (
        <form onSubmit={handleSubmit} className="form-validate mb-lg">
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-column flex-md-row flex-wrap px-0 mt-2 borde-bottom">
                    <div className="d-flex flex-column flex-1">
                        <span className="text-sm bold azul-gris-oscuro">{props.t('Cohorte')}</span>
                        <span className="text-sm celeste-oscuro">{item.cohorte ? item.cohorte.nombre : ''}</span>
                    </div>
                    <div className="d-flex flex-column flex-1 mt-3 mt-md-0">
                        <span className="text-sm bold azul-gris-oscuro">{props.t('Monto')}</span>
                        <RenderCurrency className="text-sm celeste-oscuro" value={item.cohorte ? item.cohorte.costo : 0} prefix="USD " />
                    </div>
                </div>
                <div className="d-flex flex-column flex-wrap px-3 mt-5 mb-2">
                    <span className="text-sm bold azul-gris-oscuro">{props.t('Datos de la tarjeta')}</span>
                    <div className="w-100 bg-gris" style={{ height: "3px" }}></div>
                </div>
                {/* <div className="d-flex flex-lg-row flex-column flex-wrap px-3 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombre_tarjeta" className="m-0">{props.t('Nombre de la tarjeta')}</label>
                        <Field name="nombre_tarjeta" component={renderField} type="text" className="form-control" />
                    </div>
                </div> */}
                <div className="d-flex flex-lg-row flex-column flex-wrap px-3 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <div className="d-flex justify-content-between" >
                            <label htmlFor="numero_tarjeta" className="m-0">{props.t('Número de la tarjeta')}</label>
                            <CustomTooltip msj={props.t('Ingrese el numero de su tarjeta de crédito o débito')+'.' }/>
                        </div>
                        <Field
                            name="numero_tarjeta"
                            component={renderNumber}
                            type="text"
                            className="form-control"
                            numberFormat="#### #### #### ####"
                            placeholder="#### #### #### ####"
                        />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-3 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <div className="d-flex justify-content-between" >
                            <label htmlFor="fecha_tarjeta" className="m-0">{props.t('Fecha de expiración (Mes / año)')}</label>
                            <CustomTooltip msj={props.t('Ingrese la fecha de expiración de su tarjeta de crédito o débito')+'.'}/>
                        </div>
                        <Field name="fecha_tarjeta" component={renderMonthPicker} type="text" />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-3 mt-2">
                    <div className="d-flex flex-column flex-3 p-2">
                        <div className="d-flex justify-content-between" >
                            <label htmlFor="codigo_tarjeta" className="m-0">{props.t('Código de seguridad (CVC)')}</label>
                            <CustomTooltip msj={props.t('Ingrese el código CVC que se encuentra el la parte posterior de su tarjeta de crédito o débito')+'.'}/>
                        </div>
                        <Field
                            name="codigo_tarjeta"
                            component={renderNumber}
                            type="number"
                            className="form-control"
                            numberFormat="####"
                            placeholder="CVC"
                        />
                    </div>
                    <div className="d-flex justify-content-end align-items-center flex-column flex-1 p-2">
                        <img src={tarjeta} />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-3 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <Field
                            name="guardar_tarjeta"
                            label={props.t('Guardar datos de la tarjeta')}
                            component={renderFieldCheck}
                        />
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <button type="submit" className="btn btn-primary">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )

}

export default reduxForm({
    form: 'PasarelaForm',
    asyncBlurFields: [],
    initialValues: {
        guardar_tarjeta: false
    },
    validate: (data, props) => {
        return validate(data, {
            // 'nombre_tarjeta': validators.exists()(`${props.t('Campo requerido')}`),
            'numero_tarjeta': validators.exists()(`${props.t('Campo requerido')}`),
            'fecha_tarjeta': validators.exists()(`${props.t('Campo requerido')}`),
            'codigo_tarjeta': validators.exists()(`${props.t('Campo requerido')}`),
        })
    },
})(PasarelaForm);
