import { handleActions } from 'redux-actions';
import { api } from "api";
import {NotificationManager} from "react-notifications";
import _ from "lodash";
import { SwalExito } from '../../../common/components/Utils/Notificaciones';
import { getI18n } from 'react-i18next';

const LOADER = 'LOADER_COMENTARIO';
const DATA = 'DATA_COMENTARIO';
const PAGE = 'PAGE_COMENTARIO';
const COMENTARIO = 'COMENTARIO';
const SUBCOMENTARIO = 'SUBCOMENTARIO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});


const setComentario =comentario => ({
    type: COMENTARIO,
    comentario,
});

const setSubComentario = subComentario => ({
    type: SUBCOMENTARIO,
    subComentario,
});


const setPage = page => ({
    type: PAGE,
    page,
});


// ------------------------------------
// Actions
// ------------------------------------

const onSubmit = (data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    return api.postAttachments(`comentario`, data, attachments).then(() => {
        NotificationManager.success(getI18n().t('Comentario Enviado'), getI18n().t('Éxito'),3000);
    }).catch((error) => {
        let mensaje = getI18n().t('Error al comentar');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    })
};

const editar = (id, data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    return api.putAttachments(`comentario/${id}`, data, attachments).then(() => {
        NotificationManager.success(getI18n().t('Comentario Editado'), getI18n().t('Éxito'), 3000);
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la edición de comentario');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    return api.eliminar(`comentario/${id}`).then(() => {
        NotificationManager.success(getI18n().t('El comentario se ha eliminado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error al eliminar comentario'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    onSubmit,
    editar,
    eliminar,
    //setEditar,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [COMENTARIO]: (state, { comentario }) => {
        return {
            ...state,
            comentario,
        };
    },
    [SUBCOMENTARIO]: (state, { subComentario }) => {
        return {
            ...state,
            subComentario,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    comentario:{},
    subComentario:{},
};

export default handleActions(reducers, initialState);
