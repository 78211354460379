import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import moment from 'moment';
import { FAMILY_MEETING, ADMIN, GROWTH_ANGEL } from '../../../utility/constants';
import { SwalExito, SwalError } from '../../../common/components/Utils/Notificaciones';
import Swal from 'sweetalert2';
import { getI18n } from 'react-i18next';
import { errorSweet, validacionSweet } from '../../../common/components/Utils/ValidacionUsuarioZoom';

const LOADER = 'LOADER_FAMILY';
const DATA = 'DATA_FAMILY';
const ITEM_DATA = 'ITEM_FAMILY';
const PAGE = 'PAGE_FAMILY';
const ORDERING = 'ORDERING_FAMILY';
const SEARCH = 'SEARCH_FAMILY';
const COLABORADORES = 'COLABORADORES_FAMILY';
const DATA_ASIGNADOS = 'DATA_ASIGNADOS_FAMILY';
const DATA_INVITADOS = 'DATA_INVITADOS_FAMILY';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (data, type=DATA) => ({
    type,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setDataAsignados = data_asignados => ({
    type: DATA_ASIGNADOS,
    data_asignados,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1, nuevo=true, tab='nuevos') => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const resource = getStore().familyMeeting;
    const me = getStore().login.me;
    const params = { page };
    if(tab==='nuevos')
        params.nuevos = true;
    else if (tab==='pasados')
        params.pasados = true; 
    params.tipo_publicacion = FAMILY_MEETING;
    params.ordering = resource.ordering;
    params.search = resource.search;
    if ([ADMIN, GROWTH_ANGEL].includes(me.tipo_usuario) && cohorte) params.cohorte = JSON.parse(cohorte).id;
    dispatch(setLoader(true));
    api.get('evento', params).then((response) => {
        const data_anterior = resource.data;
        const results = data_anterior.results;
        results.push(...response.results);
        dispatch(setData(response));
        if (!nuevo) {
            dispatch(setData({...response, results: results}));
        }
        dispatch(setPage(page));
    }).catch(() => {
        dispatch(setData({count:0, results:[]}));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    dispatch(setLoader(true));
    api.get(`evento/${id}`).then((response) => {
        if(me&&[ADMIN, GROWTH_ANGEL].includes(me.tipo_usuario)&&cohorte){
            if(JSON.parse(cohorte).id!==response.cohorte.id){
                Swal.fire({
                    title: getI18n().t('¿Ver evento?'),
                    type: 'warning',
                    text: getI18n().t('Actualmente te encuentras en la cohorte {{cohorte1}} y el evento que deseas ver pertenece a la cohorte {{cohorte2}} por favor cambia de cohorte', {'cohorte1':JSON.parse(cohorte).nombre,'cohorte2':response.cohorte.nombre}),
                    //showCancelButton: true,
                    confirmButtonText: getI18n().t('¡Ok!'),
                    //cancelButtonText: 'No, cancelar',
                    reverseButtons: true
                }).then((result) => {});
                dispatch(setItem({}));
            }
            if(JSON.parse(cohorte).id===response.cohorte.id){
                dispatch(setItem(response));
                dispatch(obtenerAsignados(id));
                dispatch(obtenerInvitados(id));
            }
        }else{
            dispatch(setItem(response));
            dispatch(obtenerAsignados(id));
        }
    }).catch((error) => {
        dispatch(push('/404'))
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const activarEvaluacion = (id, idTaller) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`evaluacion/${id}/activar_evaluacion`, {}).then(() => {
        SwalExito(getI18n().t('Éxito'), getI18n().t('Se ha activado la evaluación'));
        dispatch(leer(idTaller));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al activar la evaluación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        SwalError(getI18n().t('Error'), mensaje);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const activarEvaluaciones = (idTaller) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`evaluacion/activar_evaluaciones`, {taller: idTaller}).then(() => {
        SwalExito(getI18n().t('Éxito'), getI18n().t('Se han activado todas las evaluaciones'));
        dispatch(leer(idTaller));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al activar las evaluacines');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        SwalError(getI18n().t('Error'), mensaje);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerColab = () => (dispatch, getStore) => {
    const item_taller = getStore().familyMeeting.item;
    const me = getStore().login.me;
    const params = {
        admin_empresa: false,
        colider:false,
        //is_active: true,
        empresa: me.empresa
    }
    dispatch(setLoader(true));
    api.get('colaborador', params).then((response) => {
        const data = {...response};
        const array = [];
        for (const item of data.results) {
            const ya_asignado = _.find(item_taller.asignados_taller, (_item) => _item.usuario ? _item.usuario.id == item.id : false)
            if (!ya_asignado)
                array.push({...item})
        }
        data.results = array;
        dispatch(setData(data, COLABORADORES));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const agregarAsignaciones = (idTaller, data, closeModal) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`evento/${idTaller}/agregar_asignados`, data).then(() => {
        SwalExito(getI18n().t('Exito'), getI18n().t('Se han agregado colaboradores a la reunión familiar'));
        dispatch(leer(idTaller));
        closeModal();
    }).catch((error) => {
        let mensaje = getI18n().t('Error al agregar colaboradores');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        SwalError(getI18n().t('Error'), mensaje);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarAsignacion = (id, idTaller) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`evento/eliminar_asignado`, {asignacion: id}).then(() => {
        SwalExito(getI18n().t('Exito'), getI18n().t('Se ha eliminado al colaborador a la reunión familiar'));
        dispatch(leer(idTaller));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al eliminar al colaborador a la reunión familiar');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        SwalError(getI18n().t('Error'), mensaje);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const generarLink=(id)=>(dispatch)=>{
    api.post(`evento/${id}/generar_meeting_link`).then(
        ()=>{
            SwalExito(getI18n().t('Exito'), getI18n().t('Se ha generado el link de la reunión familiar'));
            dispatch(leer(id))
        }
    ).catch(()=>{
        SwalError(getI18n().t('Error'), getI18n().t('El link ya ha sido generado'));
    })
}


const obtenerAcceso=(id, me)=>(dispatch)=>{
    api.get(`evento/${id}/acceso_meeting`).then((response)=>{
        validacionSweet(response);
    }).catch((e)=>{
        errorSweet(e, 'La reunión familiar aun no esta disponible');
    })
}

const obtenerAsignados=(id)=>(dispatch)=>{
    dispatch(setLoader(true))
    api.get(`evento/${id}/obtener_asignados`).then((response)=>{
        dispatch(setDataAsignados(response));
    }).catch(()=>NotificationManager.error(getI18n().t('Algo salio mal al cargar asignados'), getI18n().t('ERROR'),3000)
    ).finally(()=>dispatch(setLoader(false)))
}

const asignarResponsable = (idTaller, item, setItem) => (dispatch) => {
    api.post(`evento/asignarResponsable`, item).then(() => {
        SwalExito(getI18n().t('Exito'), getI18n().t('Se ha cambiado el responsable'));
        dispatch(leer(idTaller));
        dispatch(obtenerAsignados(idTaller));
        setItem({...item, responsable: true })
    }).catch((error) => {
        let mensaje = getI18n().t('Error al cambiar el responsable');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        SwalError(getI18n().t('Error'), mensaje);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const subirMemo = (idTaller, data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('memo_drill', data, attachments).then(() => {
        SwalExito(getI18n().t('Exito'), getI18n().t('Memo de drill subido'));
        dispatch(leer(idTaller));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al subir un drill');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        SwalError(getI18n().t('Error'), mensaje);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarArchivoMemo = (idMemo, idArchivo, idTaller) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`memo_drill/${idMemo}/eliminar_archivo`, {archivo: idArchivo}).then(() => {
        SwalExito(getI18n().t('Exito'), getI18n().t('Se ha eliminado el archivo del memo'));
        dispatch(leer(idTaller));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al borrar el archivo');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        SwalError(getI18n().t('Error'), mensaje);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const anularAsistencia = (idTaller, data, closeModal) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`evento/${idTaller}/anular_asistencia`, data).then(() => {
        SwalExito(getI18n().t('Exito'), getI18n().t('Se ha anulado la asistencia'));
        dispatch(leer(idTaller));
        dispatch(obtenerAsignados(idTaller));
        closeModal();
    }).catch((error) => {
        let mensaje = getI18n().t('Error al anular la asistencia');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        SwalError(getI18n().t('Error'), mensaje);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const obtenerInvitados = (idTaller, page=1)=>(dispatch)=>{
    dispatch(setLoader(true));
    api.get(`evento_read/${idTaller}/obtener_inscritos_eventos`,{page}).then((response)=>{
        dispatch(setData(response, DATA_INVITADOS));
        dispatch(setPage(page));
    }).catch((error) => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const descargarExcel=(idTaller)=>{
    let endpoint = `/api/evento_read/${idTaller}/obtener_inscritos_eventos/?excel=true&`;
    endpoint += `auth_token=${localStorage.getItem("token")}`
    window.open(endpoint, '_blank');
}


export const actions = {
    listar,
    leer,
    activarEvaluacion,
    activarEvaluaciones,
    obtenerColab,
    agregarAsignaciones,
    eliminarAsignacion,
    generarLink,
    obtenerAcceso,
    obtenerAsignados,
    asignarResponsable,
    subirMemo,
    eliminarArchivoMemo,
    anularAsistencia,
    obtenerInvitados,
    descargarExcel,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [COLABORADORES]: (state, { data }) => {
        return {
            ...state,
            colaboradores: data,
        };
    },
    [DATA_ASIGNADOS]: (state, { data_asignados }) => {
        return {
            ...state,
            data_asignados,
        };
    },
    [DATA_INVITADOS]:(state, { data })=>{
        return{
            ...state,
            data_invitados:data,
        }
    }
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    data_invitados:{
        results: [],
        count: 0,
    },
    colaboradores: {
        results: [],
        count: 0,
    },
    data_asignados:{
        results: [],
        count: 0,
    }
};

export default handleActions(reducers, initialState);
