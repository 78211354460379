import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/cuenta/amigosGrowmotor';
import Register from './RegisterAmigosGrowmotor';


const ms2p = (state) => {
  return {
    ...state.amigosGrowmotor,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Register);
