import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import LoadMask from '../../../Utils/LoadMask/LoadMask';

import PersonasTab from './PersonasTab';
import EmpresasTab from './EmpresasTab';
import PublicacionesTab from './PublicacionesTab';

class Busquedas extends Component {

    componentDidMount() {
        console.log(this.props);
        this.props.resultadosUsuarios();
        this.props.resultadosEmpresas();
        this.props.resultadosPublicaciones();
    }

    componentDidUpdate(prevProps, prevState) {
        const old_search = prevProps.search;
        const new_search = this.props.search;

        if (old_search != new_search) {
            this.props.resultadosUsuarios();
            this.props.resultadosEmpresas();
            this.props.resultadosPublicaciones();
        }
    }

    render() {
        const { loader} = this.props;

        return (
            <div className="social-layout mb-4 col-12 mt-3 mt-md-0" style={{maxWidth: "100%"}}>
                <LoadMask loading={loader} blur fixed>
                    <Tabs
                        defaultActiveKey="personas"
                        onChange={this.callback}
                        tabPosition="top"
                    >
                        <Tabs.TabPane tab={this.props.t("Personas")} key="personas">
                            <PersonasTab  {...this.props} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={this.props.t("Empresas")} key="empresas">
                            <EmpresasTab {...this.props} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={this.props.t("Publicaciones")} key="publiciones">
                            <PublicacionesTab {...this.props} />
                        </Tabs.TabPane>
                    </Tabs>
                </LoadMask>
            </div>
        )
    }

}

export default withTranslation()(Busquedas)
