import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderFieldRadio, renderNumber, renderTextArea, SelectField, AsyncSelectField } from '../../../Utils/renderField/renderField';
import { COUNTRIES } from '../../../../../utility/paises';
import CustomTooltip from '../../../Utils/Tooltip';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const getEmpresas = (search) => {
    const params = {}
    if (search) params.search = search;
    return api.get("empresa", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}


let ExamenKPIsForm = props => {
    const { handleSubmit, periodo } = props;
    const notificacion = ()=> {
        if(!props.valid){
            NotificationManager.error(props.t('¡Por favor, conteste todas las preguntas!'), 'ERROR', 3000)
        } 
    }
    return (
        <form onSubmit={handleSubmit} className="form-validate mb-lg">
            <div className="form-group d-flex flex-column flex-1">

                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">1.{props.t('¿Cuáles son sus ventas en lo que va del año?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1"  msj={props.t('Ingrese la cantidad en dolares de las ventas desde el inicio del año hasta este momento, puede utilizar una herramienta online para realizar la conversion si maneja otro tipo de moneda')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-center flex-2">
                        <Field name="pregunta1_a" prefix="USD " component={renderNumber} placeholder="USD 0.00" decimalScale={2} type="text" className="form-control w-100" />
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul-gris-oscuro pl-2 pl-md-4">1.1.{props.t('¿Incremento o decremento con respecto al mismo periodo del año pasado?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese el porcentaje de incremento o decremento de las ventas, comparando las ventas en lo que va del año con las ventas del año pasado en el mismo periodo')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-center flex-2">
                        <Field name="pregunta1_b" prefix="% " component={renderNumber} placeholder="% 0.00" decimalScale={2} type="text" className="form-control w-100" />
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul-gris-oscuro pl-2 pl-md-4">1.2.{props.t('¿Espera sobrepasar los ingresos del año pasado?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si considera sobrepasar los ingresos del año pasado')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('Sí')}
                                className="oso"
                                type="radio"
                                value={true}
                                name="pregunta1_c"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta1_c"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta1_c) && (
                    <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                        <div className="d-flex align-items-md-center flex-3">
                            <span className="text-sm azul-gris-oscuro pl-4 pl-md-5">1.2.1.{props.t('De ser así, ¿por cuánto aproximadamente?')} </span>
                            <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1"  msj={props.t('Ingrese el porcentaje de incremento en los ingresos que considera obtener este año')+'.'}/>
                        </div>
                        <div className="d-flex justify-content-end align-items-center flex-2">
                            <Field name="pregunta1_c_i" prefix="% " component={renderNumber} placeholder="% 0.00" decimalScale={2} type="text" className="form-control w-100" />
                        </div>
                    </div>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">2-{props.t('¿En este momento cuántos empleados de tiempo completo tiene?')} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese la cantidad de empleados de tiempo completo que trabajan en su empresa')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-center flex-2">
                        <Field name="pregunta2" component={renderNumber} type="text" className="form-control w-100" />
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul-gris-oscuro pl-2 pl-md-4">2.1.{props.t('¿Ha hecho nuevas contrataciones en el periodo {{periodo}}?',{'periodo':periodo})} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si ha realizado nuevas contrataciones en el periodo {{periodo}}',{'periodo':periodo})+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('Sí')}
                                type="radio"
                                value={true}
                                name="pregunta2_a"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta2_a"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta2_a) && (
                    <Fragment>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4 pl-md-5">2.1.1.{props.t('De ser así, indique cuánto')+'.'} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese la cantidad de las contrataciones que ha realizado en el periodo {{periodo}}',{'periodo':periodo})+'.'}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2">
                                <Field name="pregunta2_a_i" component={renderNumber} type="text" className="form-control w-100" />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4 pl-md-5">2.1.2.{props.t('Tipo de trabajo / roles')} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese cada uno de los roles de las personas que ha contratado separados de una coma. ejemplo; contadores, agente de ventas, agentes de publicidad')}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2">
                                <Field name="pregunta2_a_ii" component={renderField} type="text" className="form-control w-100" />
                            </div>
                        </div>
                    </Fragment>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">3-{props.t('¿Obtuvo nuevo financiamiento (por ejemplo, préstamos, líneas de crédito, etc.) en el periodo {{periodo}}?',{'periodo':periodo})} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si ha obtenido un nuevo financiamiento por ejemplo, préstamos, líneas de crédito, etc')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('Sí')}
                                type="radio"
                                value={true}
                                name="pregunta3"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta3"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta3) && (
                    <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                        <div className="d-flex align-items-md-center flex-3">
                            <span className="text-sm azul-gris-oscuro pl-2 pl-md-4">3.1.{props.t('De ser así, indique cuánto')+'.'} </span>
                            <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese la cantidad obtenida en el financiamiento o la suma de los financiamientos obtenidos son mas de uno')+'.'}/>
                        </div>
                        <div className="d-flex justify-content-end align-items-center flex-2">
                            <Field name="pregunta3_a" prefix="USD " placeholder="USD 0.00" component={renderNumber} decimalScale={2} type="text" className="form-control w-100" />
                        </div>
                    </div>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">4-{props.t('¿Ha expandido su capacidad ( por ejemplo, capacidad de manufactura, edificio o puntos de venta) en el periodo {{periodo}}?',{'periodo':periodo})} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si ha crecido en las areas de ventas, manufactura, edificios y otros')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('Sí')}
                                type="radio"
                                value={true}
                                name="pregunta4"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta4"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta4) && (
                    <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                        <div className="d-flex align-items-md-center flex-3">
                            <span className="text-sm azul-gris-oscuro pl-2 pl-md-4">4.1.{props.t('Por favor, describa')} </span>
                            <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Describa cada una de las areas donde ha tenido una expansion de capacidad en el periodo {{periodo}}',{'periodo':periodo})+'.'}/>
                        </div>
                        <div className="d-flex justify-content-end align-items-center flex-2">
                            <Field name="pregunta4_a" component={renderTextArea} type="text" className="form-control w-100" />
                        </div>
                    </div>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">5-{props.t('¿Ha hecho negocios con otra empresa de su cohorte dentro del periodo {{periodo}}?',{'periodo':periodo})} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si ha hecho negocios con otras empresas de la cohorte dentro del periodo {{periodo}}',{'periodo':periodo})+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('Sí')}
                                type="radio"
                                value={true}
                                name="pregunta5"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta5"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta5) && (
                    <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                        <div className="d-flex align-items-md-center flex-3">
                            <span className="text-sm azul-gris-oscuro pl-2 pl-md-4">5.1.{props.t('De ser así, seleccione las empresas')} </span>
                            <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Seleccione todas las empresas con las que ha hecho negocios dentro del periodo {{periodo}}',{'periodo':periodo})+'.'}/>
                        </div>
                        <div className="d-flex justify-content-end align-items-center flex-2 select-kpis">
                            <Field
                                name="pregunta5_a"
                                loadOptions={getEmpresas}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="nombre"
                                isMulti={true}
                                isClearable={true}
                                className="form-control"
                            />
                        </div>
                    </div>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">6-{props.t('¿Ha vendido a clientes en otros estados/regiones de su país en el periodo {{periodo}}?',{'periodo':periodo})+'.'} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si le ha vendido a clientes de otros estados, regiones o departamentos dentro de su país en el periodo {{periodo}}',{'periodo':periodo})+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('Sí')}
                                type="radio"
                                value={true}
                                name="pregunta7"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta7"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta7) && (
                    <Fragment>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-2 pl-md-4">6.1.{props.t('De ser así, porfavor indique')} </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4 pl-md-5">6.1.1.{props.t('Estados/regiones')+'.'} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese cada uno de los estados, regiones o departamentos donde realizado las ventas, separadas por una coma. ejemplo; San Marcos, Quetzaltenango')}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2">
                                <Field name="pregunta7_i" component={renderTextArea} type="text" className="form-control w-100" />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4 pl-md-5">6.1.2.{props.t('Total aproximado de ingresos por ventas')+'.'} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese el total de las ventas realizadas a otros estados, regiones o departamentos (que no sean en suyo) en el periodo {{periodo}}',{'periodo':periodo})+'.'}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2">
                                <Field name="pregunta7_ii" prefix="USD " placeholder="USD 0.00" component={renderNumber} decimalScale={2} type="text" className="form-control w-100" />
                            </div>
                        </div>
                    </Fragment>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">7-{props.t('¿Ha exportado a otros países en el periodo {{periodo}}?',{'periodo':periodo})+'.'} </span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Marque si, si a realizado exportaciones a otros países en el periodo {{periodo}}',{'periodo':periodo})+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('Sí')}
                                type="radio"
                                value={true}
                                name="pregunta8"
                                parse={val => !!val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="d-flex flex-1 justify-content-start sin-padding p-2 pt-md-0">
                            <Field
                                label={props.t('No')}
                                type="radio"
                                value={false}
                                name="pregunta8"
                                parse={val => !val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                </div>
                {(props.pregunta8) && (
                    <Fragment>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-2 pl-md-4">7.1.{props.t('De ser así, porfavor indique')} </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4 pl-md-5">7.1.1.{props.t('Paises')+'.'} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Seleccione todos los países a los que ha realizado exportaciones en el periodo {{periodo}}',{'periodo':periodo})+'.'}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2 select-kpis">
                                <Field
                                    name="pregunta8_i"
                                    className="w-100"
                                    isMulti
                                    valueKey="id"
                                    labelKey="name"
                                    options={COUNTRIES}
                                    component={SelectField}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                            <div className="d-flex align-items-md-center flex-3">
                                <span className="text-sm azul-gris-oscuro pl-4 pl-md-5">7.1.2.{props.t('Total aproximado de ingresos de exportación')+'.'} </span>
                                <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese la cantidad en dolares del total de ventas en exportaciones a otros países en el periodo {{periodo}}, puede utilizar una herramienta online para realizar la conversion si maneja otro tipo de moneda',{'periodo':periodo})+'.'}/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-2">
                                <Field name="pregunta8_ii" prefix="USD " placeholder="USD 0.00" component={renderNumber} decimalScale={2} type="text" className="form-control w-100" />
                            </div>
                        </div>
                    </Fragment>
                )}


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">8-{props.t('¿Cuáles son sus impuestos federales o nacionales (IVA si aplica), en lo que va del año actual?')}</span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('Ingrese la cantidad en dolares del total de los impuestos federales o nacionales pagados en lo que va del año por su empresa')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <Field name="pregunta9_a" prefix="USD " component={renderNumber} placeholder="USD 0.00" decimalScale={2} type="text" className="form-control w-100" />
                    </div>
                </div>


                <div className="d-flex flex-column flex-md-row flex-1 mt-4">
                    <div className="d-flex align-items-md-center flex-3">
                        <span className="text-sm azul bold">9-{props.t('¿Cuáles son sus impuestos estatales o regionales en lo que va del año actual (si aplica)?')}</span>
                        <CustomTooltip className="d-flex flex-1 justify-content-end justify-content-md-start pl-md-3 pr-1 pt-1 pb-1" msj={props.t('(Si en su país aplica) Ingrese la cantidad en dolares del total de los impuestos estatales o regionales pagados en lo que va del año por su empresa')+'.'}/>
                    </div>
                    <div className="d-flex justify-content-end align-items-end flex-2">
                        <Field name="pregunta10_a" prefix="USD " component={renderNumber} placeholder="USD 0.00" decimalScale={2} type="text" className="form-control w-100" />
                    </div>
                </div>

                <div className="d-none d-lg-flex buttons-box mt-5">
                    <button type="submit" onClick={notificacion} className="btn btn-primary">{props.t('Enviar KPIs')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-5">
                    <button type="submit" onClick={notificacion} className="btn btn-primary flex-1 mt-3">{props.t('Enviar KPIs')}</button>
                </div>
            </div>
        </form>
    )

}

const selector = formValueSelector('ExamenKPIsForm');

ExamenKPIsForm = reduxForm({
    form: 'ExamenKPIsForm',
    asyncBlurFields: [],
    initialValues: {
        pregunta1_c: false,
        pregunta2_a: false,
        pregunta3: false,
        pregunta4: false,
        pregunta5: false,
        pregunta6: false,
        pregunta7: false,
        pregunta8: false,
        pregunta10_a: 0,
    },
    validate: (data, props) => {
        return validate(data, {
            'pregunta1': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta1_a': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta1_b': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta1_c_i': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta2': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta2_a_i': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta2_a_ii': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta3_a': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta4_a': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta7_i': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta7_ii': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta8_i': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta8_ii': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta9': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta9': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta9_a': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta10': validators.exists()(`${props.t('Campo requerido')}`),
            // 'pregunta10_a': validators.exists()(`${props.t('Campo requerido')}`),
        })
    },
})(ExamenKPIsForm);

const mstp = state => {
    const pregunta1_c = selector(state, 'pregunta1_c');
    const pregunta2_a = selector(state, 'pregunta2_a');
    const pregunta3 = selector(state, 'pregunta3');
    const pregunta4 = selector(state, 'pregunta4');
    const pregunta5 = selector(state, 'pregunta5');
    const pregunta6 = selector(state, 'pregunta6');
    const pregunta7 = selector(state, 'pregunta7');
    const pregunta8 = selector(state, 'pregunta8');

    return {
        pregunta1_c,
        pregunta2_a,
        pregunta3,
        pregunta4,
        pregunta5,
        pregunta6,
        pregunta7,
        pregunta8,
    }
};

export default connect(mstp, null)(ExamenKPIsForm);
