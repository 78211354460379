import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, RenderImageUpload } from '../../Utils/renderField';
import { withTranslation } from 'react-i18next';
import CustomTooltip from '../../Utils/Tooltip';

const validators = (values, props)=>{
    const errors= [];
    if (!values.nombres){
        errors.nombres= `${props.t('Campo requerido')}`; 
    }
    if (!values.email){
        errors.email = `${props.t('Campo requerido')}`; 
    }
    if (!values.password){
        errors.password = `${props.t('Campo requerido')}`; 
    }
    if (!values.verificar_password){
        errors.verificar_password = `${props.t('Campo requerido')}`; 
    }
    if(values.password&&String(values.password).length<8){
        errors.password= `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`; 
    }
    if(values.verificar_password&&String(values.verificar_password).length<8){
        errors.verificar_password= `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`; 
    }
    if( values.password && values.verificar_password ){
        if (values.password !==values.verificar_password){
            errors.verificar_password = `${props.t('Las contraseñas no coinciden')}`; 
        }
    }
    return errors;
}


const PasoUnoForm = (props) => {
    const { handleSubmit, setAvatar, imagen} = props;
    return (
        <form className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <div className="d-flex flex-row justify-content-between" >
                    <label htmlFor="nombres" className="label">{props.t('Nombres')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Ingrese su nombre completo')} />
                </div>
                <Field name="nombres" label="Nombre" component={renderField} type="text" className="form-control" />
            </div>
            <div className="form-group has-feedback">
                <div className="d-flex flex-row justify-content-between" >
                    <label htmlFor="email" className="label">{props.t('Correo')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Ingrese su correo electrónico personal')+'.'} />
                </div>
                <Field name="email" label="email" component={renderField} type="email" className="form-control" />
            </div>
            <div className="form-group has-feedback">
                <div className="d-flex flex-row justify-content-between" >
                    <label htmlFor="password" className="label">{props.t('Contraseña')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Ingrese una contraseña de ocho caracteres o mas, combinando letras y números')+'.'} />
                </div>
                <Field name="password" label="Password" component={renderField} type="password" className="form-control" />
            </div>
            <div className="form-group has-feedback">
                <div className="d-flex flex-row justify-content-between" >
                    <label htmlFor="verificar_password" className="label">{props.t('Confirmar contraseña')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Ingrese una contraseña de ocho caracteres o mas, combinando letras y números')+'.'} />
                </div>
                <Field name="verificar_password" label="Password verificacion" component={renderField} type="password" className="form-control" />
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="foto" className="label">{props.t('Foto de Perfil')}</label>
                <div style={{height:"9.1rem", width:"9.6rem"}} >
                    <RenderImageUpload imagen={imagen} aspect={1} multiple={false} setFile={setAvatar} />
                </div>
            </div>
            <div className="buttons-box">
                <button type="submit" className="btn btn-primary m-1 align-self-center">{props.t('Siguiente')}</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'RegistroColabForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: validators,
})(withTranslation()(PasoUnoForm));
