import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import _ from "lodash";
import { renderFieldCheck } from '../../../Utils/renderField/renderField';


let ModalColaboradores = (props) => {


    const { loader, t, colaboradores, item, seleccionados, handleSubmit, cerrar } = props;

    const getSeleccionados = () => {

        let asignados = item.asignados_taller.filter(t => { return !t.usuario.colider && !t.usuario.admin_empresa});
        asignados = asignados ? asignados.length : 0;

        let total = 0;
        if (seleccionados) {
            for (const _item in seleccionados){
                total += seleccionados[_item] ? 1 : 0;
            }
        }

        return total + asignados;
    }

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column flex-1 max-width-100">
            <div className="d-flex flex-column flex-1 mb-4">
                <span className="gris-oscuro text-qts">{t('Agrega a tus colaboradores al encuentro')}</span>
                <span className="marron text-sm">{`Limite de colaboradores ${getSeleccionados()}/${item.limite_participantes}`}</span>
            </div>
            <div className="layout-tabla d-flex flex-row flex-1 mb-3">
                <Grid hover striped data={colaboradores} loading={loader} pagination={false} >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataFormat={(cell, row) => {
                            return (
                                <div className="d-flex flex-row flex-1 justify-content-center">
                                    <Field
                                        name={`seleccionados.s-${cell}`}
                                        label=""
                                        component={renderFieldCheck}
                                    />
                                </div>
                            )
                        }}
                    >
                        {t('Acciones')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombres"
                    >
                        {t('Colaboradores')}
                    </TableHeaderColumn>
                </Grid>
            </div>
            {(getSeleccionados()>item.limite_participantes)&&(
                        <span className="text-center h4" style={{color:"red"}} >
                        {props.t('Ha sobrepasado el limite de colaboradores permitidos para este evento')+'.'}</span>
                    )}
            <div className="d-none d-lg-flex buttons-box my-4">
                <button type="button" onClick={cerrar} className="btn btn-secondary mr-3">{t('Cerrar')}</button>
                <button type="submit" className="btn btn-primary ml-3">{t('Guardar')}</button>
            </div>
            <div className="d-flex d-lg-none flex-column my-4">
                <button type="button" onClick={cerrar} className="btn btn-secondary flex-1">{t('Cerrar')}</button>
                <button type="submit" className="btn btn-primary flex-1 mt-3">{t('Guardar')}</button>
            </div>
        </form>
    )
}

const selector = formValueSelector('ColabAsignadosForm');

ModalColaboradores = reduxForm({
    form: 'ColabAsignadosForm',
    asyncBlurFields: [],
})(ModalColaboradores);

const mstp = state => {
    let seleccionados = selector(state, 'seleccionados');
    // seleccionados = seleccionados ? seleccionados : []
    // seleccionados = seleccionados.filter(item => item == true);
    return {
        seleccionados,
    }
};

export default connect(mstp, null)(ModalColaboradores);
