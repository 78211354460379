import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { SwalError } from '../../Utils/Notificaciones';
import LoadMask from "../../Utils/LoadMask/LoadMask";

import PasoUnoForm from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';
import PasoTresForm from './PasoTresForm';
import PasoCuatroForm from './PasoCuatroForm';
import ExamenNivelacionForm from './PasoCincoForm';
import ExamenKPIsForm from './PasoSeisForm';
import PasarelaForm from './PasoSieteForm';
import moment from 'moment';

import Navbar from "../../layout/Navbar/Navbar2";
import Card from "../../Utils/Cards/CardForm";
import { withTranslation } from 'react-i18next';

class Registro extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    state = {
        step: 1,
        step_name: this.props.t('Info general'),
        logo: null,
        avatar: null,
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        console.log("props ", this.props);
        this.props.leerEmpresa(id, this.setExamen, this.setEvaluacionKPIs, this.setPasarelaPago);
    }

    setExamen = () => {
        this.setState({ step: 5, step_name: this.props.t('Información sobre nivel de conocimiento') });
        window.scroll(0, 0);
    }

    setEvaluacionKPIs = () => {
        this.setState({ step: 6, step_name: 'Growth KPIs' });
        window.scroll(0, 0);
    }

    setPasarelaPago = () => {
        this.setState({ step: 7, step_name: this.props.t('Método de pago') });
        window.scroll(0, 0);
    }

    setLogo = (logo) => {
        this.setState({ logo });
    };

    setAvatar = (avatar) => {
        this.setState({ avatar });
    };

    nextStep = () => {
        let step = this.state.step;
        if (step==1 && this.state.logo===null){
            SwalError('Error', this.props.t('Necesita agregar el logo de su empresa'));
        }else{
            step += 1;
        }
        if (step == 2)
            this.setState({step_name: this.props.t('Giro de negocio') });
        if (step == 3)
            this.setState({step_name: this.props.t('Info del representante') });
        if (step == 4)
            this.setState({step_name: this.props.t('Invitación de colaboradores') });

        // Verificar si el usuario desde la creacion cohorte, marco realizar cuestionario de nivelacion
        if (this.props.item.cohorte.cuestionario_nivelacion & step == 5){
            this.setState({step_name: this.props.t('Información sobre nivel de conocimiento') });
        }else{
            // Si se encuentra en el paso 5 y el usuario marco realizar cuestionario
            if (step == 5) {
                step = 6;    
            }
        }
        if (step == 6)
            this.setState({step_name: 'Growth KPIs' });
        if (step == 7)
            this.setState({step_name: this.props.t('Método de pago') });
        this.setState({ step });
        window.scroll(0, 0);
    }

    previousStep = () => {
        let step = this.state.step;
        step -= 1;
        if (step == 1)
            this.setState({step_name: this.props.t('Info general') });
        if (step == 2)
            this.setState({step_name: this.props.t('Giro de negocio') });
        if (step == 3)
            this.setState({step_name: this.props.t('Info del representante') });
        if (step == 4)
            this.setState({step_name: this.props.t('Invitación de colaboradores') });
        if (step == 5)
            this.setState({step_name: this.props.t('Información sobre nivel de conocimiento') });
        if (step == 6)
            this.setState({step_name: 'Growth KPIs' });
        if (step == 7)
            this.setState({step_name: this.props.t('Método de pago') });
        this.setState({ step });
        window.scroll(0, 0);
    }

    onSubmit = (values) => {
        const body = {...values};
        body.fecha_fundacion = moment(body.fecha_fundacion).format("YYYY-MM-DD");
        if (body.sectores) {
            body.sectores = body.sectores.map(item => ({...item, tipo: item.value}))
        }
        if (body.pais){
            body.pais= values.pais.name
        }

        if(body.usuario && body.usuario.fecha_nacimiento){
            body.usuario.fecha_nacimiento = moment(body.usuario.fecha_nacimiento).format('YYYY-MM-DD');
        }

        const array = [];
        array.push({ "file": this.state.logo, "name": "logo" })
        array.push({ "file": this.state.avatar, "name": "avatar" })

        if(this.state.avatar!==null){
            this.props.inscribirEmpresa(this.props.match.params.id, body, array, this.nextStep);
        }else{
            SwalError('Error', this.props.t('Necesita agregar su foto de perfil'));
        }
    }

    guardarExamen = (values) => {
        console.log("values ", values);
        const keys = Object.keys(values ? values : {});
        if (keys.length < 6) {
            NotificationManager.error(this.props.t('Porfavor, responda todas las preguntas'), 'ERROR', 3000);
        } else {
            this.props.crearExamen(this.props.match.params.id, values, this.nextStep);
        }
    }

    invitarColaboradores=(values)=>{
        const { colaboradores }= values;
        const data = {}
        if (colaboradores&&colaboradores.length>0){
            data.colaboradores = colaboradores
            data.empresa = this.props.match.params.id
            this.props.invitarColaboradores(data, this.nextStep);
        }else{
            this.nextStep()
        }
    }

    guardarEvaluacionKPIs = (values) => {
        const data = {...values};
        if (data.pregunta1_c == false)
            delete data.pregunta1_c_i;
        if (data.pregunta2_a == false) {
            delete data.pregunta2_a_i;
            delete data.pregunta2_a_ii;
        }
        if (data.pregunta3 == false)
            delete data.pregunta3_a;
        if (data.pregunta4 == false)
            delete data.pregunta4_a;
        if (data.pregunta7 == false) {
            delete data.pregunta7_i;
            delete data.pregunta7_ii;
        }
        if (data.pregunta8 == false) {
            delete data.pregunta8_i;
            delete data.pregunta8_ii;
        }
        
        data.unico = true
        this.props.crearEvaluacionKPIs(this.props.match.params.id, values, this.nextStep);

        console.log("data ", data);
    }

    pagarCohorte = (values) => {
        const data = {...values};
        data.empresa = this.props.match.params.id;
        data.month = moment(data.fecha_tarjeta).month()
        data.year = moment(data.fecha_tarjeta).year()
        console.log("data ", data);
        this.props.pagarCohorte(data);
    }

    render() {
        const { onSubmit, loader, colaboradores } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="d-flex flex-column">
                <Navbar title={this.props.t('Registro de empresa')} />
                <div className='col-12 col-lg-6 m-auto pt-4'>
                    <Card col="12" noPaddingXContent={this.state.step == 6}>
                        <div className="d-flex flex-row mb-3">
                            <div className="mr-2">
                                <i className={`fas ${this.state.step == 7 ? 'fa-money-check-alt' : 'fa-file-alt'}  azul-gris-oscuro`} style={{fontSize: "3rem"}}></i>
                            </div>
                            <div className="d-flex flex-column">
                                <h4 className="mb-0 bold azul-gris-oscuro">{`${this.props.t('Paso')} ${this.state.step} ${this.props.t('de')} ${7}`}</h4>
                                <span className="gris-oscuro text-sm">{`${this.state.step_name}`}</span>
                            </div>
                        </div>
                        <LoadMask loading={loader} light>
                            {(this.state.step == 1 && (
                                <PasoUnoForm logo={this.state.logo} setLogo={this.setLogo} onSubmit={this.nextStep} previousStep={this.previousStep} t={this.props.t}  />
                            ))}
                            {(this.state.step == 2 && (
                                <PasoDosForm onSubmit={this.nextStep} previousStep={this.previousStep} t={this.props.t} />
                            ))}
                            {(this.state.step == 3 && (
                                <PasoTresForm onSubmit={this.onSubmit} t={this.props.t} imagen={this.state.avatar} previousStep={this.previousStep} setAvatar={this.setAvatar} />
                            ))}
                            {(this.state.step == 4 && (
                                <PasoCuatroForm onSubmit={this.invitarColaboradores}  previousStep={this.previousStep} t={this.props.t} colabs={colaboradores} />
                            ))}
                            {( (this.state.step == 5 && this.props.item && this.props.item.cohorte &&  this.props.item.cohorte.cuestionario_nivelacion) && (
                                <ExamenNivelacionForm onSubmit={this.guardarExamen} previousStep={this.previousStep} t={this.props.t} />
                            ))}
                            {(this.state.step == 6 && (
                                <ExamenKPIsForm item={this.props.item} onSubmit={this.guardarEvaluacionKPIs} previousStep={this.previousStep} t={this.props.t} />
                            ))}
                            {(this.state.step == 7 && (
                                <PasarelaForm item={this.props.item} onSubmit={this.pagarCohorte} previousStep={this.previousStep} t={this.props.t} />
                            ))}
                        </LoadMask>
                    </Card>

                </div>
            </div>
        );
    }
}

export default withTranslation()(Registro);
