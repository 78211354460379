import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from "react-notifications";
import Card from '../../../Utils/Cards/Card';
import CardForm from '../../../Utils/Cards/CardForm';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { getDescuento } from '../../../../../utility/constants';
import PagoMembresiaForm from './PagoForm'
import moment from 'moment';

const pagoMembresia = require('../../../../../../assets/img/iconos/pago_membresia.png');

class PagoMembresia extends Component {

    componentDidMount() {
        const { leer, obtenerTarjeta, listarTarjetas, me } = this.props;
        leer(me.empresa);
        listarTarjetas();
        // obtenerTarjeta();
    }

    getCantidadMinima = (cantidad) => {
        const { item } = this.props;
        const fecha_vencimiento = item.ultimo_pago ? moment(item.ultimo_pago.fecha_vencimiento) : moment(item.cohorte.fecha_fin);
        let fecha_nueva = moment(fecha_vencimiento).endOf('year')

        fecha_nueva = fecha_nueva.add(item.ultimo_pago ? cantidad : cantidad -1, 'years')
        if (fecha_nueva > moment()) {
            return cantidad;
        } else {
            const diferencia = moment().diff(fecha_nueva, 'years') + 1;
            return cantidad + diferencia;
        }
    }

    getFechaNuevaVencimiento = (cantidad) => {
        const { item } = this.props;
        const fecha_vencimiento = item.ultimo_pago ? moment(item.ultimo_pago.fecha_vencimiento) : moment(item.cohorte.fecha_fin);
        let fecha_nueva = moment(fecha_vencimiento);
        if (item.ultimo_pago == null) {
            fecha_nueva = moment.utc(fecha_vencimiento).endOf('year').add(1,'s')
        }
        return fecha_nueva.add(item.ultimo_pago ? cantidad : cantidad -1, 'years')
    }

    getTotal = (cantidad) => {
        const { item } = this.props;
        const descuento = getDescuento(cantidad);
        const precio_membresia = item.cohorte ? item.cohorte.precio_membresia : 0;

        const dias_año = moment("12-31", "MM-DD").dayOfYear();
        const ultimo_dia =  moment(item.cohorte ? item.cohorte.fecha_fin : '2020-01-01').dayOfYear();
        if (item.ultimo_pago) {
            let total = precio_membresia * cantidad;
            total = total - (total * descuento);
            return parseFloat(Math.ceil((total + Number.EPSILON) * 100) / 100);
        } else {
            const costo_dia = (precio_membresia - (precio_membresia * descuento)) / dias_año;
            const dias_restantes = dias_año - ultimo_dia;
            const total_year = costo_dia * dias_restantes;

            let total = precio_membresia * (cantidad - 1);
            total = total - (total * descuento) + total_year;
            return parseFloat(Math.ceil((total + Number.EPSILON) * 100) / 100);
        }
    }

    onSubmit = (values) => {
        const data = {...values, cantidad: parseInt(values.cantidad)}
        const cantidad_necesaria = this.getCantidadMinima(data.cantidad);

        if (cantidad_necesaria == data.cantidad) {
            data.fecha_vencimiento = this.getFechaNuevaVencimiento(data.cantidad).format();
            if (data.tarjeta_id)
                data.tarjeta_id = data.tarjeta_id.id;
            data.descuento = getDescuento(data.cantidad);
            data.monto = this.getTotal(data.cantidad);
            data.month = moment(data.fecha_tarjeta).month();
            data.year = moment(data.fecha_tarjeta).year();
            console.log("data ", data);
            this.props.pagarMembresia(data, this.props.me.empresa)
        } else {
            const mensaje = `${this.props.t('La cantidad mínima de membresías a comprar debe ser de')} ${cantidad_necesaria}`;
            NotificationManager.error(mensaje, 'ERROR', 3000);
        }
    }

    render() {
        const { item, loader, item_tarjeta, me } = this.props;
        const obligatorio = this.props.match.params.obligatorio;
        const fecha_habilitacion = item.cohorte ? moment(item.cohorte.fecha_fin).subtract(1, 'months') : moment();

        if (me && me.bloqueo_pago && !obligatorio) {
            return <Redirect to="/pago_membresia/true" />
        }
        return (
            <div className="mb-4 col-12 mt-0">
                <LoadMask loading={loader} blur>
                    {(item.cohorte && moment() >= fecha_habilitacion) ? (
                        <Fragment>
                            <Card noPadding={true}>
                                <div className="d-flex flex-column flex-md-row align-items-md-center flex-1 my-4 mx-md-5 mx-4">
                                    <div className="d-flex flex-column flex-1">
                                        <span className="text-sm bold celeste-oscuro">{this.props.t('Cohorte')}</span>
                                        <span className="text-sm bold celeste-oscuro">{item.cohorte ? item.cohorte.nombre : ''}</span>
                                    </div>
                                    <div className="d-flex flex-column flex-1 mt-2 mt-md-0">
                                        <span className="text-sm gris-oscuro">{this.props.t('Empresa')}</span>
                                        <span className="text-sm bold celeste-oscuro">{item.nombre ? item.nombre : ''}</span>
                                    </div>
                                    <div className="d-flex flex-column flex-1 mt-2 mt-md-0">
                                        <span className="text-sm gris-oscuro">{this.props.t('Fecha de vencimiento de membresía')}</span>
                                        <span className="text-sm bold celeste-oscuro">
                                            {item.ultimo_pago ? moment.utc(item.ultimo_pago.fecha_vencimiento).format("MMM-DD-YYYY hh:mm a z") : moment.utc(item.cohorte.fecha_fin).format("MMM-DD-YYYY hh:mm a z")}
                                        </span>
                                    </div>
                                    {(me && me.bloqueo_pago) && (
                                        <div className="d-flex flex-column flex-1 mt-2 mt-md-0">
                                            <span className="text-sm bold marron">
                                                {this.props.t('Cuenta suspendida por vencimiento de membresía, el contenido está oculto hasta que la membresía se renueve')+'.'}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </Card>
                            <CardForm col={12}>
                                <PagoMembresiaForm
                                    {...this.props}
                                    onSubmit={this.onSubmit}
                                    getFechaNuevaVencimiento={this.getFechaNuevaVencimiento}
                                    initialValues={{cantidad: this.getCantidadMinima(1), tarjeta: item_tarjeta ? true : false, guardar_tarjeta: false}}
                                />
                            </CardForm>
                        </Fragment>
                    ) : (
                        <Card noPadding={true}>
                            <div className="d-flex flex-column flex-1 justify-content-center align-items-center my-5">
                                <img className="" src={pagoMembresia} />
                                <h3 className="bold azul-gris-oscuro mt-5">{this.props.t('No disponible')}</h3>
                                <span className="text-qts bold gris text-center">{`${this.props.t('El pago de membresia se habilitará el')} ${moment(fecha_habilitacion).format("MMM-DD-YYYY")}`}</span>
                            </div>
                        </Card>
                    )}
                </LoadMask>
            </div>
        )
    }
}

export default withTranslation()(PagoMembresia)
