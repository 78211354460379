import React, { Fragment, useRef } from 'react';
import { Tooltip } from 'antd';
import { EXTENSIONES } from '../../../../utility/constants';


function ArchivosUploader(props) {
    const inputFile = useRef(null);
    const { archivos, upload, eliminar, disabled, accept, btnLabel, onlyLectura, descargar } = props;

    const onFileChange = (e, file) => {
        upload(e.target.files)
    }

    return (
        <Fragment>
            <div className="d-flex flex-column flex-1">
                {(archivos.length) ? (
                    archivos.map((item, index) => (
                        <div key={index} className="d-flex flex-row flex-1 mb-2">
                            <div className="d-flex flex-5 justify-content-start align-items-center">
                                <div className="input-archivo">
                                    <i className="fas fa-file-archive gris-oscuro mr-3" />
                                    <Tooltip title={item.nombre} placement="top">
                                        <span>{item.nombre}</span>
                                    </Tooltip>
                                </div>
                            </div>
                            {(onlyLectura) ? (
                                <div className="d-flex flex-1 justify-content-center align-items-center">
                                    <a className="btn btn-clear" href={item.archivo} target="_blank" >
                                        <i className="fas fa-download marron-claro" />
                                    </a>
                                </div>
                            ) : (descargar) ? 
                            (
                                <div className="d-flex flex-1 justify-content-center align-items-center">
                                    <a className="btn btn-clear" href={item.archivo} target="_blank" >
                                        <i className="fas fa-download marron-claro" />
                                    </a>
                                    <div className='ml-4'/>
                                    <button type="button" className="btn-delete" onClick={() => eliminar(index, item.id)}>
                                        <i style={{ fontSize: "20px" }} className="fas fa-times marron" />
                                    </button>
                                </div>
                            ) 
                            : (
                                <div className="d-flex flex-1 justify-content-center align-items-center">
                                    <button type="button" className="btn-delete" onClick={() => eliminar(index, item.id)}>
                                        <i style={{ fontSize: "20px" }} className="fas fa-times marron" />
                                    </button>
                                </div>
                            )}
                        </div>
                    ))
                ): (<div></div>)}
                {(!onlyLectura) && (
                    <div className="d-flex flex-row flex-1 justify-content-start align-items-center mt-3">
                        <div className="flex-1">
                            <label style={{width: "13rem"}} className="btn btn-option-two">
                                {btnLabel}
                                <input
                                    disabled={disabled ? disabled : false}
                                    className="d-none"
                                    type="file"
                                    accept={ accept ? accept : EXTENSIONES}
                                    multiple
                                    onChange={onFileChange}
                                    ref={inputFile}
                                    name="files[]"
                                />
                            </label>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>

    )
}

export default ArchivosUploader;
