import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';
import Card from '../../../Utils/Cards/Card';
import { ADMIN, GROWTH_ANGEL, USUARIO } from '../../../../../utility/constants';
import { withTranslation } from 'react-i18next';

const avatar = require("../../../../../../assets/img/avatar.png");

const Personas = (props) => {
    const { interesados, id } = props;
    return (
        <Card noPadding={true}>
            <div className="d-flex flex-column flex-1 py-3 px-3">
                <div className="d-flex flex-row justify-content-end">
                    <button onClick={()=> props.descargarExcel(id)} className="btn btn-primary">{props.t('Exportar a excel')}</button>
                </div>
                <span className="text-xsm gris ml-4">{`${props.t('Mostrando')} ${interesados.results.length} ${props.t('resultados')}`}</span>
                <div className="d-flex flex-column flex-1 my-2">
                    <span className="text-sm bold azul-gris-oscuro ml-4">{props.t('Personas que les intereso')}</span>
                    {interesados.results.map((item, index) => (
                        <Link key={index} to={`/perfil/${item.usuario.id}`} className="d-flex flex-row borde-bottom flex-1 py-3">
                            <div className="d-flex align-items-center justify-content-center flex-1">
                                <img style={{width: "2.5rem", height: "2.5rem"}} className="logo-tabla" src={item.usuario.avatar ? item.usuario.avatar : avatar} />
                            </div>
                            <div className="d-flex flex-column justify-content-center flex-4">
                                <span className="text-sm bold gris-oscuro mr-2">{item.usuario.nombres}</span>
                                {(item.usuario.tipo_usuario == USUARIO && item.usuario.cargo && item.usuario.empresa_nombre) && (
                                    <span className="text-xs gris">{`${item.usuario.cargo} ${props.t('de')} ${item.usuario.empresa_nombre}`}</span>
                                )}
                                <span className="text-xs gris">{item.usuario.pais}</span>
                            </div>
                            <div className="d-flex flex-column justify-content-center flex-2">
                                <span className="text-sm bold gris-oscuro mr-2">{props.t('Correo')}</span>
                                <span className="text-xs gris">{item.usuario.email}</span>
                            </div>
                        </Link>
                    ))}
                </div>
                {(interesados.results.length) ? (
                    <div className="d-flex flex-row flex-1 align-items-center my-3">
                        <Pagination pageSize={10} showSizeChanger={false} current={props.page} total={interesados.count} onChange={(page)=>props.listarInteresados(props.item.id, page)} />
                    </div>
                ) : (
                    <div className="d-flex flex-row flex-1 justify-content-center align-items-center my-3">
                        <span className="text-sm bold gris-oscuro">{props.t('Sin resultados')}</span>
                    </div>
                )}
            </div>
        </Card>
    )
}

export default withTranslation()(Personas);
