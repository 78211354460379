import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Progress } from 'antd';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import { renderFieldRadio } from '../../../Utils/renderField/renderField';
import { EN_DESACUERDO, ALGO_EN_DESACUERDO, ALGO_DE_ACUERDO, DE_ACUERDO } from '../../../../../utility/constants';
import _, { round } from "lodash";


const dataTabla = (item, bloque_index, pregunta_index) => {
    const results = [];
    let bloque = item.bloques;
    if (bloque) {
        bloque = bloque[bloque_index];
        const pregunta = bloque.preguntas[pregunta_index];
        // Obtenemos el total de respuestas
        const total = pregunta.otras_respuestas.length;
        const respuestas = pregunta.otras_respuestas;

        // 1. Obtenemos las respuesta ( 0. EN_DESACUERDO )
        const muy_desacuerdo =  _.filter(respuestas, { opcion_elegida: EN_DESACUERDO });

        // 2. Obtenemos las respuesta ( 1. ALGO_EN_DESACUERDO )
        const desacuerdo =  _.filter(respuestas, { opcion_elegida: ALGO_EN_DESACUERDO });

        // 4. Obtenemos las respuesta ( 2. ALGO_DE_ACUERDO )
        const deacuerdo =  _.filter(respuestas, { opcion_elegida: ALGO_DE_ACUERDO });

        // 5. Obtenemos las respuesta ( 3. DE_ACUERDO )
        const completamento =  _.filter(respuestas, { opcion_elegida: DE_ACUERDO });

        results.push({ id: EN_DESACUERDO, pregunta: pregunta.pregunta, porcentaje: ( muy_desacuerdo.length / total ) });
        results.push({ id: ALGO_EN_DESACUERDO, pregunta: pregunta.pregunta, porcentaje: ( desacuerdo.length / total ) });
        results.push({ id: ALGO_DE_ACUERDO, pregunta: pregunta.pregunta, porcentaje: ( deacuerdo.length / total ) });
        results.push({ id: DE_ACUERDO, pregunta: pregunta.pregunta, porcentaje: ( completamento.length / total ) });
    }

    return results;
}

const renderPreguntas = ({ fields, item, bloques, bloque_index, openModal, me, preguntas, loader, t, item_inicial, meta: { error } }) => (
    <div className="d-flex flex-1 flex-column">
        {/* {round()} */}
        {fields.map((pregunta, index) => (
            <div key={index} className={"d-flex flex-1 flex-column my-2 " + (preguntas.includes(item.bloques[bloque_index].preguntas[index].id) ? "pregunta-incompleta": "")} >
                {(item.bloques && item.bloques.length) && (
                    <div className="d-flex flex-column px-0 mt-2">
                        <span className="text-sm azul bold">{`${index + 1}.`} {item.bloques[bloque_index].preguntas[index].pregunta.pregunta}</span>
                        <div className="d-flex w-100 bg-gris" style={{ height: '3px' }} />
                    </div>
                )}
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-4">
                    <span className="">{t('En equipo discutan esas respuestas')}</span>
                    <div className="d-flex w-100 bg-gris" style={{ height: '3px' }} />
                </div>
                <div className="layout-tabla d-flex flex-row px-0 mt-2 mb-4">
                    <Grid hover striped data={{ count: 4, results: dataTabla(item, bloque_index, index) }} loading={loader} pagination={false} noResponsive={true} >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={cell => (
                                <div className="d-flex justify-content-center">
                                    <a title={t('Ver')} className="action action-azul-oscuro px-1" style={{cursor: "pointer"}}
                                        onClick={() => openModal(item.bloques[bloque_index].preguntas[index], cell )}>
                                        <i className="fas fa-eye azul-oscuro"></i>
                                    </a>
                                </div>
                            )}
                        >
                            {t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            dataFormat={ cell => {
                                if (cell == EN_DESACUERDO) return t('En desacuerdo');
                                if (cell == ALGO_EN_DESACUERDO) return t('Algo en desacuerdo');
                                if (cell == ALGO_DE_ACUERDO) return t('Algo de acuerdo');
                                if (cell == DE_ACUERDO) return t('De acuerdo');
                                return '';

                            }}
                        >
                            {t('Respuesta')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="porcentaje"
                            dataSort
                            thStyle={{ padding: "0 1rem" }}
                            tdStyle={{ padding: "0 1rem" }}
                            dataFormat={cell => (
                                <div className="d-flex flex-1">
                                    <div className="d-flex flex-row flex-1 borde-progress">
                                        <Progress showInfo={false} percent={cell ? cell * 100 : 0} strokeColor="#538BAF" trailColor="#E6E6E6" />
                                    </div>
                                    <div className="d-flex flex-row flex-3 justify-content-start">
                                        <span className="gris-oscuro">{cell ? `${round(cell * 100, 2)}%` : '0%'}</span>
                                    </div>
                                </div>
                            )}
                        >
                            {t('Respuestas')}
                        </TableHeaderColumn>
                    </Grid>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <span className="">{t('En equipo deben llegar a un concenso y elegir la respuesta final')}</span>
                    <div className="d-flex w-100 bg-gris" style={{ height: '3px' }} />
                </div>
                {((me&&item_inicial) && (item_inicial.responsable===me.id)) && (
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2 mb-4 ">
                        <div className="flex-1">
                            <Field
                                type="radio"
                                name={`${pregunta}.opcion_elegida`}
                                label={t('En desacuerdo')}
                                value={`${EN_DESACUERDO}`}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1">
                            <Field
                                type="radio"
                                name={`${pregunta}.opcion_elegida`}
                                label={t('Algo en desacuerdo')}
                                value={`${ALGO_EN_DESACUERDO}`}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1">
                            <Field
                                type="radio"
                                name={`${pregunta}.opcion_elegida`}
                                label={t('Algo de acuerdo')}
                                value={`${ALGO_DE_ACUERDO}`}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="flex-1">
                            <Field
                                type="radio"
                                name={`${pregunta}.opcion_elegida`}
                                label={t('De acuerdo')}
                                value={`${DE_ACUERDO}`}
                                component={renderFieldRadio}
                            />
                        </div>
                    </div>
                )}
            </div>
        ))}
    </div>
)

let PasoDosForm = props => {
    const { handleSubmit, previousBloque, nextBloque, bloque, item, loader, bloques, item_inicial, preguntas } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-row px-0 mt-2">
                    <div className="d-flex flex-column flex-1 mt-2 max-width-100">
                        <FieldArray
                            name={`bloques[${bloque}].preguntas`}
                            bloques={bloques}
                            openModal={props.openModal}
                            component={renderPreguntas}
                            item={item}
                            bloque_index={bloque}
                            loader={loader}
                            me={props.me}
                            t={props.t}
                            item_inicial={item_inicial}
                            preguntas={preguntas}
                        />
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    {(bloque > 0) && (
                        <button type="button" onClick={previousBloque} className="btn btn-secondary mr-3">{props.t('Anterior')}</button>
                    )}
                    <button type="submit" className={`${(item_inicial.cantidad_bloques && item_inicial.cantidad_bloques == bloque + 1) ? '' : 'd-none'}  btn btn-primary ml-3`}>{props.t('Guardar')}</button>
                    {!(item_inicial.cantidad_bloques && item_inicial.cantidad_bloques == bloque + 1) && (
                        <button type="button" onClick={() => nextBloque(2)} className="btn btn-primary ml-3">{props.t('Siguiente')}</button>
                    )}
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    {(bloque > 0) && (
                        <button type="button" onClick={previousBloque} className="btn btn-secondary flex-1">{props.t('Anterior')}</button>
                    )}
                    <button type="submit" className={`${(item_inicial.cantidad_bloques && item_inicial.cantidad_bloques == bloque + 1) ? '' : 'd-none'}  btn btn-primary flex-1 mt-3`}>{props.t('Guardar')}</button>
                    {!(item_inicial.cantidad_bloques && item_inicial.cantidad_bloques == bloque + 1) && (
                        <button type="button" onClick={() => nextBloque(2)} className="btn btn-primary flex-1 mt-3">{props.t('Siguiente')}</button>
                    )}
                </div>
            </div>
        </form>
    )

}

const selector = formValueSelector('ResolverEvaluacionOficialForm');

PasoDosForm = reduxForm({
    form: 'ResolverEvaluacionOficialForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    asyncBlurFields: [],
})(PasoDosForm);

const mstp = state => {
    const bloques = selector(state, 'bloques');

    return {
        bloques,
    }
};

export default connect(mstp, null)(PasoDosForm);
