import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/reportes/calificacionEventos';
import ListaEventos from './calificacionEventos';
import DetalleEvento from './detalleCalificacion';

const ms2p = (state) => {
    return {
        ...state.calificacionesEventos,
    };
};

const md2p = { ...actions };

const Listar = connect(ms2p, md2p)(ListaEventos);
const Detalle = connect(ms2p, md2p)(DetalleEvento)

export default {
    Listar,
    Detalle,
}
