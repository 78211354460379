import React, { Component, Fragment } from 'react';
import Card from '../../../Utils/Cards/Card';
import { Link, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import { USUARIO, ADMIN, GROWTH_ANGEL } from '../../../../../utility/constants';
import CardInteres from '../../../Utils/Cards/CardInteres';
import { Menu, Dropdown } from 'antd';
import Compartir from './CompartirBadge';

import moment from 'moment';

const fondo = require('../../../../../../assets/img/fondo-perfil.png');
const avatar = require("../../../../../../assets/img/icono-empresa.png");


class VerPerfilEmpresa extends Component {

    state = {
        open: false
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const { leer, me } = this.props;
        leer(id ? id : me.empresa);
    }

    onCompartir = () => {
        this.setState({ open: true });
    }

    onCloseModal = () => {
        this.setState({ open: false });
    }

    componentDidUpdate(prevProps, prevState) {
        const idAnterior = prevProps.match.params.id;
        const id = this.props.match.params.id;

        if (idAnterior != id) {
            const { leer, obtenerEmpresas, me } = this.props;
            obtenerEmpresas();
            leer(id ? id : me.empresa);
        }
    }

    getSectores = () => {
        const { item } = this.props;
        let sectores = null;
        if (item.sectores) {
            item.sectores.forEach(_item => {
                if (sectores) {
                    sectores += `, ${_item.tipo_text}`
                } else {
                    sectores = _item.tipo_text
                }
            })
        }
        return sectores ? sectores : this.props.t('Sin información')
    }

    menu = () => {
        const { item } = this.props;
        return (
            <Menu>
                <Menu.Item>
                    <a href={item.badge_small} download>
                        {this.props.t('Pequeño')} 200px
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a href={item.badge_medium} download>
                        {this.props.t('Mediano')} 300px
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a href={item.badge_large} download>
                        {this.props.t('Grande')} 400px
                    </a>
                </Menu.Item>
            </Menu>
        );
    }

    descargarDiploma = () => {
        const { item } = this.props;
        window.open(item.diploma, "_blank");
    }

    render() {
        const { item, loader, me, data } = this.props;
        const id = this.props.match.params.id;
        if (me.empresa == id) {
            return (<Redirect to="/mi-empresa" />);
        }
        return (
            <div className="mb-4 col-12 mt-0">
                <LoadMask loading={loader} blur>
                    <Card noPadding={true}>
                        <div className="d-flex flex-column flex-1">
                            <div className="perfil-portada" style={{ backgroundImage: `url(${fondo})` }}>
                                {(!id) ? (
                                    <Link to="/mi-empresa/editar" className="btn-action bg-marron-claro d-flex justify-content-center align-items-center float-right m-2">
                                        <i className="fas fa-pen-alt blanco"></i>
                                    </Link>
                                ) : (<div></div>)}
                            </div>
                            <div className="d-flex flex-column flex-md-row flex-1">
                                <div className="d-flex flex-1 justify-content-center perfil-avatar">
                                    <img className="perfil-avatar-img" src={item.logo ? item.logo : avatar} />
                                </div>
                                <div className="d-flex flex-column mb-3 flex-3">
                                    <div className="d-flex flex-column flex-md-row flex-3 align-items-center mb-0 mb-md-3 perfil-datos">
                                        <div className="d-flex flex-column align-items-md-start align-items-center flex-2">
                                            <span className="text-md azul-gris-oscuro mt-3 mt-md-0">{item.nombre}</span>
                                            <span className="celeste-oscuro">{item.ciudad} / {item.pais}</span>
                                        </div>
                                        <div className="d-flex flex-column flex-md-row flex-2 mt-3 mt-md-0 px-3 px-sm-4 px-md-0 w-100">
                                            <div className="d-flex flex-column flex-1">
                                                <label className="text-sm azul-gris-oscuro p-0 mb-0">{this.props.t('Web')}</label>
                                                <a href={item.sitio_web} target="_blank" className="span celeste-oscuro">{item.sitio_web}</a>
                                            </div>
                                            <div className="d-flex flex-column flex-1">
                                                <label className="text-sm azul-gris-oscuro p-0 mb-0">{this.props.t('Año de fundación')}</label>
                                                <span className="celeste-oscuro">{moment(item.fecha_fundacion).format("YYYY")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-md-row flex-3 align-items-center mb-3 px-3 px-sm-4 px-md-0 w-100">
                                        <div className="d-flex flex-column align-items-start flex-2 w-100">
                                            <label className="text-sm azul-gris-oscuro p-0 mb-0">{this.props.t('Sectores')}</label>
                                            <span className="celeste-oscuro">{this.getSectores()}</span>
                                        </div>
                                        <div className="d-flex flex-column align-items-start flex-2 w-100">
                                            <label className="text-sm azul-gris-oscuro p-0 mb-0">{this.props.t('Modelo de negocio')}</label>
                                            <span className="celeste-oscuro">{item.modelo_negocios_text}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="d-flex flex-1 flex-column flex-md-row" >
                                {(item.graduado) && (
                                    <div className="d-flex flex-column flex-1 my-3 px-3 px-sm-4 px-lg-5">
                                        <div className="d-flex flex-row flex-wrap px-0">
                                            <span className="text-sm azul-gris-oscuro bold mr-4">{this.props.t('Logro')}</span>
                                            {(!id) && (
                                                <Fragment>
                                                    <Dropdown overlay={this.menu} placement="bottomCenter">
                                                        <div className='d-flex flex-row align-items-center text-touch mx-3'>
                                                            <i className="azul-gris-oscuro fas fa-download fa-xs px-2 pb-0"></i>
                                                            <span className="text-xs azul-gris-oscuro bold">{this.props.t('Descargar')}</span>
                                                        </div>
                                                    </Dropdown>
                                                    <div className='d-flex flex-row align-items-center text-touch' onClick={e=>this.onCompartir()}>
                                                        <i className="azul-gris-oscuro fas fa-share-alt fa-xs px-2 pb-0"></i>
                                                        <span className="text-xs azul-gris-oscuro bold">{this.props.t('Compartir')}</span>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                        <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                        <div className="d-flex w-100 justify-content-center align-items-center mt-2">
                                            <img style={{width: "70%"}} src={item.badge_medium} />
                                        </div>
                                        {(!id) && (
                                            <Fragment>
                                                <div className="d-flex flex-row flex-wrap px-0">
                                                    <span className="text-sm azul-gris-oscuro bold mr-4">{this.props.t('Diploma')}</span>
                                                </div>
                                                <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                                <div className="d-flex w-100 justify-content-center align-items-center mt-2">
                                                    <a href={item.diploma} target="_blank" className="btn btn-primary" download>{this.props.t('Descargar diploma')}</a>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                )}
                                <div className="d-flex flex-column flex-2 my-3 px-3 px-sm-4 px-lg-5">
                                    <div className="d-flex flex-column flex-wrap px-0 px-lg-4" >
                                        <span className="text-sm azul-gris-oscuro bold">{this.props.t('¿Qué es lo que hace la empresa?')}</span>
                                        <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                    </div>
                                    <div className="d-flex flex-1 mt-3 px-lg-4">
                                        <p>{item.descripcion_empresa ? item.descripcion_empresa : this.props.t('Sin descripción')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card noPadding={true}>
                        <div className="w-100 py-4 px-4">
                            <Grid hover striped data={{ count: item.colaboradores ? item.colaboradores.length : 0, results: item.colaboradores}}
                            loading={false} pagination={false}>
                                <TableHeaderColumn
                                    isKey
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={cell => (
                                        <div className="d-flex justify-content-center">
                                            <Link to={`/perfil/${cell}`} title={this.props.t('Ver perfil')} className="action action-azul-oscuro px-1">
                                                <i className="fas fa-eye azul-oscuro"></i>
                                            </Link>
                                        </div>
                                    )}
                                >
                                    {this.props.t('Acciones')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nombres"
                                    dataFormat={(cell, row) => (cell ?
                                        <div className="d-flex flex-1 align-items-center">
                                            <img className="logo-tabla mr-3" src={row.avatar} />
                                            <span>{cell}</span>
                                        </div>
                                        : ''
                                    )}
                                >
                                    {this.props.t('Nombre')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cargo"
                                >
                                    {this.props.t('Puesto')}
                                </TableHeaderColumn>
                            </Grid>
                        </div>
                    </Card>
                    {(me.tipo_usuario == USUARIO) && (
                        <div className="d-flex flex-row flex-1">
                            <CardInteres
                                fullWidth={true}
                                empresas={data}
                                t={this.props.t}
                                perfilEmpresa={true}
                            />
                        </div>
                    )}
                    <Compartir open={this.state.open} item={this.props.item} onClose={this.onCloseModal}/>
                </LoadMask>
            </div>

        )
    }
}

export default withTranslation()(VerPerfilEmpresa)

