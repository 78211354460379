import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';
import Card from '../../../Utils/Cards/Card';
import { ADMIN, GROWTH_ANGEL, USUARIO } from '../../../../../utility/constants';

const avatar = require("../../../../../../assets/img/avatar.png");

const PersonasTab = (props) => {
    const { usuarios, page_usr } = props;
    return (
        <Card noPadding={true}>
            <div className="d-flex flex-column flex-1 py-3 px-3">

                <span className="text-xsm gris ml-4">{`${props.t('Mostrando')} ${usuarios.results.length} ${props.t('resultados')}`}</span>
                <div className="d-flex flex-column flex-1 my-2">
                    <span className="text-sm bold azul-gris-oscuro ml-4">{props.t('Personas')}</span>
                    {usuarios.results.map((item, index) => (
                        <Link key={index} to={`/perfil/${item.id}`} className="d-flex flex-row borde-bottom flex-1 py-3">
                            <div className="d-flex align-items-center justify-content-center flex-1">
                                <img style={{width: "2.5rem", height: "2.5rem"}} className="logo-tabla" src={item.avatar ? item.avatar : avatar} />
                            </div>
                            <div className="d-flex flex-column justify-content-center flex-4">
                                <span className="text-sm bold gris-oscuro mr-2">{item.nombres}</span>
                                {(item.tipo_usuario == ADMIN) && (
                                    <span className="text-xs gris">{props.t('Administrador')}</span>
                                )}
                                {(item.tipo_usuario == GROWTH_ANGEL) && (
                                    <span className="text-xs gris">{props.t('Growth Angel')}</span>
                                )}
                                {(item.tipo_usuario == USUARIO && item.cargo && item.empresa_nombre) && (
                                    <span className="text-xs gris">{`${item.cargo} ${props.t('de')} ${item.empresa_nombre}`}</span>
                                )}
                                <span className="text-xs gris">{item.pais}</span>
                            </div>
                        </Link>
                    ))}
                </div>
                {(usuarios.results.length) ? (
                    <div className="d-flex flex-row flex-1 align-items-center my-3">
                        <Pagination pageSize={10} showSizeChanger={false} current={page_usr} total={usuarios.count} onChange={props.resultadosUsuarios}/>
                    </div>
                ) : (
                    <div className="d-flex flex-row flex-1 justify-content-center align-items-center my-3">
                        <span className="text-sm bold gris-oscuro">{props.t('Sin resultados')}</span>
                    </div>
                )}
            </div>
        </Card>
    )
}

export default PersonasTab;
