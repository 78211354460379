import Swal from 'sweetalert2';
import { ADMIN } from '../../../../utility/constants';
import { getI18n } from 'react-i18next';
import { SwalError } from '../Notificaciones';

export const validacionSweet = (response) => {
    if (response.me){
        if (response.me === ADMIN){
            if (response.mstatus === 'started'){
                const a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = response.link;
                a.target = "_blank"
                a.click();
                document.body.removeChild(a);
            }else{
                Swal.fire({
                    title: getI18n().t('¿Esta seguro de iniciar la reunion de Zoom?'),
                    text: getI18n().t('Asegurate de que la reunion de zoom que estas por iniciar no llegue a cerrarse, ¡Si la reunion de zoom llega a cerrarse, despues de 5 minutos de inactividad tendras que generar un nuevo link!'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: getI18n().t('¡Sí, inicar!'),
                    cancelButtonText: getI18n().t('Cancelar'),
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = response.link;
                        a.target = "_blank"
                        a.click();
                        document.body.removeChild(a);
                    }
                });
            }
        }else{
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = response.link;
            a.target = "_blank"
            a.click();
            document.body.removeChild(a);
        }
    }
}

export const errorSweet = (response, mensaje) => {
    console.log(response);
    if (response.body) {
        if (response.body.detail == 'The link expired'){
            SwalError(getI18n().t('Error'), getI18n().t('El enlace expiro, porfavor vuelva a generar un nuevo enlace'))
        }else{
            SwalError(getI18n().t('Error'), getI18n().t(mensaje))
        }
    }else{
        SwalError(getI18n().t('Error'), getI18n().t(mensaje))
    }
}