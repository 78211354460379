import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import { renderFieldRadio } from '../../../Utils/renderField/renderField';
import { EN_DESACUERDO, ALGO_EN_DESACUERDO, ALGO_DE_ACUERDO, DE_ACUERDO } from '../../../../../utility/constants';


const renderPreguntas = ({ fields, item, preguntas, bloque_index, t, meta: { error } }) => (
    <div className="d-flex flex-1 flex-column">
        {fields.map((pregunta, index) => (  
            
            <div key={index} className={"d-flex flex-1 flex-column my-2 " + (preguntas.includes(item.bloques[bloque_index].preguntas[index].id) ? "pregunta-incompleta": "")} >
                {(item.bloques && item.bloques.length) && (
                    <div className="d-flex flex-column flex-wrap px-0 mt-2">
                        <span className="text-sm azul bold">{`${index + 1}.`} {item.bloques[bloque_index].preguntas[index].pregunta}</span>
                        <div className="d-flex w-100 bg-gris" style={{ height: '3px' }} />
                    </div>
                )}
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-5 mb-4 ">
                    <div className="flex-1">
                        <Field
                            type="radio"
                            name={`${pregunta}.opcion_elegida`}
                            label={t('En desacuerdo')}
                            value={`${EN_DESACUERDO}`}
                            component={renderFieldRadio}
                        />
                    </div>
                    <div className="flex-1">
                        <Field
                            type="radio"
                            name={`${pregunta}.opcion_elegida`}
                            label={t('Algo en desacuerdo')}
                            value={`${ALGO_EN_DESACUERDO}`}
                            component={renderFieldRadio}
                        />
                    </div>
                    <div className="flex-1">
                        <Field
                            type="radio"
                            name={`${pregunta}.opcion_elegida`}
                            label={t('Algo de acuerdo')}
                            value={`${ALGO_DE_ACUERDO}`}
                            component={renderFieldRadio}
                        />
                    </div>
                    <div className="flex-1">
                        <Field
                            type="radio"
                            name={`${pregunta}.opcion_elegida`}
                            label={t('De acuerdo')}
                            value={`${DE_ACUERDO}`}
                            component={renderFieldRadio}
                        />
                    </div>
                </div>
            </div>
        ))}
    </div>
)

let PasoUnoForm = props => {
    const { handleSubmit, previousBloque, nextBloque, bloque, item, preguntas } = props;
    console.log("bloque ", bloque)
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-row flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 mt-2">
                        <FieldArray name={`bloques[${bloque}].preguntas`} component={renderPreguntas} item={item} bloque_index={bloque} t={props.t} preguntas={preguntas}/>
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    {(bloque > 0) && (
                        <button type="button" onClick={previousBloque} className="btn btn-secondary mr-3">{props.t('Anterior')}</button>
                    )}
                    <button type="submit" className={`${(item.cantidad_bloques && item.cantidad_bloques == bloque + 1) ? '' : 'd-none'}  btn btn-primary ml-3`}>{props.t('Guardar')}</button>
                    {!(item.cantidad_bloques && item.cantidad_bloques == bloque + 1) && (
                        <button type="button" onClick={() => nextBloque(1)} className="btn btn-primary ml-3">{props.t('Siguiente')}</button>
                    )}
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    {(bloque > 0) && (
                        <button type="button" onClick={previousBloque} className="btn btn-secondary flex-1">{props.t('Anterior')}</button>
                    )}
                    <button type="submit" className={`${(item.cantidad_bloques && item.cantidad_bloques == bloque + 1) ? '' : 'd-none'}  btn btn-primary flex-1 mt-3`}>{props.t('Guardar')}</button>
                    {!(item.cantidad_bloques && item.cantidad_bloques == bloque + 1) && (
                        <button type="button" onClick={() => nextBloque(1)} className="btn btn-primary flex-1 mt-3">{props.t('Siguiente')}</button>
                    )}
                </div>
            </div>
        </form>
    )

}

// const validators = (values, props)=>{
//     const errors= {};
//     if (values.bloques && values.bloques.length) {
//         const bloques = [];

//         values.bloques.forEach((bloque, indexB) => {
//             const preguntas = [];

//             if (bloque.preguntas && bloque.preguntas.length) {
//                 bloque.preguntas.forEach((pregunta, indexP) => {
//                     if (!pregunta.opcion_elegida) {
//                         preguntas[indexP] = `${props.t('¡Por favor, responda a esta pregunta!')}`
//                     }
//                 });
//             }

//             bloques[indexB] = { preguntas }
//         });

//         errors.bloques = bloques;
//     }
//     return errors;
// }

const selector = formValueSelector('ResolverEvaluacionForm');

PasoUnoForm = reduxForm({
    form: 'ResolverEvaluacionForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    asyncBlurFields: [],
    // validate: validators,
})(PasoUnoForm);

const mstp = state => {
    const nombre = selector(state, 'nombre');

    return {
        nombre,
    }
};

export default connect(mstp, null)(PasoUnoForm);
