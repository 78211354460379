import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { getMe } from '../cuenta/login';
import { COUNTRIES } from '../../../utility/paises';
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_BUSCADOR';
const PREVIOS = 'PREVIOS_BUSCADOR';
const PUBLICACIONES = 'PUBLICACIONES_BUSCADOR';
const PAGE_PUB = 'PAGE_PUB_BUSCADOR';
const USUARIOS = 'USUARIOS_BUSCADOR';
const PAGE_USR = 'PAGE_USR_BUSCADOR';
const EMPRESAS = 'EMPRESAS_BUSCADOR';
const PAGE_EMP = 'PAGE_EMP_BUSCADOR';
const SEARCH = 'SEARCH_BUSCADOR';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data
});

const setPage = (type, page) => ({
    type,
    page
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const resultadosPrevios = (search) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setSearch(search));
    api.get('buscador', { search }).then((response) => {
        dispatch(setData(PREVIOS, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
}

const resultadosPublicaciones = (page=1) => (dispatch, getStorage) => {
    const search = getStorage().buscador.search;
    dispatch(setLoader(true));
    api.get('buscador/detalle_publicaciones', { search, page }).then((response) => {
        dispatch(setData(PUBLICACIONES, response));
        dispatch(setPage(PAGE_PUB, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const resultadosUsuarios = (page=1) => (dispatch, getStorage) => {
    const search = getStorage().buscador.search;
    dispatch(setLoader(true));
    api.get('buscador/detalle_usuarios', { search, page }).then((response) => {
        dispatch(setData(USUARIOS, response));
        dispatch(setPage(PAGE_USR, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const resultadosEmpresas = (page=1) => (dispatch, getStorage) => {
    const search = getStorage().buscador.search;
    dispatch(setLoader(true));
    api.get('buscador/detalle_empresas', { search, page }).then((response) => {
        dispatch(setData(EMPRESAS, response));
        dispatch(setPage(PAGE_EMP, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    searchChange,
    resultadosPrevios,
    resultadosPublicaciones,
    resultadosUsuarios,
    resultadosEmpresas,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PREVIOS]: (state, { data }) => {
        return {
            ...state,
            previos: data,
        };
    },
    [PUBLICACIONES]: (state, { data }) => {
        return {
            ...state,
            publicaciones: data,
        };
    },
    [PAGE_PUB]: (state, { page }) => {
        return {
            ...state,
            page_pub: page,
        };
    },
    [USUARIOS]: (state, { data }) => {
        return {
            ...state,
            usuarios: data,
        };
    },
    [PAGE_USR]: (state, { page }) => {
        return {
            ...state,
            page_usr: page,
        };
    },
    [EMPRESAS]: (state, { data }) => {
        return {
            ...state,
            empresas: data,
        };
    },
    [PAGE_EMP]: (state, { page }) => {
        return {
            ...state,
            page_emp: page,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    search: '',
    previos: {
        publicaciones: [],
        usuarios: [],
        empresas: []
    },
    publicaciones: {
        results: [],
        count: 0,
    },
    page_pub: 1,
    usuarios: {
        results: [],
        count: 0,
    },
    page_usr: 1,
    empresas: {
        results: [],
        count: 0,
    },
    page_emp: 1,
};

export default handleActions(reducers, initialState);

