import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchInput from './SearchInput';

export class HeaderBasico extends Component {
    render() {
        const { search, titulo, searchChange, placeholder, className} = this.props;

        return (
            <div className={`d-flex flex-1 row px-2 ${className}`}>
                <div className="d-flex col-md-4 col-6 px-0 align-items-center">
                    {titulo ? titulo : ''}
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-4 col-12 px-0">
                    {(search !== undefined) && (
                        <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                    )}
                </div>
            </div>
        )
    }
}
