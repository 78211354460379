import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { renderTextArea } from '../../../Utils/renderField/renderField';

const avatar = require("../../../../../../assets/img/avatar.png")

let ModalAsistencia = (props) => {

    const { id, item, cerrar, loader, anularAsistencia, nota_inasistencia } = props;
    const [asistencia, setAsistencia] = useState(null);

    return (
        <div style={{ width: "50vw" }} className="d-flex flex-column flex-1 w-100-responsivo">
            <div className="d-flex flex-row borde-bottom px-3 py-1 flex-1 pt-3">
                <span className="text-sm azul-gris-oscuro">{props.t('Asistencias')}</span>
            </div>
            <div className="d-flex flex-column flex-1 my-3 px-3">
                <div className="d-flex flex-md-row flex-column flex-1 align-items-md-center mb-2 pl-1">
                    <div className="flex-1">
                        <img style={{width: "2rem", height: "2rem"}} className="logo-tabla" src={item ? item.empresa.logo : avatar }/>
                        <span className="text-sm bold azul-gris-oscuro ml-3">{item ? item.empresa.nombre : ''}</span>
                    </div>
                    {(asistencia) && (
                        <div className="flex-1 mt-2 mt-md-0">
                            <span className="text-sm bold azul-gris-oscuro">{props.t('Colaborador')}</span>
                            <span className="text-sm regular azul-gris-oscuro ml-3">{asistencia.usuario ? asistencia.usuario.nombres : ''}</span>
                        </div>
                    )}
                </div>
                <div className={`w-100 ${asistencia ? 'd-none' : ''}`}>
                    <Grid hover striped data={{count: 0, results: item ? item.asistencia : []}} loading={loader} pagination={false} >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            width="5%"
                            dataFormat={cell => ''}
                        />
                        <TableHeaderColumn
                            dataField="usuario"
                            dataFormat={ cell => cell ? cell.nombres : ''}
                        >
                            {props.t('Colaboradores')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="asistencia"
                            dataAlign="center"
                            dataFormat={ (cell, row) => (
                                cell ? (
                                    <button className="btn btn-secondary-fill" onClick={()=> setAsistencia(row)}>{props.t('Anular asistencia')}</button>
                                ) : (<span className="marron">{props.t('Asistencia anulada')}</span>)
                            )}
                        >
                            {props.t('Estado')}
                        </TableHeaderColumn>
                    </Grid>
                </div>
                <div className={`${asistencia ? 'd-flex' : 'd-none'} flex-column flex-1`}>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="motivo" className="m-0">{props.t('Motivo de anulación')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nota_inasistencia" component={renderTextArea} type="text" className="form-control" />
                    </div>
                </div>
            </div>
            <div className={`${asistencia ? 'd-flex' : 'd-none'}  buttons-box flex-column flex-md-row my-4 px-3`}>
                <button type="button" onClick={()=> setAsistencia(null)} className="d-none d-md-flex btn btn-secondary mr-2">{props.t('Cancelar')}</button>
                <button type="button" onClick={()=> anularAsistencia({asistencia, nota_inasistencia})} className="d-none d-md-flex btn btn-primary ml-2">{props.t('Guardar')}</button>
                <button type="button" onClick={()=> setAsistencia(null)} className="d-flex d-md-none btn btn-secondary w-100">{props.t('Cancelar')}</button>
                <button type="button" onClick={()=> anularAsistencia({asistencia, nota_inasistencia})} className="d-flex d-md-none btn btn-primary w-100 mt-3">{props.t('Guardar')}</button>
            </div>
            <div className={`${asistencia ? 'd-none' : 'd-flex'}  buttons-box my-4 px-3`}>
                <button type="button" onClick={cerrar} className="d-none d-md-flex btn btn-secondary">{props.t('Regresar')}</button>
                <button type="button" onClick={cerrar} className="d-flex d-md-none btn btn-secondary flex-1">{props.t('Regresar')}</button>
            </div>
        </div>
    )
}

const selector = formValueSelector('AnulacionAsistenciaForm');

ModalAsistencia = reduxForm({
    form: 'AnulacionAsistenciaForm',
    asyncBlurFields: [],
})(ModalAsistencia);

const mstp = state => {
    const nota_inasistencia = selector(state, 'nota_inasistencia');

    return {
        nota_inasistencia,
    }
};

export default connect(mstp, null)(ModalAsistencia);
