import React, {Fragment} from 'react';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import {  renderCountrySelect , renderField, RenderImageUpload } from '../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';
import CustomTooltip from '../../Utils/Tooltip';

const PasoUnoForm = props => {
    const { handleSubmit, setLogo, logo } = props;

    return (
        <form onSubmit={handleSubmit} className="form-validate mb-lg">
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="nombre" className="m-0">{props.t('Nombre de la empresa')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Ingrese el nombre de su empresa')+'.'} />
                    </div>
                    <Field name="nombre" component={renderField} type="text" className="form-control"/>
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <label htmlFor="nombre" className="m-0">{props.t('Logo de la empresa')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <div style={{height:"9.1rem", width:"9.6rem"}} >
                        <RenderImageUpload imagen={logo} aspect={1} multiple={false} setFile={setLogo} crop={false} />
                    </div>
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="sitio_web" className="m-0">{props.t('Sitio web o página de Facebook')}</label>
                        <CustomTooltip msj={(<p>{props.t('Ingrese el sitio web de su empresa o el link de su pagina de Facebook u otra red social, este mismo tiene que ser completo, por ejemplo')}: http://wwww.growmotor.io</p>)}/>
                    </div>
                    <Field name="sitio_web" component={renderField} type="url" className="form-control"/>
                </div>
                <div className="d-flex flex-column p-0 my-3">
                    <span className="text-sm azul-claro bold">{props.t('Dirección')} </span>
                    <div className="d-flex w-100 bg-gris" style={{ height: '3px'}} />
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="pais" className="m-0"> {props.t('País')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Seleccione el país donde se encuentra ubicada su empresa')+'.'} />
                    </div>
                    <Field name="pais" component={renderCountrySelect} type="text" />
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="ciudad" className="m-0">{props.t('Ciudad')}  <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Ingrese la ciudad o provincia donde se encuentra ubicada su empresa')+'.'} />
                    </div>
                    <Field name="ciudad" component={renderField} type="text" className="form-control"/>
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="localidad" className="m-0">{props.t('Localidad')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Ingrese la locación donde se encuentra ubicada su empresa')+'.'} />
                    </div>
                    <Field name="localidad" component={renderField} type="text" className="form-control"/>
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="codigo_postal" className="m-0">{props.t('Código postal')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Ingrese su código postal')+'.'} />
                    </div>
                    <Field name="codigo_postal" component={renderField} validate={val => val&&val.length >10 ? props.t('Debe ser menor a 10 caracteres') : undefined} type="text" className="form-control"/>
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="linea1" className="m-0">{props.t('Línea 1')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Detalle la dirección de su empresa')+'.'} />
                    </div>
                    <Field name="linea1" component={renderField} type="text" className="form-control"/>
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="linea2" className="m-0">{props.t('Línea 2')} </label>
                        <CustomTooltip msj={props.t('Detalle la dirección de su empresa')+'.'} />
                    </div>
                    <Field name="linea2" component={renderField} type="text" className="form-control"/>
                </div>
            <div className="d-none d-lg-flex buttons-box mt-4">
                <button type="submit" className="btn btn-primary ml-3">{props.t('Siguiente')} </button>
            </div>
            <div className="d-flex d-lg-none flex-column mt-4">
                <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Siguiente')} </button>
            </div>
                
            
                
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'register',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncBlurFields: [],
    validate: (data, props) => {
        return validate(data, {
            'nombre': validators.exists()(`${props.t('Campo requerido')}`),
            'pais': validators.exists()(`${props.t('Campo requerido')}`),
            'ciudad': validators.exists()(`${props.t('Campo requerido')}`),
            'localidad': validators.exists()(`${props.t('Campo requerido')}`),
            'codigo_postal': validators.exists()(`${props.t('Campo requerido')}`),
            'linea1': validators.exists()(`${props.t('Campo requerido')}`),
        })
    },
})(withTranslation()(PasoUnoForm));
