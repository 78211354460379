import { handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import moment from 'moment';
import { SwalError, SwalExito } from '../../../common/components/Utils/Notificaciones';
import { ADMIN, GROWTH_ANGEL, HISTORIAS, PARTNER, SPONSOR } from '../../../utility/constants';
import Swal from 'sweetalert2';
import { getI18n } from 'react-i18next';

const LOADER = 'LOADER_HISTORIA';
const DATA = 'DATA_HISTORIA';
const DATA_USUARIO = 'DATA_HISTORIA_USUARIO';
const ITEM_DATA = 'ITEM_HISTORIA';
const PAGE = 'PAGE_HISTORIA';
const ORDERING = 'ORDERING_HISTORIA';
const SEARCH = 'SEARCH_HISTORIA';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});
const setDataUsuario = data_usuario => ({
    type: DATA_USUARIO,
    data_usuario,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1, filter, nuevo=true) => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const resource = getStore().historias;
    const me = getStore().login.me;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.tipo_publicacion = HISTORIAS;
    if ([ADMIN, GROWTH_ANGEL, SPONSOR].includes(me.tipo_usuario) && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (filter) params.valida = filter.valida;
    dispatch(setLoader(true));
    api.get('publicacion', params).then((response) => {
        const data_anterior = resource.data;
        const results = data_anterior.results;
        results.push(...response.results);
        dispatch(setData(response));
        if (!nuevo) {
            console.log("results ", results);
            dispatch(setData({...response, results: results}));
        }
        dispatch(setPage(page));
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error al obtener las publicaciones'), getI18n().t('ERROR'),3000)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer =(id)=>(dispatch,getStore)=>{
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const params = {};
    params.tipo_publicacion = HISTORIAS;
    dispatch(setLoader(true))
    api.get(`publicacion/${id}`, params).then((response)=>{
        if(me&&[ADMIN, GROWTH_ANGEL, SPONSOR].includes(me.tipo_usuario)&&cohorte){
            if(JSON.parse(cohorte).id!==response.cohorte.id){
                Swal.fire({
                    title: getI18n().t('¿Ver publicación?'),
                    type: 'warning',
                    text:  getI18n().t('Actualmente te encuentras en la cohorte {{cohorte1}} y el evento que deseas ver pertenece a la cohorte {{cohorte2}} por favor cambia de cohorte', {'cohorte1':JSON.parse(cohorte).nombre,'cohorte2':response.cohorte.nombre}),
                    //showCancelButton: true,
                    confirmButtonText: getI18n().t('¡Ok!'),
                    //cancelButtonText: 'No, cancelar',
                    reverseButtons: true
                }).then((result) => {});
                dispatch(setItem({}));
            }
            if(JSON.parse(cohorte).id===response.cohorte.id){
                dispatch(setItem(response));
            }
        }else{
            dispatch(setItem(response));
        }
    }).catch(
        ()=>{
            //NotificationManager.error('Esta publicación ya no existe');
            dispatch(push('/404'))}
    ).finally(
        ()=> dispatch(setLoader(false))
    )
}


const loadFormData = (data) => (dispatch) => {
    dispatch(initializeForm('HistoriaForm',data))
    dispatch(setItem(data))
};

const onSubmit = (data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments(`publicacion`, data, attachments).then(() => {
        SwalExito(getI18n().t('Éxito'), getI18n().t('Su historia ha sido envidada exitosamente para ser aprobada'));
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(listar())
    }).catch((error) => {
        let mensaje = getI18n().t('Error al enviar la historia');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    })
};

const editar = (id, data, attachments = [], detalle) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const { me } = getStore().login;
    const params = {};
    params.tipo_publicacion = HISTORIAS;
    api.putAttachments(`publicacion/${id}`, data, attachments, params).then(() => {
        if (me&&me.tipo_usuario===ADMIN)
            SwalExito(getI18n().t('Éxito'), getI18n().t('La publicacion se ha actualizando exitosamente'));
        else
            SwalExito(getI18n().t('Exito'), getI18n().t('Su historia ha sido envidada exitosamente para ser aprobada'));
        dispatch(cleanForm());
        if(detalle){
            dispatch(leer(id));
        }else{
            dispatch(listar());
        }
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la edición');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = (id, detalle=false) => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    params.tipo_publicacion = HISTORIAS;
    api.eliminar(`publicacion/${id}`, params).then(() => {
        if(detalle){
            dispatch(push('/historias'));
        }else{
            dispatch(listar());
        }
        NotificationManager.success(getI18n().t('La historia se ha eliminado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la transacción'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const aprobar = id =>(dispatch)=>{
    dispatch(setLoader(true));
    api.post(`publicacion/${id}/aprobar`).then(()=>{
        dispatch(listar());
        NotificationManager.success(getI18n().t('Aprobación de historia exitosa'), getI18n().t('Éxito'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('HistoriaForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().historias.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};



const getComentarios = (page = 1, id) => (dispatch, getStore) => {
    const params = { };
    params.publicacion = id

    dispatch(setLoader(true));
    api.get('comentario', params).then((response) => {
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error'), getI18n().t('ERROR'), 3000)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const compartir = (id, data)=>(dispatch)=>{
    dispatch(setLoader(true))
    api.post(`publicacion/${id}/compartir`, data).then(()=>{
        SwalExito(getI18n().t('Exito'), getI18n().t('El Growth Gists a sido compartida'))
    }).catch(
        ()=> SwalError(getI18n().t('Error'),getI18n().t('Algo salio mal al compartir el Growth Gists'))
    ).finally(
        ()=>dispatch(setLoader(false))
    )
}

const meGusta = (id, data, detalle) =>(dispatch)=>{
    //dispatch(setLoader(true));
    const params = {};
    params.tipo_publicacion = HISTORIAS;
    api.post(`publicacion/${id}/reaccionar`, data, params).then(()=>{
         if(detalle){
            dispatch(leer(id));
        }else{
            dispatch(listar());
        }
        NotificationManager.success(getI18n().t('Te ha gustado esta publicacion'), getI18n().t('Éxito'), 3000);
    }).catch((error)=> {
        let mensaje = getI18n().t('Algo salio mal intenta de nuevo');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);}
    ).finally(() => {
        dispatch(setLoader(false));
    });
}

const removerMeGusta = (id, data, detalle) =>(dispatch)=>{
    dispatch(setLoader(true));
    const params = {};
    params.tipo_publicacion = HISTORIAS;
    api.post(`publicacion/${id}/reaccionar`, data, params).then(()=>{
         if(detalle){
            dispatch(leer(id));
        }else{
            dispatch(listar());
        }
        NotificationManager.success(getI18n().t('Ya no te gusta esta publicacion'), getI18n().t('Éxito'), 3000);
    }).catch((error)=> {
        let mensaje = getI18n().t('Algo salio mal intenta de nuevo');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);}
    ).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarMeGustas = (id)=>(dispatch)=>{
    dispatch(setLoader(true));
    const params = {};
    params.tipo_publicacion = HISTORIAS;
    return api.get(`publicacion/${id}/lista_me_gusta`, params)
    .finally(()=>dispatch(setLoader(false)))
}

export const actions = {
    listar,
    loadFormData,
    onSubmit,
    editar,
    eliminar,
    aprobar,
    searchChange,
    onSortChange,
    cleanForm,
    getComentarios,
    compartir,
    leer,
    meGusta,
    removerMeGusta,
    listarMeGustas,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_USUARIO]: (state, { data_usuario }) => {
        return {
            ...state,
            data_usuario,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_usuario: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
