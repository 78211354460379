import { handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm, change} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import moment from 'moment';
import { SwalError, SwalExito } from '../../../common/components/Utils/Notificaciones';
import { ADMIN, BENEFICIO,SPONSOR, PARTNER } from '../../../utility/constants';
import Swal from 'sweetalert2';
import { getI18n } from 'react-i18next';

const LOADER = 'LOADER_BENEFICIOS_OPORTUNIDADES';
const DATA = 'DATA_BENEFICIOS_OPORTUNIDADES';
const DATA_INTERESADOS = 'DATA_BENEFICIOS_OPORTUNIDADES_USUARIO';
const ITEM_DATA = 'ITEM_BENEFICIOS_OPORTUNIDADES';
const PAGE = 'PAGE_BENEFICIOS_OPORTUNIDADES';
const ORDERING = 'ORDERING_BENEFICIOS_OPORTUNIDADES';
const SEARCH = 'SEARCH_BENEFICIOS_OPORTUNIDADES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});
const setDataInteresados = data_interesados => ({
    type: DATA_INTERESADOS,
    data_interesados,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1, filter, nuevo=true) => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const resource = getStore().beneficios;
    const me = getStore().login.me;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.tipo_publicacion = BENEFICIO;
    if ([ADMIN, SPONSOR, PARTNER].includes(me.tipo_usuario) && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (filter) params.valida = filter.valida;
    dispatch(setLoader(true));
    api.get('publicacion', params).then((response) => {
        const data_anterior = resource.data;
        const results = data_anterior.results;
        results.push(...response.results);
        dispatch(setData(response));
        if (!nuevo) {
            console.log("results ", results);
            dispatch(setData({...response, results: results}));
        }
        dispatch(setPage(page));
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error al obtener publicaciones'), getI18n().t('ERROR'), 3000)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer =(id)=>(dispatch, getStore)=>{
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const params = {};
    params.tipo_publicacion = BENEFICIO;
    dispatch(setLoader(true))
    api.get(`publicacion/${id}`,params).then((response)=>{
        if(me&& [ADMIN, SPONSOR, PARTNER].includes(me.tipo_usuario) && cohorte){
            if(JSON.parse(cohorte).id!==response.cohorte.id){
                Swal.fire({
                    title: getI18n().t('¿Ver publicación?'),
                    type: 'warning',
                    text: getI18n().t('Actualmente te encuentras en la cohorte {{cohorte1}} y el evento que deseas ver pertenece a la cohorte {{cohorte2}} por favor cambia de cohorte', {'cohorte1':JSON.parse(cohorte).nombre,'cohorte2':response.cohorte.nombre}),
                    //showCancelButton: true,
                    confirmButtonText: getI18n().t('¡Ok!'),
                    //cancelButtonText: 'No, cancelar',
                    reverseButtons: true
                }).then((result) => {});
                dispatch(setItem({}));
            }
            if(JSON.parse(cohorte).id===response.cohorte.id){
                dispatch(setItem(response));
            }
        }else{
            dispatch(setItem(response));
        }
    }).catch(
        ()=>{
            //NotificationManager.error('Esta publicación ya no existe');
            dispatch(push('/404'))}
    ).finally(
        ()=> dispatch(setLoader(false))
    )
}

const loadFormData = (data) => (dispatch) => {
    dispatch(initializeForm('BeneficioForm',data))
    dispatch(setItem(data))
};

const onSubmit = (data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments(`publicacion`, data, attachments).then(() => {
        SwalExito(getI18n().t('Éxito'), getI18n().t('Su beneficio u oportunidad ha sido envidada exitosamente para ser aprobada'));
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(listar())
    }).catch((error) => {
        let mensaje = getI18n().t('Error al enviar la publicación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    })
};

const editar = (id, data, attachments = [], detalle) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const { me } = getStore().login;
    const params = {};
    params.tipo_publicacion = BENEFICIO;
    api.putAttachments(`publicacion/${id}`, data, attachments, params).then(() => {
        if (me&&(me.tipo_usuario===SPONSOR||me.tipo_usuario===PARTNER))
            SwalExito(getI18n().t('Éxito'), getI18n().t('Su beneficio u oportunidad ha sido envidada exitosamente para ser aprobada'));
        if (me&&me.tipo_usuario===ADMIN)
        SwalExito(getI18n().t('Exito'), getI18n().t('La publicacion se ha actualizando exitosamente'));

        dispatch(cleanForm());
        if(detalle){
            dispatch(leer(id));
        }else{
            dispatch(listar());
        }
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la edición');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = (id, detalle) => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    params.tipo_publicacion = BENEFICIO;
    api.eliminar(`publicacion/${id}`, params).then(() => {
        if(detalle){
            dispatch(push('/beneficios'));
        }else{
            dispatch(listar());
        }
        NotificationManager.success(getI18n().t('La publicacion se ha eliminado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la transacción'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const aprobar = id =>(dispatch)=>{
    dispatch(setLoader(true));
    api.post(`publicacion/${id}/aprobar`).then(()=>{
        dispatch(listar());
        NotificationManager.success(getI18n().t('Aprobación del beneficio u oportunidad exitosa'), getI18n().t('Éxito'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const setArchivos = (archivos_nuevos) => (dispatch, getStore) => {
    const form = getStore().form.BeneficioForm
    const archivos = (form.values&&form.values.archivos) ? _.cloneDeep(form.values.archivos) : [];
    for (const item of archivos_nuevos) {
        archivos.push({
            nombre: item.name,
        });
    }
    dispatch(change('BeneficioForm', 'archivos', archivos));
}

const eliminarArchivo = (index) => (dispatch, getStore) => {
    const form = getStore().form.BeneficioForm.values;

    let archivos = _.cloneDeep(form.archivos);
    archivos = _.filter(archivos, (item, i) => i != index );
    dispatch(change('BeneficioForm', 'archivos', archivos));
}

const meInteresa = (id, data) =>(dispatch)=>{
    dispatch(setLoader(true));
    const params = {};
    params.tipo_publicacion = BENEFICIO;
    api.post(`publicacion/${id}/reaccionar`, data, params).then(()=>{
        dispatch(listar());
        NotificationManager.success(getI18n().t('Se le ha notificado a la empresa que estas interesado'), getI18n().t('Éxito'), 3000);
    }).catch(
        ()=> {NotificationManager.error(getI18n().t('Algo salio mal intenta de nuevo'), getI18n().t('ERROR'), 3000);}
    ).finally(() => {
        dispatch(setLoader(false));
    });

}

const listarInteresados =(id, page=1)=>(dispatch)=>{
    dispatch(setLoader(true));
    const params = {};
    params.page = page
    params.tipo_publicacion = BENEFICIO;
    api.get(`publicacion/${id}/lista_interesados`, params).then(
        (response)=>{
            dispatch(setDataInteresados(response));
            dispatch(setPage(page));
        }
    ).catch(

    ).finally(
        ()=> {dispatch(setLoader(false));}

    )
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('BeneficioForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().beneficios.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


const getComentarios = (page = 1, id) => (dispatch, getStore) => {
    const params = { };
    params.publicacion = id

    dispatch(setLoader(true));
    api.get('comentario', params).then((response) => {
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error al obtener comentarios'), getI18n().t('ERROR'), 3000)
    }).finally(() => { 
        dispatch(setLoader(false));
    });
};

const descargarExcel = (id) => (dispatch, getStore) => {
    const resource = getStore().beneficios;
    window.open(
        `/api/publicacion/${id}/descargar_listado_me_interesa?tipo_publicacion=${BENEFICIO}&auth_token=${localStorage.getItem("token")}`,
        '_blank'
    );
}

/* const compartir = (id, data)=>(dispatch)=>{
    dispatch(setLoader(true))
    api.post(`publicacion/${id}/compartir`, data).then(()=>{
        SwalExito(getI18n().t('Exito'), 'El Growth Gists a sido compartida')
    }).catch(
        ()=> SwalError('Error','Algo salio mal al compartir el Growth Gists')
    ).finally(
        ()=>dispatch(setLoader(false))
    )
} */

const meGusta = (id, data,detalle) =>(dispatch)=>{
    //dispatch(setLoader(true));
    const params = {};
    params.tipo_publicacion = BENEFICIO;
    api.post(`publicacion/${id}/reaccionar`, data, params).then(()=>{
        if(detalle){
            dispatch(leer(id));
        }else{
            dispatch(listar());
        }
        dispatch(listar());
        NotificationManager.success(getI18n().t('Te ha gustado esta publicacion'), getI18n().t('Éxito'), 3000);
    }).catch((error)=> {
        let mensaje = getI18n().t('Algo salio mal intenta de nuevo');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);}
    ).finally(() => {
        dispatch(setLoader(false));
    });
}

const removerMeGusta = (id, data, detalle) =>(dispatch)=>{
    dispatch(setLoader(true));
    const params = {};
    params.tipo_publicacion = BENEFICIO;
    api.post(`publicacion/${id}/reaccionar`, data, params).then(()=>{
        if(detalle){
            dispatch(leer(id));
        }else{
            dispatch(listar());
        }
        NotificationManager.success(getI18n().t('Ya no te gusta esta publicacion'), getI18n().t('Éxito'), 3000);
    }).catch((error)=> {
        let mensaje = getI18n().t('Algo salio mal intenta de nuevo');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);}
    ).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarMeGustas = (id)=>(dispatch)=>{
    dispatch(setLoader(true));
    const params = {};
    params.tipo_publicacion = BENEFICIO;
    return api.get(`publicacion/${id}/lista_me_gusta`, params)
    .finally(()=>dispatch(setLoader(false)))
}

export const actions = {
    listar,
    loadFormData,
    onSubmit,
    editar,
    eliminar,
    aprobar,
    searchChange,
    onSortChange,
    cleanForm,
    getComentarios,
    //compartir,
    meInteresa,
    leer,
    listarInteresados,
    descargarExcel,
    meGusta,
    listarMeGustas,
    removerMeGusta,
    setArchivos,
    eliminarArchivo,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_INTERESADOS]: (state, { data_interesados }) => {
        return {
            ...state,
            data_interesados,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_interesados: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
