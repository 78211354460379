import React from "react";
import { withTranslation, getI18n } from "react-i18next";
import Grid from "../../../Utils/Grid";
import Card from "../../../Utils/Cards/Card";
import { Link } from "react-router-dom";
import { TableHeaderColumn } from "react-bootstrap-table";
import { HeaderBasico } from "../../../Utils/Grid/Headers";
import moment from "moment";
import Modal from "react-responsive-modal";
import Cuestionario from "./cuestionario";
import Select from 'react-select';
import '../../../../../i18nextInit'
import i18n from '../../../../../i18nextInit'
import { MENTORIA, TALLER, ENCUENTRO_GUERREROS, FAMILY_MEETING } from '../../../../../utility/constants';

import IconoTaller from '../../../../../../assets/img/iconos/taller.png';
import IconoGuerreros from '../../../../../../assets/img/iconos/guerreros.png';
import IconoMentorias from '../../../../../../assets/img/iconos/mentoria.png';
import IconoFamiliares from  "../../../../../../assets/img/iconos/familia.png";



const options = [
    { value: 1, label: getI18n().t('Todos los usuarios') },
    { value: 2, label: getI18n().t('CEO') },
    { value: 3, label: getI18n().t('Colaborador') },
  ]

class DetalleEvento extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            item: {},
        };
    }

    componentDidMount = () => {
        const id = this.props.match.params.id;
        this.props.detalleEvento(id);
    };
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
        this.props.clearForm();
    };
    onVer(id, item) {
        this.props.getCalificacion(id).then(() => {
            this.onOpenModal();
        });
    }

    onChangeFiltro =(e)=>{
        const id = this.props.match.params.id;
        this.props.changeFiltro(id, e.value);

    }

    getIcono=(tipo)=>{
        switch(tipo){
            case MENTORIA:
                return (<img src={IconoMentorias} style={{width:"3.2rem", height:"3.2rem"}} />)
            case TALLER:
                return (<img src={IconoTaller} style={{width:"3.2rem", height:"3.2rem"}} />)
            case FAMILY_MEETING:
                return (<img src={IconoFamiliares} style={{width:"3.2rem", height:"3.2rem"}} />)
            case ENCUENTRO_GUERREROS:
                return (<img src={IconoGuerreros} style={{width:"3.2rem", height:"3.2rem"}} />)
            default:
                return (<img src={IconoTaller} style={{width:"3.2rem", height:"3.2rem"}} />)

        }
    }

    render() {
        const { data2, page2, loader, item } = this.props;
        const getEventDetail = page => {
            const id = this.props.match.params.id;
            this.props.detalleEvento(id, page);
        }
        const lang = i18n.language ? i18n.language : 'en'
        return (
            <div className="px-lg-4 px-0">
                <Card className="mb-3 py-2">
                    {(item)&&(
                        <div className="d-flex flex-column flex-md-row pb-3">
                            <div className="d-flex flex-1 pb-2 pb-md-0" >
                                <div className="d-flex flex-column flex-1 align-items-center">
                                    {this.getIcono(item.tipo_publicacion)}
                                </div>
                                <div className="d-flex flex-column flex-1">
                                    <span className="text-sm regular" >
                                        {this.props.t('Evento')}
                                    </span>
                                    <span className="text-sm celeste-oscuro bold" >
                                        {item.titulo}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex flex-1" >
                                <div className="d-flex flex-column flex-1">
                                    <span className="text-sm regular" >
                                        {this.props.t('Fecha Realizada')}
                                    </span>
                                    <span className="text-sm celeste-oscuro bold" >
                                        {moment(item.fecha_inicio).locale(lang).format('MMM-DD-YYYY')}
                                    </span>
                                </div>
                                <div className="d-flex flex-column flex-1">
                                    <span className="text-sm regular" >
                                        {this.props.t('Tipo evento')}
                                    </span>
                                    <span className="text-sm celeste-oscuro bold">
                                        {item.tipo_publicacion_text}
                                    </span>
                                </div>
                            </div>
                           {/*  <div className="d-flex flex-1" /> */}
                        </div>
                    )}
                </Card>
                <Card className="p-lg-4">
                    <div className="d-flex flex-row">
                        <div className="flex-4 d-none d-md-flex" />
                        <div className="flex-1" >
                            <span>{this.props.t('Filtro')}</span>
                            <br/>
                            <Select
                                options={options}
                                placeholder={this.props.t('Filtrar por')+'...'}
                                onChange={this.onChangeFiltro}
                                defaultValue={options.filter(item=>item.value===this.props.filtro)}
                                className="w-100" />
                        </div>
                    </div>
                    <br />
                    <Grid
                        hover
                        striped
                        data={data2}
                        page={page2}
                        loading={loader}
                        onPageChange={getEventDetail}
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataFormat={(cell, row) => (
                                <div className="d-flex justify-content-center">
                                    <a
                                        title={this.props.t("Ver")}
                                        onClick={(e) => this.onVer(cell, row)}
                                        className="action action-azul-oscuro  px-1 py-2"
                                    >
                                        <i className="fas fa-eye azul-oscuro"></i>
                                    </a>
                                </div>
                            )}
                        >
                            {this.props.t("Acciones")}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataFormat={(cell) =>
                                moment(cell).locale(lang).format("MMM-DD-YYYY")
                            }
                        >
                            {this.props.t("Fecha enviado")}
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="tipo_usuario">
                            {this.props.t("Tipo usuario")}
                        </TableHeaderColumn>
                    </Grid>
                    <div className="d-flex justify-content-center pb-4 pb-lg-0">
                        <Link
                            className="btn btn-secondary"
                            to="/reporte/calificaciones_eventos"
                        >
                            {this.props.t("Regresar")}
                        </Link>
                    </div>
                </Card>
                <Modal
                    key="modal-1"
                    open={this.state.open}
                    onClose={this.onCloseModal}
                    center
                >
                    <Cuestionario
                        t={this.props.t}
                        item={this.state.item}
                        loader={this.props.loader}
                        onClose={this.onCloseModal}
                    />
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(DetalleEvento);
