import { result } from 'lodash';
import Swal from 'sweetalert2';
import { getI18n } from 'react-i18next'
import '../../../../i18nextInit'

const repAll = (str, old, current) => { //cadena en donde se hara el reeplazo, lo que se quiere reemplazar, por lo que se va reemplazar
    const rep = (str.match(old) || []).length
    for(var i=0; i<rep; i++){
        str = str.replace(old, current)
    }
    console.log("CADENA DEVUELTA:", str)
    return str
} 

export function SwalExito(titulo, subtitulo, next=()=>{}) {

    subtitulo = repAll(subtitulo, /\n/g, "<br/>")

    const SwalMod = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          content: 'd-flex justify-content-center mb-4 gris-oscuro',
          title: 'azul-gris-oscuro bold',
        },
        buttonsStyling: false,
        focusConfirm: false,
        focusCancel: false,
    })

    return (
        SwalMod.fire({
            type: 'success',
            title: titulo,
            html: subtitulo,
            confirmButtonText: getI18n().t('Aceptar')
        }).then(result=>{
            next()
        })
    )
}

export function SwalWarningOK(titulo, subtitulo, next=()=>{}) {

    subtitulo = repAll(subtitulo, /\n/g, "<br/>")

    const SwalMod = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          content: 'd-flex text-align-left mb-4 gris-oscuro',
          title: 'azul-gris-oscuro bold',
        },
        buttonsStyling: false,
        focusConfirm: false,
        focusCancel: false,
    })

    return (
        SwalMod.fire({
            type: 'warning',
            title: titulo,
            html: subtitulo,
            confirmButtonText: getI18n().t('Aceptar')
        }).then(result=>{
            next()
        })
    )
}

export function SwalError(titulo, subtitulo, next=()=>{}) {

    subtitulo = repAll(subtitulo, /\n/g, "<br/>")

    const SwalMod = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-error',
          content: 'd-flex justify-content-center mb-4 gris-oscuro',
          title: 'azul-gris-oscuro bold',
        },
        buttonsStyling: false,
        focusConfirm: false,
        focusCancel: false,
    })
    return (
        SwalMod.fire({
            type: 'error',
            title: titulo,
            html: subtitulo,
            confirmButtonText: getI18n().t('Aceptar')
        }).then(result=>{
            next()
        })
    )
}

export function SwalWarning(titulo, subtitulo, next_true=()=>{}, next_false=()=>{}) {

    subtitulo = repAll(subtitulo, /\n/g, "<br/>")

    const SwalMod = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-secondary',
          title: 'azul-gris-oscuro bold',
        },
    })

    return (
        SwalMod.fire({
            title: titulo,
            html: subtitulo,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: getI18n().t('Sí'),
            cancelButtonText: getI18n().t('No'),
            type: 'warning',
            reverseButtons: true
        }).then(result=>{
            if(result.value){
                next_true()
            } else {
                next_false()
            }
        })
    )
}
