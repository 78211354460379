import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import Toolbar from '../../../Utils/Grid/Toolbar';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';


class ListarColaboradores extends Component {

    componentWillMount() {
        console.log('props ', this.props);
        const { listar, page } = this.props;
        listar(page);
    }

    eliminar = (id) => {
        Swal.fire({
            title: this.props.t('¿Eliminar?'),
            text: this.props.t('¡No podrá revertir esta acción!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, eliminar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.eliminar(id);
            }
        });
    };

    reenviar = (id) => {
        Swal.fire({
            title: this.props.t('¿Reenviar?'),
            text: this.props.t('¡Desea volver a enviar la invitación!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, enviar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.reenviar(id);
            }
        });
    };

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, reenviar , me} = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                {/* Botones */}
                <div className="d-flex flex-1 row px-3">
                    <div className="col-md-4 col-6 px-0">
                        <Link to='/colaborador/crear' className="btn btn-primary mb-3" disabled={data.count >= 10} >{this.props.t('Invitar colaborador')}</Link>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4 col-6 px-0">
                    </div>
                </div>
                <Card>
                    <div className="px-lg-2 my-2">
                        <HeaderBasico {...this.props} titulo={<span className="marron text-sm">{this.props.t("Límite de colaboradores {{limite}}/10", {"limite": data.count})}</span>} />
                    </div>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={(cell, row) => (
                                <div className="d-flex justify-content-center">
                                    {(row.is_active&&(me.colider&&!row.colider||me.admin_empresa)) && (
                                        <Link title={this.props.t('Editar')} className="action action-marron-claro px-1 py-2" to={`/colaboradores/${cell}/editar`} >
                                            <i className="fas fa-pen-alt marron-claro"></i>
                                        </Link>
                                    )}
                                    {(!row.is_active&&(me.colider&&!row.colider||me.admin_empresa)) && (
                                        <Link title={this.props.t('Editar')} className="action action-marron-claro px-1 py-2" to={`/colaborador/${cell}/editar`} >
                                            <i className="fas fa-pen-alt marron-claro"></i>
                                        </Link>
                                    )}
                                    {(!row.is_active&&(me.colider&&!row.colider||me.admin_empresa)) && (
                                        <a title={this.props.t('Reenviar')} className="action action-azul-oscuro  px-1 py-2" style={{cursor: "pointer"}} onClick={()=> this.reenviar(cell)}>
                                            <i className="fas fa-share"></i>
                                        </a>
                                    )}
                                    {(me.colider&&!row.colider||me.admin_empresa)&&(
                                        <a title={this.props.t('Eliminar')} className="action action-gris px-1 py-2" style={{cursor: "pointer"}} onClick={() => this.eliminar(cell)}>
                                            <i className="fas fa-times gris"></i>
                                        </a>
                                    )}
                                </div>
                            )}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombres"
                            dataSort
                        >
                            {this.props.t('Nombre')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                            dataSort
                        >
                            {this.props.t('Correo')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="colider"
                            dataFormat={cell=> cell? this.props.t('Colíder'):this.props.t('Colaborador')}
                        >
                            {this.props.t('Rol')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="is_active"
                            dataSort
                            dataFormat={ cell => cell ? <span className="verde">{this.props.t('Registrado')}</span> : <span className="marron" >{this.props.t('Invitado')}</span>}
                        >
                            {this.props.t('Estado')}
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>

        )
    }
}

export default withTranslation()(ListarColaboradores)

