import React, { Component } from 'react';
import Card from '../../../Utils/Cards/Card';
import CardForm from '../../../Utils/Cards/CardForm';
import { withTranslation } from 'react-i18next';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import moment from 'moment';
import ExamenKPIsForm from './ExamenKPIsForm';
import ExamenKPIsUnico from './ExamenKPIsUnico';
import i18n from '../../../../../i18nextInit'

const evaluacionKPIs = require('../../../../../../assets/img/iconos/evaluacion_kpis.png');


class VerPerfilEmpresa extends Component {

    componentDidMount() {
        const { leer, setDatosEvaluacion, me } = this.props;
        // leer(me.empresa);
        setDatosEvaluacion();
    }

    guardarEvaluacionKPIs = (values) => {
        const data = {...values};
        if (data.pregunta1_c == false)
            delete data.pregunta1_c_i;
        if (data.pregunta2_a == false) {
            delete data.pregunta2_a_i;
            delete data.pregunta2_a_ii;
        }
        if (data.pregunta3 == false)
            delete data.pregunta3_a;
        if (data.pregunta4 == false)
            delete data.pregunta4_a;
        if (data.pregunta5 == false)
            delete data.pregunta5_a;
        if (data.pregunta6 == false)
            delete data.pregunta6_a;
        if (data.pregunta7 == false) {
            delete data.pregunta7_i;
            delete data.pregunta7_ii;
        }
        if (data.pregunta8 == false) {
            delete data.pregunta8_i;
            delete data.pregunta8_ii;
        }

        this.props.mandarEvaluacionKPIs(data);
    }
    updateEvaluacionKPIs = (values)=>{
        const data = {...values};
        if (data.pregunta1_c == false)
            data.pregunta1_c_i = null;
        if (data.pregunta2_a == false) {
            data.pregunta2_a_i = null;
            data.pregunta2_a_ii = null;
        }
        if (data.pregunta3 == false)
            data.pregunta3_a = null;
        if (data.pregunta4 == false)
            data.pregunta4_a = null;
        if (data.pregunta5 == false)
            delete data.pregunta5_a;
        if (data.pregunta6 == false)
            data.pregunta6_a = null;
        if (data.pregunta7 == false) {
            data.pregunta7_i = null;
            data.pregunta7_ii =null;
        }
        if (data.pregunta8 == false) {
            delete data.pregunta8_i;
            data.pregunta8_ii = null;
        }

        this.props.updateUltimoKPIs(data);

    }

    render() {
        const { evaluacion, loader, me } = this.props;
        const obligatorio = this.props.match.params.obligatorio;
        const lang = i18n.language ? i18n.language : 'en'
        moment.locale(lang)
        let fecha_limite = moment(`${evaluacion.anio_proximo}-${evaluacion.mes_proximo}-1`).endOf('month');
        const trimeste_inicio = moment(fecha_limite).subtract(2, 'months');
        const fecha_habilitacion = moment(fecha_limite).subtract(15,'day').startOf('day');
        if ((moment() > fecha_habilitacion && moment() <= fecha_limite) || obligatorio ) {
            return (
                <div className="mb-4 col-12 mt-0">
                    <LoadMask loading={loader} blur>
                        <Card noPadding={true}>
                            <div className="d-flex flex-column flex-md-row align-items-md-center flex-1 my-4 mx-md-5 mx-4">
                                <div className="d-flex flex-column flex-1">
                                    <span className="text-sm bold celeste-oscuro">{this.props.t('Growth KPIs')}</span>
                                    <span className="text-sm bold celeste-oscuro">
                                        {`${trimeste_inicio.format("MMMM")} - ${moment(fecha_habilitacion).format("MMMM")} ${trimeste_inicio.format("YYYY")}`}
                                    </span>
                                </div>
                                <div className="d-flex flex-column flex-1 mt-2 mt-md-0">
                                    <span className="text-sm gris-oscuro">{this.props.t('Fecha limite')}</span>
                                    <span className="text-sm bold celeste-oscuro">{moment(fecha_limite).format("MMM-DD-YYYY")}</span>
                                </div>
                                <div className="d-flex flex-column flex-1 mt-2 mt-md-0">
                                    <span className="text-sm gris-oscuro">{this.props.t('Cohorte')}</span>
                                    <span className="text-sm bold celeste-oscuro">{evaluacion.cohorte ? evaluacion.cohorte.nombre : ''}</span>
                                </div>
                            </div>
                        </Card>
                        <CardForm>
                            <ExamenKPIsForm
                                {...this.props}
                                onSubmit={this.guardarEvaluacionKPIs}
                                periodo={`${trimeste_inicio.format("MMMM")} - ${moment(fecha_habilitacion).format("MMMM")} ${trimeste_inicio.format("YYYY")}`}
                            />
                        </CardForm>
                    </LoadMask>
                </div>

            )
        }
        if(this.props.item_kpis&&this.props.item_kpis.id){
            const { item_kpis }= this.props;
            const trimeste_fin = moment(`${item_kpis.year}-${item_kpis.month}-1`);
            const trimeste_inicio = moment(trimeste_fin).subtract(2, 'months');
            return(
                <React.Fragment>
                    <Card noPadding={true}>
                        <div className="d-flex flex-column flex-md-row align-items-md-center flex-1 my-4 mx-md-5 mx-4">
                            <div className="d-flex flex-column flex-1">
                                <span className="text-sm bold celeste-oscuro">{this.props.t('Growth KPIs')}</span>
                                <span className="text-sm bold celeste-oscuro">
                                    {`${trimeste_inicio.format("MMMM")} - ${moment(trimeste_fin).format("MMMM")} ${trimeste_inicio.format("YYYY")}`}
                                </span>
                            </div>
                            <div className="d-flex flex-column flex-1 mt-2 mt-md-0"></div>
                            <div className="d-flex flex-column flex-1 mt-2 mt-md-0">
                                <span className="text-sm gris-oscuro">{this.props.t('Cohorte')}</span>
                                <span className="text-sm bold celeste-oscuro">{evaluacion.cohorte ? evaluacion.cohorte.nombre : ''}</span>
                            </div>
                        </div>
                    </Card>
                    {(item_kpis.unico===true) ?(
                        <CardForm>
                            <ExamenKPIsUnico
                                {...this.props}
                                onSubmit={this.updateEvaluacionKPIs}
                                periodo={`${trimeste_inicio.format("MMMM")} - ${moment(trimeste_fin).format("MMMM")} ${trimeste_inicio.format("YYYY")}`}
                            />
                        </CardForm>
                    ):(
                        <CardForm>
                            <ExamenKPIsForm
                                {...this.props}
                                onSubmit={this.updateEvaluacionKPIs}
                                periodo={`${trimeste_inicio.format("MMMM")} - ${moment(trimeste_fin).format("MMMM")} ${trimeste_inicio.format("YYYY")}`}
                            />
                        </CardForm>)}
                </React.Fragment>
                )
        }

        return (
            <Card noPadding={true}>
                <div className="d-flex flex-column flex-1 justify-content-center align-items-center my-4">
                    <img className="" src={evaluacionKPIs} />
                    <span className="text-qts bold gris text-center pt-4">{`${this.props.t('Puedes editar la ultima evaluacion de KPIs enviada')}`}</span>
                    <div className='d-flex flex-1 justify-content-center' >
                        <button type='button' onClick={e=>this.props.obtenerUltimoKPIs()} className='btn btn-primary my-2' >{this.props.t('Editar ultima evaluacion de  KPI')}</button>
                    </div>
                </div>
                <div className='d-flex flex-1 justify-content-center flex-column py-4' style={{borderTop:'solid 3px #363C3F'}} >
                    <span className="text-qts bold gris text-center">{`${this.props.t('La evaluación de KPIs se habilitará el')} ${moment(fecha_habilitacion).format("MMM-DD-YYYY")}`}</span>
                </div>
            </Card>
        )
    }
}

export default withTranslation()(VerPerfilEmpresa)
