import React, { Component } from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import ColaboradorForm from './ColaboradorForm';
import moment from 'moment';
import { withTranslation } from 'react-i18next';


class CrearColaborador extends Component {

    componentWillMount() {
        console.log("HGere we are", this.props)
        const id = this.props.match.params.id;
        if (id)
            this.props.leer(id);
    }

    onSubmit = (values) => {
        const id = this.props.match.params.id;
        if (id) {
            this.props.editar(id, values);
        } else {
            this.props.crear(values);
        }
    }
    onCancelar = ()=>{
        this.props.history.push('/colaboradores');
    }
    render() {
        const { loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <ColaboradorForm 
                            onSubmit={this.onSubmit} 
                            onCancelar={this.onCancelar} 
                            me={this.props.me}
                            t={this.props.t} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(CrearColaborador);