import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
import 'font-awesome/css/font-awesome.min.css';


class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: this.props.t('¿Eliminar?'),
                text: this.props.t('¡No podrá revertir esta acción!'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.props.t('¡Sí, eliminar!'),
                cancelButtonText: this.props.t('No, cancelar'),
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    };

    reenviar = (id)=>{
        return () => {
            Swal.fire({
                title: this.props.t('¿Reenviar?'),
                text: this.props.t('¡Desea volver a enviar la invitación!'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.props.t('¡Sí, enviar!'),
                cancelButtonText: this.props.t('No, cancelar'),
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.reenviar(id);
                }
            });
        }

    };

    render() {
        let { id, ver, editar, eliminar, reenviar } = this.props;
        if (id.valida){
            editar = undefined;
            eliminar = undefined;
        }

        return (
            <div className="d-flex justify-content-center">
                {(editar !== undefined) && (
                    <Link title={this.props.t('Editar')} className="action action-marron-claro px-1 py-2" to={`${editar}/${id.id ? id.id :id }/editar`} ><i className="fas fa-pen-alt marron-claro"></i></Link>
                )}
                {(ver !== undefined) && (
                    <Link title={this.props.t('Ver')} className="action action-azul-oscuro  px-1 py-2" to={`${ver}/${id.id ? id.id :id}/`}><i className="fas fa-eye azul-oscuro"></i></Link>
                )}
                {(reenviar !== undefined) && (
                    <a title={this.props.t('Reenviar')} className="action action-azul-oscuro  px-1 py-2" style={{cursor: "pointer"}} onClick={this.reenviar(id)}> <i className="fas fa-share"></i></a>
                )}
                {(eliminar !== undefined) && (
                    <a title={this.props.t('Eliminar')} className="action action-gris px-1 py-2" style={{cursor: "pointer"}} onClick={this.eliminar(id.id? id.id :id)}><i className="fas fa-times gris"></i></a>
                )}
            </div>
        );
    }
}

Acciones.propTypes = {

};

const Actions = withTranslation()(Acciones)

export function standardActions(acciones) {
    return (cell, row) => {
        return ( <Actions id={cell} {...acciones}/> )
    };
}
