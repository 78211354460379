import React from 'react';
import { Tooltip  } from "antd";

const CustomTooltip =(props)=>{
    return(
        <div className={`${props.className ? props.className :''}`}  >
            <Tooltip 
                placement="topRight" 
                title={props.msj} 
                trigger={['click','hover']} >
                    <i className="fa fas fa-question fa-sm btn-tooltips" />
            </Tooltip>
        </div>
    )
} 

export default CustomTooltip;