import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import { Menu, Dropdown, DownOutlined } from "antd";
import { ADMIN, ME_INTERESA, ME_GUSTA, USUARIO, BENEFICIO } from '../../../../utility/constants';
import Com from '../Comentarios';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../i18nextInit'
import EditarPost from './EditarCard';
import Lightbox from 'react-image-lightbox';
import ArchivosUploader from '../ArchivoUploader';

const imgDefault = require("../../../../../assets/img/avatar.png");
const clockIcon = require("../../../../../assets/img/iconos/clock.png");

import ListarReacciones from './CardListaMeGusta';

const menu = (props, onEditar) => {
    return (

        <Menu>
            {
                ((props.item.publicado_por.id===props.me.id)||props.me.tipo_usuario===ADMIN) && (
                    <Menu.Item key="editar" onClick={(e)=> onEditar()} >{props.t('Editar')}</Menu.Item>
                )
            }
            {
                (props.me.is_superuser && !props.item.valida) && (
                    <React.Fragment>
                        <Menu.Item key="aprobar" onClick={(e)=>{
                            Swal.fire({
                            title:`${props.t('¿Aprobar Beneficio u Oportunidad?')}`,
                            text:`${props.t('¡No podrá revertir esta acción!')}`,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText:`${props.t('¡Sí, Aprobar!')}`,
                            cancelButtonText: `${props.t('No, cancelar')}`,
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                props.aprobar(props.item.id);

                            }
                        });
                        }}>{props.t('Aprobar')}</Menu.Item>
                    </React.Fragment>
                )
            }

            {
                ((props.item.publicado_por.id ===props.me.id)||(props.me.is_superuser))&& (
                    <Menu.Item key="eliminar" onClick={(e)=>{
                        Swal.fire({
                            title:`${props.t('¿Eliminar Beneficio u Oportunidad?')}`,
                            text:`${props.t('¡No podrá revertir esta acción!')}`,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText:`${props.t('¡Sí, eliminar!')}`,
                            cancelButtonText: `${props.t('No, cancelar')}`,
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                props.eliminar(props.item.id, props.detalle? props.detalle: false)

                            }
                        });
                        }} >{props.t('Eliminar')}</Menu.Item>
                )
            }
        </Menu>
    );
};

class CardPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            comentar: false,
            editar:false,
            open: false,
            data: {results:[],count:0},
            wabble:0,
            clickPrevent:false,
            lightbox:false,
        };
    }
    onMeGusta = (e)=>{
        const { item  } =this.props;
        if(this.state.clickPrevent){
            e.preventDefault();

        }else if (!item.me_gusta){
            this.props.meGusta(item.id, {'tipo': ME_GUSTA}, 
                this.props.detalle? this.props.detalle: false);
            this.setState({wabble:1});
            this.setState({clickPrevent:true});
            setTimeout(()=>{this.setState({clickPrevent:false})}, 1500);
        }else{
            this.props.removerMeGusta(item.id, {'tipo': ME_GUSTA, 'remover': true}, 
                this.props.detalle? this.props.detalle: false);
            this.setState({clickPrevent:true});
            setTimeout(()=>{this.setState({clickPrevent:false})}, 1500); 
        }  
        
    }
    onDoubleClick = ()=>{
        const { item  } =this.props;
        
    }
    onVerMeGusta = ()=>{
        const {item}= this.props;
        this.props.listarMeGustas(item.id).then((response)=>{
            this.setState({data:response});
            this.setState({open:true});
        })
    }

    setComentar=()=>{
        this.setState({comentar:!this.state.comentar});

    }
    onCancelar=()=>{
        this.setState({editar:false});
    }
    onEditar = ()=>{
        this.setState({editar:true});
    }
    sendEditar=(item)=>{
        const array = item.array ? item.array : []; 
        if(item.imagen){
            array.push({ file: item.imagen, name: "imagen" });
        }else{
            array.push({ file: null, name: "imagen" });
        }
        delete item.imagen;
        delete item.array;

        this.props.editar(item.id, item, array,
            this.props.detalle ? this.props.detalle: false)
    }
    onMeInteresa=()=>{
        const {item} =this.props;
        const data = { tipo: ME_INTERESA };
        this.props.meInteresa(item.id ,data);
    }
    getRuta=()=>{
        const { item }= this.props;
        if(item.tipo_publicacion===BENEFICIO){
            return 'beneficio'
        }
    }
    getColorCard=()=>{
        if(!this.props.item.valida&&!this.state.editar){
            return 'bg-gris-oscuro-2'
        }
        if(!this.props.item.valida&&this.state.editar){
            return ''
        }
    }
    render() {
        const { id, item, me } = this.props;
        
        const lang = i18n.language ? i18n.language : 'en'
        return (
            <Fragment>
                <div className={`card card-post ${this.getColorCard()}`}>
                    <div className={`card-content-form flex-column px-0 pt-2 pb-0 ${this.getColorCard()}`}>
                        <div className="card-post-header">
                            <div className="d-flex align-items-center justify-content-center flex-1">
                                <img
                                    src={item.empresa && item.empresa.logo ?
                                        item.empresa.logo:
                                        imgDefault}
                                    style={{ height: "2rem", width: "2rem" }}
                                    className="perfil-img"
                                />
                            </div>
                            <div className="d-flex flex-column flex-5">
                                <div className="d-flex flex-row align-items-center flex-1">
                                    <Link className="h5 link-perfil" to={`/empresa/${item.empresa ? item.empresa.id : 0}`}>
                                        {item.empresa ? item.empresa.nombre : ''}
                                    </Link>
                                    <span className="h5 gris-oscuro">
                                        &nbsp;/&nbsp;
                                        {(item&&item.fecha_inicio) ? me && me.timezone ? moment(item.fecha_inicio).tz(me.timezone).locale(lang).fromNow() : moment(item.fecha_inicio).locale(lang).fromNow() : me && me.timezone ? moment(item.creado).tz(me.timezone).locale(lang).fromNow() : moment(item.creado).locale(lang).fromNow()}
                                    </span>
                                </div>
                                <div className="d-flex flex-row align-items-center flex-1">
                                    <span className="gris">
                                        {item.publicado_por
                                            ? item.publicado_por
                                                  .tipo_usuario_text
                                            : ""}
                                    </span>
                                </div>
                            </div>
                            <div className="d-none d-lg-flex flex-row align-items-center flex-3"></div>
                            <div className="d-flex flex-row">
                                {
                                    this.props.item && !this.props.item.valida && (
                                        <div className="pr-2 d-flex flex-row align-items-start">
                                            <span className="marron pr-2">{this.props.t('Pendiente')}</span>
                                            <div className="d-flex bg-marron"  style={{border:"1px solid",borderRadius:"100%",width:"1.3rem",height:"1.3rem"}}>
                                                <img src={clockIcon} className="m-auto" style={{width:"1rem",height:"1rem"}} />
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (this.props.me.is_superuser || this.props.item.publicado_por.id===this.props.me.id)&&(
                                        <Dropdown overlay={menu(this.props,this.onEditar)}
                                            trigger={['click']}
                                            placement="bottomRight" arrow>
                                            <div className="d-flex justify-content-center align-items-center"  style={{border:"1px solid ",borderRadius:"100%",width:"1.3rem",height:"1.3rem"}}>
                                                <a className="fas fa-ellipsis-h"
                                                    onClick={(e) => e.preventDefault()}/>
                                            </div>
                                        </Dropdown>
                                    )
                                }
                            </div>
                        </div>
                        {
                            this.state.editar ? (
                                <div className="px-4">
                                    <EditarPost me={this.props.me} item={item} onEditar={this.sendEditar} onCancelar={this.onCancelar} />
                                </div>
                            ):(
                                <React.Fragment>
                                    <div className="card-post-texto">
                                        <p className="mb-0">
                                            {item.descripcion}&nbsp;&nbsp;{this.props.verMas&&(
                                                <Link to={`/${this.getRuta()}/${id}`} className="btn-post-vermas">{this.props.t('Ver más')}</Link>)}
                                        </p>
                                    </div>
                                    <div className="d-flex  flex-column flex-1 align-items-center">
                                    {item.imagen && (
                                        <React.Fragment>
                                            <div className="col-16 col-md-8 col-lg-6 pb-3">
                                                <img src={item.imagen} 
                                                style={{opacity:`${!this.props.item.valida ? '20%': '100%' }`, maxWidth:"100%", position:"center"}}
                                                onClick={e=>this.setState({lightbox:true})}
                                                className="text-touch" 
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}
                                    </div>
                                    {this.state.lightbox&&(
                                        <Lightbox mainSrc={item.imagen} onCloseRequest={e=>this.setState({lightbox:false})} />
                                    )}
                                </React.Fragment>
                            )
                        }
                        {(this.props.me&&this.props.me.tipo_usuario===USUARIO)&&(
                            <div className="d-flex flex-row p-4" style={{backgroundColor:"rgb(230,230,230,0.5)"}}>
                                <div className="d-flex flex-1 align-items-center " >
                                    <span className="text-equal bold azul-gris-oscuro ml-4">{this.props.t('¿Te interesa esta oportunidad?')}</span>
                                </div>
                                <div className="d-flex flex-1 justify-content-end">
                                    <button 
                                        onClick={e=>this.onMeInteresa()} 
                                        type="button" 
                                        className="btn  btn-secondary-fill  mr-4"
                                        disabled={(this.props.me.tipo_usuario!==USUARIO)||this.props.item.vencida}>
                                        {this.props.t('Me interesa')}
                                    </button>
                                </div>
                            </div>
                        )}
                        {(item&&(item.archivos.length>0&&!this.state.editar))&&(
                            <React.Fragment>
                                <div className="d-flex flex-column flex-wrap px-5 mb-3 pt-2">
                                    <span className="text-sm azul-gris-oscuro bold">{this.props.t('Archivos para descargar')}</span>
                                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                </div>
                                <div className="pr-0 px-5 mb-2">
                                    <ArchivosUploader archivos={item.archivos} onlyLectura={true} />
                                </div>
                            </React.Fragment>
                        )}
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-2 align-items-center my-2 justify-content-start">
                                <div className="d-flex pl-3 pl-md-5 flex-row btn" onClick={e=>this.onVerMeGusta()}>
                                    <div className="px-1" >
                                        <i className={`text-equal2 fas fa-thumbs-up ${this.props.item.me_gusta ? 'marron': 'celeste-oscuro' }`}></i>
                                    </div>
                                    <span className="text-equal2 gris-oscuro px-1">
                                        {item.cantidad_me_gusta}
                                    </span>
                                </div>
                                <div className="px-4">
                                    <i className="text-equal2 fas fa-comments celeste-oscuro"></i>
                                    <span className="text-equal2 gris-oscuro ml-1">
                                        {item.cantidad_comentarios}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-1" style={{borderTop:"3px solid #E6E6E6",borderBottom:`${this.state.comentar?'3px':'0px'} solid #E6E6E6`}} >
                            <button
                                type="button" 
                                className={`btn-post-action azul-oscuro ${this.props.item.me_gusta ? 'marron bold': 'celeste-oscuro' }`}
                                onClick={this.onMeGusta}>
                                <i 
                                    className="fas fa-thumbs-up mr-1 py-2 me__gusta" 
                                    wobble={this.state.wabble} 
                                    onAnimationEnd={e=>this.setState({wabble:0})}></i>
                                {this.props.t('Me Gusta')}
                            </button>   
                            <button
                                className={`btn-post-action azul-oscuro ${
                                    this.props.comentar && "active"
                                }`}
                                onClick={(e) => {this.setComentar()}}
                            >
                                <i className={`fas fa-comments azul-oscuro mr-1 py-2`}></i>
                                {this.props.t('Ver comentarios')}
                            </button>
                        </div>
                    </div>
                </div>
                {((this.state.comentar && item.id)||(this.props.detalle && item.id)) &&(
                        <Com.Base publicacion={item} me={this.props.me} likes={true} comentar={!item.vencida} />
                    )}

                <div className="mb-4" />
                <ListarReacciones 
                    open={this.state.open} 
                    data={this.state.data}
                    cantidad={this.props.item.cantidad_me_gusta} 
                    onClose={e=>this.setState({open:false})}  />

            </Fragment>
        );
    }
}

export default withTranslation()(CardPost);
