import React from 'react';
import { withTranslation } from 'react-i18next';
import CardHistorias from '../../../Utils/Cards/CardHistoria';;
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Compartir from '../Compartir/compartir';

class Detalle extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            open:false,
        }
    }

    componentDidMount=()=>{
        const { me }= this.props;
        const id = this.props.match.params.id;
        this.props.leer(id);
    }
    
    onOpenModal = (item) => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };
    onCompartir = (values)=>{
        const { cohorte } = values;
        const id = this.props.item.id;
        this.props.compartir(id,{cohorte:cohorte.id});
        this.setState({open:false});
        this.setState({item:{}});
    }

    render(){
        const { item, loader } =this.props;
        return(
            <div className="social-layout mb-4 col-12 mt-0" >
                <LoadMask loading={loader} blur>
                    {(item&&item.id)&&(
                        <CardHistorias id={item.id} item={item} me={this.props.me} 
                            aprobar={this.props.aprobar} eliminar={this.props.eliminar} 
                            editar={this.props.editar} detalle={true} Compartir={this.onOpenModal}
                            meGusta={this.props.meGusta} 
                            removerMeGusta={this.props.removerMeGusta}
                            listarMeGustas={this.props.listarMeGustas}
                        />)}
                </LoadMask>
                <Modal key="modal-1" open={this.state.open} onClose={this.onCloseModal} >
                    <Compartir onSubmit={this.onCompartir} onCloseModal={this.onCloseModal}/>
                </Modal>
            </div>
        )
    }
}


export default withTranslation()(Detalle);