import { handleActions } from 'redux-actions';
import { api } from "api";
import moment from 'moment';
import _ from "lodash";
import { propTypes } from 'react-bootstrap-country-select';
import { ADMIN } from '../../../utility/constants';

const COLORES = ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"]
const LOADER = 'LOADER_DASHBOARD';
const DATA = 'DATA_DASHBOARD';
const PAGE = 'PAGE_DASHBOARD';
const SEARCH = 'SEARCH_DASHBOARD';

// Preguntas
const PREGUNTA1_1 = 'PREGUNTA1_1_DASHBOARD';
const PREGUNTA1_3 = 'PREGUNTA1_3_DASHBOARD';
const PREGUNTA1_3_1 = 'PREGUNTA1_3_1_DASHBOARD';
const PREGUNTA2 = 'PREGUNTA2_DASHBOARD';
const PREGUNTA3 = 'PREGUNTA3_DASHBOARD';
const PREGUNTA3_1 = 'PREGUNTA3_1_DASHBOARD';
const PREGUNTA4 = 'PREGUNTA4_DASHBOARD';
const PREGUNTA5 = 'PREGUNTA5_DASHBOARD';
const PREGUNTA6 = 'PREGUNTA6_DASHBOARD';
const PREGUNTA7 = 'PREGUNTA7_DASHBOARD';
const PREGUNTA7_1 = 'PREGUNTA7_1_DASHBOARD';
const PREGUNTA8 = 'PREGUNTA8_DASHBOARD';
const PREGUNTA9 = 'PREGUNTA9_DASHBOARD';

// Filtros
const FIRST_YEAR = 'FIRST_YEAR_DASHBOARD';
const LAST_YEAR = 'LAST_YEAR_DASHBOARD';
const PAIS = 'PAIS_DASHBOARD';
const COHORTE = 'COHORTE_DASHBOARD';
const EMPRESA = 'EMPRESA_DASHBOARD';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (data, type=DATA) => ({
    type,
    data
});

const setPage = (page) => ({
    type: PAGE,
    page
});

const setSearch = (search) => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listarTabla = (page=1) => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = { page };
    params.search = resource.search;
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica11 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta1_1', params).then((response) => {
        let data = ['Ventas'];
        let first_year = moment(resource.first_year).year();
        const last_year = moment(resource.last_year).year();
        while (first_year <= last_year) {
            data.push(`${first_year}`);
            first_year += 1
        }

        data = [[...data], ...response.data]
        dispatch(setData(data, PREGUNTA1_1));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica13 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta1_3', params).then((response) => {
        console.log("response ", response)
        dispatch(setData(response.data, PREGUNTA1_3));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica131 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta1_3_1', params).then((response) => {
        dispatch(setData([['Ingresos', ''], ...response.data], PREGUNTA1_3_1));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica2 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta2', params).then((response) => {
        dispatch(setData([['Empleados', ''], ...response.data], PREGUNTA2));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica3 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta3', params).then((response) => {
        dispatch(setData(response.data, PREGUNTA3));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica31 = () => (dispatch, getStore) => {
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (resource.cohorte) params.cohorte = resource.cohorte.id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta3_1', params).then((response) => {
        let data = ['Financiamiento'];
        let first_year = moment(resource.first_year).year();
        const last_year = moment(resource.last_year).year();
        while (first_year <= last_year) {
            data.push(`${first_year}`);
            first_year += 1
        }

        data = [[...data], ...response.data]
        dispatch(setData(data, PREGUNTA3_1));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica4 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta4', params).then((response) => {
        dispatch(setData(response.data, PREGUNTA4));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica5 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta5', params).then((response) => {
        if (response.data.length) {
            let data = [...response.data];
            data = data.map((item, index) => [...item, COLORES[index]])
            dispatch(setData([['Empresa', '', { role: 'style' }], ...data], PREGUNTA5));
        } else {
            dispatch(setData(null, PREGUNTA5));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica6 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta6', params).then((response) => {
        dispatch(setData(response.data, PREGUNTA6));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica7 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta7', params).then((response) => {
        dispatch(setData(response.data, PREGUNTA7));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica7_1 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta7_1', params).then((response) => {
        if (response.data.length) {
            let data = [...response.data];
            data = data.map((item, index) => [...item, COLORES[index]])
            dispatch(setData([['País', '', { role: 'style' }], ...data], PREGUNTA7_1));
        } else {
            dispatch(setData(null, PREGUNTA7_1));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica8 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta8', params).then((response) => {
        dispatch(setData([['Impuestos', ''], ...response.data], PREGUNTA8));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerGrafica9 = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const me = getStore().login.me;
    const resource = getStore().dashboard;
    const params = {};
    if (resource.first_year) params.first_year = moment(resource.first_year).format("YYYY");
    if (resource.last_year) params.last_year = moment(resource.last_year).format("YYYY");
    if (resource.pais) params.pais = resource.pais.name;
    if (me.tipo_usuario == ADMIN && cohorte) params.cohorte = JSON.parse(cohorte).id;
    if (resource.empresa) params.empresa = resource.empresa.id;
    dispatch(setLoader(true));
    api.get('dashboard/grafica_pregunta9', params).then((response) => {
        dispatch(setData([['Impuestos', ''], ...response.data], PREGUNTA9));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const graficas = () => (dispatch) => {
    dispatch(obtenerGrafica11());
    dispatch(obtenerGrafica13());
    dispatch(obtenerGrafica131());
    dispatch(obtenerGrafica2());
    dispatch(obtenerGrafica3());
    dispatch(obtenerGrafica31());
    dispatch(obtenerGrafica4());
    dispatch(obtenerGrafica5());
    dispatch(obtenerGrafica6());
    dispatch(obtenerGrafica7());
    dispatch(obtenerGrafica7_1());
    dispatch(obtenerGrafica8());
    dispatch(obtenerGrafica9());
}

const changeFirstYear = value => (dispatch) => {
    if (value) {
        dispatch(setData(value, FIRST_YEAR));
        dispatch(listarTabla());
        dispatch(graficas());
    }
    else{
        dispatch(setData({}, FIRST_YEAR));
        dispatch(listarTabla());
        dispatch(graficas());
    }
    
}

const changeLastYear = value => (dispatch) => {
    if (value) {
        dispatch(setData(value, LAST_YEAR));
        dispatch(listarTabla());
        dispatch(graficas());
    }
    else{
        dispatch(setData({}, LAST_YEAR));
        dispatch(listarTabla());
        dispatch(graficas());
    }
}

const changePais = value => (dispatch) => {
    dispatch(setData(value, PAIS));
    dispatch(listarTabla());
    dispatch(graficas());
}

const changeCohorte = value => (dispatch) => {
    dispatch(setData(value, COHORTE));
    dispatch(listarTabla());
    dispatch(graficas());
}

const changeEmpresa = value => (dispatch) => {
    dispatch(setData(value, EMPRESA));
    dispatch(listarTabla());
    dispatch(graficas());
}

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarTabla());
};


const descargarExcel = () => (dispatch, getStore) => {
    const resource = getStore().dashboard;
    const me = getStore().login.me;
    const cohorte = localStorage.getItem('cohorte');
    let endpoint = '/api/dashboard/?excel=true&';
    if (resource.first_year)
        endpoint += `first_year=${moment(resource.first_year).year()}&`;
    if (resource.last_year)
        endpoint += `last_year=${moment(resource.last_year).year()}&`;
    if (resource.pais)
        endpoint += `pais=${resource.pais.name}&`;
    if (me.tipo_usuario == ADMIN && cohorte)
        endpoint += `cohorte=${JSON.parse(cohorte).id}&`;
    if (resource.empresa)
        endpoint += `empresa=${resource.empresa.id}&`;

    endpoint += `auth_token=${localStorage.getItem("token")}`
    window.open(endpoint, '_blank');
}

export const actions = {
    listarTabla,
    graficas,
    changeFirstYear,
    changeLastYear,
    changePais,
    changeCohorte,
    changeEmpresa,
    searchChange,
    descargarExcel,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PREGUNTA1_1]: (state, { data }) => {
        return {
            ...state,
            pregunta1_1: data,
        };
    },
    [PREGUNTA1_3]: (state, { data }) => {
        return {
            ...state,
            pregunta1_3: data,
        };
    },
    [PREGUNTA1_3_1]: (state, { data }) => {
        return {
            ...state,
            pregunta1_3_1: data,
        };
    },
    [PREGUNTA2]: (state, { data }) => {
        return {
            ...state,
            pregunta2: data,
        };
    },
    [PREGUNTA3]: (state, { data }) => {
        return {
            ...state,
            pregunta3: data,
        };
    },
    [PREGUNTA3_1]: (state, { data }) => {
        return {
            ...state,
            pregunta3_1: data,
        };
    },
    [PREGUNTA4]: (state, { data }) => {
        return {
            ...state,
            pregunta4: data,
        };
    },
    [PREGUNTA5]: (state, { data }) => {
        return {
            ...state,
            pregunta5: data,
        };
    },
    [PREGUNTA6]: (state, { data }) => {
        return {
            ...state,
            pregunta6: data,
        };
    },
    [PREGUNTA7]: (state, { data }) => {
        return {
            ...state,
            pregunta7: data,
        };
    },
    [PREGUNTA7_1]: (state, { data }) => {
        return {
            ...state,
            pregunta7_1: data,
        };
    },
    [PREGUNTA8]: (state, { data }) => {
        return {
            ...state,
            pregunta8: data,
        };
    },
    [PREGUNTA9]: (state, { data }) => {
        return {
            ...state,
            pregunta9: data,
        };
    },
    [FIRST_YEAR]: (state, { data }) => {
        return {
            ...state,
            first_year: data,
        };
    },
    [LAST_YEAR]: (state, { data }) => {
        return {
            ...state,
            last_year: data,
        };
    },
    [PAIS]: (state, { data }) => {
        return {
            ...state,
            pais: data,
        };
    },
    [COHORTE]: (state, { data }) => {
        return {
            ...state,
            cohorte: data,
        };
    },
    [EMPRESA]: (state, { data }) => {
        return {
            ...state,
            empresa: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        count: 0,
        results: []
    },
    page: 1,
    search: '',
    pregunta1_1: null,
    pregunta1_3: null,
    pregunta1_3_1: null,
    pregunta2: null,
    pregunta3: null,
    pregunta3_1: null,
    pregunta4: null,
    pregunta5: null,
    pregunta6: null,
    pregunta7: null,
    pregunta7_1: null,
    pregunta8: null,
    pregunta9: null,
    first_year: moment(),
    last_year: moment(),
    pais: null,
    cohorte: null,
    empresa: null,
};

export default handleActions(reducers, initialState);
