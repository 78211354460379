import React, { Component } from 'react';
import Chart from "react-google-charts";
import { ADMIN } from '../../../../../utility/constants';
import Card from '../../../Utils/Cards/Card';

const Graficas = (props) => {
    const { me } = props;

    if (me && me.tipo_usuario == ADMIN) {
        return (
            <div className="d-flex flex-column flex-1">
                <div className="d-flex flex-md-row flex-column flex-1">
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">1-{props.t('Ventas')}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="ColumnChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={props.pregunta1_1 ? props.pregunta1_1 : [
                                            [props.t('Ventas'), props.t('Año actual')],
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            bar: { groupWidth: "35%" },
                                            legend: { position: 'top', maxLines: 3 },
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"],
                                        }}
                                        rootProps={{ 'data-testid': '8' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">1.3-{props.t('Empresas que esperan sobrepasar los ingresos del año pasado')}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="PieChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={[
                                            ['', ''],
                                            [props.t('Si'), props.pregunta1_3 ? props.pregunta1_3.si : 0],
                                            [props.t('No'), props.pregunta1_3 ? props.pregunta1_3.no : 0],
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            legend: { position: 'top', maxLines: 2 },
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"],
                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">1.3.1-{props.t('Porcentaje que las empresas esperan sobrepasar de ingresos')}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="ColumnChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={props.pregunta1_3_1 ? props.pregunta1_3_1 : [
                                            ['Ingresos', ''],
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            bar: { groupWidth: "35%" },
                                            legend: { position: 'none' },
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#538BAF"],
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>

                <div className="d-flex flex-md-row flex-column flex-1">
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">2-{props.t('Cantidad de empleados de las empresas')}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="LineChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={props.pregunta2 ? props.pregunta2 : [
                                            [props.t('Empleados'), ''],
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            legend: { position: 'none' },
                                            pointSize: 7,
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#538BAF"],
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">3-{props.t('Empresas que han obtenido algún financiamiento (por ejemplo, préstamos, líneas de crédito, etc.)')}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="PieChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={[
                                            ['', ''],
                                            [props.t('Si'), props.pregunta3 ? props.pregunta3.si : 0],
                                            [props.t('No'), props.pregunta3 ? props.pregunta3.no : 0],
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            legend: { position: 'top', maxLines: 2 },
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"],
                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">3.1-{props.t('Financiamiento obtenido')}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="ColumnChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={props.pregunta3_1 ? props.pregunta3_1 : [
                                            [props.t('Financiamiento'), props.t('Año actual')],
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            bar: { groupWidth: "35%" },
                                            legend: { position: 'top', maxLines: 3 },
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"],
                                        }}
                                        rootProps={{ 'data-testid': '8' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>

                <div className="d-flex flex-md-row flex-column flex-1">
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">4-{props.t('Empresas que han expandido su capacidad ( por ejemplo, capacidad de manufactura, edificio o puntos de venta)')}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="PieChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={[
                                            ['', ''],
                                            [props.t('Si'), props.pregunta4 ? props.pregunta4.si : 0],
                                            [props.t('No'), props.pregunta4 ? props.pregunta4.no : 0],
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            legend: { position: 'top', maxLines: 2 },
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"],
                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">5-{props.t('Top empresas que más han realizado negocios con empresas de su cohorte')}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="BarChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={props.pregunta5 ? props.pregunta5 : [
                                            [props.t('Empresa'), props.t('Negocios'), { role: 'style' }],
                                            ['', 0, '']
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            bar: { groupWidth: "50%" },
                                            legend: { position: 'none' },
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"],
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">6-{props.t('Empresas que han vendio a clientes en estados/regiones de su país')+'.'}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="PieChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={[
                                            ['', ''],
                                            [props.t('Si'), props.pregunta6 ? props.pregunta6.si : 0],
                                            [props.t('No'), props.pregunta6 ? props.pregunta6.no : 0],
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            legend: { position: 'top', maxLines: 2 },
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"],
                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>

                <div className="d-flex flex-md-row flex-column flex-1">
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">7-{props.t('Empresas que han exportado a otros países')+'.'}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="PieChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={[
                                            ['', ''],
                                            [props.t('Si'), props.pregunta7 ? props.pregunta7.si : 0],
                                            [props.t('No'), props.pregunta7 ? props.pregunta7.no : 0],
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            legend: { position: 'top', maxLines: 2 },
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"],
                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">7.1-{props.t('Top países con los que más hacen negocios las empresas de Growmotor')}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="BarChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={props.pregunta7_1 ? props.pregunta7_1 : [
                                            [props.t('Empresa'), props.t('Negocios'), { role: 'style' }],
                                            ['', 0, '']
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            bar: { groupWidth: "50%" },
                                            legend: { position: 'none' },
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"],
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">8-{props.t('Impuestos nacionales pagados')}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="LineChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={props.pregunta8 ? props.pregunta8 : [
                                            [props.t('Impuestos'), ''],
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            legend: { position: 'none' },
                                            pointSize: 7,
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#538BAF"],
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>

                <div className="d-flex flex-md-row flex-column flex-1">
                    <div className="d-flex flex-1 px-2">
                        <Card className="w-100" noPadding={true}>
                            <div className="d-flex flex-column flex-1 py-2 px-3">
                                <span className="text-sm bold azul">9-{props.t('Impuestos regionales pagados')}</span>
                                <div className="d-flex flex-1 w-100">
                                    <Chart
                                        width={'100%'}
                                        height={'30vh'}
                                        chartType="LineChart"
                                        loader={<div>{props.t('Cargando graficas')}</div>}
                                        data={props.pregunta9 ? props.pregunta9 : [
                                            [props.t('Impuestos'), ''],
                                        ]}
                                        options={{
                                            chartArea: { width: "70%" },
                                            legend: { position: 'none' },
                                            pointSize: 7,
                                            hAxis: {
                                                title: "",
                                                minValue: 0,
                                            },
                                            vAxis: {
                                                title: "",
                                            },
                                            colors: ["#538BAF"],
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex flex-column flex-1">
            <div className="d-flex flex-md-row flex-column flex-1">
                <div className="d-flex flex-1 px-2">
                    <Card className="w-100" noPadding={true}>
                        <div className="d-flex flex-column flex-1 py-2 px-3">
                            <span className="text-sm bold azul">1-{props.t('Ventas')}</span>
                            <div className="d-flex flex-1 w-100">
                                <Chart
                                    width={'100%'}
                                    height={'30vh'}
                                    chartType="ColumnChart"
                                    loader={<div>{props.t('Cargando graficas')}</div>}
                                    data={props.pregunta1_1 ? props.pregunta1_1 : [
                                        [props.t('Ventas'), props.t('Año actual')],
                                    ]}
                                    options={{
                                        chartArea: { width: "70%" },
                                        bar: { groupWidth: "35%" },
                                        legend: { position: 'top', maxLines: 3 },
                                        hAxis: {
                                            title: "",
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            title: "",
                                        },
                                        colors: ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"],
                                    }}
                                    rootProps={{ 'data-testid': '8' }}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="d-flex flex-1 px-2">
                    <Card className="w-100" noPadding={true}>
                        <div className="d-flex flex-column flex-1 py-2 px-3">
                            <span className="text-sm bold azul">2-{props.t('Cantidad de empleados')}</span>
                            <div className="d-flex flex-1 w-100">
                                <Chart
                                    width={'100%'}
                                    height={'30vh'}
                                    chartType="LineChart"
                                    loader={<div>{props.t('Cargando graficas')}</div>}
                                    data={props.pregunta2 ? props.pregunta2 : [
                                        [props.t('Empleados'), ''],
                                    ]}
                                    options={{
                                        chartArea: { width: "70%" },
                                        legend: { position: 'none' },
                                        pointSize: 7,
                                        hAxis: {
                                            title: "",
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            title: "",
                                        },
                                        colors: ["#538BAF"],
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="d-flex flex-1 px-2">
                    <Card className="w-100" noPadding={true}>
                        <div className="d-flex flex-column flex-1 py-2 px-3">
                            <span className="text-sm bold azul">3.1-{props.t('Financiamiento obtenido')}</span>
                            <div className="d-flex flex-1 w-100">
                                <Chart
                                    width={'100%'}
                                    height={'30vh'}
                                    chartType="ColumnChart"
                                    loader={<div>{props.t('Cargando graficas')}</div>}
                                    data={props.pregunta3_1 ? props.pregunta3_1 : [
                                        [props.t('Financiamiento'), props.t('Año actual')],
                                    ]}
                                    options={{
                                        chartArea: { width: "70%" },
                                        bar: { groupWidth: "35%" },
                                        legend: { position: 'top', maxLines: 3 },
                                        hAxis: {
                                            title: "",
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            title: "",
                                        },
                                        colors: ["#1446A0", "#538BAF", "#57B6F3", "#4D7ACB", "#88B2FC"],
                                    }}
                                    rootProps={{ 'data-testid': '8' }}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div className="d-flex flex-md-row flex-column flex-1">
                <div className="d-flex flex-1 px-2">
                    <Card className="w-100" noPadding={true}>
                        <div className="d-flex flex-column flex-1 py-2 px-3">
                            <span className="text-sm bold azul">8-{props.t('Impuestos nacionales pagados (IVA)')}</span>
                            <div className="d-flex flex-1 w-100">
                                <Chart
                                    width={'100%'}
                                    height={'30vh'}
                                    chartType="LineChart"
                                    loader={<div>{props.t('Cargando graficas')}</div>}
                                    data={props.pregunta8 ? props.pregunta8 : [
                                        [props.t('Impuestos'), ''],
                                    ]}
                                    options={{
                                        chartArea: { width: "70%" },
                                        legend: { position: 'none' },
                                        pointSize: 7,
                                        hAxis: {
                                            title: "",
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            title: "",
                                        },
                                        colors: ["#538BAF"],
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="d-flex flex-1 px-2">
                    <Card className="w-100" noPadding={true}>
                        <div className="d-flex flex-column flex-1 py-2 px-3">
                            <span className="text-sm bold azul">9-{props.t('Impuestos regionales pagados')}</span>
                            <div className="d-flex flex-1 w-100">
                                <Chart
                                    width={'100%'}
                                    height={'30vh'}
                                    chartType="LineChart"
                                    loader={<div>{props.t('Cargando graficas')}</div>}
                                    data={props.pregunta9 ? props.pregunta9 : [
                                        [props.t('Impuestos'), ''],
                                    ]}
                                    options={{
                                        chartArea: { width: "70%" },
                                        legend: { position: 'none' },
                                        pointSize: 7,
                                        hAxis: {
                                            title: "",
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            title: "",
                                        },
                                        colors: ["#538BAF"],
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Graficas;
