import React, { Component, Fragment } from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import Modal from 'react-responsive-modal';
import CardForm from '../../../Utils/Cards/CardForm';
import Wizard from '../../../Utils/wizard/Wizard';
import { NotificationManager } from "react-notifications";
import { withTranslation } from 'react-i18next';

import PasoUnoForm from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';
import ModalRespuestas from './ModalRespuestas';

import moment from 'moment';
import "moment-timezone";
import Swal from 'sweetalert2';


class ResolverEvaluacion extends Component {

    state = {
        step: 1,
        bloque: 0,
        bloque_name: '',
        item_modal: null,
        item_opcion: null,
        modalRespuesta: false,
        modalUsuarios: false,
        preguntas : [],
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const { existeEvaluacion, leerEvaluacion } = this.props;
        this.props.clearForm();
        leerEvaluacion(id, this.setBloqueName);
        existeEvaluacion(id, this.nextStep, this.setBloqueName);

        this.buscarResp = setInterval(() => this.actualizarRespuestas(), 15000);
    }

    componentWillUnmount() {
        this.props.clearForm();
        clearInterval(this.buscarResp);
    }

    actualizarRespuestas = () => {
        const id = this.props.match.params.id;
        const { step } = this.state;
        if (step == 2) {
            console.log("Buscar respuesta")
            this.props.existeEvaluacion(id, null, null, false, false);
        }
    }

    nextStep = () => {
        this.setState({ step: 2 });
        let bloque = this.state.bloque;
        const { item } = this.props;
        bloque = 0;
        const bloque_name = item.bloques[bloque].nombre
        this.setState({ bloque, bloque_name });
        window.scroll(0, 0);
    }

    setBloqueName = (bloque_name) => {
        this.setState({ bloque_name });
    }

    nextBloque = (paso = 1) => {
        console.log("paso ", paso)
        let bloque = this.state.bloque;
        let bloques = []
        let preguntas=[]
        if (paso == 1) {
            bloques = this.props.subForm.values.bloques;
            // const { values: { bloques } } = this.props.subForm;
        } else {
            bloques = this.props.formOficial.values.bloques;
            // const { values: { bloques } } = this.props.form;
        }
        const bloque_item = bloques ? bloques[bloque] : { pregutas: [] };
        let error = false;
        let error_mensaje = [this.props.t('¡Por favor, conteste todas las preguntas!')]
        for (const item of bloque_item.preguntas) {
            if (!item.opcion_elegida) {
                error = true;
                if (paso == 1 )
                    error_mensaje.push(`${this.props.t('¡La pregunta')} ${item.pregunta} ${this.props.t('no ha sido contestado!')}`);
                if (paso == 2)
                    error_mensaje.push(`${this.props.t('¡La pregunta')} ${item.pregunta.pregunta} ${this.props.t('no ha sido contestado!')}`);
                preguntas.push(item.id)
                //break;
                
                
            }
        }
        this.setState({preguntas})
        if (error) {
            for (const mensaje of error_mensaje)
                NotificationManager.error(mensaje, 'ERROR', 3000);
        } else {
            const { item } = this.props;
            if (bloque < item.cantidad_bloques) {
                bloque += 1;
                const bloque_name = item.bloques[bloque].nombre
                this.setState({ bloque, bloque_name });
                window.scroll(0, 0);
            }
        }

    }

    previousBloque = () => {
        let bloque = this.state.bloque;
        const { item } = this.props;
        if (bloque > 0) {
            bloque -= 1;
            const bloque_name = item.bloques[bloque].nombre
            this.setState({ bloque, bloque_name });
            window.scroll(0, 0);
        }
    }

    onSubmit = (values) => {

        console.log("values ", values);
        const opciones_elegidas = [];
        let error = false;
        let preguntas = []
        let error_mensaje = [this.props.t('¡Por favor, conteste todas las preguntas!')];
        for (const item of values.bloques) {
            for (const item2 of item.preguntas) {
                if (!item2.opcion_elegida) {
                    error = true;
                    error_mensaje.push(`${this.props.t('¡La pregunta')} ${item2.pregunta} ${this.props.t('no ha sido contestado!')}`);
                    //break;
                    preguntas.push(item2.id)
                }
                opciones_elegidas.push({
                    pregunta: item2.id,
                    opcion_elegida: parseInt(item2.opcion_elegida)
                })
                
            }

            if (error) {
                break;
            }
        }
        this.setState({preguntas})
        if (error) {
            for (const mensaje of error_mensaje)
                NotificationManager.error(mensaje, 'ERROR', 3000);
        } else {
            const data = {
                evaluacion: this.props.match.params.id,
                opciones_elegidas: opciones_elegidas
            }
            console.log('data ', data);
            this.props.guardarRespuesta(this.props.match.params.id, data, this.nextStep);
            window.scroll(0, 0);
        }
    }

    closeModal = () => {
        this.setState({ modalRespuesta: false, modalUsuarios: false });
    };

    openModalRespuesta = (item_modal, item_opcion) => {
        this.setState({ modalRespuesta: true, item_modal, item_opcion });
    };

    renderModalRespuesta = () => {
        const { item_modal, item_opcion } = this.state;
        return (
            <Modal
                open={this.state.modalRespuesta}
                onClose={this.closeModal}
                // closeIcon={false}
                // showCloseIcon={false}
                center
            >
                <div className="mt-5 mx-lg-5 mx-2">
                    <ModalRespuestas
                        loader={this.props.loader}
                        item={item_modal}
                        item_opcion={item_opcion}
                        t={this.props.t}
                    />
                </div>
            </Modal>
        )
    };

    crearRespuestaOficial = (values) => {
        const { me, item } = this.props;
        console.log("values ", values);
        const opciones_elegidas = [];
        let error = false;
        let preguntas = []
        let error_mensaje = [this.props.t('¡Por favor, conteste todas las preguntas!')];
        if (me.id===item.responsable) {
            for (const item of values.bloques) {
                for (const item2 of item.preguntas) {
                    if (!item2.opcion_elegida) {
                        error = true;
                        error_mensaje.push(`${this.props.t('¡La pregunta')} ${item2.pregunta.pregunta} ${this.props.t('no ha sido contestado!')}`);
                        preguntas.push(item2.id)
                        //break;
                    }
                    opciones_elegidas.push({
                        pregunta: item2.pregunta.id,
                        opcion_elegida: parseInt(item2.opcion_elegida)
                    })
                }

                if (error) {
                    break;
                }
            }
            this.setState({preguntas})
            if (error) {
                for (const mensaje of error_mensaje)
                    NotificationManager.error(mensaje, 'ERROR', 3000);
            } else {
                const data = {
                    evaluacion: this.props.match.params.id,
                    opciones_elegidas: opciones_elegidas
                }
                console.log('data ', data);
                this.props.guardarRespuestaOficial(data, this.props.match.params.id);
            }
        } else {
            console.log("ir al tablero");
            this.props.obtenerRespuestaOficial(this.props.match.params.id)
        }
    }

    getUsuariosEnviados = () => {
        const { item2 } = this.props;
        let total = 1;
        if (item2.respuestas && item2.respuestas.length) {
            if (item2.respuestas[0].otras_respuestas)
                total = item2.respuestas[0].otras_respuestas.length;
        }

        return total;
    }

    obtenerTotal = () => {
        let punteo = 0;
        const { formOficial } = this.props;
        if (formOficial && formOficial.values && formOficial.values.bloques) {
            const values = formOficial.values;
            for (const item of values.bloques) {
                for (const item2 of item.preguntas) {
                    if (item2.opcion_elegida)
                        punteo += parseInt(item2.opcion_elegida);
                }
            }
        }

        return punteo;
    }

    obtenerTotalBloque = (bloque_index) => {
        let punteo = 0;
        const { formOficial } = this.props;
        if (formOficial && formOficial.values && formOficial.values.bloques) {
            const values = formOficial.values;
            if (values.bloques && values.bloques.length) {
                const item = values.bloques[bloque_index];
                for (const item2 of item.preguntas) {
                    if (item2.opcion_elegida)
                        punteo += parseInt(item2.opcion_elegida);
                }
            }
        }
        return punteo;
    }

    render() {
        const { loader, item, item2, me } = this.props;
        const { step, bloque, bloque_name, preguntas } = this.state;
        return (
            <div className="mb-4 col-12 mt-0">
                {/* Modales */}
                {this.renderModalRespuesta()}
                <CardForm noPaddingXContent={true} noPaddingYContent={true}>
                    <div className="d-flex flex-row flex-1 align-items-center mx-4" style={{ height: "6rem" }}>
                        <Wizard step={step} totalSteps={2} steps={[this.props.t('Fase 1'), this.props.t('Fase 2')]} stepPositions={[0, 100]} />
                    </div>
                </CardForm>
                <CardForm noPaddingXContent={true} noPaddingYContent={true}>
                    <div className="d-flex flex-lg-row flex-column flex-1 align-items-lg-center px-lg-2 my-3">
                        <div className="d-flex flex-column flex-2">
                            <span className="text-sm">{this.props.t('Taller')}:</span>
                            <span className="celeste-oscuro bold text-sm">{item.publicacion ? item.publicacion.titulo : ''}</span>
                        </div>
                        <div className="d-flex flex-column flex-2">
                            <span className="text-sm">{this.props.t('Autoevaluación')}:</span>
                            <span className="celeste-oscuro bold text-sm">{item.nombre}</span>
                        </div>
                        {(step == 1) && (
                            <div className="d-flex flex-column flex-2">
                                <span className="text-sm">{this.props.t('Fecha a realizarse')}:</span>
                                <span className="celeste-oscuro bold text-sm">
                                    {item.publicacion ? me && me.timezone ? moment(item.publicacion.fecha_inicio).tz(me.timezone).format("MMM-DD-YYYY hh:mm a z") : moment(item.publicacion.fecha_inicio).format("MMM-DD-YYYY hh:mm a z") : ''}
                                </span>
                            </div>
                        )}
                        {(me && me.admin_empresa && step == 2) && (
                            <div className="d-flex flex-row flex-2 align-items-center">
                                <div className="flex-1">
                                    <span className="text-xs bold azul">{this.props.t('Resultado global de la Autoevaluación')}</span>
                                </div>
                                <div className="flex-1">
                                    <div className="circulo-puntos">
                                        <span className="blanco text-sm bold">{`${this.obtenerTotal()} PT`}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </CardForm>
                <CardForm noPaddingXContent={true} noPaddingYContent={true} col={11}>
                    <div className="d-flex flex-column flex-1 px-lg-2 my-3">
                        <div className="d-flex flex-lg-row flex-column mb-3">
                            <div className="d-flex flex-row flex-2">
                                <div className="mr-2">
                                    <i className="fas fa-file-alt azul-gris-oscuro" style={{ fontSize: "2rem" }}></i>
                                </div>
                                <div className="d-flex flex-column">
                                    <h5 className="mb-0 bold azul-gris-oscuro">{`${this.props.t('Bloque')} ${bloque + 1} ${this.props.t('de')} ${item.cantidad_bloques}`}</h5>
                                    <span className="gris-oscuro text-sm">{`${bloque_name}`}</span>
                                </div>
                            </div>
                            {(step == 2) && (
                                <div className="d-flex flex-row flex-1 my-auto">
                                    <div className="align-self-center flex-3">
                                        <span className="align-self-center celeste-oscuro text-sm">{this.props.t('Respuestas enviadas')}: {`${this.getUsuariosEnviados()}`}</span>
                                    </div>
                                    {(me && me.admin_empresa) && (
                                        <div className="align-self-center flex-2">
                                            <div className="ml-auto circulo-puntos bg-celeste-oscuro">
                                                <span className="blanco text-sm bold">{`${this.obtenerTotalBloque(bloque)} PT`}</span>
                                            </div>
                                        </div>
                                    )}
                                    {/* <button className="btn btn-option-one ml-2">Ver usuarios</button> */}
                                </div>
                            )}
                        </div>
                        <LoadMask loading={loader} blur>
                            {(this.state.step == 1 && (
                                <PasoUnoForm
                                    {...this.props}
                                    {...this.state}
                                    onSubmit={this.onSubmit}
                                    previousBloque={this.previousBloque}
                                    nextBloque={this.nextBloque}
                                    t={this.props.t}
                                />
                            ))}
                            {(this.state.step == 2 && (
                                <PasoDosForm
                                    item_inicial={item}
                                    item={item2}
                                    loader={false}
                                    {...this.state}
                                    openModal={this.openModalRespuesta}
                                    onSubmit={this.crearRespuestaOficial}
                                    previousBloque={this.previousBloque}
                                    nextBloque={this.nextBloque}
                                    me={this.props.me}
                                    t={this.props.t}
                                />
                            ))}
                        </LoadMask>
                    </div>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(ResolverEvaluacion)
