import React from 'react';
import { withTranslation } from 'react-i18next';
import Card from '../../../Utils/Cards/Card';

const ejemplo1 = require('../../../../../../assets/img/intercambio.png');
const ayudaBeneficios =(props)=>{

    return(
        <div className="social-layout mb-4 col-12 mt-0" >
            <Card>
                <div className="d-flex flex-1 flex-column">
                        <span className="gris h4 bold p-2 px-3">
                            {props.t('¿No sabes como escribir un Beneficio u oportunidad')}
                        </span>
                        <span className="gris h5 light">
                            {props.t('Ejemplos de como escribir un Beneficio u oportunidad')}
                        </span>
                        <img src={ejemplo1} style={{maxWidth:"100%", borderRadius:"1rem"}}></img>
                        <span className="gris h4 light p-2 -px-3">
                            {props.t('Te recomendamos ver este video explicativo para entender el proceso que lleva realizar')+'.'}
                        </span>
                        <br/>
                        <iframe
                            width="100%"
                            height="400"
                            src="https://www.youtube.com/embed/aYrUoC4iN9U"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <br/>
                    </div>
            </Card>
        </div>
    )
}

export default withTranslation()(ayudaBeneficios);


