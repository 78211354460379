import React, { Component } from 'react';
import Toolbar from '../../../Utils/Grid/Toolbar';
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { withTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import Swal from 'sweetalert2'

class ListarTarjetas extends Component {

    componentDidMount() {
        const { listarTarjetas} = this.props;
        listarTarjetas();
    }

    eliminar = (id) => {
        Swal.fire({
            title: this.props.t('¿Eliminar?'),
            text: this.props.t('¡No podrá revertir esta acción!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, eliminar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.eliminarTarjeta(id);
            }
        });
    };

    cambiarTarjeta = (id) => {
        Swal.fire({
            title: this.props.t('Cambiar tarjeta por defecto?'),
            text: this.props.t('¡Esta será tu tarjeta por defecto!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, cambiar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.cambiarTarjeta(id);
            }
        });
    };

    render() {
        const { data_tarjetas, loader, eliminarTarjeta,  } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                {/* Botones */}
                <Toolbar to='/mis-tarjetas/crear' textBtn={this.props.t('Agregar tarjeta')} />
                <Card>
                    <div className="px-lg-2 my-4">
                        <Grid hover striped data={{count: 0, results: data_tarjetas.data}} loading={loader} pagination={false} >
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataAlign="center"
                                width="15%"
                                dataFormat={cell => (
                                    <div className="d-flex justify-content-center">
                                        {(cell == data_tarjetas.default_source) ? (
                                            <div></div>
                                        ) : (
                                            <a title={this.props.t('Eliminar')} className="action action-gris px-1 py-2" style={{cursor: "pointer"}} onClick={() => this.eliminar(cell)}>
                                                <i className="fas fa-times gris"></i>
                                            </a>
                                        )}
                                    </div>
                                )}
                            >
                                {this.props.t('Acciones')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                dataFormat={cell => (
                                    <Checkbox checked={cell == data_tarjetas.default_source} onChange={() => this.cambiarTarjeta(cell)}></Checkbox>
                                )}
                            >
                                {this.props.t('Por defecto')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="brand"
                            >
                                {this.props.t('Tipo')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="last4"
                                dataFormat={cell => `**** **** **** ${cell}`}
                            >
                                {this.props.t('Número de tarjeta')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="exp_month"
                                dataFormat={(cell, row) => `${cell}/${row.exp_year}`}
                            >
                                {this.props.t('Fecha de expiración')}
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </Card>
            </div>

        )
    }
}

export default withTranslation()(ListarTarjetas)

