import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import Card from '../../../Utils/Cards/Card';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { ADMIN, GROWTH_ANGEL } from '../../../../../utility/constants';
import { withTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import { SwalExito, SwalError } from '../../../Utils/Notificaciones';
import ModalColaboradores from './ModalColaboradores';
import DetalleTab1 from './DetalleTab1';
import MasInfoTab from './MasInfoTab';
import ModalDrill from './ModalDrill';
import ModalResponsable from './ModalResponsable';
import ModalAsistencia from './ModalAsistencia';

import Swal from 'sweetalert2';
import moment from 'moment';
import _ from 'lodash';



class DetalleTaller extends Component {

    state = {
        modalColab: false,
        modalDrill: false,
        modalResponsable: false,
        modalAsistencia: false,
        item: null,
        archivos: [],
    }

    componentWillMount() {
        const id = this.props.match.params.id;
        const { leer, obtenerAsignados } = this.props;
        leer(id);
        //obtenerAsignados(id);
    }

    todasActivas = () => {
        const { item } = this.props;
        let bandera = true;
        if (item.evaluaciones && item.evaluaciones.length) {
            item.evaluaciones.forEach(item2 => {
                if (!item2.visible)
                    bandera = false;
            });
        }
        else {
            bandera = null;
        }

        return bandera;
    }

    activarEvaluacion = (id) => {
        Swal.fire({
            title: this.props.t('¿Activar evaluación?'),
            text: this.props.t('¡No podrá revertir esta acción!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, activar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                const idTaller = this.props.match.params.id;
                this.props.activarEvaluacion(id, idTaller);
            }
        });
    }

    ejemplo = () => {
        // SwalExito('Exito', 'Se ha activado la evaluación');
        SwalError('Error', 'Se ha activado la evaluación');
    }

    obtenerAcceso= ()=>{
        const { id } = this.props.match.params;
        this.props.obtenerAcceso(id,this.props.me);
    }
    generarLink =(nuevo)=>{
        const {id} = this.props.match.params;
        Swal.fire({
            title: !nuevo ? this.props.t('¿Generar link Zoom?'):this.props.t('¿Generar nuevo link Zoom?') ,
            text: !nuevo ? this.props.t('¡El link tiene una vigencia de 90 días!'):this.props.t('¡Esto elimina el link anterior, el nuevo tiene una vigencia de 90 días!') ,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, Generar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.generarLink(id);
            }
        });
    }

    activarEvaluaciones = () => {
        Swal.fire({
            title: this.props.t('¿Activar todas las evaluaciones?'),
            text: this.props.t('¡No podrá revertir esta acción!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, activar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                const idTaller = this.props.match.params.id;
                this.props.activarEvaluaciones(idTaller);
            }
        });
    };

    openModalColab = () => {
        this.props.obtenerColab();
        this.setState({ modalColab: true });
    };

    openModalDrill = (item) => {
        this.setState({ modalDrill: true, item });
    };

    openModalResponsable = (item) => {
        this.setState({ modalResponsable: true, item });
    };

    openModalAsistencia = (item) => {
        this.setState({ modalAsistencia: true, item });
    };

    closeModal = () => {
        this.setState({ modalColab: false, modalDrill: false, modalResponsable: false, modalAsistencia: false, item: null });
    };

    submitModal = (values) => {
        const itemTaller = this.props.item;
        const id = this.props.match.params.id;
        const asignados = [];
        if (values.seleccionados) {
            let total_asignados = 0;
            for (const item in values.seleccionados) {
                if (values.seleccionados[item]) {
                    asignados.push({ id: item.replace('s-', '') })
                    total_asignados += 1;
                }
            }
            total_asignados = total_asignados + itemTaller.asignados_taller.length;
            if (total_asignados > itemTaller.limite_participantes ) {
                SwalError(this.props.t('Error'), this.props.t('Ha sobrepasado el limite de participantes para este encuentro'));
            } else {
                console.log("asignados ", asignados);
                this.props.agregarAsignaciones(id, { asignados }, this.closeModal);
            }
        } else {
            SwalError(this.props.t('Error'), this.props.t('Seleccione al menos un colaborador'));
        }
    };

    eliminarAsignado = (id) => {
        Swal.fire({
            title: this.props.t('¿Eliminar colaborador del encuentro?'),
            text: this.props.t('¡No podrá revertir esta acción!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, eliminar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                const idTaller = this.props.match.params.id;
                this.props.eliminarAsignacion(id, idTaller);
            }
        });
    }

    anularAsistencia = (values) => {
        const id = this.props.match.params.id;
        const data = {...values, asistencia: values.asistencia.id};
        console.log("data ", data);
        if (values.nota_inasistencia) {
            this.props.anularAsistencia(id, data, this.closeModal)
        } else {
            SwalError(this.props.t('Error'), this.props.t('Especificar el motivo para anular la asistencia'));
        }
    }

    renderModalColaboradores = () => {
        const { item_modal, item_opcion } = this.state;
        return (
            <Modal
                open={this.state.modalColab}
                onClose={this.closeModal}
                center
            >
                <div className="mt-5 mx-lg-5 mx-0 px-3 px-lg-0">
                    <ModalColaboradores
                        loader={this.props.loader}
                        t={this.props.t}
                        colaboradores={this.props.colaboradores}
                        item={this.props.item}
                        cerrar={this.closeModal}
                        onSubmit={this.submitModal}
                        invitarNuevoColab={this.props.invitarNuevoColab}
                        cant_colaboradores={this.props.cant_colaboradores}
                    />
                </div>
            </Modal>
        )
    };

    renderModalDrill = () => {
        return (
            <Modal
                open={this.state.modalDrill}
                onClose={this.closeModal}
                center
            >
                <div className="px-5 pt-2">
                    <ModalDrill
                        loader={this.props.loader}
                        item={this.state.item}
                        cerrar={this.closeModal}
                        t={this.props.t}
                    />
                </div>
            </Modal>
        )
    };

    renderModalResponsable = () => {
        return (
            <Modal
                open={this.state.modalResponsable}
                onClose={this.closeModal}
                center
            >
                <div className="px-5 pt-2">
                    <ModalResponsable
                        id={this.props.match.params.id}
                        asignarResponsable={this.props.asignarResponsable}
                        loader={this.props.loader}
                        item={this.state.item}
                        cerrar={this.closeModal}
                        t={this.props.t}
                    />
                </div>
            </Modal>
        )
    };

    renderModalAsistencia = () => {
        return (
            <Modal
                open={this.state.modalAsistencia}
                onClose={this.closeModal}
                center
            >
                <div className="px-md-5 px-2 pt-2">
                    <ModalAsistencia
                        id={this.props.match.params.id}
                        anularAsistencia={this.anularAsistencia}
                        loader={this.props.loader}
                        item={this.state.item}
                        cerrar={this.closeModal}
                        t={this.props.t}
                    />
                </div>
            </Modal>
        )
    };

    subirMemo = (files) => {
        console.log("files ", files);
        const archivos = [];
        const archivos_nombres = [];
        for (let index = 0; index < files.length; index++) {
            archivos.push(files[index]);
            archivos_nombres.push({nombre: files[index].name});
        }

        const array = [];
        archivos.forEach((file, index) => {
            array.push({ "file": file, "name": `${index}`})
        });

        // construimos
        const data = {
            taller: this.props.match.params.id,
            archivos: archivos_nombres
        }

        console.log("data ", data);
        this.props.subirMemo(this.props.match.params.id, data, array);
    }

    eliminarArchivo = (index, idArchivo) => {
        console.log("idArchivo ", idArchivo)
        const { item: { memo_drill } } = this.props;
        Swal.fire({
            title: this.props.t('¿Eliminar el archivo del drill?'),
            text: this.props.t('¡No podrá revertir esta acción!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, eliminar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.eliminarArchivoMemo(memo_drill.id, idArchivo, this.props.match.params.id);
            }
        });
    }

    render() {
        const { item, loader, me } = this.props;
        return (
            <div className="social-layout mb-4 col-12 mt-0" style={{maxWidth:"100%"}} >
                {this.renderModalColaboradores()}
                {this.renderModalDrill()}
                {this.renderModalResponsable()}
                {this.renderModalAsistencia()}
                {((item&&item.id)&&(
                    <React.Fragment>
                    {(me && (me.tipo_usuario===ADMIN || me.tipo_usuario === GROWTH_ANGEL)) ? (
                        <Tabs
                            defaultActiveKey="detalle"
                            onChange={this.callback}
                            tabPosition="top"
                        >
                            <Tabs.TabPane tab={this.props.t("Evento")} key="detalle">
                                <LoadMask loading={loader} blur fixed={true}>
                                    <DetalleTab1
                                        {...this.props}
                                        obtenerAcceso={this.obtenerAcceso}
                                        openModalColab={this.openModalColab}
                                        generarLink={this.generarLink}
                                        eliminarAsignado={this.eliminarAsignado}
                                        activarEvaluaciones={this.activarEvaluaciones}
                                        activarEvaluacion={this.activarEvaluacion}
                                        todasActivas={this.todasActivas}
                                        subirMemo={this.subirMemo}
                                        eliminarArchivo={this.eliminarArchivo}
                                    />
                                </LoadMask>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={this.props.t("Mas información")} key="info_admin">
                                <LoadMask loading={loader} blur fixed={true}>
                                    <MasInfoTab
                                        {...this.props}
                                        openModalDrill={this.openModalDrill}
                                        openModalResponsable={this.openModalResponsable}
                                        openModalAsistencia={this.openModalAsistencia}
                                    />
                                </LoadMask>
                            </Tabs.TabPane>
                        </Tabs>
                    ):(
                        <LoadMask loading={loader} blur fixed={true}>
                            <DetalleTab1
                                {...this.props}
                                obtenerAcceso={this.obtenerAcceso}
                                openModalColab={this.openModalColab}
                                generarLink={this.generarLink}
                                eliminarAsignado={this.eliminarAsignado}
                                activarEvaluaciones={this.activarEvaluaciones}
                                activarEvaluacion={this.activarEvaluacion}
                                todasActivas={this.todasActivas}
                                subirMemo={this.subirMemo}
                                eliminarArchivo={this.eliminarArchivo}
                            />
                        </LoadMask>
                    )}

                    </React.Fragment>
                ))}
            </div>
        )
    }
}

export default withTranslation()(DetalleTaller)
