import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import PerfilForm from './PerfilForm';
import moment from 'moment';
import { GROWTH_ANGEL } from '../../../../../utility/constants';

import { withTranslation } from 'react-i18next';


class EditarPerfil extends Component {

    state = {
        avatar: null
    }

    componentDidMount() {
        const { leer } = this.props;
        leer();
    }

    setAvatar = (avatar) => {
        this.setState({ avatar });
    }

    onSubmit = (values) => {
        const data = {...values};
        if (data.fecha_nacimiento)
            data.fecha_nacimiento = moment(data.fecha_nacimiento).format("YYYY-MM-DD");
        if (data.pais){
            data.pais = data.pais.name;
        }
        if (data.tipo_usuario == GROWTH_ANGEL) {
            if (data.idiomas && data.idiomas.length)
                data.idiomas = data.idiomas.map(item => ({...item, tipo: item.value}));
        }
        if (this.state.avatar != null) {
            this.props.editar(data, [{"file": this.state.avatar, "name": "avatar"}])
        } else {
            this.props.editar(data, [])
        }
    }

    render() {
        const { loader, item, me } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <PerfilForm item={item} me={me} onSubmit={this.onSubmit} setAvatar={this.setAvatar} t={this.props.t} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(EditarPerfil)