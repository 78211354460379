import React, { useEffect, useState } from 'react';
import { Page, Text, View, Image } from "@react-pdf/renderer";


const ColaboradoresPage = ({ fondoPadre, encabezadoAll, respuesta, evaluacion, bloque, bloqueNombre, textoPreguntas, linea, preguntasHTML, contenedor, t }) => {
  const info = 
  respuesta.respuestas
    ? respuesta.respuestas.map((item, index) => {
        return (
        ((item.usuario.admin_empresa && !item.entrega_elegida) && (
                <View key={`colaboradoresN-${index}`} style={{paddingTop: '20px', paddingLeft: '20px', alignItems:'center', flexDirection: 'row'}}>
                    <View style={{marginRight: '30px'}}><Image style={{width:'50px'}} src={item.empresa.logo} alt={'logo'}></Image></View>
                    <View>
                        <Text style={{fontSize: '12px', color: '#5F5F5F'}}>{t('Colaboradores')}:</Text>
                        <Text style={{fontSize: '12px', color: '#538BAF'}}>{item.empresa ? item.empresa.nombre : ''}</Text>
                    </View>
                </View>
            ))
        )
    })
  : ""
  return (
            /* DATOS DEL CEO */
            (evaluacion.bloques)
                ? evaluacion.bloques.map((a, indexB) => {
                    return(
                        <Page
                            size="A4"
                            style={fondoPadre}
                        >
                            {encabezadoAll}
                            <View style={contenedor}>
                                {indexB===0 && info}
                                <View key={`colab-${indexB}`} style={{padding: '20px'}}>
                                    <View>
                                        {/* bloque */}
                                        <Text style={bloque}>{t('Bloque')} {indexB + 1} {t('De')} {evaluacion.cantidad_bloques}</Text>
                                        <Text style={bloqueNombre}>{a.nombre}</Text>
                                            {/* pregunta */}
                                            {a.preguntas ? a.preguntas.map( (res, indexP) => {
                                                return(
                                                    <View wrap={ indexP > 14 }>
                                                        <Text style={textoPreguntas} key={`colabView-${indexP}`}>{indexP + 1}{". "}{res.pregunta}</Text>
                                                        <View style={linea}></View>

                                                        {/* Inicio */}
                                                        {respuesta.respuestas
                                                            ? respuesta.respuestas.map((item, indexPG) => {
                                                                return (
                                                                ( (!item.usuario.admin_empresa && !item.entrega_elegida) && (
                                                                    item.respuestas.map((x) => {
                                                                        if (x.pregunta.bloque_nombre === a.nombre && x.pregunta.id === res.id) {
                                                                            return (
                                                                                <View>
                                                                                    <Text style={{fontSize: '12px', color: '#538BAF', marginTop:'2px'}}>
                                                                                        {t('Nombre') + ": " +item.usuario.nombres}
                                                                                    </Text>
                                                                                    {preguntasHTML(x.opcion_elegida, indexPG, t)}
                                                                                </View>
                                                                                //<Html>{html(x.opcion_elegida)}</Html>
                                                                            )
                                                                        }
                                                                        })
                                                                    ))
                                                                )
                                                            })
                                                        : ""}
                                                        {/* Fin */}
                                                    </View>
                                                )
                                            }):""}
                                    </View>
                                </View>
                            </View>
                        </Page>
                    )
                }): ''
  )
}

export default ColaboradoresPage