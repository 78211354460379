import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { renderTextArea } from "../../Utils/renderField/renderField"
import { withTranslation } from "react-i18next";
import FileImgUploader from '../../Utils/FileUploader/FileImgUploader';
import { Link } from  'react-router-dom';

const logoUpload = require('../../../../../assets/img/iconos/12-Img.png');
const avatar = require("../../../../../assets/img/avatar.png");
const imageIcon = require('../../../../../assets/img/image-solid.svg');

const validators = (values, props) => {
    const errors = {};
    if (!values.descripcion) {
        errors.descripcion = `${props.t('Campo requerido')}`;
    }
    if (values.descripcion) {
        let texto = String(values.descripcion);
        const conteo = texto.length;
        const porcentaje = conteo / 2.8;
        props.setProgress(conteo, porcentaje);
    }
    return errors;
};

const IntercambioForm = (props) => {
    const { handleSubmit, me, expandir, setImagen, imagen } = props;
    return (
        <form onSubmit={handleSubmit} >
            <div className="card card-content py-3 mb-4">
                <div className="d-flex flex-column flex-1 px-4">
                    <div className="d-flex flex-row align-content-center" style={{cursor:"pointer"}} onClick={(e)=>{
                        props.onExpandir()
                    }}>
                        <img
                            className="perfil-img mr-2"
                            style={{ height: "2.1rem", width: "2.1rem" }}
                            src={me&&me.avatar ? me.avatar : avatar}
                            alt="Perfil"
                        />
                        <span className="gris h4">
                            {props.t('En esta sección puedes ofrecer un recurso útil para los demás o puedes solicitar ayuda si tienes alguna necesidad')+'.'}
                        </span>
                    </div>
                    {expandir && (
                        <div style={{ transition: "all .5s ease" }}>
                            <div className="d-flex flex-column flex-1 p-2">
                                <Field
                                    name="descripcion"
                                    component={renderTextArea}
                                    rows={5}
                                    maxLength={280}
                                    progress={props.progress}
                                    style={{ borderRadius: "10px" }}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    )}
                    <div className="d-flex  flex-column flex-1 align-items-center">
                        {
                            (imagen&& imagen.loaded)&& (
                                <React.Fragment>
                                    <div className="d-flex flex-row justify-content-end col-12 col-md-8 col-lg-6"
                                        style={{position:"relative", bottom:"-1.8rem", cursor:"pointer",zIndex:"1"}}
                                        onClick={()=>props.removeImagen()}>
                                        <li className="blanco bg-gris p-2 fa fa-times fa-lg"  style={{borderRadius:"5px"}}/>
                                    </div>
                                    <div className="d-flex col-12 col-md-8 col-lg-6 pb-3" >
                                        <img src={imagen&&imagen.imageSrc} style={{maxWidth:"100%"}}/>
                                    </div>

                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
                <span
                    className={`${!expandir ? "mt-4" : ""}`}
                    style={{ borderTop: "3px solid #E6E6E6" }}
                />
                <div className="d-flex flex-row  flex-grow-1 px-4 pt-2">
                    <div className="d-flex flex-row w-50 align-items-center align-top">
                        <div className="d-flex align-items-center">
                            <FileImgUploader width="1.3rem" height="1.2rem" imageSrc={imageIcon} onFileChange={setImagen} />
                            {
                                props.editar && props.editar ? (
                                    <span className="gris h5 pl-2" >{props.t('Reemplazar Imagen')}</span>

                                ):(
                                    <span className="gris h5 pl-2">{props.t('Foto')}</span>
                                )
                            }

                        </div>
                    </div>
                    <div className="d-flex flex-row w-50 justify-content-end align-items-center">
                        <Link to="ayuda_intercambios">
                            <i className="fas fa-question question-button"></i>
                        </Link>
                        <button className="btn btn-primary ml-2" type="submit" disabled={!props.expandir}>
                            {props.t('Publicar')}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "IntercambioForm",
    asyncBlurFields: [],
    validate: validators,
})(withTranslation()(IntercambioForm));
