import React, { Component } from 'react';
import CountrySelect from 'react-bootstrap-country-select';
import Select, { Async } from 'react-select';
import { DatePicker } from 'antd';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { ADMIN } from '../../../../utility/constants';
import { COUNTRIES } from '../../../../utility/paises';

import { api } from "api";
import moment from 'moment';

const getCohortes = (search) => {
    return api.get("cohorte/obtener_cohortes", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getEmpresas = (search) => {
    return api.get("empresa", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

class ToolbarDashboard extends Component {
    render() {
        const { changeFirstYear, changeLastYear, changePais, changeCohorte, changeEmpresa, me } = this.props;

        return (
            <div className="d-flex flex-1 row px-3 mb-4 mt-2">
                <div className="col-md-3 col-12 px-2 d-flex flex-row align-items-center">
                    <DatePicker picker="year" value={moment(this.props.first_year)} onChange={changeFirstYear} />
                    <span className="text-sm blanco px-2">{this.props.t('Hasta')}</span>
                    <DatePicker picker="year" value={moment(this.props.last_year)} onChange={changeLastYear} />
                </div>
                {(me && me.tipo_usuario == ADMIN) && (
                    <div className="col-md-3 col-12 px-2 mt-2 mt-md-0">
                        <CountrySelect
                            value={this.props.pais}
                            onChange={changePais}
                            placeholder={this.props.t('País')}
                        />
                    </div>
                )}
                {(me && me.tipo_usuario == ADMIN) && (
                    <div className="col-md-3 col-12 px-2 mt-2 mt-md-0">
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.empresa}
                            isSearchable={true}
                            loadOptions={getEmpresas}
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["nombre"]}
                            type="text"
                            onChange={changeEmpresa}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames(
                                "react-select-container w-100"
                            )}
                            defaultOptions={true}
                            placeholder={this.props.t('Empresa')}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default withTranslation()(ToolbarDashboard)
