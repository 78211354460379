import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import TarjetaForm from './TarjetaForm';
import moment from 'moment';
import { withTranslation } from  'react-i18next';

class CrearEmpresa extends Component {

    componentWillMount() {
        const id = this.props.match.params.id;
        if (id)
            this.props.leer(id);
    }

    onSubmit = (values) => {
        const data = {...values};
        data.month = moment(data.fecha_tarjeta).month();
        data.year = moment(data.fecha_tarjeta).year();
        console.log("dfd ", data);
        this.props.agregarTarjeta(data)
    }

    render() {
        const { loader } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <TarjetaForm onSubmit={this.onSubmit} t={this.props.t} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
export default withTranslation()(CrearEmpresa);