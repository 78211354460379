import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderEditorField } from '../../../Utils/renderField/renderField';
import ArchivosUploader from '../../../Utils/ArchivoUploader';

import moment from 'moment';
import "moment-timezone";


let AsignarSlot = props => {
    const { handleSubmit, cerrar, archivos, slot, me } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <span className="text-sm">{props.t('Reservación de slot')}</span>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="fecha" className="m-0">{props.t('Fecha')}</label>
                        <label className="celeste-oscuro">{slot ? me && me.timezone ? moment(slot.fecha_inicio).tz(me.timezone).format("MMM-DD-YYYY") : moment(slot.fecha_inicio).format("MMM-DD-YYYY") : ''}</label>
                    </div>
                    <div className="d-flex flex-column justify-content-end flex-1 p-2">
                        <label htmlFor="hora" className="m-0">{props.t('Hora')}</label>
                        <label className="celeste-oscuro">{slot ? me && me.timezone ?  moment(slot.fecha_inicio).tz(me.timezone).format("hh:mm a z") : moment(slot.fecha_inicio).format("hh:mm a z") : ''}</label>
                    </div>
                </div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <Field name="descripcion" height="15%" height2="100%" component={renderEditorField} />
                </div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <span className="text-sm azul-gris-oscuro bold">{props.t('Archivos para subir')}</span>
                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                </div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <ArchivosUploader
                        archivos={archivos}
                        upload={props.setArchivos}
                        eliminar={props.eliminarArchivo}
                        btnLabel={props.t('Cargar archivos')}
                    />
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <button type="button" onClick={cerrar} className="btn btn-secondary mr-3">{props.t('Cancelar')}</button>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <button type="button" onClick={cerrar} className="btn btn-secondary flex-1">{props.t('Cancelar')}</button>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )
}

const selector = formValueSelector('AsignarSlotForm');

AsignarSlot = reduxForm({
    form: 'AsignarSlotForm',
    asyncBlurFields: [],
})(AsignarSlot);

const mstp = state => {
    let archivos = selector(state, 'archivos');
    archivos = archivos ? archivos : [];

    return {
        archivos,
    }
};

export default connect(mstp, null)(AsignarSlot);
