import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/comentarios';

import ComentarioForm1 from './FormPadre';
import ComentarioForm2 from './FormHijo';
import CardComentario from './CardComentarios';
import Comentarios from './Comentarios';
import ComentariosEditar from './ComentariosEditar';

const ms2p = (state) => {
    return {
        ...state.comentarios,
    };
};

const md2p = { ...actions };

const FormPadre = connect(ms2p, md2p)(ComentarioForm1);
const FormHijo = connect(ms2p, md2p)(ComentarioForm2);
const Card = connect(ms2p, md2p)(CardComentario);
const Base = connect(ms2p, md2p)(Comentarios);
const Editar = connect(ms2p,md2p)(ComentariosEditar);

export default {
    FormPadre,
    FormHijo, 
    Card,
    Base,
    Editar,
}


