import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { actions } from '../../../../../redux/modules/empresas/colaboradoresEditar';
import EditarColaborador from './EditarColaborador';

const ms2p = (state) => {
    return {
        ...state.colaboradoresEditar,
        me: state.login.me
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(EditarColaborador);
