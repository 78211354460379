import { handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import { getI18n } from 'react-i18next';

const LOADER = 'LOADER_GROWTH';
const ITEM_DATA = 'ITEM_GROWTH';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});
const setItem = item => ({
    type: ITEM_DATA,
    item,
});

// ------------------------------------
// Actions
// ------------------------------------
const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('FormGrowthAngel', {}));
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`growth_angels/${id}`).then((response) => {
        if(response.is_active){
            dispatch(push('/'));
            NotificationManager.error(getI18n().t('Ya estas registrado'), getI18n().t('ERROR'), 3000);
        }
        dispatch(setItem(response));
        dispatch(initializeForm('FormGrowthAngel', response));
    }).catch((error) => {
        let mensaje = getI18n().t('Error usuario no existe o ya se ha registrado');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        dispatch(push('/'))
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const onSubmit = (id, data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments(`growth_angels/${id}/registro_growth_angel`, data, attachments).then(() => {
        NotificationManager.success(getI18n().t('Te has registrado exitosamente!'), getI18n().t('Éxito'), 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        localStorage.removeItem('token');
        dispatch(push('/login'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al registrar Growth Angel');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    })
};
export const actions = {
    leer,
    onSubmit,
};



// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
};


export const initialState = {
    loader: false,
    item: {},

};


export default handleActions(reducers, initialState);
