import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import {Login, Registro, RegistroColaborador, CambioPwd} from './common/components/LoginRegister';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';
import 'react-image-lightbox/style.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
import RegistroGrowthAngel from './common/components/LoginRegister/RegistroGrowthAngels';
import RegistroAmigosGrowmotor from './common/components/LoginRegister/RegistroAmigosGrowmotor';
import { Dashboard } from './common/components/App/Home';
import { DetalleTaller, ListarTalleres } from './common/components/App/Talleres';
import { DetalleMentoria, ListarMentorias } from './common/components/App/Mentorias'
import { ResolverEvaluacion, TableroResultados, DetalleEvaluacion, DescargarResultado } from './common/components/App/Evaluaciones';
import  Historias  from './common/components/App/Historias';
import  Intercambios  from './common/components/App/Intercambios';
import { CrearColaborador, ListarColaboradores, EditarColaborador } from './common/components/App/Colaboradores';
import { EditarPerfil, EditarPerfilEmpresa, VerPerfil, VerPerfilEmpresa, Notificaciones, GrowthKPIs, PagoMembresia, ListarTarjetas, CrearTarjeta } from './common/components/App/Perfil';
import { Busquedas } from './common/components/App/Busquedas';
import EncuentroGuerreros from './common/components/App/Guerreros';
import ayudaHistorias from './common/components/App/Historias/Ayuda';
import ayudaIntercambios from './common/components/App/Intercambios/Ayuda';
import ayudaBeneficios from './common/components/App/Beneficios&Oportunidades/Ayuda';
import FamilyMeeting from './common/components/App/FamilyMeeting';
import Beneficios  from './common/components/App/Beneficios&Oportunidades';
import CalificacionesEventos from './common/components/App/Reportes/CalificacionEventos';

require('../style/index.css');

try {require('../assets/sw')}catch (e) {
    console.error('sw ', e)
}

try {require('../assets/firebase-messaging-sw')}catch (e) {
    console.error('Firebase messaging', e)
}

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/registro" component={Registro} />
                <Route exact path="/registro/:id" component={Registro} />
                <Route exact path="/registro/growth_angel/:id" component={RegistroGrowthAngel}/>
                <Route exact path="/registro_colaborador/:id" component={RegistroColaborador}/>
                <Route exact path="/cambio_contraseña/:id" component={CambioPwd.Recuperar} />
                <Route exact path="/cambio_contraseña/" component={CambioPwd.Solicitar} />
                <Route exact path="/registro_amigos_growmotor/:id" component={RegistroAmigosGrowmotor} />
                <ProtectedRoute exact path="/" component={Dashboard} ocultarLateral={true} />

                {/* Ayuda */}
                <ProtectedRoute exact path="/ayuda_historias" component={ayudaHistorias}/>
                <ProtectedRoute exact path="/ayuda_intercambios" component={ayudaIntercambios}/>
                <ProtectedRoute exact path="/ayuda_beneficios" component={ayudaBeneficios}/>

                {/* Talleres */}
                <ProtectedRoute exact path="/talleres" component={ListarTalleres} />
                <ProtectedRoute exact path="/taller/:id" component={DetalleTaller} />
                {/* Mentorias */}
                <ProtectedRoute exact path="/mentorias" component={ListarMentorias} />
                <ProtectedRoute exact path="/mentoria/:id" component={DetalleMentoria} />

                {/* Encuentro Guerreros */}
                <ProtectedRoute exact path="/panel" component={EncuentroGuerreros.Listar} />
                <ProtectedRoute exact path="/panel/:id" component={EncuentroGuerreros.Detalle} />

                {/* Reuniones Familiares */}
                <ProtectedRoute exact path="/family_meetings" component={FamilyMeeting.Listar} />
                <ProtectedRoute exact path="/family_meeting/:id" component={FamilyMeeting.Detalle} />

                {/* Evaluaciones */}
                <ProtectedRoute exact path="/evaluacion/:id" component={ResolverEvaluacion} ocultarLateral={true} ocultarMenu={true} />
                <ProtectedRoute exact path="/tablero/:id" component={TableroResultados} ocultarLateral={true} ocultarMenu={true} />
                <ProtectedRoute exact path="/descargar_resultado/:id" component={DescargarResultado} ocultarLateral={true} ocultarMenu={true} />
                <ProtectedRoute exact path="/evaluacion/:id/detalle/:idRespuesta" component={DetalleEvaluacion} ocultarLateral={true} ocultarMenu={true} />

                {/* Rutas Historias */}
                <ProtectedRoute exact path="/historias" component={Historias.Listar} />
                <ProtectedRoute exact path="/historia/:id" component={Historias.Detalle}/>

                {/* Rutas Intercambios */}
                <ProtectedRoute exact path="/intercambios" component={Intercambios.Listar} />
                <ProtectedRoute exact path="/intercambio/:id" component={Intercambios.Detalle} />

                {/* Rutas Beneficios */}
                <ProtectedRoute exact path="/beneficios" component={Beneficios.Listar} />
                <ProtectedRoute exact path="/beneficio/:id" component={Beneficios.Detalle}/>

                {/* Configuración de la empresa */}
                <ProtectedRoute exact path="/colaboradores" component={ListarColaboradores} ocultarLateral={true} />
                <ProtectedRoute exact path="/colaborador/crear" component={CrearColaborador} ocultarLateral={true} />
                <ProtectedRoute exact path="/colaborador/:id/editar" component={CrearColaborador} ocultarLateral={true}  />
                <ProtectedRoute exact path="/colaboradores/:id/editar" component={EditarColaborador} ocultarLateral={true}  />

                <ProtectedRoute exact path="/busquedas" component={Busquedas}/>
                <ProtectedRoute exact path="/notificaciones" component={Notificaciones} ocultarLateral={true}/>
                <ProtectedRoute exact path="/pago_membresia" component={PagoMembresia} ocultarLateral={true}/>
                <ProtectedRoute exact path="/pago_membresia/:obligatorio" component={PagoMembresia} ocultarLateral={true} ocultarMenu={true}/>
                <ProtectedRoute exact path="/examen_kpis" component={GrowthKPIs} ocultarLateral={true}/>
                <ProtectedRoute exact path="/examen_kpis/:obligatorio" component={GrowthKPIs} ocultarLateral={true} ocultarMenu={true}/>

                {/* Perfil */}
                <ProtectedRoute exact path="/mi-perfil" component={VerPerfil} ocultarLateral={true}/>
                <ProtectedRoute exact path="/perfil/:id" component={VerPerfil} ocultarLateral={true}/>
                <ProtectedRoute exact path="/mi-perfil/editar" component={EditarPerfil} ocultarLateral={true}/>
                <ProtectedRoute exact path="/mi-empresa" component={VerPerfilEmpresa} ocultarLateral={true}/>
                <ProtectedRoute exact path="/empresa/:id" component={VerPerfilEmpresa} ocultarLateral={true}/>
                <ProtectedRoute exact path="/mi-empresa/editar" component={EditarPerfilEmpresa} ocultarLateral={true}/>
                <ProtectedRoute exact path="/mis-tarjetas" component={ListarTarjetas} ocultarLateral={true}/>
                <ProtectedRoute exact path="/mis-tarjetas/crear" component={CrearTarjeta} ocultarLateral={true}/>

                {/* Reportes */}
                <ProtectedRoute exact path="/reporte/calificaciones_eventos" component={CalificacionesEventos.Listar} ocultarLateral={true}/>
                <ProtectedRoute exact path="/reporte/detalle_calificacion_evento/:id" component={CalificacionesEventos.Detalle} ocultarLateral={true}/>

                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
