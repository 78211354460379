import React, { Component } from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { IDIOMAS_SISTEMA } from "../../../../utility/constants";

const Logo = require("assets/header/Logo.png");

import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const menu = (changeLanguage) => {
    return (
        <Menu>
            {IDIOMAS_SISTEMA.map((item) => (
                <Menu.Item key={item.key} onClick={(e) => changeLanguage(item)}>
                    {item.name}
                </Menu.Item>
            ))}
        </Menu>
    );
};

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            idioma: "English",
            key: "en",
        };
    }
    componentWillMount = () => {
        const idioma = localStorage.getItem("idioma");
        if (idioma) this.changeLanguage(JSON.parse(idioma));
    };
    changeLanguage(item) {
        const { i18n } = this.props;
        if (item) {
            i18n.changeLanguage(item.key);
            this.setState({ idioma: item.name, key: item.key });
            localStorage.setItem("idioma", JSON.stringify(item));
        }
    }

    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };
    render() {
        const { navToggle, logOut, user, title, subtitle } = this.props;

        return (
            <nav className="header__container justify-content-center header__sx__index">
                <div className="col-12 col-lg-6 d-flex">
                    <div className="d-flex flex-1 align-items-center">
                        <div className="d-flex mr-3 ml-2">
                            <Link to="/">
                                <img
                                    style={{ width: "87px", height: "30px" }}
                                    src={Logo}
                                />
                            </Link>
                        </div>
                        <span className="ml-sm-2 text-md ml-sm-2 blanco regular">
                            {title}{" "}
                            <span className="text-md celeste-oscuro regular">
                                {subtitle}
                            </span>
                        </span>
                        <div className="d-flex flex-1 justify-content-end blanco">
                            <Dropdown
                                overlay={menu(this.changeLanguage.bind(this))}
                                className="d-none d-md-block"
                                trigger={["hover"]}
                            >
                                <a className="ant-dropdown-link"
                                    onClick={(e) => e.preventDefault()}>
                                    {this.state.idioma} <DownOutlined />
                                </a>
                            </Dropdown>
                            <Dropdown
                                overlay={menu(this.changeLanguage.bind(this))}
                                className="d-block d-md-none"
                                trigger={["hover"]}
                            >
                                <a className="ant-dropdown-link"
                                    style={{ textTransform: "uppercase" }}
                                    onClick={(e) => e.preventDefault()}>
                                    {this.state.key} <DownOutlined />
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default withTranslation()(Navbar);
