import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { renderField, renderFieldCheck } from '../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import CustomTooltip from '../../Utils/Tooltip';

const validators = (values, props)=>{
    const errors = {}
    if(values.colaboradores&&values.colaboradores.length>0){
        const erroresColaboradores = [];
        values.colaboradores.forEach((item, index) => {
            const errorColaborador = {};
            if(!item.nombres){
                errorColaborador.nombres = props.t('Campo requerido');
            }
            if(!item.email){
                errorColaborador.email = props.t('Campo requerido');
            }
            erroresColaboradores[index]= errorColaborador;
        });
        errors.colaboradores = erroresColaboradores;
    }
    return errors;
}


const renderColaboradores =({fields, t, colaboradores, colbasSet, setColabsSet, colabs, meta: { error }})=>{

    if(fields.length == 0 && colabs && !colbasSet) {
        colabs.forEach(colaborador => {
            const {
                nombres,
                email,
                colider,
            } = colaborador
            fields.push({
                nombres,
                email,
                colider,
            })
        })
        setColabsSet(true)
    }

    return(
        <div className='d-flex flex-column'>
            {fields.map((item, index)=>(
                <div key={index} className='pb-4' >
                    <div className='d-flex flex-row flex-1' >
                        <div className='d-flex flex-column col-lg-11 col-10 p-0' >
                            <div className='d-flex flex-1 flex-column pt-2' >
                                <div className="d-flex justify-content-between" >
                                    <label htmlFor={`${item}.nombres`} >{t('Nombres')} <span className="gris m-0 text-xsm">({t('Obligatorio')})</span> </label>
                                    <CustomTooltip msj={t('Ingrese el nombre del colaborador que desea invitar a formar parte de su empresa en la plataforma Growmotor')}/>
                                </div>
                                <Field name={`${item}.nombres`} component={renderField} type='text' />
                            </div>
                            <div className='d-flex flex-1 flex-column pt-2' >
                                <div className="d-flex justify-content-between" >
                                    <label htmlFor={`${item}.email`} >{t('Correo')} <span className="gris m-0 text-xsm">({t('Obligatorio')})</span></label>
                                    <CustomTooltip msj={t('Ingrese el correo del colaborador que desea invitar a formar parte de su empresa en la plataforma Growmotor')}/>
                                </div>
                                <Field name={`${item}.email`} component={renderField} type='email' />
                            </div>
                            <div className='d-flex flex-1 flex-column pt-2' >
                                <div className="d-flex justify-content-between" >
                                    <label htmlFor={`${item}.email`} >{t('Colíder')}</label>
                                    <CustomTooltip msj={t('Marque, si desea que el colaborador sea un colider, estos usuarios tendrán los mismos permisos que usted por lo que se recomienda que sea una persona de confianza')}/>
                                </div>
                                <Field name={`${item}.colider`} component={renderFieldCheck} label={colaboradores[index].colider? t('Si'):t('No')} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end col-lg-1 col-2 px-0">
                            <button className="btn-delete" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>
                                <i className="fas fa-times marron" />
                            </button>
                        </div>
                    </div>
                </div>
            ))}
            <div className="m-auto">
                <button 
                    type="button"
                    disabled={colaboradores ? colaboradores.length>=10 : false } 
                    className="btn btn-secondary-fill btn-block" 
                    onClick={() => fields.push({colider:false})}>{t('Agregar Colaborador')}</button>
            </div>
        </div>
    )
}


let PasoCuatroForm = props => {
    const { handleSubmit, previousStep, colaboradores, colabs } = props;
    const [colbasSet, setColabsSet] = useState(false)
    console.log(colaboradores)
    return (
        <form onSubmit={handleSubmit} className="form-validate mb-lg">
            <div className="form-group d-flex flex-column flex-1">
                <span className="marron text-sm">{props.t('Límite de colaboradores {{colaboradores}}/10', { 'colaboradores': colaboradores ? colaboradores.length :0 })}</span>
                {(colaboradores&&colaboradores.length==0||colaboradores===undefined)&&(
                    <div className='d-flex flex-1 justify-content-center py-5' >
                        <span className='azul-gris-oscuro h4' >{props.t('También puedes invitarlos después desde la sección de configuraciones')}</span>
                    </div>
                )}
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1">
                        <FieldArray 
                            name='colaboradores' 
                            component={renderColaboradores} 
                            t={props.t} 
                            colaboradores={colaboradores} 
                            colabs={colabs} 
                            colbasSet={colbasSet}
                            setColabsSet={setColabsSet}
                        />
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <button type="submit" className="btn btn-primary">{props.t('Guardar e invitar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar e invitar')}</button>
                </div>
            </div>
        </form>
    )

}

const selector = formValueSelector('register')

PasoCuatroForm = reduxForm({
    form: 'register',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncBlurFields: [],
    initialValues:{
        colaboradores:[]
    },
    validate:validators,

})(withTranslation()(PasoCuatroForm));


const mstp = state => {
    const colaboradores = selector(state, 'colaboradores');
    return {
        colaboradores,
    }
};

export default connect(mstp, null)(PasoCuatroForm)
