import React, { Fragment } from 'react';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderDatePicker3, renderField, renderFieldRadio, RenderImageUpload, renderTextArea, renderFieldCheck, TimeZoneSelectField } from '../../Utils/renderField/renderField';
import { HOMBRE, MUJER, OTRO, TERMINOS_Y_CONDICIONES_URL, POLITICAS_DE_PROVACIDAD_URL } from '../../../../utility/constants';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CustomTooltip from '../../Utils/Tooltip';
import Terminos from '../../Utils/Terminos';

const validators = (values, props)=>{
    const errors = {};
    errors.usuario ={}
    if(values.usuario&&!values.usuario.nombres){
        errors.usuario.nombres = `${props.t('Campo requerido')}`;
    }
    if(values.usuario&&!values.usuario.fecha_nacimiento){
        errors.usuario.fecha_nacimiento = `${props.t('Campo requerido')}`;
    }
    if(values.usuario&&!values.usuario.email){
        errors.usuario.email = `${props.t('Campo requerido')}`;
    }
    if(values.usuario&&!values.usuario.email){
        errors.usuario.email = `${props.t('Campo requerido')}`;
    }
    if(values.usuario&&!values.usuario.telefono){
        errors.usuario.telefono = `${props.t('Campo requerido')}`;
    }
    if(values.usuario&&!values.usuario.descripcion){
        errors.usuario.descripcion = `${props.t('Campo requerido')}`;
    }
    if(values.usuario&&!values.usuario.timezone){
        errors.usuario.timezone = `${props.t('Campo requerido')}`;
    }
    if(values.usuario&&values.usuario.descripcion){
        const count = String(values.usuario.descripcion).length;
        if (count>280){
            errors.usuario.descripcion= `${props.t('Has llegado al limite permitido')}`;
        }
    }
    if(values.usuario&&values.usuario.password&&String(values.usuario.password).length<8){
        errors.usuario.password= `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`;
    }
    if(values.usuario&&!values.usuario.password){
        errors.usuario.password = `${props.t('Campo requerido')}`;
    }
    return errors;
}

let PasoUnoForm = props => {
    const { handleSubmit, setAvatar, previousStep, imagen, progress } = props;
    const [acepto, setAcepto] = React.useState(false);

    return (
        <form onSubmit={handleSubmit} className="form-validate mb-lg">
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <label htmlFor="nombre" className="m-0">{props.t('Foto de perfil')}</label>
                    <RenderImageUpload aspect={1} imagen={imagen} multiple={false} setFile={setAvatar} />
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="usuario.nombres" className="m-0">{props.t('Nombres')}<span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Ingrese su nombre completo')+'.'}/>
                    </div>
                    <Field name="usuario.nombres" component={renderField} type="text" className="form-control" />
                </div>
                <div className="d-flex justify-content-between p-2" >
                    <label htmlFor="usuario.nombres" className="m-0">{props.t('Genero')}<span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Selección su genero')}/>
                </div>
                <div className="d-flex flex-row flex-1 p-2">
                    <div className="flex-1">
                        <Field
                            label={props.t('Hombre')}
                            type="radio"
                            value={HOMBRE}
                            name="usuario.genero"
                            parse={val => +val}
                            component={renderFieldRadio}
                        />
                    </div>
                    <div className="flex-1">
                        <Field
                            label={props.t('Mujer')}
                            type="radio"
                            value={MUJER}
                            name="usuario.genero"
                            parse={val => +val}
                            component={renderFieldRadio}
                        />
                    </div>
                    <div className="flex-1">
                        <Field
                            label={props.t('No especificar')}
                            type="radio"
                            value={OTRO}
                            name="usuario.genero"
                            parse={val => +val}
                            component={renderFieldRadio}
                        />
                    </div>
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="usuario.fecha_nacimiento" className="m-0">{props.t('Fecha de nacimiento')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Seleccione el mes, dia y año de su fecha de nacimiento')+'.'}/>
                    </div>
                    <Field name="usuario.fecha_nacimiento" component={renderDatePicker3} noFuturos={true} />
                </div>
                <div className="d-flex flex-column flex-2 p-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="usuario.telefono" className="m-0">{props.t('Teléfono')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Ingrese su numero telefónico en formato internacional completo, incluyen un símbolo más (+) seguido del código de país, el código de ciudad(si utiliza) y el número de teléfono local')+'.'}/>
                    </div>
                    <Field name="usuario.telefono" component={renderField} type="text" className="form-control" />
                </div>
                <div className="d-flex flex-column justify-content-end flex-1 p-2">
                    <Field
                        name="usuario.tiene_whats"
                        label={props.t("Tiene what's app")}
                        component={renderFieldCheck}
                    />
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="usuario.timezone" className="m-0"> {props.t('Zona horaria')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Seleccione su zona horaria local, ejemplo si eres de Guatemala esta seria America/Guatemala')+'.'}/>
                    </div>
                    <Field name="usuario.timezone" component={TimeZoneSelectField} />
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="usuario.email" className="m-0"> {props.t('Correo')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Ingrese su correo electrónico personal')+'.'}/>
                    </div>
                    <Field name="usuario.email" component={renderField} type="email" className="form-control" />
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="localidad" className="m-0">{props.t('Contraseña')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <CustomTooltip msj={props.t('Ingrese una contraseña de ocho caracteres o mas, combinando letras y números')+'.'}/>
                    </div>
                    <Field name="usuario.password" component={renderField}  type="password" className="form-control" />
                </div>
                <div className="d-flex flex-column flex-1 p-2 mt-2">
                    <div className="d-flex justify-content-between" >
                        <label htmlFor="usuario.descripcion" className="m-0">{props.t('Biografía')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio, Max 280 caracteres)')}</span></label>    
                        <CustomTooltip msj={props.t('Ingrese una pequeña descripcion de ¿quien es usted?, ¿a que se dedica? y cuales han sido sus logros profesionales')+'.'}/>
                    </div>
                    <Field name="usuario.descripcion" component={renderTextArea} progress={progress} maxLength={280} row="4" className="form-control" />
                </div>
                <Terminos onChange={setAcepto} />
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <button type="button" onClick={previousStep} className="btn btn-secondary ml-3">{props.t('Regresar')}</button>
                    <button type="submit" disabled={!acepto} className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column">
                    <button type="button" onClick={previousStep} className="btn btn-secondary flex-1 mt-3">{props.t('Regresar')}</button>
                    <button type="submit" disabled={!acepto} className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )

}

const selector = formValueSelector('register')

PasoUnoForm = reduxForm({
    form: 'register',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncBlurFields: [],
    validate:validators,

})(withTranslation()(PasoUnoForm));


const mstp = state => {
    const progress ={ conteo: 0, porcentaje: 0 }
    const usuario = selector(state, 'usuario');
    if(usuario&&usuario.descripcion){
        let texto = String(usuario.descripcion);
        const conteo = texto.length;
        const porcentaje = conteo / 2.8;
        progress.conteo = conteo;
        progress.porcentaje= porcentaje;
    }
    
    return {
        progress,
    }
};

export default connect(mstp, null)(PasoUnoForm)