import React from 'react';
import { withTranslation, Trans, useTranslation } from 'react-i18next';
import { TERMINOS_Y_CONDICIONES_URL, POLITICAS_DE_PROVACIDAD_URL } from '../../../../utility/constants';

const Terminos =(props)=>{
    const [checked, setChecked] = React.useState(false);

    const { t } = useTranslation();
    
    const toggleChange = () => {
        if(!checked){
            props.onChange(true);
            setChecked(true);
        }else {
            props.onChange(false);
            setChecked(false);
        }
    }
    return (
        <React.Fragment>
            <div className="d-flex flex-row p-1 mt-2" >
                <label className="checkbox c-checkbox" >
                    <input type="checkbox"
                        checked={checked}
                        onChange={toggleChange}
                    />
                    <span className="fa fa-check"></span>
                </label>
                <div className="d-flex flex-row" >
                    <span className="h5" >
                        <Trans t={t} >
                            Acepto las <a href={TERMINOS_Y_CONDICIONES_URL} target="_blank" >condiciones del servicio</a> y las <a href={POLITICAS_DE_PROVACIDAD_URL} target="_blank" >políticas de privacidad</a> Growmotor
                        </Trans>.
                    </span>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Terminos;
