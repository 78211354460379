import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';
import Card from '../../../Utils/Cards/Card';
import { ADMIN, GROWTH_ANGEL, USUARIO } from '../../../../../utility/constants';
import { withTranslation } from 'react-i18next';

const avatar = require("../../../../../../assets/img/avatar.png");

const Personas = (props) => {
    const { invitados, id } = props;
    console.log(invitados)
    console.log(props.page)
    return (
        <div className='mb-2 bg-blanco' >
            <div className="d-flex flex-column flex-1 pt-3 px-3">
                <div className="d-flex flex-row justify-content-end">
                    <button onClick={()=> props.descargarExcel(id)} className="btn btn-primary">{props.t('Exportar a excel')}</button>
                </div>
                <span className="text-xsm gris ml-4">{`${props.t('Mostrando')} ${invitados.results.length} ${props.t('resultados')}`}</span>
                <div className="d-flex flex-column flex-1 my-2">
                    <span className="text-sm bold azul-gris-oscuro ml-4">{props.t('Personas invitadas al evento')}</span>
                    {invitados.results.map((item, index) => (
                        <Link key={index} to={`/perfil/${item.id}`} className="d-flex flex-row borde-bottom flex-1 py-3">
                            <div className="d-flex align-items-center justify-content-center flex-1">
                                <img style={{width: "2.5rem", height: "2.5rem"}} className="logo-tabla" src={item.avatar ? item.avatar : avatar} />
                            </div>
                            <div className="d-flex flex-column justify-content-center flex-4">
                                <span className="text-sm bold gris-oscuro mr-2">{item.nombres}</span>
                                {(item.tipo_usuario == USUARIO && item.cargo && item.empresa_nombre) && (
                                    <span className="text-xs gris">{`${item.cargo} ${props.t('de')} ${item.empresa_nombre}`}</span>
                                )}
                            </div>
                            <div className="d-flex flex-column justify-content-center flex-2">
                                <span className="text-sm bold gris-oscuro mr-2">{props.t('Empresa')}</span>
                                <span className="text-xs gris">{item.empresa_nombre}</span>
                            </div>
                        </Link>
                    ))}
                </div>
                {(invitados.results.length) ? (
                    <div className="d-flex flex-row flex-1 align-items-center mt-3">
                        <Pagination pageSize={10} showSizeChanger={false} current={props.page} total={invitados.count} onChange={(page)=>props.obtenerInvitados(props.item.id, page)} />
                    </div>
                ) : (
                    <div className="d-flex flex-row flex-1 justify-content-center align-items-center mt-3">
                        <span className="text-sm bold gris-oscuro">{props.t('Sin resultados')}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default withTranslation()(Personas);
