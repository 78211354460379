import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/publicaciones/talleres';
import ListarTalleres from './ListarTalleres';

const ms2p = (state) => {
    return {
        ...state.talleres,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListarTalleres);
