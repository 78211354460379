import React from 'react';
import { withTranslation } from 'react-i18next';
import Card from '../../../Utils/Cards/Card';

const iconoEmpresa = require('../../../../../../assets/img/avatar.png')
const imagenPost = require('../../../../../../assets/img/imagen-post.png')

const Historia = (props) =>{
    return (
        <div className='card card-post bg-blanco mb-4'>
            <div className="d-flex flex-1 flex-row px-4 pt-4" >
                <div>
                    <img 
                        src={iconoEmpresa} 
                        style={{ height: "1.5rem", width: "1.5rem" }}
                        className="perfil-img"
                    />
                </div>
                <div className='d-flex flex-row pl-3' >
                    <span className='h5 link-perfil' >{props.t('Growmotor.io')}</span><span className='h5 gris-oscuro' >&nbsp;/&nbsp;{props.t('hace unos momentos')}</span>
                </div>
            </div>
            <div className='card-post-texto pt-2' >
                <p>
                    {props.item.contenido}
                </p>
            </div>
            <div className='d-flex flex-1' style={{borderTop:"3px solid #E6E6E6"}} >
                <div className='d-flex flex-1 justify-content-center' >
                    <i className="fas fa-thumbs-up mr-1 py-2 me__gusta"></i>
                </div>
                <div className='d-flex flex-1 justify-content-center'>
                    <i className='fas fa-comments azul-oscuro mr-1 py-2'></i>
                </div>
            </div>
        </div>
    )
}



const ayudaHistorias =(props)=>{

    const historiasArray = [
        {'index':1, 'contenido':props.t('Recibimos una orden por $80.000 de Walmart para 5 tiendas. Si el experimento sale bien, en un año, ellos harán pedidos para 50 tiendas, que representarán cerca de $1.000.000 y nos reportará un 65% de crecimiento para el 2021')+'.'},
        {'index':2, 'contenido':props.t('Las nuevas reuniones semanales y la capacitación de nuestra gente resultaron en 18% en la disminución de desechos y un incremento de 22% en utilidad')+'.'},
        {'index':3, 'contenido':props.t('Hemos generado a través de cinco empresas nuevas ventas de $538.869, lo que representa un aumento del 42% de los ingresos brutos y nos permite agregar tres empleados, un aumento del 18% en la plantilla')+'.'},
        {'index':4, 'contenido':props.t('La semana pasada, contratamos a un nuevo director comercial y tres nuevos vendedores y seguimos en la búsqueda de un representante para atención al cliente. Eso nos da la capacidad para aumentar las ventas por 50%')+'.'},
        {'index':5, 'contenido':props.t('Nuestro banco acaba de otorgarnos una nueva línea de crédito ($500.000) lo que nos ayudará a aumentar la capacidad instalada por 25%')+'.'},
        {'index':6, 'contenido':props.t('Gracias a la re-organización de nuestra planta de producción e innovación de algunos procesos, hemos aumentado nuestra productividad semanal por 30%')+'.'},
        {'index':7, 'contenido':props.t('Tras implementar un seguimiento detallado de las cuentas a cobrar, hemos aumento nuestra recaudación de ingresos por 42%, permitiéndonos tener más capital de trabajo para crecer el volumen de producción')+'.'},
        {'index':8, 'contenido':props.t('Tras tres semanas de una campaña en las redes sociales, el volumen de visitas a nuestras tiendas ha aumentado por 36%')+'.'},
        {'index':9, 'contenido':props.t('Acabamos de abrir un nuevo local de 1.000 metros cuadrados y estamos a punto de cerrar la contratación de 2 colaboradores junior. Estos hechos aportarán un aumento de 40% en ventas')+'.'},
        
    ]
    return(
        <div className="social-layout mb-4 col-12 mt-0" >
            <div className="card card-post bg-blanco mb-4" >
                <div className="d-flex flex-1 flex-column pt-4">
                    <div className='d-flex justify-content-center' style={{borderBottom:"3px solid #E6E6E6"}} >
                        <span className='gris h4 bold p-2' >{props.t('¿No sabes como realizar un Growth Gist?')}</span>
                    </div>
                    <div className='d-flex flex-1 flex-column py-4 px-5' >
                        <span className='azul-gris-oscuro h5 light' >
                            {props.t('Si sucede algo bueno y muy poca gente lo sabe, el impacto se ve socavado o nulo. Aproveche los avances y logros comunicándolos (interna y externamente) de manera oportuna y amplia. Nadie quiere bailar con una compañía moribunda. Comunicar y celebrar los logros da alas de crecimiento y anima a todos')+'.'}
                        </span>
                        <span className='azul-gris-oscuro h5 light mt-3' >
                            {props.t('En este espacio Growth Gists, los participantes de Growmotor cuentan sus logros de crecimiento (renta, caja, productividad) de una manera precisa, breve, y completa. Hay un límite de 280 caracteres. Cuanto más Growth Gists publicamos, mejor para todos (dueños, colaboradores, empleados, bancos, talentos, proveedores, aliados, compañeros de Growmotor, etc.). Vean los siguientes ejemplos.  ')}
                        </span>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column' >
                {historiasArray.map((item, index)=>(
                    <Historia key={index} item={item} t={props.t} />
                    ))}
            </div>
        </div>
    )
}

export default withTranslation()(ayudaHistorias);


