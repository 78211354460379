
import React from "react";
import { Progress, Input, Form } from 'antd';
import FileImgUploder from '../FileUploader/FileImgUploader';
import { withTranslation } from  'react-i18next';
import ArchivosUploader from '../ArchivoUploader';
import { BENEFICIO } from "../../../../utility/constants";
import _ from "lodash";


const Img = require('../../../../../assets/img/icono-imagen.png')

class Publicacion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            descripcion:'',
            imagen:null,
            imagenSrc: '',
            loaded: false,
            progress: {
                conteo: 0,
                porcentaje: 0,
            },
            errorInput: null,
            archivos: [],
            listaArchivos: [],
        }
    }
    componentDidMount=()=>{
        if(this.props.item&&this.props.item.descripcion){
            this.setState({descripcion:this.props.item.descripcion});
            this.setProgress(this.props.item.descripcion);
        }
        if(this.props.item&&this.props.item.imagen){
           this.setState({imagenSrc:this.props.item.imagen});
           this.setState({loaded:true});
        }
        if(this.props.item&&this.props.item.archivos){
            this.preSetArchivos(_.cloneDeep(this.props.item.archivos));
            this.setState({listaArchivos:_.cloneDeep(this.props.item.archivos)});
        }
    }
    preSetArchivos = (archivos_existentes) => {
        const archivos = [];
        for (const item of archivos_existentes) {
            archivos.push(null);
        }

        this.setState({ archivos });
    }
    setArchivos = (files)=>{
        const archivos = this.state.archivos;
        for (let index = 0; index < files.length; index++) {
            archivos.push(files[index]);
        }
        this.setState({archivos});

        const listaArchivos = this.state.listaArchivos;
        for (const item of files) {
            listaArchivos.push({
                nombre: item.name,
            });
        }
        this.setState({listaArchivos});
    }
    eliminarArchivo = (index)=>{
        let archivos = this.state.archivos;
        archivos = _.filter(archivos, (item, i) => i != index );
        this.setState({ archivos });
        
        let listaArchivos = this.state.listaArchivos;
        listaArchivos = _.filter(listaArchivos, (item, i) => i != index );
        this.setState({listaArchivos});
    }
    setProgress = (value) => {
        const texto = String(value);
        const conteo = texto.length;
        const porcentaje = conteo / 2.8;
        this.setState({ progress: { conteo, porcentaje } });
        this.setState({descripcion:value});
        if(conteo>=0){
            this.setState({errorInput:null});
        }
    };
    sendEditar=()=>{
        const value = String(this.state.descripcion);
        if(value.length<=0){
            this.setState({errorInput:this.props.t('Campo requerido')});
        }else{
            const publicacion = {};
            const array = [];
            publicacion.descripcion= this.state.descripcion;
            publicacion.imagen = this.state.imagen;
            publicacion.id = this.props.item.id;

            //mandamos los archivos que tengamos en el state, este es el listado con los nombres
            publicacion.archivos = _.cloneDeep(this.state.listaArchivos);

            this.state.archivos.forEach((file, index)=>{
                if(file != null){
                    array.push({"file": file, "name": `archivo-${index}`});
                }
            })
            // aqui se mandan los archivos
            publicacion.array = array;

            if(this.props.item.id){
                this.props.onEditar(publicacion);
                this.props.onCancelar();
            }
        }
    }
    setImagen = (file) =>{
        this.setState({imagen:file.file, 
            imagenSrc:file.imageSrc, 
            loaded:file.loaded,
            disabled:false,
        })
    }
    removeImagen = ()=>{
        this.setState({imagen:null, imagenSrc:'', loaded:false});
    }

    render(){
        const { item } = this.props;
        if(item&&item.tipo_publicacion===BENEFICIO){
            return(
                <div className="d-flex" >
                    <div className="d-flex flex-column flex-1 py-1">
                        <div className="d-flex flex-grow-0">
                            <textarea
                                placeholder={this.props.t('Escribe una publicacion')}
                                style={{ resize: 'none' }}
                                rows={3}
                                className={`${this.state.errorInput? 'is-invalid':''} editar-area-post`}
                                onChange={(e)=>this.setProgress(e.target.value)}
                                value={this.state.descripcion}
                            />
                        </div>
                        {this.state.errorInput&&(
                            <div className="d-flex invalid-feedback">
                                {this.state.errorInput}
                            </div>
    
                        )}
                        <div className="d-flex flex-1 justify-content-end align-items-center">
                            <div className="d-flex ml-3">
                                <FileImgUploder className="ml-3" width="1rem" height="1rem" imageSrc={Img} onFileChange={this.setImagen} />
                            </div>
                        </div>
                        <div className="d-flex  flex-column flex-1 align-items-center">
                            {
                                this.state.loaded && (
                                    <React.Fragment>
                                        <div className="d-flex flex-row justify-content-end col-12 col-md-8 col-lg-6" 
                                            style={{position:"relative", bottom:"-1.8rem", cursor:"pointer",zIndex:"1"}}  
                                            onClick={()=>this.removeImagen()}>
                                            <li className="blanco bg-gris p-2 fa fa-times fa-lg"  style={{borderRadius:"5px"}}/>
                                        </div>
                                        <div className="d-flex col-12 col-md-8 col-lg-6 pb-3">
                                            <img src={this.state.imagenSrc} style={{maxWidth:"100%" }} />
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </div>
                        <div className={`d-flex flex-column flex-wrap px-0 ${this.state.listaArchivos.length<=0 ? '':'mb-3'}`}>
                            <span className="text-sm azul-gris-oscuro bold">{this.props.t('Archivos para descargar')}</span>
                            <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                        </div>
                        <ArchivosUploader
                            key="uploader"
                            archivos={this.state.listaArchivos}
                            upload={this.setArchivos}
                            eliminar={this.eliminarArchivo}
                            btnLabel={this.props.t('Subir archivos')}
                             />
                        <div className="d-none d-lg-flex buttons-box mt-4">
                            <button className="btn btn-primary ml-3" onClick={(e)=>{this.sendEditar()}} >
                                { this.props.t('Guardar') }
                            </button>
                            <button className="btn btn-secondary ml-3" onClick={(e)=> {this.props.onCancelar()}}>
                                { this.props.t('Canelar') }
                            </button>
                        </div>
                        <div className="d-flex d-lg-none flex-column mt-4">
                        <button className="btn btn-primary" onClick={(e)=>{this.sendEditar()}} >
                                { this.props.t('Guardar') }
                            </button>
                            <button className="btn btn-secondary mt-3" onClick={(e)=> {this.props.onCancelar()}}>
                                { this.props.t('Canelar') }
                            </button>
                        </div>
                    </div>
                </div>

            )
        }else{
            return (
                <div className="d-flex" >
                    <div className="d-flex flex-column flex-1 py-1">
                        <div className="d-flex flex-grow-0">
                            <textarea
                                maxLength="280"
                                placeholder={this.props.t('Escribe una publicacion')}
                                style={{ resize: 'none' }}
                                rows={3}
                                className={`${this.state.errorInput? 'is-invalid':''} editar-area-post`}
                                onChange={(e)=>this.setProgress(e.target.value)}
                                value={this.state.descripcion}
                            />
                        </div>
                        {this.state.errorInput&&(
                            <div className="d-flex invalid-feedback">
                                {this.state.errorInput}
                            </div>
    
                        )}
                        <div className="d-none flex-row d-md-flex">
                            <div className="d-flex flex-1 justify-content-start pt-1" >
                                <button className="btn-sm btn-primary btn-sm ml-2" onClick={(e)=>{this.sendEditar()}} disabled={this.state.disabled}>
                                    { this.props.t('Guardar') }
                                </button>
                                <button className="btn-sm btn-secondary  ml-2" onClick={(e)=> {this.props.onCancelar()}}>
                                    { this.props.t('Canelar') }
                                </button>
                            </div>
                            <div className="d-flex flex-1 justify-content-end align-items-center">
                                    <span className='gris text-xs pr-2'>{this.state.progress.conteo}/280</span>
                                    <div className="d-flex" >
                                        <Progress percent={this.state.progress.porcentaje}  style={{width:"2.5rem"}}/>
                                    </div>
                                    <div className="d-flex ml-3">
                                        <FileImgUploder className="ml-3" width="1rem" height="1rem" imageSrc={Img} onFileChange={this.setImagen} />
                                    </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column-reverse d-md-none">
                            <div className="d-flex flex-1 justify-content-start pt-1" >
                                <button className="btn-sm btn-primary btn-sm ml-2" onClick={(e)=>{this.sendEditar()}} >
                                    { this.props.t('Guardar') }
                                </button>
                                <button className="btn-sm btn-secondary  ml-2" onClick={(e)=> {this.props.onCancelar()}}>
                                    { this.props.t('Canelar') }
                                </button>
                            </div>
                            <div className="d-flex flex-1 justify-content-end align-items-center">
                                <span className='gris text-xs pr-2'>{this.state.progress.conteo}/280</span>
                                <Progress percent={this.state.progress.porcentaje}  style={{width:"2.5rem"}}/>
                                <div className="d-flex ml-3">
                                    <FileImgUploder width="1rem" height="1rem" imageSrc={Img} onFileChange={this.setImagen} />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex  flex-column flex-1 align-items-center">
                            {
                                this.state.loaded && (
                                    <React.Fragment>
                                        <div className="d-flex flex-row justify-content-end col-12 col-md-8 col-lg-6" 
                                            style={{position:"relative", bottom:"-1.8rem", cursor:"pointer",zIndex:"1"}}  
                                            onClick={()=>this.removeImagen()}>
                                            <li className="blanco bg-gris p-2 fa fa-times fa-lg"  style={{borderRadius:"5px"}}/>
                                        </div>
                                        <div className="d-flex col-12 col-md-8 col-lg-6 pb-3">
                                            <img src={this.state.imagenSrc} style={{maxWidth:"100%" }} />
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }
};

export default withTranslation()(Publicacion);
