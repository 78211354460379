import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, } from 'redux-form';
import { renderCountrySelect, renderDatePicker3, renderField, RenderImageUpload, renderTextArea, SelectField } from '../../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';
import { MODELOS, TIPO_SECTORES } from '../../../../../utility/constants';


const validators = (values, props) => {
    const errors = {};
    if (!values.nombre) {
        errors.nombre = `${props.t('Campo requerido')}`; ;
    }
    if (!values.pais) {
        errors.pais = `${props.t('Campo requerido')}`; ;
    }
    if (!values.ciudad) {
        errors.ciudad = `${props.t('Campo requerido')}`; ;
    }
    if (!values.localidad) {
        errors.localidad = `${props.t('Campo requerido')}`; ;
    }
    if (!values.codigo_postal) {
        errors.codigo_postal = `${props.t('Campo requerido')}`; ;
    }
    if (!values.linea1) {
        errors.linea1 = `${props.t('Campo requerido')}`; ;
    }
    if (!values.descripcion_empresa) {
        errors.descripcion_empresa = `${props.t('Campo requerido')}`; ;
    }
    if (!values.modelo_negocios) {
        errors.modelo_negocios = `${props.t('Campo requerido')}`; ;
    }
    if (!values.fecha_fundacion) {
        errors.fecha_fundacion = `${props.t('Campo requerido')}`; ;
    }

    return errors;
}

const PerfilForm = (props) => {
    const { handleSubmit, item, me } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    {item.logo && (
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="imagen" className="m-0">{props.t('Logo actual')}</label>
                            <img src={item.logo} alt="fotografia" className="img-fluid" style={{ width: "10rem", marginTop: "7px" }} />
                        </div>
                    )}
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="imagen" className="m-0">{props.t('Nuevo Logo')}</label>
                        <RenderImageUpload aspect={1} multiple={false} setFile={props.setLogo} crop={false} />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombre" className="m-0">{props.t('Nombre de la empresa')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nombre" component={renderField} type="text" className="form-control" />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="sitio_web" className="m-0">{props.t('Sitio web o página de Facebook')}</label>
                        <Field name="sitio_web" component={renderField} type="url" className="form-control" />
                    </div>
                </div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <span className="text-sm azul-gris-oscuro bold">{props.t('Dirección')} </span>
                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px'}} />
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="pais" className="m-0"> {props.t('País')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <Field name="pais" component={renderCountrySelect} type="text" />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="ciudad" className="m-0">{props.t('Ciudad')}  <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <Field name="ciudad" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="localidad" className="m-0">{props.t('Localidad')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <Field name="localidad" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="codigo_postal" className="m-0">{props.t('Código postal')}  <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <Field name="codigo_postal" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="linea1" className="m-0">{props.t('Línea 1')}  <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <Field name="linea1" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="linea2" className="m-0">{props.t('Línea 2')} </label>
                        <Field name="linea2" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <span className="text-sm azul-gris-oscuro bold">{props.t('Giro de negocio')} </span>
                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px'}} />
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="descripcion_empresa" className="m-0">{props.t('¿Qué es lo que hace la empresa?')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <Field name="descripcion_empresa" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="sector" className="m-0">{props.t('Sector')} {props.t('(todos los que apliquen)')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <Field
                            name="sectores"
                            isMulti
                            options={TIPO_SECTORES}
                            component={SelectField}
                        />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="sector" className="m-0">{props.t('Modelo de negocio')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <Field
                            name="modelo_negocios"
                            component={SelectField}
                            options={MODELOS}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="fecha_fundacion" className="m-0">{props.t('Fecha de fundación de la empresa')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                        <Field name="fecha_fundacion" component={renderDatePicker3} noFuturos={true} />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="retos_importantes" className="m-0">{props.t('Retos más importantes que están impidiendo el crecimiento de mi empresa')}</label>
                        <Field name="retos_importantes" component={renderTextArea} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/mi-empresa">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/mi-empresa">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )

}

export default reduxForm({
    form: 'EmpresaPerfilForm', // a unique identifier for this form
    validate: validators,
})(withTranslation()(PerfilForm));
