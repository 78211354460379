import React from 'react';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../i18nextInit'
import { Menu, Dropdown, DownOutlined } from "antd";
import { ADMIN, BENEFICIO } from '../../../../utility/constants';
import moment from "moment";
import Com from '../Comentarios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';;
import Lightbox from 'react-image-lightbox';

const imgDefault = require("../../../../../assets/img/avatar.png");

const menu = (props, setEditar) => {
    return (
        <Menu>
            {((props.item.publicado_por.id===props.me.id)||props.me.tipo_usuario===ADMIN) && (
                <React.Fragment>
                    <Menu.Item key="editar"
                        onClick={(e)=> {setEditar(props.item);}} >{props.t('Editar')}</Menu.Item>
                </React.Fragment>
            )}

            {((props.item.publicado_por.id===props.me.id) || (props.me.tipo_usuario===ADMIN)) && (
                <Menu.Item key="eliminar" onClick={(e)=>{
                    Swal.fire({
                                title:`${props.t('¿Eliminar comentario?')}`,
                                text:`${props.t('¡No podrá revertir esta acción!')}`,
                                type: 'warning',
                                showCancelButton: true,
                                confirmButtonText:`${props.t('¡Sí, eliminar!')}`,
                                cancelButtonText: `${props.t('No, cancelar')}`,
                                reverseButtons: true
                            }).then((result) => {
                                if (result.value) {
                                    props.eliminar(props.item.id);
                                    props.getComentarios(1,props.publicacion)

                                }
                            });
                }} >{props.t('Eliminar')}</Menu.Item>
            )}
        </Menu>
    );
};


class CardComentario extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            editar:false,
            dropdownOpen: false,
            responder: false,
            comentario:{},
            lightbox:false,
        };
    }

    onCancelar =()=>{
        this.setState({editar:false});
    }
    serResponder=()=>{
        this.setState({responder:!this.state.responder});

    }
    onEditar=(item)=>{
        const array = [];
        array.push({ file:item.imagen ? item.imagen: null , name: "imagen" });
        const body = {'contenido':item.contenido};
        this.props.editar(item.id, body, array).then(()=>{
            this.props.getComentarios(1, {id:item.publicacion})
        })
    }
    setEditar=(item)=>{
        this.setState({editar:true});
    }
    render(){
        const { item, isComment, isReply, padre } =this.props;
        
        const lang = i18n.language ? i18n.language : 'en'
        return(
            <div className="d-flex flex-row flex-1">
                <div className="d-flex align-items-start pt-1 pr-1 justify-content-center w-20 pl-md-3">
                    <img
                        src={item.publicado_por && item.publicado_por.avatar ?
                                item.publicado_por.avatar:
                                imgDefault}
                        style={{ height: "1.5rem", width: "1.5rem" }}
                        className="perfil-img"
                    />
                </div>
                <div className="d-flex flex-column flex-grow-1" >
                    <div className="d-flex flex-column bg-gris-claro p-2 flex-grow-1" style={{borderRadius:"0.3rem"}} >
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column flex-5">
                                    <div className="d-flex flex-row align-items-center flex-1">
                                        <Link className="text-sm medium link-perfil" to={`/perfil/${item.publicado_por ? item.publicado_por.id : 0}`}>
                                            {item.publicado_por ? item.publicado_por.nombres : ''}
                                        </Link>
                                        <span className="text-sm  gris">
                                            &nbsp;/&nbsp;
                                            {moment(item.creado).locale(lang).fromNow()}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-row align-items-center flex-1">
                                        <span className="text-equal2 gris">
                                            {item.publicado_por
                                                ? this.props.t(item.publicado_por
                                                    .tipo_usuario_text)
                                                : ""}
                                        </span>
                                    </div>
                                </div>
                            <div className="d-flex flex-1 justify-content-end" >
                                {
                                    (this.props.me.tipo_usuario===ADMIN || this.props.item.publicado_por.id===this.props.me.id)&&(
                                        <Dropdown overlay={menu(this.props, this.setEditar)}
                                            trigger={[`${this.props.me.tipo_usuario===ADMIN || this.props.item.publicado_por.id===this.props.me.id ?'click':''}`]}
                                            placement="bottomRight" arrow>
                                            <div className="d-flex justify-content-center align-items-center bg-blanco"
                                                style={{border:"1px solid ",borderRadius:"100%",width:"1.3rem",height:"1.3rem"}}>
                                                <a className="fas fa-ellipsis-h"
                                                    onClick={(e) => e.preventDefault()}/>
                                            </div>
                                        </Dropdown>
                                    )
                                }
                            </div>
                        </div>

                            { this.state.editar ? (
                                <Com.Editar me={this.props.me} onEditar={this.onEditar} item={item} onCancelar={this.onCancelar} />
                            ):(
                                <React.Fragment>
                                    <div className="d-flex flex-column" >
                                        {item.imagen && (
                                            <div className="col-10 col-md-8 col-lg-6 m-auto mt-1 card__img__container">
                                                <div className="card__img">
                                                    <img
                                                        className="text-touch"
                                                        src={item.imagen}
                                                        onClick={e=>this.setState({lightbox:true})}/>
                                                </div>
                                            </div>
                                        )}
                                        <p className="mb-0 text-sm light" >
                                            {item.contenido}&nbsp;&nbsp;
                                        </p>
                                    </div>
                                    {this.state.lightbox&&(
                                        <Lightbox mainSrc={item.imagen} onCloseRequest={e=>this.setState({lightbox:false})} />
                                    )}
                                    <div className="d-flex pt-2">
                                        <div className="d-flex flex-2 align-items-center justify-content-start">

                                            <div className="px-4">
                                                {
                                                    (isReply && !this.props.publicacion.vencida )&& (
                                                        <div>
                                                            <i className="text-equal2 fas fa-comments gris-oscuro"></i>
                                                            <span className="ml-1 gris-oscuro" style={{cursor:"pointer"}} onClick={(e)=>this.serResponder()} >{this.props.t('Responder')}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex flex-1">
                                        </div>
                                    </div>
                                </React.Fragment>

                            )

                            }
                    </div>
                    <div className="d-flex flex-column flex-grow-1" >
                        {
                            this.props.children
                        }
                        { this.state.responder &&(
                            <Com.FormHijo me={this.props.me} isReply={isReply}
                            getComentarios={this.props.getComentarios}
                            publicacion={this.props.publicacion} padre={item}
                            setResponder={this.serResponder}  contenido={this.state.contenido} />
                        ) }
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(CardComentario);
