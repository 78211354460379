import React from "react";
import { Field, reduxForm, FieldArray, formValueSelector,  } from "redux-form";
import { renderTextArea, AsyncSelectField, renderFieldCheck, renderSwitch, renderDatePicker3 } from "../../Utils/renderField/renderField"
import { withTranslation } from "react-i18next";
import FileImgUploader from '../../Utils/FileUploader/FileImgUploader';
import { Link } from  'react-router-dom';
import { connect } from 'react-redux';
import { api } from "api";
import ArchivosUploader from '../../Utils/ArchivoUploader';

const logoUpload = require('../../../../../assets/img/iconos/12-Img.png');
const avatar = require("../../../../../assets/img/avatar.png");
const imageIcon = require('../../../../../assets/img/image-solid.svg');

const Options = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("cohorte/obtener_cohortes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const validators = (values, props) => {
    const errors = {};
    if (!values.descripcion) {
        errors.descripcion = `${props.t('Campo requerido')}`;
    }
    if (!values.cohorte){
        errors.cohorte = `${props.t('Campo requerido')}`;
    }
    if (values.fecha_limite){
        if(!values.fecha_fin){
            errors.fecha_fin = `${props.t('Campo requerido')}`;
        }
    }

    return errors;
};

let BeneficioForm = (props) => {
    const { handleSubmit, me, expandir, setImagen, imagen, fecha_limite, archivos } = props;

    return (
        <form onSubmit={handleSubmit} >
            <div className="card card-content py-3 mb-4">
                <div className="d-flex flex-column flex-1 px-4">
                    <div className="d-flex flex-row align-content-center" style={{cursor:"pointer"}} onClick={(e)=>{
                        props.onExpandir()
                    }}>
                        <img
                            className="perfil-img mr-2"
                            style={{ height: "2.1rem", width: "2.1rem" }}
                            src={me&&me.avatar ? me.avatar : avatar}
                            alt="Perfil"
                        />
                        <span className="gris h4">
                            {props.t('En esta sección puedes ofrecer oportunidades que sirvan para potenciar el crecimiento de renta, caja y/o productividad de las empresas participantes')+'.'}
                        </span>
                    </div>
                    {expandir && (
                        <div className="d-flex flex-1 flex-column">
                            <div className="d-flex flex-column flex-md-row p-2 flex-wrap" >
                                <div className="flex-1 pr-md-2" >
                                    <label htmlFor='cohorte'>{props.t('Cohorte')}
                                        <span className="gris m-0 text-xsm"> ({props.t('Obligatorio')})</span>
                                    </label>
                                    <Field name="cohorte"
                                        component={AsyncSelectField}
                                        placeholder={props.t("Buscar cohorte")+"...."}
                                        type="text"
                                        valueKey="id"
                                        labelKey="nombre"
                                        loadOptions={Options}
                                        className="form-control" />
                                </div>
                                <div className="flex-1 pl-md-2 pt-3 pt-md-0" >
                                    <label htmlFor='cohorte'>{props.t('Fecha límite')}</label>
                                    <Field name='fecha_limite' component={renderSwitch} className="form-control" />
                                </div>
                            </div>
                            { fecha_limite&&(
                                <div className="d-flex flex-column flex-md-row p-2 flex-wrap">
                                    <div className="flex-1" >
                                        <label htmlFor='cohorte'>{props.t('Fecha fin de beneficio')}
                                            <span className="gris m-0 text-xsm"> ({props.t('Obligatorio')})</span>
                                        </label>
                                        <Field name='fecha_fin' component={renderDatePicker3} noPasados={true} />
                                    </div>
                                </div>
                            )}
                            <div className="d-flex flex-column flex-1 p-2">
                                <label htmlFor='cohorte'>{props.t('Descripción')}
                                    <span className="gris m-0 text-xsm"> ({props.t('Obligatorio')})</span>
                                </label>
                                <Field
                                    name="descripcion"
                                    component={renderTextArea}
                                    rows={5}
                                    style={{ borderRadius: "10px" }}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="px-2 pt-2" >
                                <div className={`d-flex flex-column flex-wrap px-0 pt-2 ${archivos.length<=0 ? '':'mb-3'}`}>
                                    <span className="text-sm azul-gris-oscuro bold">{props.t('Archivos para descargar')}</span>
                                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                </div>
                                <ArchivosUploader 
                                    archivos={archivos} 
                                    upload={props.setArchivos}
                                    eliminar={props.eliminarArchivo}
                                    btnLabel={props.t('Subir archivos')} />
                            </div>
                        </div>
                    )}
                    <div className="d-flex  flex-column flex-1 align-items-center">
                        {
                            (imagen&& imagen.loaded)&& (
                                <React.Fragment>
                                    <div className="d-flex flex-row justify-content-end col-12 col-md-8 col-lg-6"
                                        style={{position:"relative", bottom:"-1.8rem", cursor:"pointer",zIndex:"1"}}
                                        onClick={()=>props.removeImagen()}>
                                        <li className="blanco bg-gris p-2 fa fa-times fa-lg"  style={{borderRadius:"5px"}}/>
                                    </div>
                                    <div className="d-flex col-12 col-md-8 col-lg-6 pb-3" >
                                        <img src={imagen&&imagen.imageSrc} style={{maxWidth:"100%"}}/>
                                    </div>

                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
                <span
                    className={`${!expandir ? "mt-4" : ""}`}
                    style={{ borderTop: "3px solid #E6E6E6" }}
                />
                <div className="d-flex flex-row  flex-grow-1 px-4 pt-2">
                    <div className="d-flex flex-row w-50 align-items-center align-top">
                        <div className="d-flex align-items-center">
                            <FileImgUploader width="1.3rem" height="1.2rem" imageSrc={imageIcon} onFileChange={setImagen} />
                            {
                                props.editar && props.editar ? (
                                    <span className="gris h5 pl-2" >{props.t('Reemplazar Imagen')}</span>

                                ):(
                                    <span className="gris h5 pl-2">{props.t('Foto')}</span>
                                )
                            }

                        </div>
                    </div>
                    <div className="d-flex flex-row w-50 justify-content-end align-items-center">
                        {/* <Link to="ayuda_beneficios">
                            <i className="fas fa-question question-button"></i>
                        </Link> */}
                        <button className="btn btn-primary ml-2" type="submit" disabled={!props.expandir}>
                            {props.t('Publicar')}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

const selector = formValueSelector('BeneficioForm');


BeneficioForm = reduxForm({
    form: "BeneficioForm",
    asyncBlurFields: [],
    validate: validators,
})(withTranslation()(BeneficioForm));

const mstp = state => {
    const fecha_limite = selector(state, 'fecha_limite');
    let archivos = selector(state, 'archivos');
    archivos = archivos ? archivos : [];

    return {
        fecha_limite,
        archivos,
    }
};

export default connect(mstp, null)(BeneficioForm);
