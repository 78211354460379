import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { getMe } from '../cuenta/login';
import { COUNTRIES } from '../../../utility/paises';
import moment from 'moment';
import _ from "lodash";
import { ADMIN } from '../../../utility/constants';
import { getI18n } from 'react-i18next';

const LOADER = 'LOADER_PERFIL';
const DATA = 'DATA_PERFIL';
const DATA2 = 'DATA2_PERFIL';
const ITEM_DATA = 'ITEM_PERFIL';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

// ------------------------------------
// Actions
// ------------------------------------

const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`user/${id}`).then((response) => {
        dispatch(setItem(response));
        const data = {...response}
        if (data.pais) {
            const item = _.find(COUNTRIES, {name: data.pais});
            data.pais = item;
        }
        if (data.idiomas) {
            data.idiomas = data.idiomas.map(item => {
                return { ...item, value: item.tipo }
            })
        }
        dispatch(initializeForm('ColaboradorEditarForm', data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerPerfil = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`user/${id}`).then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const obtenerEmpresas = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`cohorte/obtener_sugerencias`).then((response) => {
        console.log("respose ", response)
        dispatch(setData(DATA, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const obtenerEventos = () => (dispatch, getStore) => {
    const cohorte = localStorage.getItem('cohorte');
    const params = { };
    if (cohorte){
        params.cohorte = JSON.parse(cohorte).id;
    }

    dispatch(setLoader(true));
    api.get(`evento/proximos_eventos`, params).then((response) => {
        dispatch(setData(DATA2, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`user/${id}/update_colaborador`, data, attachments).then(() => {
        NotificationManager.success(getI18n().t('Colaborador actualizado'), getI18n().t('Éxito'), 3000);
        dispatch(push('/colaboradores'));
    }).catch((e) => {
        const err = e.detail ? ': '+ e.detail : ''
        NotificationManager.error(getI18n().t('Error en la edición') + err, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    leer,
    leerPerfil,
    obtenerEmpresas,
    obtenerEventos,
    editar,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA2]: (state, { data }) => {
        return {
            ...state,
            data2: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
    data2: [],
    item: {},
};

export default handleActions(reducers, initialState);

