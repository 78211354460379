import React from 'react';
import Modal from 'react-responsive-modal';
import { withTranslation } from 'react-i18next';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import { renderTextArea, renderFieldRadio  } from '../../Utils/renderField/renderField';
import { Field, reduxForm, } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { MUY_EFICAZ, EFICAZ, MODERADAMENTE_EFICAZ, INEFICAZ, MUY_INEFICAZ } from '../../../../utility/constants';
import Item from 'antd/lib/list/Item';
import IconoTaller from '../../../../../assets/img/iconos/taller.png';

const Form = (props)=>{

    const { item } = props;
    return(
        <LoadMask loading={props.loader} blur >
            <form onSubmit={props.handleSubmit} style={{ width: "50vw" }} className="flex-1 w-100-responsivo pt-4 px-md-4" >
                <div className="d-flex flex-column bg-blanco p-4">
                    <div className="d-flex flex-row py-2">
                        <span className="text-sm bold" >
                            {props.t('Hola {{nombres}}, por favor contesta el siguiente formulario para ayudarnos a seguir mejorando el programa. Sus respuestas serán completamente anónima',{'nombres':props.me.nombres})+'.'}</span>
                    </div>
                    {(item&&item.evento)&&(<div className="d-flex p-4 flex-row border__redondos mb-2">
                        <div className="d-flex flex-1 justify-content-center align-items-center" style={{borderRight:"2px solid #D6D6D6"}} >
                            <img src={IconoTaller} style={{width:"2.2rem", height:"2.2rem" }}/>
                        </div>
                        <div className="d-flex flex-3 flex-row px-3">
                            <div className="d-flex flex-column flex-1" >
                                <span className="text-equal bold azul-gris-oscuro" >{props.t('Evento')}</span>
                                <span className="celeste-oscuro" >{item.evento ? item.evento : '' }</span>
                            </div>
                            <div className="d-flex flex-column flex-1" >
                                <span className=" text-equal bold azul-gris-oscuro" >{props.t('Cohorte')}</span>
                                <span className="celeste-oscuro" >{item.cohorte ? item.cohorte : '' }</span>
                            </div>
                        </div>
                    </div>)}
                    <div className="d-flex flex-1 flex-column py-2" >
                        <label htmlFor="pregunta1" className="azul text-sm bold" >{props.t('1-En general, ¿cómo califica la efectividad de este evento?')}</label>
                        <div style={{border:'3px solid #8C8F9B', borderRadius:"5px", backgroundColor:"#8C8F9B"}} className="my-3" ></div>
                        <div className="d-flex flex-column flex-wrap px-0 mt-2 px-4" >
                            <div className="d-flex flex-column flex-md-row" >
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta1"
                                        component={renderFieldRadio}
                                        label={props.t('Muy eficaz')}
                                        type="radio"
                                        value={MUY_EFICAZ}
                                        parse={val => +val}
                                        />
                                </div>
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta1"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Eficaz')}
                                        value={EFICAZ}
                                        parse={val => +val} />
                                </div>
                                <div className="flex-2" >
                                    <Field
                                        name="pregunta1"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Moderadamente eficaz')}
                                        value={MODERADAMENTE_EFICAZ}
                                        parse={val => +val} />
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row" >
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta1"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Ineficaz')}
                                        value={INEFICAZ}
                                        parse={val => +val} />
                                </div>
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta1"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Muy ineficaz')}
                                        value={MUY_INEFICAZ}
                                        parse={val => +val} />
                                </div>
                                <div className="flex-2" />
                            </div>
                        </div>
                        <div className="d-flex flex-1 flex-column p-2 px-2">
                            <label htmlFor="pregunta1_texto" >{props.t('Por favor explique su respuesta')}</label>
                            <div className="px-3" >
                                <Field name="pregunta1_texto" component={renderTextArea} className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-1 flex-column py-2" >
                        <label htmlFor="pregunta2" className="azul text-sm bold" >{props.t('2-¿Qué tan relevante fue el evento para usted?')}</label>
                        <div style={{border:'3px solid #8C8F9B', borderRadius:"5px", backgroundColor:"#8C8F9B"}} className="my-3" ></div>
                        <div className="d-flex flex-column flex-wrap px-0 mt-2 px-4" >
                            <div className="d-flex flex-column flex-md-row" >
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta2"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Muy Relevante')}
                                        value={MUY_EFICAZ}
                                        parse={val => +val} />
                                </div>
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta2"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Relevante')}
                                        value={EFICAZ}
                                        parse={val => +val} />
                                </div>
                                <div className="flex-2" >
                                    <Field
                                        name="pregunta2"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Moderadamente relevante')}
                                        value={MODERADAMENTE_EFICAZ}
                                        parse={val => +val} />
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row" >
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta2"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Irrelevante')}
                                        value={INEFICAZ}
                                        parse={val => +val} />
                                </div>
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta2"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Muy irrelevante')}
                                        value={MUY_INEFICAZ}
                                        parse={val => +val} />
                                </div>
                                <div className="flex-2" />
                            </div>
                        </div>
                        <div className="d-flex flex-1 flex-column p-2 px-2">
                            <label htmlFor="pregunta2_texto" >{props.t('Por favor explique su respuesta')}</label>
                            <div className="px-3" >
                                <Field name="pregunta2_texto" component={renderTextArea} className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-1 flex-column py-2" >
                        <label htmlFor="pregunta3" className="azul text-sm bold" >{props.t('3-¿Cómo evalúa la organización del evento?')}</label>
                        <div style={{border:'3px solid #8C8F9B', borderRadius:"5px", backgroundColor:"#8C8F9B"}} className="my-3" ></div>
                        <div className="d-flex flex-column flex-wrap px-0 mt-2 px-4" >
                            <div className="d-flex flex-column flex-md-row" >
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta3"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Muy bien organizado')}
                                        value={MUY_EFICAZ}
                                        parse={val => +val} />
                                </div>
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta3"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Bien organizado')}
                                        value={EFICAZ}
                                        parse={val => +val} />
                                </div>
                                <div className="flex-2" >
                                    <Field
                                        name="pregunta3"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Moderadamente bien organizado')}
                                        value={MODERADAMENTE_EFICAZ}
                                        parse={val => +val} />
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row" >
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta3"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Mal organizado')}
                                        value={INEFICAZ}
                                        parse={val => +val} />
                                </div>
                                <div className="flex-1" >
                                    <Field
                                        name="pregunta3"
                                        component={renderFieldRadio}
                                        type="radio"
                                        label={props.t('Muy mal organizado')}
                                        value={MUY_INEFICAZ}
                                        parse={val => +val} />
                                </div>
                                <div className="flex-2" />
                            </div>
                        </div>
                        <div className="d-flex flex-1 flex-column p-2 px-2">
                            <label htmlFor="pregunta3_texto" >{props.t('Por favor explique su respuesta')}</label>
                            <div className="px-3" >
                                <Field name="pregunta3_texto" component={renderTextArea} className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-1 flex-column pt-4">
                        <label htmlFor="pregunta4" className="azul text-sm bold"  >{props.t('4-¿Cuáles aspectos del evento le parecieron más valiosos?')}</label>
                        <div style={{border:'3px solid #8C8F9B', borderRadius:"5px", backgroundColor:"#8C8F9B"}} className="my-3" ></div>
                        <div className="px-3" >
                            <Field name="pregunta4" component={renderTextArea} className="form-control"/>
                        </div>
                    </div>
                    <div className="d-flex flex-1 flex-column pt-4">
                        <label htmlFor="pregunta5" className="azul text-sm bold" >{props.t('5-¿Cuáles aspectos del evento le parecieron menos valiosos?')}</label>
                        <div style={{border:'3px solid #8C8F9B', borderRadius:"5px", backgroundColor:"#8C8F9B"}} className="my-3" ></div>
                        <div className="px-3" >
                            <Field name="pregunta5" component={renderTextArea} className="form-control"/>
                        </div>
                    </div>
                    <div className="d-flex flex-1 flex-column pt-4">
                        <label htmlFor="pregunta6" className="azul text-sm bold" >{props.t('6-¿Por favor deje sus sugerencias de mejora?')}</label>
                        <div style={{border:'3px solid #8C8F9B', borderRadius:"5px", backgroundColor:"#8C8F9B"}} className="my-3" ></div>
                        <div className="px-3" >
                            <Field name="pregunta6" component={renderTextArea} className="form-control"/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center py-4">
                        <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                    </div>
                </div>
            </form>
        </LoadMask>
    )
}

export default reduxForm({
    form: 'CalificacionForm',
    validate: (data,props) => {
        return validate(data, {
            'pregunta4': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta5': validators.exists()(`${props.t('Campo requerido')}`),
            'pregunta6': validators.exists()(`${props.t('Campo requerido')}`),

        })
    },

})(withTranslation()(Form));
