import React from "react";
import { Progress, Input, Form } from 'antd';
import { withTranslation } from  'react-i18next';
import FileImgUploder from '../FileUploader/FileImgUploader';

const avatar = require("../../../../../assets/img/avatar.png");
const Img = require('../../../../../assets/img/icono-imagen.png')


class Comentario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            contenido:'',
            imagen:null,
            imagenSrc: '',
            loaded: false,
            progress: {
                conteo: 0,
                porcentaje: 0,
            },
        }
    }
    componentDidMount=()=>{
        if(this.props.item&&this.props.item.contenido){
            this.setState({contenido:this.props.item.contenido});
        }
        if(this.props.item&&this.props.item.imagen){
           this.setState({imagenSrc:this.props.item.imagen});
           this.setState({loaded:true});
        }
    }
    setProgress = (value) => {
        const texto = String(value);
        const conteo = texto.length;
        const porcentaje = conteo / 2.8;
        this.setState({ progress: { conteo, porcentaje } });
        this.setState({contenido:value});
        if(conteo<=0){
            this.setState({disabled:true});
        }else{
            this.setState({disabled:false});
        }
    };
    sendEditar=()=>{
        const comentario = {};
        comentario.contenido= this.state.contenido;
        comentario.imagen = this.state.imagen;
        comentario.publicacion = this.props.item.publicacion;
        if(this.props.item.id && this.props.item.publicacion){
            comentario.publicacion= this.props.item.publicacion;
            comentario.id= this.props.item.id;
            this.props.onEditar(comentario);
            this.props.onCancelar();
        }

    }
    setImagen = (file) =>{
        this.setState({imagen:file.file, 
            imagenSrc:file.imageSrc, 
            loaded:file.loaded,
            disabled:false,
        })
    }
    removeImagen = ()=>{
        this.setState({imagen:null, imagenSrc:'', loaded:false});

    }

    render(){
        const { me, isReply } = this.props;
        return (
            <div className="d-flex" >
                <div className="d-flex flex-column flex-1 py-1">
                    <div className="d-flex flex-grow-0">
                        <textarea
                            maxLength="280"
                            placeholder={this.props.t("Escribe un comentario")}
                            style={{ resize: 'none' }}
                            rows={3}
                            className="editar-area"
                            onChange={(e)=>this.setProgress(e.target.value)}
                            value={this.state.contenido}
                        />
                    </div>
                    <div className="d-none flex-row d-md-flex">
                        <div className="d-flex flex-1 justify-content-start pt-1" >
                            <button className="btn-sm btn-primary btn-sm ml-2" onClick={(e)=>{this.sendEditar()}} disabled={this.state.disabled}>
                                { this.props.t('Guardar') }
                            </button>
                            <button className="btn-sm btn-secondary  ml-2" onClick={(e)=> {this.props.onCancelar()}}>
                                { this.props.t('Cancelar') }
                            </button>
                        </div>
                        <div className="d-flex flex-1 justify-content-end align-items-center">
                                <span className='gris text-xs pr-2'>{this.state.progress.conteo}/280</span>
                                <div className="d-flex" >
                                    <Progress percent={this.state.progress.porcentaje}  style={{width:"2.5rem"}}/>
                                </div>
                                <div className="d-flex ml-3">
                                    <FileImgUploder width="1rem" height="1rem" imageSrc={Img} onFileChange={this.setImagen} />
                                </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column-reverse d-md-none">
                        <div className="d-flex flex-1 justify-content-start pt-1" >
                            <button className="btn-sm btn-primary btn-sm ml-2" onClick={(e)=>{this.sendEditar()}} disabled={this.state.disabled}>
                                { this.props.t('Guardar') }
                            </button>
                            <button className="btn-sm btn-secondary  ml-2" onClick={(e)=> {this.props.onCancelar()}}>
                                { this.props.t('Cancelar') }
                            </button>
                        </div>
                        <div className="d-flex flex-1 justify-content-end align-items-center">
                            <span className='gris text-xs pr-2'>{this.state.progress.conteo}/280</span>
                            <Progress percent={this.state.progress.porcentaje}  style={{width:"2.5rem"}}/>
                            <div className="d-flex ml-3">
                                <FileImgUploder width="1rem" height="1rem" imageSrc={Img} onFileChange={this.setImagen} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  flex-column flex-1 align-items-center">
                        {this.state.loaded && (
                            <React.Fragment>
                                <div className="d-flex flex-row justify-content-end col-12 col-md-8 col-lg-6" 
                                    style={{position:"relative", bottom:"-1.8rem", cursor:"pointer",zIndex:"1"}}  
                                    onClick={()=>this.removeImagen()}>
                                    <li className="blanco bg-gris p-2 fa fa-times fa-lg"  style={{borderRadius:"5px"}}/>
                                </div>
                                <div className="d-flex col-12 col-md-8 col-lg-6 pb-3">
                                    <img src={this.state.imagenSrc} style={{maxWidth:"100%" }} />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
        
                </div>
            </div>
        );
    }
};

export default withTranslation()(Comentario);
