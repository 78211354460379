import React, { useEffect, useState } from 'react';
import Select, { Creatable, Async } from 'react-select';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DayPicker from '../DayPicker';
import FileUploader from '../FileUploader/FileUploader';
import DatePicker from "react-datepicker";
import { Progress, DatePicker as DatePickerAnt } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/dist/antd.css';
import CountrySelect from 'react-bootstrap-country-select';
import _ from "lodash";
import moment from 'moment';
import 'moment-timezone';
import DropdownDatePicker from '../DropDownPicker';
import { getI18n } from 'react-i18next'
import '../../../../i18nextInit'

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { COUNTRIES } from '../../../../utility/paises'

const logoUpload = require('assets/img/iconos/12-Img.png')
// Iconos para el WYSWYG
const bold = require('assets/img/iconos/bold.png');
const italica = require('assets/img/iconos/italica.png');
const subrayado = require('assets/img/iconos/subrayado.png');
const tachado = require('assets/img/iconos/tachado.png');
const monospace = require('assets/img/iconos/monospace.png');
const superIndice = require('assets/img/iconos/superIndice.png');
const subIndice = require('assets/img/iconos/subIndice.png');
const list1 = require('assets/img/iconos/list1.png');
const list2 = require('assets/img/iconos/list2.png');
const sangriaLeft = require('assets/img/iconos/sangriaLeft.png');
const sangriaRight = require('assets/img/iconos/sangriaRight.png');
const alignLeft = require('assets/img/iconos/alignLeft.png');
const alignCenter = require('assets/img/iconos/alignCenter.png');
const alignRight = require('assets/img/iconos/alignRight.png');
const alignJustify = require('assets/img/iconos/alignJustify.png');
const colorPicker = require('assets/img/iconos/colorPicker.png');
const link = require('assets/img/iconos/link.png');
const unlink = require('assets/img/iconos/unlink.png');
const ctrlZ = require('assets/img/iconos/ctrlZ.png');
const ctrlY = require('assets/img/iconos/ctrlY.png');

export const renderField = ({
                                input, placeholder, type, meta: { touched, error },
                            }) => {
    const invalid = touched && error;
    return (
        <div className="w-100">
            <input
                {...input}
                placeholder={placeholder}
                type={type}
                className={classNames('form-control', { 'is-invalid': invalid })}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};
// progress se agregar par obtener el procreso de conteo de caracteres text area
export const renderTextArea = ({
                                   input, placeholder, rows, meta: { touched, error }, progress, maxLength, disabled
                               }) => {
    const invalid = touched && error;
    return (
        <div className="w-100">
            <textarea
                {...input}
                maxLength={maxLength ? maxLength: null}
                placeholder={placeholder}
                style={{ resize: 'none' }}
                rows={rows || 3}
                disabled={ disabled ? disabled: false}
                className={classNames('form-control', { 'is-invalid': invalid })}
            />    {
                progress && (
                    <div className="progress__bar">
                        <span className='gris text-xs flex-2'>{progress.conteo}/280</span>
                        <Progress percent={progress.porcentaje} className="flex-1 pb-1" />
                    </div>

                )

            }
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumber = ({
                                 input, decimalScale, placeholder, meta: { touched, error }, prefix="", suffix="", numberFormat,
                             }) => {
    const invalid = touched && error;
    return (
        <div className="w-100">
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrency = ({
                                   input, meta: { touched, error }
                               }) => {
    const invalid = touched && error;
    return (
        <div className="w-100">
            <CountrySelect
                className={classNames('form-control', { 'is-invalid': invalid })}
                value={input.value}
                onChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderSwitch = ({
    input, meta: { touched, error }, label, disabled,
}) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center">
            <Switch
                onColor="#BE7D3C"
                height={25}
                width={55}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
            // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFieldCheck = ({ input, label, value, disabled, onCambio, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        onChange={(value) => {
                            input.onChange(value);
                            if (onCambio) onCambio(value);
                        }}
                        checked={input.value}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderFieldRadio = ({ input, label, value, disabled, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderFieldRadio2 = ({ input, label, checked, value, disabled, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    // checked={checked ? checked : input.checked}
                    // value={value}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

const multiSelecCustomStyles = {
    // Tomado de https://github.com/JedWatson/react-select/issues/1322#issuecomment-436615179
    control: base => ({
        ...base,
        minHeight: 30,
        borderRadius: 25,
    }),
    // Tomado de https://react-select.com/styles#cx-and-custom-components
    multiValue: (styles, { data }) => {
        return {
            ...styles,
        };
    },
};

export const SelectField = (
    {
        input,
        disabled,
        isClearable,
        isMulti,
        isSearchable,
        options,
        onCambio,
        placeholder,
        totalOption,
        labelKey="label",
        valueKey="value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({...option, label: getI18n().t(option[labelKey]), value: option[valueKey]});
    });
    let value = input.value;
    if(Array.isArray(input.value)){
        for(var i=0; i < input.value.length; input.value[i]["label"]=getI18n().t(input.value[i++]["label"]));
        console.log("VALUEEEEEE", input.value)
    }
    if (value !== null && value !== undefined) {
        value = _.find(_options, {value});
    }
    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                styles={isMulti ? multiSelecCustomStyles: {} }
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    if (isMulti || totalOption) {
                        input.onChange(e ? e : null);
                        if (onCambio) onCambio(e);
                    } else {
                        input.onChange(e ? e[valueKey] : null);
                        if (onCambio) onCambio(e[valueKey]);
                    }
                }}
                value={isMulti ? input.value : value}
                isDisabled={disabled}
                placeholder={getI18n().t("Seleccionar")+"..."}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


export const TimeZoneSelectField =({ input,  meta: { touched, error } }) =>{
    const timeZones =()=> {
        const timeZones = moment.tz.names();
        const offsetTmz = [];

        for (const i in timeZones) {
            const tz = moment.tz(timeZones[i]).format('Z').replace(':00', '').replace(':30', '.5');
            let x = (tz === 0) ? 0 : parseInt(tz).toFixed(2);

            const timeZone = {
            label: `(GMT${moment.tz(timeZones[i]).format('Z')})${timeZones[i]}`,
            value: `${timeZones[i]}`,
            time: `${x}`,
            };
            offsetTmz.push(timeZone);
        }
        return _.sortBy(offsetTmz, [function (el) { return -(el.time); }]);
        }
    const invalid = touched && error;
    let value = input.value;
    if (value !== null && value !== undefined && value !== '') {
        value = _.find(timeZones(), (item)=> (item.value===value));
    } else {
        //SI NO TIENE ASIGNADO NINGUN VALOR, LE ASIGNA LA ZONA HORARIA DEL SISTEMA DEL USUARIO 
        //PARA EVITAR QUE QUEDE VACIO
        value = _.find(timeZones(), (item)=> (item.value===moment.tz.guess()));
        input.onChange(moment.tz.guess())
    }
    return(
        <div className={classNames({ 'is-invalid': invalid })}>
            <Select
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                options={timeZones()}
                onChange={(val) => input.onChange(val.value)}
                value={value} />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    )
}

export const AsyncSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        isMulti,
        onCambio,
        valueKey,
        labelKey,
        defaultOptions,
        meta: { touched, error }
    }) => {

    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder ? placeholder : getI18n().t("Seleccionar")+"..." }
                onChange={(e) => {
                    input.onChange(e ? e : null);
                    if (onCambio) onCambio(e);
                }}
                value={input.value}
                isDisabled={disabled}
                isMulti={isMulti}
                styles={isMulti ? multiSelecCustomStyles: {} }
                getOptionValue={(option) => (option[valueKey])}
                getOptionLabel={(option) => (option[labelKey])}
                defaultOptions={defaultOptions ? defaultOptions : true}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


export const CreatableSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        options,
        placeholder,
        labelKey="label",
        valueKey="value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({...option, label: option[labelKey], value: option[valueKey]});
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderCountrySelect = ({ input, meta: { touched, error }}) => {
    const invalid = touched && error;
    const options = []
    COUNTRIES.forEach(country => {
        options.push({
            data: country,
            value: country.name,
            label: (
                <span><i>{country.flag}</i> {country.name}</span>
            )
        })
    })
    var i = 0
    const [defaultValue, setDefaultValue] = useState(null)
    if(!defaultValue && input.value.name){
        options.forEach(item => {
            if(item.value === input.value.name)
                setDefaultValue(options[i])
            i++
        })
    }
    console.log(defaultValue)
    return (
        <div>
            <Select
                isClearable={true}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                isSearchable={true}
                options={options}
                placeholder={getI18n().t("Seleccionar")+"..."}
                value={defaultValue}
                onChange={item => {
                    input.onChange(item.data)
                    setDefaultValue(item)
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
    /*
    <CountrySelect
        className={classNames({ 'is-invalid': invalid })}
        value={input.value}
        onChange={(value) => {
            input.onChange(value);
        }}
        placeholder="Seleccionar..."
        // valueAs='id'
    />
    */
};


/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({photo, setFile, className, disabled, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploader
                disabled={disabled}
                img= {!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDayPicker = ({className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={e => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDatePicker = ({ className, disabled, onCambio, showTimeSelect, maxDate, minDate, todayButton, addClass, dateFormat, timeIntervals, timeFormat, placeholder, showPopperArrow, fecha_inicial, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                showPopperArrow={showPopperArrow ? showPopperArrow : false}
                dateFormat={dateFormat ? dateFormat : "DD/MM/YYYY"}
                timeFormat={timeFormat ? timeFormat : "HH:mm"}
                timeIntervals={timeIntervals ? timeIntervals : 15}
                timeCaption={getI18n().t("Hora")}
                placeholderText={placeholder}
                onChange={(e) => {
                    input.onChange(e);
                    if (!!onCambio) {
                        onCambio(e)
                    }
                }}
                showTimeSelect={showTimeSelect ? showTimeSelect : false}
                selected={input.value ? moment(input.value) : fecha_inicial}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                todayButton={todayButton}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    )
};


export const renderDatePicker2 = ({ className,input, placeholder, meta: { touched, error } })=>{
    const invalid = touched && error;
    return (
        <div className={classNames(`${className?className:''}`)}>
            <DatePickerAnt
                className={classNames('react-select-container', { 'is-invalid': invalid } )}
                placeholder={placeholder? placeholder : ''}
                onChange={(e) => {
                    input.onChange(e);
                }}
                value={input.value ? moment(input.value) : null}
             />
            {invalid && (
                <div className="d-flex invalid-feedback">
                    {error}
                </div>
            )}

        </div>

    )
}

export const renderDatePicker3 = ({input, noPasados, noFuturos, meta: { touched, error } })=>{
    const invalid = touched && error;
    return (
        <React.Fragment>
            <DropdownDatePicker noPasados={noPasados} noFuturos={noFuturos} {...input}/>
            {invalid && (
                <div className="d-flex invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
        )
}

export const renderMonthPicker = ({ className, disabled, onCambio, allowClear, addClass, placeholder, fecha_inicial, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePickerAnt
                format="MMMM/yyyy"
                picker="month"
                placeholder={placeholder ? placeholder : getI18n().t('Seleccionar')}
                onChange={(e) => {
                    input.onChange(e);
                    if (!!onCambio) {
                        onCambio(e)
                    }
                }}
                allowClear={allowClear ? allowClear : false}
                disabled={disabled ? disabled : false}
                // locale={locale}
                value={input.value ? moment(input.value) : fecha_inicial}
                className={classNames('form-control month-picker ', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    )
};

export const RenderImageUpload = ({ setFile, aspect, grid, multiple, className, imagen, crop=true }) => {
    const [fileList, setFileList] = useState([
    ]);

    useEffect(() => {
        if (imagen) {
            const files = [{...imagen, originFileObj: imagen}];
            console.log("files ", files)
            setFileList(files);
        }
    }, []);

    const onChange = ({ fileList: newFileList }) => {
        console.log("filelist ", newFileList)
        setFileList(newFileList);
        if (multiple) {
            setFile(newFileList);
        } else {
            setFile(newFileList[0].originFileObj);
        }
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    return (
        <div className={classNames('image-input', `${className}`, 'text-center')}>
            {crop == true ? 
                <ImgCrop
                    rotate
                    aspect={aspect ? aspect : 1}
                    modalOk={getI18n().t("Seleccionar")}
                    modalCancel={getI18n().t("Cancelar")}
                    modalTitle={getI18n().t("Recortar imagen")}
                    grid={grid ? grid : false}
                >
                    <Upload
                        action={`${window.location.origin}/api/user/fake_endpoint/`}
                        listType="picture-card"
                        className="avatar-uploader"
                        // showUploadList={false}
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        multiple={multiple ? multiple : false}
                    >
                        {(multiple) || fileList.length == 0 && (
                            <div className="d-flex flex-column align-items-center">
                                <div style={{width:"9.3rem"}}>
                                    <img style={{height:"6rem", widows:"5rem" }} src={logoUpload} ></img>
                                </div>
                            </div>
                        )}
                    </Upload>
                </ImgCrop> : 
                <Upload
                    action={`${window.location.origin}/api/user/fake_endpoint/`}
                    listType="picture-card"
                    className="avatar-uploader"
                    // showUploadList={false}
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    multiple={multiple ? multiple : false}
                >
                    {(multiple) || fileList.length == 0 && (
                        <div className="d-flex flex-column align-items-center">
                            <div style={{width:"9.3rem"}}>
                                <img style={{height:"6rem", widows:"5rem" }} src={logoUpload} ></img>
                            </div>
                        </div>
                    )}
                </Upload>
            }
        </div>
    );
};



export const RenderImageUploadPost = ({ setFile, aspect, grid, multiple, className, imagen, srcImagen }) => {
    const [fileList, setFileList] = useState([
    ]);

    useEffect(() => {
        if (imagen) {
            const files = [{...imagen, originFileObj: imagen}];
            console.log("files ", files)
            setFileList(files);
        }
    }, []);

    const onChange = ({ fileList: newFileList }) => {
        console.log("filelist ", newFileList)
        setFileList(newFileList);
        if (multiple) {
            setFile(newFileList);
        } else {
            setFile(newFileList[0].originFileObj);
        }
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    return (
        <div className={classNames('image-input',`${className}`, 'text-center')}>
            <Upload
                action={`${window.location.origin}/api/user/fake_endpoint/`}
                listType="picture-card"
                className="avatar-uploader"
                // showUploadList={false}
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
                multiple={multiple ? multiple : false}

            >
                {(multiple) || fileList.length == 0 && (
                    <div  className="d-flex flex-column align-items-center">
                        <div>
                            <img style={{height:"8rem", widows:"7rem" }} src={ srcImagen? srcImagen:logoUpload} ></img>
                        </div>

                    </div>
                )}
            </Upload>
        </div>
    );
};

export const renderEditorField = ({ defaultValue, input, disabled, height, height2, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    const op_toolbar = disabled ? [] : ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'history'];
    return (
        <div className={classNames({ 'is-invalid': invalid })}>
            <Editor
                editorState={defaultValue ? defaultValue : input.value ? input.value : EditorState.createEmpty()}
                wrapperClassName="editor-container"
                // wrapperStyle={disabled ? { height: height ? height : "20rem", border: "none" } : { height: height ? height : "20rem" }}
                toolbarClassName={`editor-toolbar ${disabled && 'disabled'}`}
                editorClassName="editor-content"
                // editorStyle={{ height: height2 ? height2 : height ? height : "20rem" }}
                onEditorStateChange={(value) => input.onChange(value)}
                toolbar={{
                    options: op_toolbar,
                    inline: {
                        bold: { icon: bold },
                        italic: { icon: italica },
                        underline: { icon: subrayado },
                        strikethrough: { icon: tachado },
                        monospace: { icon: monospace },
                        superscript: { icon: superIndice },
                        subscript: { icon: subIndice },
                    },
                    list: {
                        unordered: { icon: list1 },
                        ordered: { icon: list2 },
                        indent: { icon: sangriaLeft },
                        outdent: { icon: sangriaRight },
                    },
                    textAlign: {
                        left: { icon: alignLeft },
                        center: { icon: alignCenter },
                        right: { icon: alignRight },
                        justify: { icon: alignJustify },
                    },
                    colorPicker: {
                        icon: colorPicker,
                        className: "editor-colorpicker",
                        component: undefined,
                        popupClassName: undefined,
                        colors: [
                            '#0A1F40', '#363C3F', '#BE7D3C', '#5F5F5F', '#8C8F9B', '#AF5555', '#539241',
                            '#538BAF', '#E6E6E6', '#D6D6D6', '#CCAA4D', '#FFD1A4', '#1446A0', '#66C4FF',
                            '#FDCA40', '#844F39', '#54311A', '#FF9D3B', '#FFFFFF', '#000000'
                        ],
                    },
                    link: {
                        link: { icon: link},
                        unlink: { icon: unlink},
                        className: "editor-link",
                    },
                    history: {
                        undo: { icon: ctrlZ },
                        redo: { icon: ctrlY },
                    }
                }}
                readOnly={disabled ? disabled : false}
            />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};

export const RenderField = {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
    renderDatePicker,
    RenderImageUpload,
    renderCountrySelect,
    renderEditorField,
};
