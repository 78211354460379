import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { SPONSOR, PARTNER, ADMIN, GROWTH_ANGEL, USUARIO } from '../../../../utility/constants';
const { SubMenu } = Menu;

// Iconos del menú
const Home1 = require("../../../../../assets/img/menu/Home1.png");
const Home2 = require("../../../../../assets/img/menu/Home2.png");
const Configuracion1 = require("../../../../../assets/img/menu/Configuracion1.png");
const Configuracion2 = require("../../../../../assets/img/menu/Configuracion2.png");
const GrowGist1 = require("../../../../../assets/img/menu/GrowGist1.png");
const GrowGist2 = require("../../../../../assets/img/menu/GrowGist2.png");
const Intercambios1 = require("../../../../../assets/img/menu/Intercambios1.png");
const Intercambios2 = require("../../../../../assets/img/menu/Intercambios2.png");
const Talleres1 = require("../../../../../assets/img/menu/Talleres1.png");
const Talleres2 = require("../../../../../assets/img/menu/Talleres2.png");
const Mentorias1 = require("../../../../../assets/img/menu/Mentorias1.png");
const Mentorias2 = require("../../../../../assets/img/menu/Mentorias2.png");
const Panel1 = require("../../../../../assets/img/menu/Panel1.png");
const Panel2 = require("../../../../../assets/img/menu/Panel2.png");
const Familia1 = require("../../../../../assets/img/menu/Familiares1.png");
const Familia2 = require("../../../../../assets/img/menu/Familiares2.png");
const Beneficios1 = require("../../../../../assets/img/menu/Beneficios1.png");
const Beneficios2 = require("../../../../../assets/img/menu/Beneficios2.png");
const Perfil1 = require("../../../../../assets/img/menu/Perfil1.png");
const Perfil2 = require("../../../../../assets/img/menu/Perfil2.png");
const Salir1 = require("../../../../../assets/img/menu/Salir1.png");
const Salir2 = require("../../../../../assets/img/menu/Salir2.png");
const Reportes1 = require("../../../../../assets/img/menu/Reportes1.png");
const Reportes2 = require("../../../../../assets/img/menu/Reportes2.png");


const avatar = require("../../../../../assets/img/avatar.png");
const logo = require("../../../../../assets/header/Logo.png");

class SideBar extends Component {

    // submenu keys of first level
    rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

    state = {
        openKeys: [],
    };

    constructor(props) {
        super(props);
    }

    onOpenChange = openKeys => {
        if (openKeys && openKeys.length) {
            this.setState({ openKeys: [openKeys[openKeys.length - 1]] })
        } else {
            this.setState({ openKeys });
        }
    };

    navigate = (path) => {
        this.props.navToggle();
        this.props.history.push(path);
    }

    render() {
        const { toggleOpen, navToggle, logOut, me, ocultarMenu } = this.props;
        const url = window.location.href;

        return (
            <aside className={`${ocultarMenu ? 'd-lg-none' : ''} main-sidebar px-0 col-10 col-md-3 col-lg-2 ${toggleOpen ? '' : 'open'}`}>
                <div className="main-navbar">
                    <nav className="align-items-stretch bg-white flex-md-nowrap p-0 navbar navbar-light">
                        <div className="d-flex d-md-none flex-row flex-1 justify-content-center align-items-center mt-3">
                            <img src={logo} style={{ width: "7rem" }} />
                        </div>
                        <a href="#" onClick={navToggle} className="w-100 mr-0 navbar-brand" >
                            <div className="d-flex flex-column flex-1 justify-content-center align-items-center"
                                style={{ overflow: "hidden" }}>
                                <img className="perfil-img" src={me ? me.avatar ? me.avatar : avatar : avatar} alt="Perfil" />
                                <span className="perfil-nombre">
                                    {me ? `${me.nombres && me.nombres}` : 'dfdfdf'}
                                </span>
                            </div>
                        </a>
                        <div className="d-flex d-md-none flex-row flex-1 justify-content-center">
                            <a title={this.props.t("Notificaciones")} className="d-flex mr-4" onClick={()=>this.navigate("/notificaciones")}>
                                <i style={{ fontSize: "20px" }} className={`fas fa-bell ${url.includes("notificaciones") ? 'marron-claro' : 'blanco'}`} />
                            </a>
                            <a title={this.props.t("Mi perfil")} className="d-flex ml-4" onClick={()=>this.navigate("/mi-perfil")}>
                                <i style={{ fontSize: "20px" }} className={`fas fa-user ${url.includes("mi-perfil") ? 'marron-claro' : 'blanco'}`} />
                            </a>
                        </div>
                    </nav>
                </div>
                <div className="nav-wrapper w-100">
                    <Menu
                        mode="inline"
                        openKeys={this.state.openKeys}
                        onOpenChange={this.onOpenChange}
                        style={{ width: 256 }}
                    >
                        {(me&&((me.admin_empresa||me.colider) && me.tipo_usuario===USUARIO || me.tipo_usuario === ADMIN))&&(
                            <Menu.Item
                                key="home"
                                icon={<Fragment>
                                    <img src={Home1} className={`icon-menu1`} />
                                    <img src={Home2} className={`icon-menu2`} />
                                </Fragment>}
                                onClick={key => this.navigate('/')}
                            >
                                {this.props.t('Inicio')}
                            </Menu.Item>
                        )}
                        {(me && me.tipo_usuario !== PARTNER) && (
                            <Menu.Item
                                key="talleres"
                                icon={<Fragment>
                                    <img src={Talleres1} className={`icon-menu1`} />
                                    <img src={Talleres2} className={`icon-menu2`} />
                                </Fragment>}
                                onClick={key => this.navigate('/talleres')}
                            >
                                {this.props.t('Talleres')}
                            </Menu.Item>
                        )}
                        {(me && me.tipo_usuario !== PARTNER) && (
                            <Menu.Item
                                key="2"
                                icon={<Fragment>
                                    <img src={GrowGist1} className={`icon-menu1`} />
                                    <img src={GrowGist2} className={`icon-menu2`} />
                                </Fragment>}
                                onClick={key => this.navigate('/historias')}
                            >
                                Growth Gists
                            </Menu.Item>
                        )}
                        {(me && (me.tipo_usuario === ADMIN || me.tipo_usuario === GROWTH_ANGEL || (me.tipo_usuario == USUARIO && (me.admin_empresa||me.colider)))) && (
                            <Menu.Item
                                key="familyMeeting"
                                icon={<Fragment>
                                    <img src={Familia1} className={`icon-menu1`} />
                                    <img src={Familia2} className={`icon-menu2`} />
                                </Fragment>}
                                onClick={key => this.navigate('/family_meetings')}
                            >
                                {this.props.t('Reuniones familiares')}
                            </Menu.Item>
                        )}
                        {(me && me.tipo_usuario !== PARTNER) && (
                            <Menu.Item
                                key="intercambios"
                                icon={<Fragment>
                                    <img src={Intercambios1} className={`icon-menu1`} />
                                    <img src={Intercambios2} className={`icon-menu2`} />
                                </Fragment>}
                                onClick={key => this.navigate('/intercambios')}
                            >
                                {this.props.t('Intercambios')}
                            </Menu.Item>
                        )}
                        {(me && me.tipo_usuario !== PARTNER && me.tipo_usuario !== SPONSOR) && (
                            <Menu.Item
                                key="mentorias"
                                icon={<Fragment>
                                    <img src={Mentorias1} className={`icon-menu1`} />
                                    <img src={Mentorias2} className={`icon-menu2`} />
                                </Fragment>}
                                onClick={key => this.navigate('/mentorias')}
                            >
                                {this.props.t('Mentorías')}
                            </Menu.Item>
                        )}
                        {(me && me.tipo_usuario !== PARTNER) && (
                            <Menu.Item
                                key="panel"
                                icon={<Fragment>
                                    <img src={Panel1} className={`icon-menu1`} />
                                    <img src={Panel2} className={`icon-menu2`} />
                                </Fragment>}
                                onClick={key => this.navigate('/panel')}
                            >
                                {this.props.t('Panel')}
                            </Menu.Item>
                        )}
                        {(me && me.tipo_usuario !== GROWTH_ANGEL) && (
                            <Menu.Item
                                key="beneficios"
                                icon={<Fragment>
                                    <img src={Beneficios1} className={`icon-menu1`} />
                                    <img src={Beneficios2} className={`icon-menu2`} />
                                </Fragment>}
                                onClick={key => this.navigate('/beneficios')}
                            >
                                {this.props.t('Beneficios')}
                            </Menu.Item>
                        )}
                        {(me && (me.tipo_usuario === ADMIN || me.tipo_usuario === GROWTH_ANGEL)) && (
                            <SubMenu
                                key="reportes"
                                icon={<Fragment>
                                    <img src={Reportes1} className={`icon-menu1`} />
                                    <img src={Reportes2} className={`icon-menu2`} />
                                </Fragment>}
                                title={this.props.t('Reportes')}
                            >
                                <Menu.Item key="feedback" onClick={key => this.navigate('/reporte/calificaciones_eventos')}>{this.props.t('Retroalimentación')}</Menu.Item>
                            </SubMenu>
                        )}
                        {(me && (me.admin_empresa||me.colider)) && (
                            <SubMenu
                                key="config"
                                icon={<Fragment>
                                    <img src={Configuracion1} className={`icon-menu1`} />
                                    <img src={Configuracion2} className={`icon-menu2`} />
                                </Fragment>}
                                title={this.props.t('Configuraciones')}
                            >
                                {(me.tipo_usuario == USUARIO) && (
                                    <Menu.Item key="colaboradores" onClick={key => this.navigate('/colaboradores')}>{this.props.t('Colaboradores')}</Menu.Item>
                                )}
                                <Menu.Item key="mi-empresa" onClick={key => this.navigate('/mi-empresa')}>{this.props.t('Perfil Empresa')}</Menu.Item>
                                {(me.tipo_usuario == USUARIO) && (
                                    <Menu.Item key="examen_kpis" onClick={key => this.navigate('/examen_kpis')}>{this.props.t('Growth KPIs')}</Menu.Item>
                                )}
                                {(me.tipo_usuario == USUARIO) && (
                                    <Menu.Item key="pago_membresia" onClick={key => this.navigate('/pago_membresia')}>{this.props.t('Pago membresía')}</Menu.Item>
                                )}
                                {(me.tipo_usuario == USUARIO) && (
                                    <Menu.Item key="mis-tarjetas" onClick={key => this.navigate('/mis-tarjetas')}>{this.props.t('Tarjetas')}</Menu.Item>
                                )}
                            </SubMenu>
                        )}
                        <Menu.Item
                            key="exit"
                            icon={<Fragment>
                                <img src={Salir1} className={`icon-menu1`} />
                                <img src={Salir2} className={`icon-menu2`} />
                            </Fragment>}
                            onClick={key => { this.navigate('/login'); logOut(); }}
                        >
                            {this.props.t('Salir')}
                        </Menu.Item>
                    </Menu>
                </div>
                <div className="d-block d-lg-none menu-fondo" onClick={navToggle}></div>
            </aside>
        )
    }
}

export default withRouter(withTranslation()(SideBar));
