import React, { Component, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '../../../Utils/Cards/Card';
import { Collapse } from 'antd';
import { Tabs } from 'antd';
import { Tag } from 'antd';
import { ADMIN, GROWTH_ANGEL, getNivelRecomendacion, USUARIO, OPCIONAL, RECOMENDADO } from '../../../../../utility/constants';
import ArchivosUploader from '../../../Utils/ArchivoUploader/index';
import i18n from '../../../../../i18nextInit'
import Com from '../../../Utils/Comentarios';

import moment from 'moment';
import "moment-timezone";
import _ from 'lodash';
import { Tab } from 'react-bootstrap';

const defaultImg = require('../../../../../../assets/img/avatar.png');
const iconoMentoria = require('../../../../../../assets/img/iconos/mentoria.png');
const gmailCalender1 = require('../../../../../../assets/img/iconos/gmail1.png');
const gmailCalender2 = require('../../../../../../assets/img/iconos/gmail2.png');
const icsCalender1 = require('../../../../../../assets/img/iconos/calender1.png');
const icsCalender2 = require('../../../../../../assets/img/iconos/calender2.png');

const imgMentoria = require('../../../../../../assets/img/mentoria.png');

const Evaluaciones = (props) => {
    const { item, loader, me } = props;
    return (
        <div className="d-flex flex-column pb-3 flex-1 px-lg-1">
            <div className="d-flex flex-column">
                {(item.evaluaciones) && (
                    item.evaluaciones.map(item2 => (
                        (me) && (me.tipo_usuario == ADMIN || me.tipo_usuario == GROWTH_ANGEL || item2.visible) && (
                            <div key={item2.id} className="d-flex flex-row flex-1 py-2 mb-2 borde-bottom">
                                <div className="d-flex flex-row flex-2 align-items-center mx-lg-5">
                                    <div className="circulo-icono borde-celeste-oscuro ml-3 ml-lg-0">
                                        <i className="fas fa-book celeste-oscuro"></i>
                                    </div>
                                    <span className="text-xs azul-gris-oscuro ml-3">{item2.nombre}</span>
                                </div>
                                <div className="d-flex flex-row flex-1 align-items-center justify-content-end mx-lg-5">
                                    {(me) && (me.tipo_usuario == ADMIN || me.tipo_usuario == GROWTH_ANGEL) ? (
                                        (item2.visible) ? (
                                            <div>
                                                <Link style={{ width: "10rem" }} to={`/tablero/${item2.id}`} className="btn btn-option-one-fill">{props.t('Ver resultados')}</Link>
                                            </div>
                                        ) : (
                                                <button style={{ width: "10rem" }} type="button" onClick={() => props.activarEvaluacion(item2.id)} className="btn btn-secondary">{props.t('Activar')}</button>
                                            )
                                    ) : (
                                            (me) && (me.tipo_usuario == USUARIO) && (
                                                (item2.respuesta_oficial ? (
                                                    <Link style={{ width: "10rem" }} to={`/tablero/${item2.id}`} className="btn btn-option-one-fill">
                                                        {props.t('Ver resultados')}
                                                    </Link>
                                                ) : (
                                                        <Link style={{ width: "10rem" }} to={`/evaluacion/${item2.id}`} className="btn btn-option-one">
                                                            {props.t('Evaluar')}
                                                        </Link>
                                                    ))
                                            ))}
                                </div>
                            </div>
                        )
                    ))
                )}
            </div>
        </div>
    )
}

const DetalleTab1 = (props) => {
    const { item, loader, me } = props;
    const nivel = item.pre_workshop ? getNivelRecomendacion(me, item.tipo_preworkshop) : null;

    const getYaAsignada = () => {
        const ya_asignada = _.find(item.slots, _item => _item.empresa ? _item.empresa.id == me.empresa : false)
        return ya_asignada ? true : false
    }
    const [comentar, setComentar] = useState(true);

    const getOpenDefault = () => {
        if (me.admin_empresa||me.colider) {
            const ya_asignada = _.find(item.slots, _item => _item.empresa ? _item.empresa.id == me.empresa : false)
            if (ya_asignada == undefined)
                return 'panel-slots'
            if (item.tiene_drill)
                return 'panel-drill'
            return 'panel-colaboradores'
        }
        return 'panel-evaluaciones'
    }

    const getIcs = () =>{
        const { id } = props.item;
        let endpoint = `/api/evento_read/${id}/obtener_ics?`;
        endpoint += `auth_token=${localStorage.getItem("token")}`
        window.open(endpoint, '_blank');
    }

    
    const lang = i18n.language ? i18n.language : 'en'

    return (
        <Card noPadding={true}>
            <div className='card-content-form mx-0 mx-md-3 flex-column px-3 p-md-0 pt-3 pb-0 pt-md-2'>
                <div className="card-post-header borde-bottom py-1">
                    <div className="d-flex align-items-center justify-content-center flex-1">
                        <img src={iconoMentoria} style={{ width: "2rem" }} />
                    </div>
                    <div className="d-flex flex-column flex-5 pr-2">
                        <div className="d-flex flex-row align-items-center flex-1">
                            <span className="gris-oscuro h5">
                                {item.titulo}
                            </span>
                        </div>
                        <div className="d-flex flex-row align-items-center flex-1">
                            <Link className="link-perfil" to={`/perfil/${item.publicado_por ? item.publicado_por.id : 0}`}>
                                {props.t('Publicado por')} {item.publicado_por ? item.publicado_por.nombres : ''}
                                &nbsp;{me && me.timezone ? moment(item.creado).tz(me.timezone).locale(lang).fromNow() : moment(item.creado).locale(lang).fromNow()}
                            </Link>
                        </div>
                    </div>
                    {(item.proximo_taller) && (
                        <div className="d-none d-lg-flex flex-row align-items-center flex-1">
                            <Tag color={"warning"}>
                                {props.t('Próximo evento')}
                            </Tag>
                        </div>
                    )}
                    <div className="d-none d-lg-flex flex-row align-items-center flex-1">
                        {(item.pre_workshop) && (
                            <Tag color={nivel ? nivel.tag : "success"}>
                                {nivel == null ? props.t('Pre-workshop') : nivel.nivel == OPCIONAL ? props.t('Opcional')
                                    : nivel.nivel == RECOMENDADO ? props.t('Recomendado')
                                        : props.t('Muy recomendado')}
                            </Tag>
                        )}
                    </div>
                </div>

                <div className="d-flex flex-column-reverse flex-lg-row flex-1 borde-bottom py-lg-3">
                    {(item.imagen) ? (
                            <div className="d-flex flex-1 my-3 my-lg-0">
                                <img src={item.imagen} style={{ width: "100%" }} />
                            </div>
                        ):(
                            <div className="d-flex flex-1 my-3 my-lg-0">
                                <img src={imgMentoria} style={{ width: "100%" }} />
                            </div>
                        )}
                    <div className="d-flex flex-column flex-1 px-4 pt-3 pt-lg-0">
                        <div className={`d-flex flex-row flex-1 align-items-center ${item.link&&'borde-bottom'} mt-lg-0`}>
                            <div className="d-flex flex-column flex-1">
                                <i className="fas fa-calendar-alt azul-gris-oscuro"></i>
                                <span className="text-xs azul-gris-oscuro mb-1">{props.t('Fecha')}</span>
                                <span className="text-sm celeste-oscuro">{me && me.timezone ? moment(item.fecha_inicio).tz(me.timezone).format("MMM-DD-YYYY") : moment(item.fecha_inicio).format("MMM-DD-YYYY")}</span>
                            </div>
                            <div className="d-flex flex-column flex-1">
                                <i className="fas fa-clock azul-gris-oscuro"></i>
                                <span className="text-xs azul-gris-oscuro mb-1">{props.t('Hora')}</span>
                                <span className="text-sm celeste-oscuro">{me && me.timezone ? moment(item.fecha_inicio).tz(me.timezone).format("hh:mm a z") : moment(item.fecha_inicio).format("hh:mm a z")}</span>
                            </div>
                        </div>  
                            
                        {(item.calender)&&(
                            <div className="d-flex flex-row flex-column justify-content-center flex-1 borde-bottom py-3">
                                <div className='d-flex flex-row' >
                                    <span className='azul-gris-oscuro flex-2' >
                                        {props.t('Agregar evento a su Google Calendar')}
                                    </span>
                                    <button type='button' className='btn-xs btn-secondary-fill btn-icon ml-md-1 flex-1' onClick={e=>window.open(item.calender,'_blank')}>
                                        {props.t('Gmail')} 
                                        <img src={gmailCalender1} className='icon-btn-1 ml-1'/> 
                                        <img src={gmailCalender2} className='icon-btn-2 ml-1' /> 
                                    </button>
                                </div>
                                <div className='d-flex flex-row pt-3' >
                                    <span className='azul-gris-oscuro flex-2'>
                                        {props.t('Agregar evento a su iCal/Outlook')}
                                    </span>
                                    <button type='button' className='btn-xs btn-secondary-fill ml-md-1 btn-icon flex-1' onClick={e=>getIcs()}>
                                        {props.t('Outlook')}
                                        <img src={gmailCalender1} className='icon-btn-1 ml-1'/> 
                                        <img src={gmailCalender2} className='icon-btn-2 ml-1' /> 
                                    </button>
                                </div>
                            </div>
                        )}
                        {(item&&item.link)&&(
                            <div className="d-flex flex-row flex-1 justify-content-end py-3 flex-column flex-md-row">
                                <div className='d-flex flex-column' >
                                    <button onClick={props.obtenerAcceso} disabled={!item.acceder_taller} className="btn btn-primary mb-3">
                                        {props.t('Entrar a videollamada')}
                                    </button>
                                    {(me && (me.tipo_usuario === ADMIN)) && (
                                        <button onClick={e=>props.generarLink(item.link_host ? true: false)}
                                            className="btn btn-secondary-fill">{!item.link_host ? props.t('Generar Link') : props.t('Volver a generar Link')}</button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex flex-column flex-1 pt-3 px-lg-3">
                    <div className="w-100 px-3" dangerouslySetInnerHTML={{ __html: item.descripcion ? item.descripcion : '' }}/>
                </div>
                {(item&&item.archivos.length>0)&&(
                    <React.Fragment>
                        <div className="d-flex flex-column flex-wrap px-0 mb-3 pt-2">
                            <span className="text-sm azul-gris-oscuro bold">{props.t('Archivos para descargar')}</span>
                            <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                        </div>
                        <div className="pr-0 pr-md-5 mb-4">
                            <ArchivosUploader archivos={item.archivos} onlyLectura={true} />
                        </div>
                    </React.Fragment>
                )}
                {console.log("defatul ", getOpenDefault())}
                <Collapse defaultActiveKey={['panel-drill', 'panel-evaluaciones', 'panel-colaboradores', 'panel-slots', 'panel-growth_angels']}>
                    {(me && (me.admin_empresa||me.colider)) && (
                        <Collapse.Panel
                            header={
                                <div className="d-flex flex-row flex-1 py-2">
                                    <span className="azul-gris-oscuro bold text-sm ml-4" style={{ textTransform: "uppercase" }}>{props.t('Slots disponibles para la mentoría')}</span>
                                </div>
                            }
                            key='panel-slots'
                        >
                            <div className="d-flex flex-column flex-1 py-3 px-lg-1">
                                {(item.slots) && (
                                    item.slots.map(item2 => (
                                        <div key={item2.id} className="d-flex flex-row flex-1 py-2 mb-2 borde-bottom">
                                            <div className="d-flex flex-row flex-2 align-items-center pl-md-4">
                                                <span className="text-xs azul-gris-oscuro ml-3">
                                                    {`${props.t('De')} ${me && me.timezone ? moment(item2.fecha_inicio).tz(me.timezone).format('hh:mm a z') : moment(item2.fecha_inicio).format('hh:mm a z')} ${props.t('a')} ${me && me.timezone ? moment(item2.fecha_fin).tz(me.timezone).format('hh:mm a z') : moment(item2.fecha_fin).format('hh:mm a z')}`}
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column flex-4 align-items-center">
                                                <div className="d-flex flex-column justify-content-start" >
                                                    <span className="text-xs azul-gris-oscuro bold" >Growth angels:</span>
                                                    <span className="text-xs azul-gris-oscuro">
                                                        {item2.growth_angels.join(',  ')}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row flex-2 align-items-center justify-content-center">
                                                {(getYaAsignada()) ? (
                                                    <span className="text-center gris-oscuro">{item2.empresa ? item2.empresa.id == me.empresa ? props.t('Slot asignado') : '' : ''}</span>
                                                ) : (
                                                        (item2.empresa) ? (
                                                            <span className="text-center gris-oscuro">{props.t('Reservado')}</span>
                                                        ) : (
                                                                <button type="button" className="btn btn-secondary" onClick={() => props.openModalSlot(item2)}>{props.t('Reservar')}</button>
                                                            )
                                                    )}
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </Collapse.Panel>
                    )}
                    {(item.tiene_drill) && (
                        <Collapse.Panel
                            header={
                                <div className="d-flex flex-row flex-1 py-2">
                                    <span className="azul-gris-oscuro bold text-sm ml-4" style={{ textTransform: "uppercase" }}>{props.t('Drill')}</span>
                                </div>
                            }
                            key='panel-drill'
                        >
                            <div className="d-flex flex-column flex-1 py-3 px-lg-1">
                                <div className="d-flex flex-column flex-wrap px-0 mt-2 mb-3">
                                    <div className="d-flex flex-row justify-content-between flex-1">
                                        <span className="text-sm azul-gris-oscuro bold">{props.t('Instrucciones')}</span>
                                        <span className="text-sm celeste-oscuro">{props.t('Fecha limite')}: {moment(item.fecha_limite_entregables).format("MMM-DD-YYYY hh:mm a z")}</span>
                                    </div>
                                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                </div>
                                <div className="w-100 pt-3 px-3 px-md-3" dangerouslySetInnerHTML={{ __html: item.instrucciones ? item.instrucciones : '' }}></div>
                                <div className="d-flex flex-column flex-wrap px-0 mt-2 mb-3">
                                    <span className="text-sm azul-gris-oscuro bold">{props.t('Archivos del drill para descargar')}</span>
                                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                </div>
                                <div className="pr-0 pr-md-5">
                                    <ArchivosUploader archivos={item.archivos_drill} onlyLectura={true} />
                                </div>
                                {(item.acceder_taller && item.tiene_drill && (me.admin_empresa||me.colider)) && (
                                    <div className="d-flex flex-column flex-1">
                                        <div className="d-flex flex-column flex-wrap px-0 mt-2 mb-3">
                                            <span className="text-sm azul-gris-oscuro bold">{props.t('Archivos para subir')}</span>
                                            <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                        </div>
                                        <div className="w-100 pr-0 pr-md-5">
                                            <ArchivosUploader
                                                archivos={item.memo_drill ? item.memo_drill.archivos : []}
                                                upload={props.subirMemo}
                                                btnLabel={props.t('Subir archivos')}
                                                eliminar={props.eliminarArchivo}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        header={
                            <div className="d-flex flex-row flex-1 py-2">
                                <span className="azul-gris-oscuro bold text-sm ml-4" style={{ textTransform: "uppercase" }}>{props.t('Growth Angels')}</span>
                            </div>
                        }
                        key='panel-growth_angels'
                    >
                        <div className="d-flex flex-column flex-1 px-lg-1">
                            {(item.growth_angels && item.growth_angels.length) ? (
                                item.growth_angels.map((item2, index) => (
                                    <div key={index} className="d-flex flex-row flex-1 align-items-center borde-bottom py-2">
                                        <div className="flex-5">
                                            <img src={item2.avatar ? item2.avatar : defaultImg} className="collapse__logo ml-3"></img>
                                            <span className="pl-2 text-sm azul-gris-oscuro" >{item2.nombres}</span>
                                        </div>
                                        <div className="d-flex flex-1 justify-content-center">
                                            <Link to={`/perfil/${item2.id}`} className="btn btn-clear">
                                                <i className="fas fa-eye azul-oscuro" />
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                    <div className="d-flex flex-1 justify-content-center">
                                        <span className="text-sm bold celeste-oscuro">{props.t('Sin Growth Angels')}</span>
                                    </div>
                                )}
                        </div>
                    </Collapse.Panel>
                    {(me && (me.admin_empresa||me.colider)) && (
                        <Collapse.Panel
                            header={
                                <div className="d-flex flex-row flex-1 py-1">
                                    <span className="azul-gris-oscuro bold text-sm ml-4" style={{ textTransform: "uppercase" }}>{props.t('Colaboradores')}</span>
                                </div>
                            }
                            key='panel-colaboradores'
                        >
                            {(item&&item.link)&&(
                                <div className="d-flex flex-1 justify-content-start mt-4 ml-5">
                                    {(me && (me.admin_empresa||me.colider)) && (
                                        <button onClick={props.openModalColab} className="btn btn-secondary-fill">{props.t('Invitar colaboradores')}</button>
                                    )}
                                </div>
                            )}
                            <div className="d-flex flex-column flex-1 py-3 px-lg-1">
                                {(item.asignados_taller) && (
                                    item.asignados_taller.map(item2 => (
                                        <div key={item2.id} className="d-flex flex-row flex-1 py-2 mb-2 borde-bottom">
                                            <div className="d-flex flex-row flex-4 align-items-center mx-lg-5">
                                                <span className="text-xs azul-gris-oscuro ml-3">
                                                {item2.usuario.colider ? item2.usuario.nombres + ' ('+props.t('Colíder')+')' : 
                                                item2.usuario.admin_empresa ? item2.usuario.nombres + ' ('+props.t('CEO')+')' : item2.usuario.nombres}
                                                </span>
                                            </div>
                                            <div className="d-flex flex-row flex-1 align-items-center justify-content-end mx-lg-5">
                                                {!(item2.usuario.admin_empresa) && (!item2.usuario.colider) && (
                                                    <button type="button" className="btn-delete" onClick={() => props.eliminarAsignado(item2.id)}>
                                                        <i className="fas fa-times marron" style={{ fontSize: "20px" }} />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        header={
                            <div className="d-flex flex-row flex-1 py-1">
                                <span className="azul-gris-oscuro bold text-sm ml-4" style={{ textTransform: "uppercase" }}>{props.t('Autoevaluaciones')}</span>
                                {(me) && (me.tipo_usuario == ADMIN || me.tipo_usuario == GROWTH_ANGEL) && (!props.todasActivas()) && (
                                    <button style={{ width: "10rem" }} type="button" onClick={props.activarEvaluaciones} className="btn btn-secondary ml-auto mr-lg-5">{props.t('Activar todas')}</button>
                                )}
                            </div>
                        }
                        key='panel-evaluaciones'
                    >
                        <div className="d-flex flex-column flex-1 py-3 px-lg-1">
                            <Evaluaciones {...props} />
                        </div>
                    </Collapse.Panel>
                </Collapse>
                <div className="d-flex flex-row flex-1 borde-top">
                    <div className="d-flex align-items-center justify-content-center flex-1">
                        <i className="text-equal2 fas fa-comments celeste-oscuro"></i>
                        <span className="text-equal2 gris-oscuro ml-1">{item.cantidad_comentarios}</span>
                    </div>
                    <div className="d-flex flex-1">
                        <button type="button" onClick={e => setComentar(!comentar)} className={`btn-post-action azul-oscuro`}>
                            <i className="fas fa-comments azul-oscuro mr-1 py-3"></i>
                            {props.t('Ver comentarios')}
                        </button>
                    </div>
                </div>
                {(comentar && item.id) && (
                    <Com.Base publicacion={item} me={props.me} />
                )}
            </div>
        </Card>
    )
}

export default DetalleTab1;
