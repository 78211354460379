import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { actions } from '../../../../../redux/modules/cuenta/buscador';
import Busquedas from './Busquedas';

const ms2p = (state) => {
    return {
        ...state.buscador,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Busquedas);
