import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';
import Card from '../../../Utils/Cards/Card';
import { ENCUENTRO_GUERREROS, FAMILY_MEETING, HISTORIAS, INTERCAMBIO, MENTORIA, TALLER, BENEFICIO } from '../../../../../utility/constants';
import moment from 'moment';
import i18n from '../../../../../i18nextInit'

const avatar = require("../../../../../../assets/img/avatar.png");
const taller = require('../../../../../../assets/img/iconos/taller.png')
const mentoria = require('../../../../../../assets/img/iconos/mentoria.png')
const guerreros = require('../../../../../../assets/img/iconos/guerreros.png')
const familia = require('../../../../../../assets/img/iconos/familia.png')
const intercambios = require('../../../../../../assets/img/iconos/intercambios.png')
const beneficios = require('../../../../../../assets/img/iconos/beneficios.png')
const growgits = require('../../../../../../assets/img/iconos/growgits.png')


const PublicacionesTab = (props) => {
    const { publicaciones, page_pub } = props;

    const getIcono = (tipo) => {
        if (tipo == TALLER) return taller;
        if (tipo == MENTORIA) return mentoria;
        if (tipo == ENCUENTRO_GUERREROS) return guerreros;
        if (tipo == FAMILY_MEETING) return familia;
        if (tipo == INTERCAMBIO) return intercambios;
        if (tipo == BENEFICIO) return beneficios;
        return growgits;
    }

    const getLinkPublicacion = (tipo) => {
        if (tipo == TALLER) return 'taller';
        if (tipo == MENTORIA) return 'mentoria';
        if (tipo == ENCUENTRO_GUERREROS) return 'panel';
        if (tipo == FAMILY_MEETING) return 'family_meeting';
        if (tipo == INTERCAMBIO) return 'intercambio';
        if (tipo == BENEFICIO) return 'beneficio';
        return 'historia';
    }
    const getDescription = (descripcion=undefined)=>{
        const div = document.createElement("div");
        div.innerHTML = descripcion ? descripcion : ''
        const texto = String(div.textContent);
        return texto.substring(0, 50)
    }

    
    const lang = i18n.language ? i18n.language : 'en'

    return (
        <Card noPadding={true}>
            <div className="d-flex flex-column flex-1 py-3 px-3">

                <span className="text-xsm gris ml-4">{`${props.t('Mostrando')} ${publicaciones.results.length} ${props.t('resultados')}`}</span>
                <div className="d-flex flex-column flex-1 my-2">
                    <span className="text-sm bold azul-gris-oscuro ml-4">{props.t('Personas')}</span>
                    {publicaciones.results.map((item, index) => (
                        <Link key={index} to={`/${getLinkPublicacion(item.tipo_publicacion)}/${item.id}`} className="d-flex flex-row borde-bottom flex-1 py-3">
                            <div className="d-flex align-items-center justify-content-center flex-1">
                                <img style={{ width: "2.5rem" }} src={getIcono(item.tipo_publicacion)} />
                            </div>
                            <div className="d-flex flex-column justify-content-center flex-4">
                                <div className="d-flex flex-row align-items-center flex-1">
                                    {(item.tipo_publicacion == HISTORIAS || item.tipo_publicacion == INTERCAMBIO || item.tipo_publicacion == BENEFICIO) ? (
                                        <span className="text-sm bold gris-oscuro">{item.publicado_por ? item.publicado_por.nombres : ''}</span>
                                    ) : (
                                            <span className="text-sm bold gris-oscuro">{item.titulo}</span>
                                        )}
                                    <span className="text-sm bold gris-oscuro">&nbsp;/&nbsp;{moment(item.creado).locale(lang).fromNow()}</span>
                                </div>
                                <span style={{ width: "25rem" }} className="texto-cortado text-xs gris">{getDescription(item.descripcion)+'...'}</span>
                            </div>
                        </Link>
                    ))}
                </div>
                {(publicaciones.results.length) ? (
                    <div className="d-flex flex-row flex-1 align-items-center my-3">
                        <Pagination pageSize={10} showSizeChanger={false} current={page_pub} total={publicaciones.count} onChange={props.resultadosPublicaciones} />
                    </div>
                ) : (
                    <div className="d-flex flex-row flex-1 justify-content-center align-items-center my-3">
                        <span className="text-sm bold gris-oscuro">{props.t('Sin resultados')}</span>
                    </div>
                )}
            </div>
        </Card>
    )
}

export default PublicacionesTab;
