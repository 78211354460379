import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, SelectField, renderDatePicker3, renderFieldCheck, TimeZoneSelectField } from '../../Utils/renderField/renderField';
import { GENEROS } from '../../../../utility/constants';
import { withTranslation, getI18n } from 'react-i18next';
import CustomTooltip from '../../Utils/Tooltip';
import Terminos from '../../Utils/Terminos';
import '../../../../i18nextInit'

const validators = (values, props)=>{
    const errors= [];
    if (!values.telefono){
        errors.telefono= `${getI18n().t('Campo requerido')}`;
    }
    if (!values.cargo){
        errors.cargo = `${getI18n().t('Campo requerido')}`;
    }
    if (!values.fecha_nacimiento){
        errors.fecha_nacimiento = `${getI18n().t('Campo requerido')}`;
    }
    if (!values.genero){
        errors.genero = `${getI18n().t('Campo requerido')}`;
    }
    if (!values.timezone){
        errors.timezone = `${getI18n().t('Campo requerido')}`;
    }
    return errors;
}


const PasoDosForm = (props) => {
    const { handleSubmit, previousStep } = props;
    const [acepto, setAcepto] = React.useState(false);

    return (
        <form className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <div className="d-flex justify-content-between" >
                    <label htmlFor="fecha_nacimiento">{props.t('Fecha de nacimiento')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Seleccione el mes, dia y año de su fecha de nacimiento')+'.'} />
                </div>
                <Field name="fecha_nacimiento" label="Fecha nacimiento" component={renderDatePicker3} noFuturos={true} />
            </div>
            <div className="d-flex flex-row flex-wrap px-0 mt-2 form-group has-feedback">
                <div className="d-flex flex-column flex-2">
                    <label htmlFor="telefono" className="m-0">{props.t('Teléfono')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <Field name="telefono" component={renderField} type="text" className="form-control" />
                </div>
                <div className="d-flex flex-column justify-content-end flex-1">
                    <div className="d-flex justify-content-end">
                        <CustomTooltip msj={props.t('Ingrese su numero telefónico en formato internacional completo, incluyen un símbolo más (+) seguido del código de país, el código de ciudad(si utiliza) y el número de teléfono local')+'.'} />
                    </div>
                    <Field
                        name="tiene_whats"
                        label={props.t("Tiene what's app")}
                        component={renderFieldCheck}
                    />
                </div>
            </div>
            <div className="form-group has-feedback">
                <div className="d-flex justify-content-between" >
                    <label htmlFor="timezone">{props.t('Zona horaria')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Seleccione su zona horaria local, ejemplo si eres de Guatemala esta seria America/Guatemala')+'.'} />
                </div>
                <Field name="timezone" component={TimeZoneSelectField} className="d-flex flex-grow-1" />
            </div>
            <div className="form-group has-feedback">
                <div className="d-flex justify-content-between" >
                    <label htmlFor="cargo">{props.t('Puesto dentro de la empresa')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Ingrese el puesto que ocupa o desempeña dentro de la empresa')+'.'} />
                </div>
                <Field name="cargo" component={renderField} className="d-flex flex-grow-1" />
            </div>
            <div className="form-group has-feedback">
                <div className="d-flex justify-content-between" >
                    <label htmlFor="genero">{props.t('Género')} <span className="gris m-0 text-xsm">{props.t('(Obligatorio)')}</span></label>
                    <CustomTooltip msj={props.t('Seleccione su genero')+'.'} />
                </div>
                <Field name="genero" component={SelectField} options={GENEROS} />
            </div>
            <Terminos onChange={setAcepto} />
            <div className="d-none d-lg-flex buttons-box mt-4">
                <button type="button" onClick={previousStep} className="btn btn-secondary ml-3">{props.t('Regresar')}</button>
                <button type="submit" disabled={!acepto} className="btn btn-primary ml-3">{props.t('Registrar')}</button>
            </div>
            <div className="d-flex d-lg-none flex-column">
                <button type="button" onClick={previousStep} className="btn btn-secondary flex-1 mt-3">{props.t('Regresar')}</button>
                <button type="submit" disabled={!acepto} className="btn btn-primary flex-1 mt-3">{props.t('Registrar')}</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'RegistroColabForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: validators,
})(withTranslation()(PasoDosForm));
