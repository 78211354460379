import React, { Fragment} from 'react';


function Card(props) {
    const { border, noPadding, margen, className } = props;
    return (
        <Fragment>
            <div className={`mb-4 card ${border ? 'card-borde' : ''} ${margen ? 'mx-2' : ''} ${className}`}>
                <div className={`card-content ${noPadding ? ' p-0 ' : ' p-0 px-3 pt-3 '}`}>
                    {props.children}
                </div>
            </div>
        </Fragment>

    )
}

export default Card;
