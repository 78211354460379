import React, { Component } from "react";
import NotificationSystem from 'react-notification-system';
import { Route, Redirect, withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import login, { logOut, getMe, newToken, enviarCalificacion, cambiarIdioma } from "./redux/modules/cuenta/login";
import { obtenerEmpresas, obtenerEventos } from "./redux/modules/cuenta/perfil";
import { messaging } from "./utility/init-fcm";

// maquetado base
import SiderBar from './common/components/layout/Sidebar/SideBar';
import CardInteres from './common/components/Utils/Cards/CardInteres';
import CardProximosEventos from './common/components/Utils/Cards/CardProximosEventos';

import Navbar from "./common/components/layout/Navbar/Navbar";
import { VerifyLogin } from "./common/components/layout";
import LoaderCohorte from "./common/components/layout/VerifyLogin/LoaderCohorte";
import CalificarEvento from './common/components/App/CalificarEvento';
import { SPONSOR, PARTNER, USUARIO } from './utility/constants';


const info = require('../assets/img/iconos/info.png');
const header = require('../assets/header/1.png');

class PrivateRouteBase extends Component {
    notificationSystem = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: true,
            loader: false,
        };
    }

    async componentDidMount() {
        // FCM
        let userAgentString = navigator.userAgent;
        try {
            if (window.safari === undefined) {
                console.log("No es safari");
                messaging.requestPermission()
                    .then(() => {
                        this.pushToken(messaging);
                    })
                    .catch(function (err) {
                        console.log("Error al obtener el token.", err);
                    });
                navigator.serviceWorker.addEventListener("message", (message) => {
                    this.handleNotificacion(message);
                });
            } else {
                console.log("Es safari");
            }
        }catch(e){}
        //Se hacen los llamados al backend para mostrar las cartas de intereses.
        this.props.obtenerEmpresas();
    }

    navToggle = () => {
        this.setState({toggleOpen: !this.state.toggleOpen });
    };

    pushToken = async (messaging) => {
        const token = await messaging.getToken();
        this.props.newToken(token);
    };

    changeCohorte = (item) => {
        this.setState({ loader: true });
        setTimeout(() => {
            if (item && item.id) {
                localStorage.setItem('cohorte', item ? JSON.stringify(item) : null);
            } else {
                localStorage.removeItem('cohorte');
            }
            this.setState({ loader: false });
            location.reload();
        }, 3000)
    }

    handleNotificacion = (message) => {
        const { data: { 'firebase-messaging-msg-data': {notification}} } = message;
        // event.preventDefault();
        const notificacion = this.notificationSystem.current;
        notificacion.addNotification({
            children: (
                <div
                    className="h-100 w-100 d-flex"
                    onClick={() => notification.click_action ? window.location.replace(notification.click_action) : null}
                >
                    <div className="notification-icono pr-2">
                        <img src={info} className="push-icono" />
                    </div>
                    <div className="h-100 noti-min-heigth notification-body">
                        <p className="title mb-2">{notification.title}</p>
                        <p className='m-0'>{notification.body}</p>
                    </div>
                </div>
            ),
            level: 'info',
            position: 'tr',
            dismissible: true,
            autoDismiss: 0,
        });
    };

    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        const { getMe, login: { me } } = this.props;
        if (!!token && !!me.email) {
            return true;
        } else if(token) {
            getMe();
            return "Verifying"
        }
        return false;
    };

    calificarEvento = ()=>{
        const { login: { me } } = this.props;
        if (!!me && me.calificar_evento) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { component: Component, logOut, login: { me },getMe, ocultarLateral, ocultarMenu, cambiarIdioma, ...rest } = this.props;
        const { loader } = this.state;
        const isAuthenticated = this.isAuthenticated();
        const calificarEvento = this.calificarEvento();
        const url = window.location.href;

        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        (isAuthenticated === true) ? (<div>
                            {(me && me.bloqueo_kpis && !url.includes("examen_kpis") && me.admin_empresa) ? (
                                <Redirect
                                    to={{
                                        pathname: "/examen_kpis/true",
                                        state: { from: props.location }
                                    }}
                                />
                            ) : 
                            (me && me.bloqueo_kpis && !url.includes("examen_kpis") && me.colider) ? (
                                <Redirect
                                    to={{
                                        pathname: "/examen_kpis/true",
                                        state: { from: props.location }
                                    }}
                                />
                            ) : (
                                (me && me.bloqueo_pago && !url.includes("pago_membresia") && me.admin_empresa) ? (
                                    <Redirect
                                        to={{
                                            pathname: "/pago_membresia/true",
                                            state: { from: props.location }
                                        }}
                                    />
                                ) : <div></div>
                            )}
                        <div className="sticky-top">
                            <Navbar navToggle={this.navToggle} logOut={logOut} me={me} changeCohorte={this.changeCohorte} cambiarIdioma={cambiarIdioma}  />
                        </div>
                        <div>
                            <NotificationSystem ref={this.notificationSystem} />
                            <div className="navbar__back" style={{backgroundImage: `url(${header})`}}></div>
                                <SiderBar ocultarMenu={ocultarMenu} toggleOpen={this.state.toggleOpen} navToggle={this.navToggle} logOut={logOut} me={me} />
                                <main  className={`main-content p-0 ${ocultarMenu ? 'col-12' : 'col-sm-12 col-md-9 col-lg-10 offset-md-3 offset-lg-2 '} `}>
                                    <div className={`${ocultarLateral? '': 'd-flex flex-1' } main-center main-content-container pl-lg-4 pr-lg-0 px-0 container-fluid`}>
                                        <div className="flex-4">
                                            <Component {...props} />
                                        </div>
                                        {!ocultarLateral && (
                                            <div className="d-none d-lg-flex columna-intereses ml-2 justify-content-lg-start">
                                                <div className="d-flex flex-column" style={{height: "85vh", position: "absolute"}}>
                                                    <CardInteres me={me} small={true} empresas={this.props.perfil ? this.props.perfil.data : []} t={this.props.t} />
                                                    <CardProximosEventos me={me} />
                                                    {/* <CardInteres me={me} small={true} talleres={this.props.perfil ? this.props.perfil.data2 : []} t={this.props.t} /> */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </main>
                        </div>
                        <CalificarEvento me={me} calificarEvento={calificarEvento} loader={loader} onSubmit={this.props.enviarCalificacion} />
                        {(loader) && (
                            <LoaderCohorte {...this.props}/>
                        )}
                        </div>) : (
                            <VerifyLogin />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = state => ({ ...state });

const mdtp = { logOut, getMe, newToken, obtenerEmpresas, obtenerEventos, enviarCalificacion, cambiarIdioma  };

const ProtectedRoute = connect(
    mstp,
    mdtp
)(withRouter(withTranslation()(PrivateRouteBase)));

export default ProtectedRoute;

