import React from 'react';
import { api } from 'api';
import Com from './index';
import { NotificationManager } from 'react-notifications';
import LoadMask from '../LoadMask/LoadMask';
import { BENEFICIO } from '../../../../utility/constants';
import { withTranslation } from  'react-i18next';

class Comentarios extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            comentarios: [],
            page:1,
            next: null,
        }
    }

    componentWillMount = ()=>{
        this.getComentarios();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps!=this.props){
            this.setState({ comentarios: []})
            this.getComentarios();
        }
    }

    setContenido=(contenido)=>{
        this.setState({contenido:contenido.contenido});
        if(contenido.imagen)this.setState({imagen:contenido.imagen})
    }
    masComentarios=()=>{
        const nextPage = this.state.page +1
        const params={ page:nextPage };
        params.publicacion= this.props.publicacion.id;
        api.get('comentario', params).then((response) => {
            const comen = [...this.state.comentarios,...response.results];
            this.setState({comentarios:comen});
            this.setState({next:response.next});
            this.setState({page:nextPage});
        }).catch(() => {
            NotificationManager.error("Error", 'ERROR', 3000);
        }).finally(() => {
        });

        this.setState({page:nextPage});
    }
    getComentarios = ()=>{
        const params={ page:this.state.page };
        params.publicacion= this.props.publicacion.id;
        api.get('comentario', params).then((response) => {
            if(this.state.page>1){
                const comen = [...this.state.comentarios,...response.results];
                this.setState({comentarios:comen});
            }else{
                this.setState({comentarios:response.results});
            }
            this.setState({next:response.next});
        }).catch(() => {
            NotificationManager.error("Error", 'ERROR', 3000);
        }).finally(() => {
        });
    }

    render(){
        return(
            <React.Fragment>
                <div className={`flex-column bg-blanco px-4 px-md-5 py-2 d-flex`} >
                    {(this.props.publicacion.tipo_publicacion===BENEFICIO&&
                        this.props.publicacion.cantidad_comentarios<1)&&
                        (<span className="d-flex mx-auto" >{this.props.t('No hay comentarios')}</span>)}
                    <LoadMask loading={this.props.loader} blur>

                    {
                        this.state.comentarios &&
                            this.state.comentarios.map((comentario)=>{
                                return(
                                    <div className="d-flex  bg-blanco py-1" key={`com-${comentario.id}`} >
                                        <Com.Card   item={comentario} me={this.props.me} likes={!!this.props.likes ? this.props.likes : false}
                                        getComentarios={this.getComentarios} publicacion={this.props.publicacion} isReply={true}
                                        >
                                            <div className="d-flex flex-column flex-grow-1">
                                                {
                                                    comentario.sub_comentarios && comentario.sub_comentarios.map((subComentario)=>(
                                                        <div key={`sub-${subComentario.id}`} className="d-flex flex-grow-1 pt-3" >
                                                            <Com.Card item={subComentario} me={this.props.me}
                                                                getComentarios={this.getComentarios} publicacion={this.props.publicacion}
                                                                likes={!!this.props.likes ? this.props.likes : false}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Com.Card>
                                    </div>
                                )
                            }
                            )
                    }
                    {(this.state.next&&(
                        <div className="py-3" >
                            <span style={{cursor:"pointer"}} onClick={e =>this.masComentarios()} className="my-3 azul-oscuro bold" >{this.props.t('Ver mas comentarios')}</span>
                        </div>
                    ))}
                    {(this.props.publicacion&&this.props.publicacion.tipo_publicacion===BENEFICIO) ?(
                        <div>
                            {(!this.props.publicacion.vencida)&&(
                                <Com.FormPadre me={this.props.me}
                                    getComentarios={this.getComentarios}
                                    publicacion={this.props.publicacion} contenido={this.state.contenido} />)
                            }
                        </div>
                    ):(
                        <Com.FormPadre me={this.props.me}
                        getComentarios={this.getComentarios}
                        publicacion={this.props.publicacion} contenido={this.state.contenido} />

                    )}
                </LoadMask>
                </div>
            </React.Fragment>
        )
    }
}

export default withTranslation()(Comentarios);
