import React, { Component, Fragment } from 'react';
import Card from '../../../Utils/Cards/Card';
import { withTranslation } from 'react-i18next';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { Link, Redirect } from 'react-router-dom';
import { USUARIO, ADMIN, GROWTH_ANGEL } from '../../../../../utility/constants';
import CardInteres from '../../../Utils/Cards/CardInteres';
import ReactWhatsapp from 'react-whatsapp';
import { Menu, Dropdown } from 'antd';
import Compartir from './CompartirBadge';
import { api } from "api";


const fondo = require('../../../../../../assets/img/fondo-perfil.png');
const avatar = require("../../../../../../assets/img/avatar.png");


class VerPerfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }
    componentDidMount() {
        const id = this.props.match.params.id;
        const { leer, leerPerfil, obtenerEmpresas } = this.props;
        obtenerEmpresas();
        if (id) {
            leerPerfil(id);
        } else {
            leer();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const idAnterior = prevProps.match.params.id;
        const id = this.props.match.params.id;

        if (idAnterior != id) {
            const { leer, leerPerfil, obtenerEmpresas } = this.props;
            obtenerEmpresas();
            if (id) {
                leerPerfil(id);
            } else {
                leer();
            }
        }
    }

    onCompartir = () => {
        this.setState({ open: true });
    }

    onCloseModal = () => {
        this.setState({ open: false });
    }

    descargarBadge = (size=1) => {
        const windowOpen = window.open();
        api.get("user/badge_url", { size }).
        then((data) => {
            windowOpen.location = data;
        }).catch(() => {
            return [];
        });
    }

    menu = () => {
        return (
            <Menu>
                <Menu.Item>
                    <a onClick={() => this.descargarBadge(1)}>
                        {this.props.t('Pequeño')} 200px
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a onClick={() => this.descargarBadge(2)}>
                        {this.props.t('Mediano')} 300px
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a onClick={() => this.descargarBadge(3)}>
                        {this.props.t('Grande')} 400px
                    </a>
                </Menu.Item>
            </Menu>
        );
    }

    render() {
        const { item, loader, me, data } = this.props;
        const id = this.props.match.params.id;
        if (me.id == id) {
            return (<Redirect to="/mi-perfil" />);
        }
        console.log(this.state.open)
        return (
            <div className="mb-4 col-12 mt-0">
                <LoadMask loading={loader} blur>
                    <Card noPadding={true}>
                        <div className="d-flex flex-column flex-1">
                            <div className="perfil-portada" style={{ backgroundImage: `url(${fondo})` }}>
                                {(!id) ? (
                                    <Link to="/mi-perfil/editar" className="btn-action bg-marron-claro d-flex justify-content-center align-items-center float-right m-2">
                                        <i className="fas fa-pen-alt blanco"></i>
                                    </Link>
                                ) : (<div></div>)}
                            </div>
                            <div className="d-flex flex-column flex-md-row flex-1">
                                <div className="d-flex flex-1 justify-content-center perfil-avatar">
                                    <img className="perfil-avatar-img" src={item.avatar ? item.avatar : avatar} />
                                </div>
                                <div className="d-flex flex-column flex-md-row flex-3 align-items-center mb-3 perfil-datos">
                                    <div className="d-flex flex-column align-items-md-start align-items-center flex-2">
                                        <span className="text-md azul-gris-oscuro mt-3 mt-md-0">{item.nombres}</span>
                                        {(item.tipo_usuario == ADMIN) && (
                                            <span className="text-sm azul-gris-oscuro">{this.props.t('Administrador')}</span>
                                        )}
                                        {(item.tipo_usuario == GROWTH_ANGEL) && (
                                            <span className="text-sm azul-gris-oscuro">{this.props.t('Growth Angel')}</span>
                                        )}
                                        {(item.tipo_usuario == USUARIO) && (
                                            <span className="text-sm azul-gris-oscuro">{this.props.t('Empresa')} {item.empresa_nombre}</span>
                                        )}
                                        {(item.tipo_usuario == USUARIO) && (
                                            <span className="celeste-oscuro mt-1">{item.cargo}</span>
                                        )}
                                    </div>
                                    <div className="d-flex flex-column flex-md-row flex-2 mt-3 mt-md-0 px-3 px-sm-4 px-md-0 w-100">
                                        {(me.tipo_usuario == ADMIN || me.id == item.id) && (
                                            <div className="d-flex flex-column flex-2">
                                                {(item.tiene_whats) ? (
                                                    <label className="text-sm azul-gris-oscuro p-0 mb-0">
                                                        {this.props.t('Teléfono')} <span className="gris m-0 text-xsm">{this.props.t("(What's app)")}</span>
                                                    </label>
                                                ) : (
                                                        <label className="text-sm azul-gris-oscuro p-0 mb-0">{this.props.t('Teléfono')}</label>
                                                    )}
                                                {(item.tiene_whats) ? (
                                                    <div className="whatsapp">
                                                        <ReactWhatsapp
                                                            number={item.telefono ? item.telefono : "1-212-736-5000"}
                                                            message=""
                                                        >
                                                            <span className="celeste-oscuro">{item.telefono ? item.telefono : this.props.t('No específicado')}</span>
                                                        </ReactWhatsapp>
                                                    </div>
                                                ) : (
                                                        <span className="celeste-oscuro">{item.telefono ? item.telefono : this.props.t('No específicado')}</span>
                                                    )}
                                            </div>
                                        )}
                                        <div className="d-flex flex-column flex-3">
                                            <label className="text-sm azul-gris-oscuro p-0 mb-0">{this.props.t('Correo')}</label>
                                            <span className="celeste-oscuro">{item.email}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-1 flex-column flex-md-row" >
                                {(item.tiene_badge) && (
                                    <div className="d-flex flex-column flex-1 my-3 px-3 px-sm-4 px-lg-5">
                                        <div className="d-flex flex-row flex-wrap px-0">
                                            <span className="text-sm azul-gris-oscuro bold mr-4">{this.props.t('Logro')}</span>
                                            {(!id) && (
                                                <Fragment>
                                                    <Dropdown overlay={this.menu} placement="bottomCenter">
                                                        <div className='d-flex flex-row align-items-center text-touch mx-3'>
                                                            <i className="azul-gris-oscuro fas fa-download fa-xs px-2 pb-0"></i>
                                                            <span className="text-xs azul-gris-oscuro bold">{this.props.t('Descargar')}</span>
                                                        </div>
                                                    </Dropdown>
                                                    <div className='d-flex flex-row align-items-center text-touch' onClick={e => this.onCompartir()}>
                                                        <i className="azul-gris-oscuro fas fa-share-alt fa-xs px-2 pb-0"></i>
                                                        <span className="text-xs azul-gris-oscuro bold">{this.props.t('Compartir')}</span>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                        <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                        <div className="d-flex w-100 justify-content-center align-items-center mt-2">
                                            <img style={{ width: "70%" }} src={item.url_badge} />
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex flex-column flex-2 my-3 px-3 px-sm-4 px-lg-5">
                                    <div className="d-flex flex-column flex-wrap px-0 px-lg-4" >
                                        <span className="text-sm azul-gris-oscuro bold">{this.props.t('Biografía')}</span>
                                        <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                    </div>
                                    <div className="d-flex flex-1 mt-3 px-lg-4">
                                        <p>{item.descripcion ? item.descripcion : this.props.t('Sin descripción')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    {(me.tipo_usuario == USUARIO) && (
                        <div className="d-flex flex-row flex-1">
                            <CardInteres
                                fullWidth={true}
                                empresas={data}
                                t={this.props.t}
                            />
                        </div>
                    )}
                    <Compartir open={this.state.open} item={this.props.item} onClose={this.onCloseModal}/>
                </LoadMask>
            </div>

        )
    }
}

export default withTranslation()(VerPerfil)

