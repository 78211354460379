import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Async } from "react-select";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ADMIN, GROWTH_ANGEL, IDIOMAS_SISTEMA, PARTNER, SPONSOR } from "../../../../utility/constants";
import { api } from '../../../../utility/api';
import "antd/dist/antd.css";

import Search from "./SearchNavbar";
const Logo = require("assets/header/Logo.png");
const Logo2 = require("assets/header/Logo2.png");


const menu = (changeLanguage) => {
    return (
        <Menu>
            {IDIOMAS_SISTEMA.map((item) => (
                <Menu.Item key={item.key} onClick={(e) => changeLanguage(item)}>{item.name}</Menu.Item>
            ))}
        </Menu>
    );
};
class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            idioma: "English",
            key: "en",
            mostrarBuscador: false,
        };
    }

    componentDidMount = async () => {
        const { me } = this.props;
        if(me&&me.idioma){
            const idioma = IDIOMAS_SISTEMA.find(item=>item.key===me.idioma);
            if(idioma) this.changeLanguage(idioma);
        }
        try{
            const cohorte = localStorage.getItem('cohorte');
            if (cohorte){
                await api.post(`cohorte/${JSON.parse(cohorte).id}/verificar_cohorte`).catch(()=>{
                    localStorage.removeItem('cohorte');
                })
            }
        }catch(e){}
    }

    getCohorte = (search) => {
        return api
            .get("cohorte/obtener_cohortes", { search })
            .then((data) => {
                const results = [{ id: null, nombre: this.props.t('Todas') }];
                if (data) results.push(...data.results)
                return results;
            }).catch(() => {
                return [];
            });
    };

    changeLanguage(item) {
        const { i18n, cambiarIdioma  } = this.props;
        if(item){
            i18n.changeLanguage(item.key);
            this.setState({ idioma: item.name, key: item.key });    
            cambiarIdioma({'idioma':item.key});
        }
    }

    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    toggleBuscador = (mostrarBuscador) => {
        this.setState({ mostrarBuscador });
    }

    render() {
        const { navToggle, logOut, me } = this.props;
        const { mostrarBuscador } = this.state;
        const url = window.location.href;
        const cohorte = localStorage.getItem('cohorte')
        return (
            <nav className="header__container">
                <div className={`${mostrarBuscador ? 'd-none d-md-flex' : ''} header__logo`}>
                    <Link to="/" >
                        <img className="d-none d-lg-block" src={Logo}></img>
                    </Link>
                    <img
                        onClick={navToggle}
                        className="d-block d-lg-none logo-responsivo"
                        src={Logo2}
                    ></img>
                </div>
                <div className={`${mostrarBuscador ? 'd-flex d-md-none' : 'd-none'} header-buscador-responsivo ml-1`}>
                    <div className="d-flex flex-1 justify-content-center align-items-center">
                        <button type="button" onClick={() => this.toggleBuscador(false)} className="btn btn-clear">
                            <i style={{ fontSize: "1.5rem" }} className="fas fa-arrow-left blanco"></i>
                        </button>
                    </div>
                    <div className="d-flex flex-5 mr-2  pr-2">
                        <Search t={this.props.t} />
                    </div>
                </div>
                <div className={`d-none d-md-flex header__search justify-content-end`}>
                    <div className="flex-1 pl-3">
                        <Search t={this.props.t} />
                    </div>
                </div>
                <div className={`${mostrarBuscador ? 'd-none d-md-flex' : ''} header__side mr-3 mr-md-5 `}>
                    <div className="d-flex d-md-none flex-1"/>
                    <div className="d-flex flex-row align-items-center justify-content-end flex-2">
                        <Link title={this.props.t("Notificaciones")} className="d-none d-md-flex mr-lg-4 mx-sm-3 ml-sm-3" to="/notificaciones">
                            <i style={{ fontSize: "20px" }} className={`fas fa-bell ${url.includes("notificaciones") ? 'marron-claro' : 'blanco'}`} />
                        </Link>
                        <Link title={this.props.t("Mi perfil")} className="d-none d-md-flex mr-lg-4 mr-sm-3" to="/mi-perfil">
                            <i style={{ fontSize: "20px" }} className={`fas fa-user ${url.includes("mi-perfil") ? 'marron-claro' : 'blanco'}`} />
                        </Link>
                        {(me && ([ADMIN, GROWTH_ANGEL, PARTNER, SPONSOR].includes(me.tipo_usuario))) && (
                            <div className="mr-lg-4 mr-sm-3 w-70-responsivo">
                                <Async
                                    isClearable={false}
                                    backspaceRemovesValue={false}
                                    value={JSON.parse(cohorte)}
                                    isSearchable={true}
                                    loadOptions={this.getCohorte}
                                    getOptionValue={(option) => option["id"]}
                                    getOptionLabel={(option) => option["nombre"]}
                                    type="text"
                                    onChange={(e) => this.props.changeCohorte(e)}
                                    multi={false}
                                    autoload={false}
                                    cache={false}
                                    className={classNames(
                                        "filtro-cohortes"
                                    )}
                                    defaultOptions={true}
                                    placeholder={this.props.t("Todas las cohortes")}
                                />
                            </div>
                        )}
                        <div className={`${(me && me.tipo_usuario == ADMIN) ? '' : ''}`}>
                            <Dropdown
                                overlay={menu(this.changeLanguage.bind(this))}
                                className="d-none d-lg-block"
                                trigger={["hover"]}
                            >
                                <a
                                    className="ant-dropdown-link"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {this.state.idioma} <DownOutlined />
                                </a>
                            </Dropdown>
                            <Dropdown
                                overlay={menu(this.changeLanguage.bind(this))}
                                className="d-block d-lg-none ml-3"
                                trigger={["hover"]}
                            >
                                <a
                                    className="ant-dropdown-link"
                                    style={{ textTransform: "uppercase" }}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {this.state.key} <DownOutlined />
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={`${mostrarBuscador ? 'd-none' : 'd-flex d-md-none'} flex-1`}>
                    <button type="button" onClick={() => this.toggleBuscador(true)} className="btn btn-clear">
                        <i style={{ fontSize: "1.5rem" }} className="fas fa-search blanco"></i>
                    </button>
                </div>
            </nav>
        );
    }
}

export default withTranslation()(Navbar);
