import React, { useEffect } from 'react';
import { withTranslation  } from 'react-i18next';
import Modal from 'react-responsive-modal';
import Card from '../../../Utils/Cards/Card';
import Select from 'react-select'

import { api } from "api";

const facebook = require('../../../../../../assets/img/redes-sociales/facebook.png')
const linkedin = require('../../../../../../assets/img/redes-sociales/linkedin.png')
const twitter = require('../../../../../../assets/img/redes-sociales/twitter.png')
const pinterest = require('../../../../../../assets/img/redes-sociales/pinterest.png')


const options = [
    { value: '1', label: 'Pequeño 200x200' },
    { value: '2', label: 'Mediano 300x300' },
    { value: '3', label: 'Grande 400x400' }
  ]

const Compartir =(props)=>{

    const [select, setSelect]= React.useState(options[0])
    const [link, setLink]= React.useState('')

    useEffect(() => {
        api.get("user/badge_url", { size: select.value }).
        then((data) => {
            setLink(data);
        }).catch(() => {
        });
    }, [])

    const changeSize = (e) => {
        setSelect(e);
        api.get("user/badge_url", { size: e.value }).
        then((data) => {
            setLink(data);
        }).catch(() => {
        });
    }

    const urlConv=(url)=>{
        console.log(url, typeof(url))
        url = url.replace(/&/g, '%26');
        url = url.replace(/#/g, '%23');
        url = url.replace(/\+/g, '%2B');
        url = url.replace(/@/g, '%40');
        url = url.replace(/:/g, '%3A');
        return url;
    }

    const getHtml = () => {
        const { item } = props;
        return `<a href="${item.cohorte ? item.cohorte.link_informativo : ''}"><img src="${link}"/></a>`
    }

    const msj = props.t('Vean lo que obtuve al graduarme en Growmotor.io')

    const shareFacebook = () => {
        const windowOpen = window.open();
        api.get("user/badge_url", { size: select.value }).
        then((data) => {
            const imageUrl = urlConv(data);
            windowOpen.location = `https://www.facebook.com/sharer/sharer.php?u=${imageUrl}`;
        }).catch(() => {
        });
    }
    const shareTwitter = () => {
        const windowOpen = window.open();
        api.get("user/badge_url", { size: select.value }).
        then((data) => {
            windowOpen.location =`https://twitter.com/intent/tweet?url=${msj+' '+data}`;
        }).catch(() => {
        });
    }
    const shareLinkedin = (url) => {
        const windowOpen = window.open();
        api.get("user/badge_url", { size: select.value }).
        then((data) => {
            const imageUrl = urlConv(data);
            windowOpen.location = `https://www.linkedin.com/sharing/share-offsite/?url=${imageUrl}&title=${msj}`
        }).catch(() => {
        });
    }
    const sharePinterest = (url) => {
        const windowOpen = window.open();
        api.get("user/badge_url", { size: select.value }).
        then((data) => {
            const imageUrl = urlConv(data);
            windowOpen.location = `https://pinterest.com/pin/create/button/?url=&media=${imageUrl}&description=${msj}`;
        }).catch(() => {
        });
    }

    return(
        <Modal open={props.open} onClose={e=>props.onClose()}>
            <meta name="twitter:image" content={`${props.urlBadge}`}></meta>
            <Card className='p-2 p-md-5' >
                <div className="d-flex flex-column" >
                    <div className="pb-4">
                        <span className="text-sm azul-gris-oscuro regular" >
                            {props.t('Selección o copia el link para compartir el badge')}
                        </span>
                    </div>
                    <div className="flex-1 py-2" >
                        <Select options={options} onChange={e=>changeSize(e)} className="react-select-container" value={select} />

                    </div>
                    <div className="d-flex flex-1 flex-row flex-wrap" >
                        <div className='flex-1 p-2' >
                            <img
                                src={facebook}
                                className='btn-redes-sociales'
                                onClick={e=>shareFacebook()} >
                                </img>
                        </div>
                        <div className='flex-1 p-2' >
                            <img
                                src={twitter}
                                className='btn-redes-sociales'
                                onClick={e=>shareTwitter(props.urlBadge)} ></img>
                        </div>
                        <div className='flex-1 p-2' >
                            <img
                                src={linkedin}
                                className='btn-redes-sociales'
                                onClick={e=>shareLinkedin(props.urlBadge)} ></img>
                        </div>
                        <div className='flex-1 p-2' >
                            <img
                                src={pinterest}
                                className='btn-redes-sociales'
                                onClick={e=>sharePinterest(props.urlBadge)} ></img>
                        </div>
                    </div>
                    <div className='d-flex flex-1 flex-column mt-4'>
                        <span className="text-sm azul-gris-oscuro regular mb-2" >
                            {props.t('Copiar link a compartir')}
                        </span>
                        <textarea rows='2' value={link} onChange={e=> e.preventDefault()} />
                    </div>
                    <div className='d-flex flex-1 flex-column mt-4'>
                        <span className="text-sm azul-gris-oscuro regular mb-2" >
                            {props.t('Incorporar Html')}
                        </span>
                        <textarea rows='4' value={getHtml()} onChange={e=> e.preventDefault()} />
                    </div>

                </div>
            </Card>

        </Modal>
    )
}

export default withTranslation()(Compartir);
