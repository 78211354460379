import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { PDFViewer, Document, Page, Text, View, Image, PDFDownloadLink } from "@react-pdf/renderer";
import { api } from "api";
import DocPDF from './DocPDF';
import './style.css'
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { EncabezadoAll, FondoPadre, Contenedor, Bloque, BloqueNombre, Linea, PreguntasHTML, TextoPreguntas } from './Componetes';



const DescargarResultado = ({ match, me, loader, t }) => {
    const [respuesta, setRespuesta] = useState({
        respuestas: [],
        results: []
    })

    const [evaluacion, setEvaluacion] = useState({})

    const asyncFunc = async () => {
        const respuesta_res = await api.get(`evaluacion/${match.params.id}/descargar_resultado`)
        setRespuesta(respuesta_res);
    }

    const leerEvaluacion = async () => {
        const respuesta_res = await api.get(`evaluacion/${match.params.id}`)
        setEvaluacion(respuesta_res);
    }

    useEffect(() => {
        asyncFunc();
        leerEvaluacion();
    }, [])

    function detectMob() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
    
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    if (detectMob()) {
        return (
            <LoadMask loading={loader} blur>
            {respuesta.respuestas.length ? (
                <div className='row justify-content-center align-items-center minh-100'>
                    <PDFDownloadLink
                        document={
                            <DocPDF
                                fondoPadre={FondoPadre}
                                encabezadoAll={EncabezadoAll}
                                contenedor={Contenedor}
                                respuesta={respuesta}
                                evaluacion={evaluacion}
                                bloque={Bloque}
                                bloqueNombre={BloqueNombre}
                                textoPreguntas={TextoPreguntas}
                                linea={Linea}
                                preguntasHTML={PreguntasHTML}
                                t={t}
                                me={me}
                            />
                        }
                    >
                        
                            <button className='btn btn-option-one-fill'>
                                {t('Descargar')}
                            </button>
                    </PDFDownloadLink>
                </div>
            ):(
                <div className='row justify-content-center align-items-center minh-100'>
                    <button className='btn btn-option-one-fill' disabled={true}>
                        {t('Cargando...')}
                    </button>
                </div>
            )}
            </LoadMask>
        )
    }else{
        return (
            <LoadMask loading={loader} blur>
                <PDFViewer style={{ width: "100%", height: "90vh" }}>
                    <DocPDF
                        fondoPadre={FondoPadre}
                        encabezadoAll={EncabezadoAll}
                        contenedor={Contenedor}
                        respuesta={respuesta}
                        evaluacion={evaluacion}
                        bloque={Bloque}
                        bloqueNombre={BloqueNombre}
                        textoPreguntas={TextoPreguntas}
                        linea={Linea}
                        preguntasHTML={PreguntasHTML}
                        t={t}
                        me={me}
                    />
                </PDFViewer>
            </LoadMask>
        )
    }
}

export default withTranslation()(DescargarResultado)
