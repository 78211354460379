import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/publicaciones/beneficios';
import detalle from './detalle';


const ms2p = (state) => {
    return {
        ...state.beneficios,
        me:state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(detalle);
