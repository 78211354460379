import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";

const avatar = require("../../../../../../assets/img/avatar.png")

const ModalColaboradores = (props) => {

    const { id, item, cerrar, setItem } = props;

    return (
        <div style={{ width: "45vw" }} className="d-flex flex-column flex-1 w-100-responsivo">
            <div className="d-flex flex-row borde-bottom px-3 py-1 flex-1 pt-3">
                <span className="text-sm azul-gris-oscuro">{props.t('Selecciona el responsable')}</span>
            </div>
            <div className="d-flex flex-column flex-1 my-3 px-3">
                <div className="d-flex flex-md-row flex-column flex-1 align-items-md-center mb-2 pl-1">
                    <div className="flex-1">
                        <img style={{width: "2rem", height: "2rem"}} className="logo-tabla" src={item ? item.empresa.logo : avatar }/>
                        <span className="text-sm bold azul-gris-oscuro ml-3">{item ? item.empresa.nombre : ''}</span>
                    </div>
                </div>
                <Grid hover striped data={{count: 0, results: item ? item.colaboradores : []}} loading={false} pagination={false} >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataAlign="center"
                        dataFormat={(cell, row) => (
                            <div className="d-flex justify-content-center">
                                <Checkbox checked={row.responsable} onChange={() => props.asignarResponsable(id, row, cerrar)}></Checkbox>
                            </div>
                        )}
                    >
                        {props.t('Acciones')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="usuario"
                        dataFormat={ cell => cell ? cell.nombres : ''}
                    >
                        {props.t('Colaboradores')}
                    </TableHeaderColumn>
                </Grid>
            </div>
            <div className="d-none d-lg-flex buttons-box my-4">
                <button type="button" onClick={cerrar} className="btn btn-secondary">{props.t('Regresar')}</button>
            </div>
            <div className="d-flex d-lg-none flex-column my-4">
                <button type="button" onClick={cerrar} className="btn btn-secondary flex-1">{props.t('Regresar')}</button>
            </div>
        </div>
    )
}

export default ModalColaboradores;
