import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import {initialize as initializeForm} from "redux-form";
import { NotificationManager } from "react-notifications";
import { HOMBRE, SPONSOR } from '../../../utility/constants';
import { COUNTRIES } from '../../../utility/paises';
import moment from 'moment';
import _ from 'lodash';
import { api } from "api";
import { getI18n } from 'react-i18next';

const SUBMIT = 'REGISTER_SUBMIT';
const LOADER = 'REGISTER_LOADER';
const TIPO_USUARIO = "RESTER_TIPO_USUARIO"

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});
export const setTipo = tipo_usuario => ({
    type: TIPO_USUARIO,
    tipo_usuario,
});
// ------------------------------------
// Actions
// ------------------------------------

const inscribir = (id, data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments(`amigos_growmotor/${id}/inscripcion`, data, attachments).then(() => {
        NotificationManager.success(getI18n().t('Se ha registrado la empresa, ahora inicia sesión con el usuario que acabas de crear'), getI18n().t('Éxito'), 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        localStorage.removeItem('token');
        dispatch(push('/login'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al registrar la empresa');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`amigos_growmotor/${id}`).then((response) => {
        if(response.is_active){
            dispatch(push('/login'));
        }
        const data = {}
        data.nombre = response.empresa_nombre;
        data.usuario={};
        data.empresa= response.empresa;
        data.usuario.genero = HOMBRE;
        data.usuario.nombres= response.nombres;
        data.usuario.email = response.email;
        dispatch(initializeForm('register', data));
        dispatch(setTipo(response.tipo_usuario))
    }).catch(() => {
        dispatch(push('/login'))
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('register', {}));
};


export const actions = {
    inscribir,
    logOut,
    leer,
    cleanForm,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [TIPO_USUARIO]: (state, { tipo_usuario }) => {
        return {
            ...state,
            tipo_usuario,
        };
    },
};

export const initialState = {
    loader: false,
    tipo_usuario: SPONSOR,

};

export default handleActions(reducers, initialState);
