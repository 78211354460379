import React from 'react';
import { withTranslation } from 'react-i18next';
import CardBeneficios from '../../../Utils/Cards/CardBeneficios';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { Tabs } from 'antd';
import TabTwo from './TabTwo';
import { SPONSOR, PARTNER } from '../../../../../utility/constants';

class Detalle extends React.Component{

    componentDidMount=()=>{
        const { me }= this.props;
        const id = this.props.match.params.id;
        this.props.leer(id);
        if (me.tipo_usuario===SPONSOR||me.tipo_usuario===PARTNER){
            this.props.listarInteresados(id);
        }
    }


    render(){
        const { item, loader, data_interesados } =this.props;
        return(
            <div className="social-layout mb-4 col-12 mt-0" >
                <LoadMask loading={loader} blur>
                {(this.props.me&&(this.props.me.tipo_usuario===SPONSOR||this.props.me.tipo_usuario===PARTNER))?(
                    <Tabs
                            defaultActiveKey="detalle"
                            onChange={this.callback}
                            tabPosition="top"
                        >
                            <Tabs.TabPane tab={this.props.t('Publicación')} key='publicacion'>
                                {(item&&item.id)&&(
                                    <CardBeneficios id={item.id} item={item} me={this.props.me}
                                        aprobar={this.props.aprobar} eliminar={this.props.eliminar}
                                        editar={this.props.editar} meInteresa={this.props.meInteresa}
                                        detalle={true} meGusta={this.props.meGusta} 
                                        removerMeGusta={this.props.removerMeGusta}
                                        listarMeGustas={this.props.listarMeGustas}
                                    />)}
                            </Tabs.TabPane>
                        <Tabs.TabPane tab={this.props.t('Mas información')} key='informacion'>
                            <TabTwo
                                {...this.props}
                                interesados={this.props.data_interesados}
                                id={this.props.match.params.id}
                            />
                        </Tabs.TabPane>
                        </Tabs>

                ):(
                    <React.Fragment>
                        {(item&&item.id)&&(
                            <CardBeneficios id={item.id} item={item} me={this.props.me}
                                aprobar={this.props.aprobar} eliminar={this.props.eliminar}
                                editar={this.props.editar} meInteresa={this.props.meInteresa}
                                detalle={true} meGusta={this.props.meGusta} 
                                removerMeGusta={this.props.removerMeGusta}
                                listarMeGustas={this.props.listarMeGustas}
                            />)}

                    </React.Fragment>
                )}
                </LoadMask>
            </div>
        )
    }
}


export default withTranslation()(Detalle);
