import React from 'react';
import { connect } from 'react-redux';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderNumber, renderFieldRadio, renderMonthPicker, renderFieldCheck, SelectField } from '../../../Utils/renderField/renderField';
import { RenderCurrency, RenderNumber } from '../../../Utils/renderField/renderReadField';
import { getDescuento } from '../../../../../utility/constants';
import moment from 'moment';


let PagoMembresiaForm = (props) => {

    const { handleSubmit, item, cantidad, data_tarjetas } = props;
    const fecha = moment.utc(props.getFechaNuevaVencimiento(cantidad));

    const getTotal = () => {
        const descuento = getDescuento(cantidad);
        const precio_membresia = item.cohorte ? item.cohorte.precio_membresia : 0;

        const dias_año = moment("12-31", "MM-DD").dayOfYear();
        const ultimo_dia =  moment(item.cohorte ? item.cohorte.fecha_fin : '2020-01-01').dayOfYear();

        if (cantidad < 1) {
            return 0
        }
        if (item.ultimo_pago) {
            let total = precio_membresia * cantidad;
            total = total - (total * descuento);
            return parseFloat(Math.ceil((total + Number.EPSILON) * 100) / 100);
        } else {
            const costo_dia = (precio_membresia - (precio_membresia * descuento)) / dias_año;
            const dias_restantes = dias_año - ultimo_dia;
            const total_year = costo_dia * dias_restantes;
            let total = precio_membresia * (cantidad - 1);

            total = total - (total * descuento) + total_year;
            return parseFloat(Math.ceil((total + Number.EPSILON) * 100) / 100);
        }
    }

    const logOut = (props) => {
        props.logOut()
        props.history.push('/login')
    }

    return (
        <form onSubmit={handleSubmit} className="form-validate mb-lg">
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-row flex-1 borde-bottom mb-3">
                    <span className="text-sm bold azul-gris-oscuro">{props.t('Datos membresia')}</span>
                </div>
                <div className="d-flex flex-row flex-1 mb-3">
                    <span className="text-sm gris">
                        {props.t('El costo de la licencia del primer año será calculado dependiendo de lo que resta del año, el resto de licencias siempre cobrarán el año completo')+'.'}
                    </span>
                </div>
                <div className="d-flex flex-md-row flex-column flex-1">
                    <div className="d-flex flex-column justify-content-center flex-1 mb-3 my-md-0">
                        <label htmlFor="cantidad" className="m-0">{props.t('Ingrese el número de años deseado')}</label>
                        <Field name="cantidad" component={renderNumber} suffix={` ${props.t('años')}`} className="form-control" />
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-md-center align-items-start flex-1 my-1 my-md-0">
                        <label htmlFor="nombre" className="m-0 p-0">{props.t('Descuento de')}</label>
                        <RenderNumber suffix=" %" value={getDescuento(cantidad) * 100} className="text-sm bold celeste-oscuro" />
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-md-center align-items-start flex-1 my-1 my-md-0">
                        <label htmlFor="nombre" className="m-0 p-0">{props.t('Total')}</label>
                        <RenderCurrency prefix="USD " value={getTotal()} className="text-sm bold celeste-oscuro" />
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-md-center align-items-start flex-1 my-1 my-md-0">
                        <label htmlFor="nombre" className="m-0 p-0">{props.t('Nueva fecha de vencimiento')}</label>
                        <span className="text-sm bold celeste-oscuro">{`${fecha.format('MMM-DD-YYYY hh:mm a z')}`}</span>
                    </div>
                </div>
                <div className="d-flex flex-row flex-1 borde-bottom mb-3 mt-4">
                    <span className="text-sm bold azul-gris-oscuro">{props.t('Datos de la tarjeta')}</span>
                </div>

                {(data_tarjetas.data.length) ? (
                    <div className="d-flex flex-md-row flex-column flex-1 mb-3">
                        <div className="d-flex flex-row align-items-center flex-1">
                            <Field
                                name="tarjeta"
                                component={renderFieldRadio}
                                type="radio"
                                label={props.t('Tarjetas guardadas')}
                                value={true}
                                parse={val => !!val}
                            />
                            <Field
                                name="tarjeta"
                                component={renderFieldRadio}
                                type="radio"
                                label={props.t('Tarjeta nueva')}
                                value={false}
                                parse={val => !val}
                            />
                        </div>
                    </div>
                ) : (<div></div>) }

                {(props.tarjeta && data_tarjetas.data.length) && (
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="tarjeta_id" className="m-0">{props.t('Seleccione la tarjeta')}</label>
                            <Field
                                name="tarjeta_id"
                                component={SelectField}
                                options={data_tarjetas.data}
                                valueKey="id"
                                labelKey="last4"
                                totalOption={true}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="d-flex flex-column flex-1 p-2">
                        </div>
                    </div>
                )}

                {(props.tarjeta) ? (
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="correo_admin" className="m-0">{props.t('Tipo tarjeta')}</label>
                            <span className="gris-oscuro ml-2">{props.tarjeta_id ? props.tarjeta_id.brand : ''}</span>
                        </div>
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="correo_admin" className="m-0">{props.t('Número de tarjeta')}</label>
                            <span className="gris-oscuro ml-2">{props.tarjeta_id ? `**** **** **** ${props.tarjeta_id.last4}` : ''}</span>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="numero_tarjeta" className="m-0">{props.t('Número de la tarjeta')}</label>
                            <Field
                                name="numero_tarjeta"
                                component={renderNumber}
                                type="text"
                                className="form-control"
                                numberFormat="#### #### #### ####"
                                placeholder="#### #### #### ####"
                            />
                        </div>
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="codigo_tarjeta" className="m-0">{props.t('Código de seguridad (CVC)')}</label>
                            <Field
                                name="codigo_tarjeta"
                                component={renderNumber}
                                type="number"
                                className="form-control"
                                numberFormat="####"
                                placeholder="CVC"
                            />
                        </div>
                    </div>
                )}

                {(props.tarjeta) ? (
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="correo_admin" className="m-0">{props.t('Fecha de expiración Mes/ año')}</label>
                            <span className="gris-oscuro ml-2">{props.tarjeta_id ? `${props.tarjeta_id.exp_month}/${props.tarjeta_id.exp_year}` : ''}</span>
                        </div>
                        <div className="d-flex flex-column flex-1 p-2"></div>
                    </div>
                ) : (
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="fecha_tarjeta" className="m-0">{props.t('Fecha de expiración (Mes / año)')}</label>
                            <Field name="fecha_tarjeta" component={renderMonthPicker} type="text" />
                        </div>
                        <div className="d-flex flex-column justify-content-end flex-1 p-2">
                            <Field
                                name="guardar_tarjeta"
                                label={props.t('Guardar datos de la tarjeta')}
                                component={renderFieldCheck}
                            />
                        </div>
                    </div>
                )}

                <div className="d-none d-lg-flex buttons-box mt-4">
                    <button type="submit" className="btn btn-primary">{props.t('Pagar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <button type="submit" className="btn btn-primary flex-1">{props.t('Pagar')}</button>
                </div>

                <div className="d-none d-lg-flex buttons-box mt-2">
                    <button onClick={() => logOut(props)} className="btn btn-secondary">{props.t('Salir')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-2">
                    <button onClick={() => logOut(props)} className="btn btn-secondary flex-1">{props.t('Salir')}</button>
                </div>
            </div>
        </form>
    )

}

const selector = formValueSelector('PagoMembresiaForm');

PagoMembresiaForm = reduxForm({
    form: 'PagoMembresiaForm',
    asyncBlurFields: [],
    validate: (data, props) => {
        return validate(data, {
            'cantidad': validators.exists()(`${props.t('Campo requerido')}`),
            'tarjeta_id': validators.exists()(`${props.t('Campo requerido')}`),
            'numero_tarjeta': validators.exists()(`${props.t('Campo requerido')}`),
            'codigo_tarjeta': validators.exists()(`${props.t('Campo requerido')}`),
            'fecha_tarjeta': validators.exists()(`${props.t('Campo requerido')}`),
        })
    },
})(PagoMembresiaForm);

const mstp = state => {
    const cantidad = selector(state, 'cantidad');
    const tarjeta = selector(state, 'tarjeta');
    const tarjeta_id = selector(state, 'tarjeta_id');

    return {
        cantidad,
        tarjeta,
        tarjeta_id,
    }
};

export default connect(mstp, null)(PagoMembresiaForm);
