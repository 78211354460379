import { Image, View, Text } from '@react-pdf/renderer'
import React from 'react'
const Logo = require("assets/header/Logo.png");
const active = require("assets/img/active.png");
const deactive = require("assets/img/deactive.png");

const encabezado = {backgroundColor:'#19233e', width:'100%', height:'50px'}
const imagen = {width: '100px', marginLeft:'22', marginTop:'10'}
const subEncabezado = {backgroundColor:'#596485', width:'100%', height:'200px'}

let tranlation = (text) => {return text}

export const FondoPadre = {backgroundColor: "#dad6d7", fontSize:'18px', position:'relative',  paddingBottom: 22, borderRadius:'10px'}

export const Contenedor ={backgroundColor:'#ffffff', width:'550px', minHeight:'738px', position:'absolute', top:'80', left:'22', borderRadius:'10px'}

export const Bloque = {color: '#444444', fontSize: '13px'}

export const BloqueNombre = {color: '#5F5F5F', fontSize: '13px'}

export const Linea = {height:'3px', backgroundColor: '#8C8F9B', width: '100%', marginTop:'2px', marginBottom:'2px'}

export const TextoPreguntas = {color: '#1446A0', fontSize:'12px', fontWeight: '500px', marginTop:'20px'}

export const EncabezadoAll = (
  <View fixed>
      <View style={encabezado}>
          <Image style={imagen} src={Logo}></Image>
      </View>
      <View style={subEncabezado}></View>
  </View>
)

export const Componetes = () => {
  return (
    <div>Estilos</div>
  )
}


const pregunta1 = (val) => {
  return (
      <View style={{flexDirection: 'row', fontSize: '10px', marginRight:'40px'}}>
          <Image
              src={val}
              style={{width: '15px', marginRight: '2px'}}
          ></Image>
          <Text>{tranlation('En desacuerdo')}</Text>
      </View>
  )
}

const pregunta2 = (val) => {
  return (
      <View style={{flexDirection: 'row', fontSize: '10px', marginRight:'40px'}}>
          <Image
              src={val}
              style={{width: '15px', marginRight: '2px'}}
          ></Image>
          <Text>{tranlation('Algo en desacuerdo')}</Text>
      </View>
  )
}

const pregunta3 = (val) =>{
  return (
      <View style={{flexDirection: 'row', fontSize: '10px', marginRight:'40px'}}>
          <Image
              src={val}
              style={{width: '15px', marginRight: '2px'}}
          ></Image>
          <Text>{tranlation('Algo de acuerdo')}</Text>
      </View>
  )
}

const pregunta4 = (val) => {
  return (
      <View style={{flexDirection: 'row', fontSize: '10px'}}>
          <Image
              src={val}
              style={{width: '15px', marginRight: '2px'}}
          ></Image>
          <Text>{tranlation('De acuerdo')}</Text>
      </View>
  )
}


export const PreguntasHTML = (val, index, t=text => text) => {
  if (t) tranlation = t
  return(
  <React.Fragment>
      { val === 0 && (
          <View key={index} style={{paddingTop: '5px', flexDirection: 'row', margin:'auto', color:'#363C3F'}} wrap={false}>
              {pregunta1(active)}
              {pregunta2(deactive)}
              {pregunta3(deactive)}
              {pregunta4(deactive)}
          </View>
      )}

      { val === 1 && (
          <View key={index} style={{paddingTop: '5px', flexDirection: 'row', margin:'auto', color:'#363C3F'}} wrap={false}>
              {pregunta1(deactive)}
              {pregunta2(active)}
              {pregunta3(deactive)}
              {pregunta4(deactive)}
          </View>
      )}

      { val === 2 && (
          <View key={index} style={{paddingTop: '5px', flexDirection: 'row', margin:'auto', color:'#363C3F'}} wrap={false}>
              {pregunta1(deactive)}
              {pregunta2(deactive)}
              {pregunta3(active)}
              {pregunta4(deactive)}
          </View>
      )}

      { val === 3 && (
          <View key={index} style={{paddingTop: '5px', flexDirection: 'row', margin:'auto', color:'#363C3F'}} wrap={false}>
              {pregunta1(deactive)}
              {pregunta2(deactive)}
              {pregunta3(deactive)}
              {pregunta4(active)}
          </View>
      )}
  </React.Fragment>)
}