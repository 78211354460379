import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { actions } from '../../../../redux/modules/cuenta/buscador';
import { ADMIN, ENCUENTRO_GUERREROS, FAMILY_MEETING, GROWTH_ANGEL, HISTORIAS, INTERCAMBIO, MENTORIA, TALLER, BENEFICIO, USUARIO } from '../../../../utility/constants';
import moment from 'moment';
import { withTranslation } from 'react-i18next'
import i18n from '../../../../i18nextInit'

const avatar = require('../../../../../assets/img/avatar.png');
const taller = require('../../../../../assets/img/iconos/taller.png');
const mentoria = require('../../../../../assets/img/iconos/mentoria.png')
const guerreros = require('../../../../../assets/img/iconos/guerreros.png')
const familia = require('../../../../../assets/img/iconos/familia.png')
const intercambios = require('../../../../../assets/img/iconos/intercambios.png')
const beneficios = require('../../../../../assets/img/iconos/beneficios.png')
const growgits = require('../../../../../assets/img/iconos/growgits.png')

const ResultadosBuscador = (props) => {

    const getIcono = (tipo) => {
        if (tipo == TALLER) return taller;
        if (tipo == MENTORIA) return mentoria;
        if (tipo == ENCUENTRO_GUERREROS) return guerreros;
        if (tipo == FAMILY_MEETING) return familia;
        if (tipo == INTERCAMBIO) return intercambios;
        if (tipo == BENEFICIO) return beneficios;
        return growgits;
    }

    const getLinkPublicacion = (tipo) => {
        if (tipo == TALLER) return 'taller';
        if (tipo == MENTORIA) return 'mentoria';
        if (tipo == ENCUENTRO_GUERREROS) return 'panel';
        if (tipo == FAMILY_MEETING) return 'family_meeting';
        if (tipo == INTERCAMBIO) return 'intercambio';
        if (tipo == BENEFICIO) return 'beneficio';
        return 'historia';
    }

    const getTotalPrevio = () => {
        let total = 0;
        total += props.previos.usuarios.length;
        total += props.previos.empresas.length;
        total += props.previos.publicaciones.length;
        return total;
    }

    const getDescription = (descripcion=undefined)=>{
        const div = document.createElement("div");
        div.innerHTML = descripcion ? descripcion : ''
        const texto = String(div.textContent);
        return texto.substring(0, 50)
    }

    
    const lang = i18n.language ? i18n.language : 'en'

    return (
        <div className="resultados-buscador pt-3 px-2">
            <span className="text-xsm gris">{`${props.t('Mostrando')} ${getTotalPrevio()} ${props.t('resultados')}`}</span>
            <div className="d-flex flex-column flex-1 my-2">
                <span className="text-sm bold azul-gris-oscuro ml-4">{props.t('Personas')}</span>
                {props.previos.usuarios.map((item, index) => (
                    <Link key={index} to={`/perfil/${item.id}`} className="d-flex flex-row borde-bottom flex-1 py-2">
                        <div className="d-flex align-items-center justify-content-center flex-1">
                            <img className="logo-tabla" src={item.avatar ? item.avatar : avatar} />
                        </div>
                        <div className="d-flex flex-column justify-content-center flex-4">
                            <div className="d-flex flex-row align-items-end">
                                <span className="text-sm bold gris-oscuro mr-2">{item.nombres}</span>
                                {(item.tipo_usuario == ADMIN) && (
                                    <span className="text-xs gris">{props.t('Administrador')}</span>
                                )}
                                {(item.tipo_usuario == GROWTH_ANGEL) && (
                                    <span className="text-xs gris">{props.t('Growth Angel')}</span>
                                )}
                                {(item.tipo_usuario == USUARIO && item.cargo && item.empresa_nombre) && (
                                    <span className="text-xs gris">{`${item.cargo} ${props.t('de')} ${item.empresa_nombre}`}</span>
                                )}
                            </div>
                            <span className="text-xs gris">{item.pais}</span>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="d-flex flex-column flex-1 my-2">
                <span className="text-sm bold azul-gris-oscuro ml-4">{props.t('Empresas')}</span>
                {props.previos.empresas.map((item, index) => (
                    <Link key={index} to={`/empresa/${item.id}`} className="d-flex flex-row borde-bottom flex-1 py-2">
                        <div className="d-flex align-items-center justify-content-center flex-1">
                            <img className="logo-tabla" src={item.logo ? item.logo : avatar} />
                        </div>
                        <div className="d-flex flex-column justify-content-center flex-4">
                            <div className="d-flex flex-row align-items-end">
                                <span className="text-sm bold gris-oscuro">{item.nombre}</span>
                            </div>
                            <span className="text-xs gris">{item.pais}</span>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="d-flex flex-column flex-1 my-2">
                <span className="text-sm bold azul-gris-oscuro ml-4">{props.t('Publicaciones')}</span>
                {props.previos.publicaciones.map((item, index) => (
                    <Link key={index} to={`/${getLinkPublicacion(item.tipo_publicacion)}/${item.id}`} className="d-flex flex-row borde-bottom flex-1 py-2">
                        <div className="d-flex align-items-center justify-content-center flex-1">
                            <img style={{width: "1.8rem"}} src={getIcono(item.tipo_publicacion)} />
                        </div>
                        <div className="d-flex flex-column justify-content-center flex-4">
                            <div className="d-flex flex-row align-items-center flex-1">
                                {(item.tipo_publicacion == HISTORIAS || item.tipo_publicacion == INTERCAMBIO || item.tipo_publicacion == BENEFICIO) ? (
                                    <span className="text-sm bold gris-oscuro">{item.publicado_por ? item.publicado_por.nombres : props.t('Publicación')}</span>
                                ) : (
                                    <span className="text-sm bold gris-oscuro">{item.titulo}</span>
                                )}
                                <span className="text-sm bold gris-oscuro">&nbsp;/&nbsp;{moment(item.creado).locale(lang).fromNow()}</span>
                            </div>
                            <span style={{width: "15rem"}} className="texto-cortado text-xs gris">{getDescription(item.descripcion)}</span>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="d-flex flex-1 justify-content-center align-items-center">
                <Link className="azul text-xs mb-2 d-flex flex-1 justify-content-center" to="/busquedas">{props.t('Ver todos los resultados')}</Link>
            </div>
        </div>
    )
}

class SearchInput extends Component {

    state = {
        focusSearch: false,
        focusPanel: false,
    }

    constructor(props) {
        super(props);
        this.buscador = React.createRef();
    }

    componentDidMount() {
        this.props.resultadosPrevios('');
    }

    onBlur = () => {
        setTimeout(()=> {
            this.setState({focusSearch: false});
        }, 300)
    }

    render() {
        const { search, resultadosPrevios, searchChange, placeholder } = this.props;
        return (
            <div className="inner-addon left-addon" style={{width: '100%'}}>
                <i className="glyphicon material-icons">search</i>
                <input
                    // onClick
                    className="form-control"
                    value=""
                    id="buscador"
                    type="text"
                    placeholder={this.props.t("Buscar en Growmotor")+"..."}
                    value={search ? search : ''}
                    ref={this.buscador}
                    onChange={(value) => {
                        resultadosPrevios(this.buscador.current.value);
                    }}
                    onFocus={() => this.setState({focusSearch: true})}
                    onBlur={this.onBlur}
                    autoComplete="off"
                />
                {(this.state.focusSearch || this.state.focusPanel) && (
                    <ResultadosBuscador {...this.props} />
                )}
            </div>
        );
    }
}

const ms2p = (state) => {
    return {
        ...state.buscador,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(withTranslation()(SearchInput));
