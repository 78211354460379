import React, { Component } from "react";
import { Fragment } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import CardBeneficios from "../../../Utils/Cards/CardBeneficios";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import Select from 'react-select'
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

const options = [
    { value: 'Aprobados', label: 'Aprobados' },
    { value: 'Pendientes', label: 'Pendientes' },
    { value: 'Todos', label: 'Todos' }
]

import BeneficiosForm from "../beneficiosForm";
import { ADMIN, BENEFICIO, SPONSOR, PARTNER } from '../../../../../utility/constants';
import { Field } from "redux-form";

class ListaGeneral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            expandir: false,
            editar:false,
            imagen: null,
            progress: {
                conteo: 0,
                porcentaje: 0,
            },
            srcImagen:null,
            archivos:[],
        };
    }
    componentWillMount=()=> {
        const { listar, match, leer, item } = this.props;
        listar(1);
    }
    onExpandir = () => {
        this.setState({ expandir: !this.state.expandir });
    };
    onSubmit = (values) => {

        const { match } = this.props;
        if (!this.state.expandir) {
            this.setState({ expandir: true });
        } else {
            this.setState({ expandir: false });
            const body = { ...values };
            body.tipo_publicacion = BENEFICIO;
            const array = [];
            if (body.cohorte){
                body.cohorte = values.cohorte.id;
            }
            if (body.fecha_fin){
                body.fecha_fin = moment(values.fecha_fin).format();
            }
            delete body.fecha_limite
            if(this.state.imagen&&this.state.imagen.file){
                array.push({ file: this.state.imagen.file, name: "imagen" });
            }else{
                array.push({ file: null, name: "imagen" });
            }

            this.state.archivos.forEach((file, index)=>{
                if(file != null){
                    array.push({"file": file, "name": `archivo-${index}`});
                }
            })

            this.props.onSubmit(body, array);
            this.setState({imagen:null});
        }
    };
    setArchivos = (files)=>{
        const archivos = this.state.archivos;
        for (let index = 0; index < files.length; index++) {
            archivos.push(files[index]);
        }
        this.setState({archivos});
        this.props.setArchivos(files);
    }
    eliminarArchivo = (index)=>{
        let archivos = this.state.archivos;
        archivos = _.filter(archivos, (item, i) => i != index );
        this.setState({ archivos });
        this.props.eliminarArchivo(index);
    }
    removeImagen=()=>{
        this.setState({imagen:null})
    }
    setFiltro = (filtro)=>{
        if(filtro==='Aprobados') this.props.listar(1,{valida:true})
        if(filtro==='Pendientes') this.props.listar(1,{valida:false})
        if(filtro==='Todos') this.props.listar()
    }
    setProgress = (conteo, porcentaje) => {
        if (conteo && porcentaje) {
            this.setState({ progress: { conteo, porcentaje } });
        }
    };
    setImagen = (imagen) => {
        this.setState({ imagen });
    };
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    render() {
        const { data, loader, listar, page, match, me } = this.props;
        return (
            <div className="social-layout mb-4 col-12 mt-0">
                <LoadMask loading={loader} blur fixed>
                    <InfiniteScroll
                        dataLength={data.results.length} //This is important field to render the next data
                        next={() => listar(page + 1, null , false)}
                        hasMore={true}
                        loader={<LoadMask loading={loader} blur></LoadMask>}
                        endMessage={
                            <div className="d-flex flex-row flex-1 justify-content-center">
                                {(data.count) ? (
                                    <span className="text-md gris-oscuro regular text-center">
                                        {this.props.t('No cuenta con más beneficios')}
                                    </span>
                                ) : (
                                    <span style={{marginTop: "20%"}} className="text-md gris-oscuro regular text-center">
                                        {this.props.t('No cuenta con beneficios actualmente')}
                                    </span>
                                )}
                            </div>
                        }
                        // below props only if you need pull down functionality
                        refreshFunction={async () => {
                            await listar(1);
                            return true;
                        }}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={100}
                        pullDownToRefreshContent={
                            <Fragment>
                                <h3 className="text-md blanco regular text-center" style={{ textAlign: 'center' }}>
                                    <i className="fas fa-chevron-circle-down blanco mr-2" />
                                    {this.props.t('Deslizar para cargar')}
                                </h3>
                            </Fragment>
                        }
                        releaseToRefreshContent={
                            <Fragment>
                                <h3 className="text-md blanco regular text-center" style={{ textAlign: 'center' }}>
                                    <i className="fas fa-chevron-circle-up blanco mr-2" />
                                    {this.props.t('Suelte para cargar')}
                                </h3>
                            </Fragment>
                        }
                        hasMore={data.next}
                    >
                        <div
                            key={
                                match.params.id
                                    ? match.params.id
                                    : "historiaForm"
                            }>
                            {
                                this.props.me && this.props.me.tipo_usuario === ADMIN &&(
                                    <div key="filtro" className="d-flex p-2">
                                        <div className="d-flex flex-2"/>
                                        <div className="d-flex flex-1 justify-content-end">
                                            <Select options={options} className="w-100" onChange={(e)=> this.setFiltro(e.value)} placeholder="Filtrar por" />
                                        </div>
                                    </div>

                                )
                            }
                            {
                                (me&&(me.tipo_usuario===SPONSOR||me.tipo_usuario===PARTNER))&&(
                                    <BeneficiosForm
                                        expandir={this.state.expandir}
                                        onSubmit={this.onSubmit}
                                        imagen={this.state.imagen}
                                        setImagen={this.setImagen}
                                        progress={this.state.progress}
                                        setProgress={this.setProgress}
                                        onExpandir={this.onExpandir}
                                        editar={this.state.editar}
                                        item={this.props.item}
                                        openMoldal={this.onOpenModal}
                                        srcImagen={this.state.srcImagen}
                                        removeImagen={this.removeImagen}
                                        me={this.props.me}
                                        t={this.props.t}
                                        setArchivos={this.setArchivos}
                                        eliminarArchivo={this.eliminarArchivo}

                                    />

                                )
                            }
                        </div>
                            {
                                data.results.length ? (
                                    data.results.map((item) => (
                                    <div key={item.id}>
                                        <CardBeneficios id={item.id} item={item} me={this.props.me}
                                            aprobar={this.props.aprobar} eliminar={this.props.eliminar}
                                            editar={this.props.editar} meInteresa={this.props.meInteresa}
                                            verMas={true} meGusta={this.props.meGusta}
                                            removerMeGusta={this.props.removerMeGusta}
                                            listarMeGustas={this.props.listarMeGustas}
                                            />
                                    </div>
                            ))
                                ):( null)
                            }
                    </InfiniteScroll>
                </LoadMask>
            </div>
        );
    }
}

export default withTranslation()(ListaGeneral);
