import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { actions } from '../../../../../redux/modules/evaluaciones';
import DescargarResultado from './DescargarResultado';

const ms2p = (state) => {
    return {
        ...state.evaluaciones,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(withRouter(DescargarResultado));
