import React,  {Component} from "react";
import PropTypes from 'prop-types';
import './FileImageUploader.css';


class FileUploader extends Component {
    static propTypes = {
        width: PropTypes.string.isRequired,
        height: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            imageSrc: '',
            loaded: false
        };

        this.onDragEnter  = this.onDragEnter.bind(this);
        this.onDragLeave  = this.onDragLeave.bind(this);
        this.onDrop       = this.onDrop.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }
    componentDidUpdate() {
        if (this.state.imageSrc === '' && this.props.source !== undefined && this.props.source !== null) {
            this.setState({imageSrc: this.props.source, loaded: true})
        }
    }

    componentWillMount(){
        if (this.props.img !== null && this.props.img !== undefined){
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: this.props.img,
                loaded: true,
                isImage: true,
            });
        }
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.img !== null && nextProps.img !== undefined){
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: nextProps.img,
                loaded: true
            });
        }
    }

    onDragEnter(e) {
        this.setState({ active: true });
    }

    onDragLeave(e) {
        this.setState({ active: false });
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDrop(e) {
        e.preventDefault();
        this.setState({ active: false });
        this.onFileChange(e, e.dataTransfer.files[0]);
    }

    onFileChange(e, file) {
        //this.props.onFileChange(e, file);
        file = file || e.target.files[0];
        const pattern = /-*/;
        const imagePattern = /image-*/;

        const reader = new FileReader();
        if (file){
            const isImage = !!file.type.match(imagePattern);
            if (!file.type.match(pattern)) {
                alert('Formato inválido');
                return;
            }

            this.setState({ loaded: false });

            reader.onload = (e) => {
                this.setState({
                    imageSrc: reader.result,
                    isImage,
                    loaded: true
                });
                this.props.onFileChange({file,...this.state});
            };
            reader.readAsDataURL(file);
        }
    }

    getFileObject() {
        return this.refs.input.files[0];
    }

    getFileString() {
        return this.state.imageSrc;
    }

    render() {
        let state = this.state;

        return (
            <label
                className={`uploaderImg ${this.props.className}`}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
                style={{width:`${this.props.width}`, height:`${this.props.height}`}}
                >
                <input disabled={this.props.disabled} type="file" accept="image/*" onChange={this.onFileChange} ref="input" />
                <img src={this.props.imageSrc} style={{width:`${this.props.width}`, height:`${this.props.height}`}}/>

            </label>
        );
    }
}
export default FileUploader
