import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import { renderNumber, renderMonthPicker, renderFieldCheck } from '../../../Utils/renderField/renderField';


const TarjetaForm = props => {
    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="numero_tarjeta" className="m-0">{props.t('Número de la tarjeta')}</label>
                        <Field
                            name="numero_tarjeta"
                            component={renderNumber}
                            type="text"
                            className="form-control"
                            numberFormat="#### #### #### ####"
                            placeholder="#### #### #### ####"
                        />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="codigo_tarjeta" className="m-0">{props.t('Código de seguridad (CVC)')}</label>
                        <Field
                            name="codigo_tarjeta"
                            component={renderNumber}
                            type="number"
                            className="form-control"
                            numberFormat="####"
                            placeholder="CVC"
                        />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="fecha_tarjeta" className="m-0">{props.t('Fecha de expiración (Mes / año)')}</label>
                        <Field name="fecha_tarjeta" component={renderMonthPicker} type="text" />
                    </div>
                    <div className="d-flex flex-column justify-content-end flex-1 p-2">
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/mis-tarjetas">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/mis-tarjetas">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )

}

export default reduxForm({
    form: 'TarjetaForm',
    asyncBlurFields: [],
    initialValues: {
        // fecha_fundacion: moment()
    },
    validate: (data, props) => {
        return validate(data, {
            'numero_tarjeta': validators.exists()(`${props.t('Campo requerido')}`),
            'codigo_tarjeta': validators.exists()(`${props.t('Campo requerido')}`),
            'fecha_tarjeta': validators.exists()(`${props.t('Campo requerido')}`),
        })
    },
})(withTranslation()(TarjetaForm));
